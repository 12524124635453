import React from 'react';
import s from './Textarea.module.css';
import {Controller} from "react-hook-form";
import cn from "classnames";


const Textarea = ({name, className, defaultValue, placeholder, rows = 5, cols = 4, ...props}) => {
  return (<Controller
    defaultValue={defaultValue}
    name={name}
    render={({field: {onChange: _onChange, value}}) => (<div className={cn([s.container, className])}>
        <textarea
          placeholder={placeholder}
          rows={rows}
          cols={cols}
          onChange={e => _onChange(e.target.value)}
          {...props}
        >
          {value}
        </textarea>
    </div>)}
  />);
};


export default Textarea;
