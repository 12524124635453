import React, {Fragment} from 'react';
import cs from '../CheckoutSummary.module.css';
import {useTranslation} from "react-i18next";
import CurrencyNumber from "../../../CurrencyNumber";
import useCheckoutProvider from "../../../../providers/CheckoutProvider/useCheckoutProvider";
import {HotelForPurchase, HotelForPurchaseRoom} from "../../../../types/hotels/hotels";
import {generateStars} from "../../../Hotels/util";
import HotelImage from "../../../Hotels/HotelImage/HotelImage";


interface HotelsSummaryProps {
  hotels: HotelForPurchase[]
}

const HotelsSummary = ({hotels}: HotelsSummaryProps) => {
  const {t} = useTranslation();
  const {setHotels, changeAdults, changeChildren} = useCheckoutProvider();

  const JoinRates = ({rooms}: { rooms: HotelForPurchaseRoom[] }) => {
    let taxes = 0
    let n: { [roomName: string]: { price: number, number: number, taxes: number } } = {};
    rooms.forEach(room => {
      if (!n[room.name]) n[room.name] = {price: 0, number: 0, taxes: 0};
      n[room.name].number++;
      n[room.name].price += room.rate.finalPrice;
      taxes += room.rate.taxes;
    });

    let result = [];
    for (let name in n) {
      result.push(<tr>
        <td className={cs.summary_table_title}>{name} X {n[name].number}</td>
        <td className={cs.summary_table_price}><CurrencyNumber>{n[name].price}</CurrencyNumber></td>
      </tr>);
    }


    if (taxes > 0) {
      result.push(<tr>
        <td className={cs.summary_table_title}>{t('hotel_rooms.taxes in hotel')}</td>
        <td className={cs.summary_table_price}><CurrencyNumber>{taxes}</CurrencyNumber></td>
      </tr>);
    }

    return <>{result}</>;
  }


  const removeHotel = (hotel: HotelForPurchase) => {
    if (!window.confirm(t('checkout.remove.hotel'))) return;

    let f_hotels = hotels.filter(h => h.code === hotel.code && h.api === hotel.api);

    if (f_hotels.length > 0) {
      let adults = 0;
      let children = 0;
      for (let i = 0; i < f_hotels.length; i++) {
        let rooms = f_hotels[i].rooms;
        if (Array.isArray(rooms)) {
          for (let j = 0; j < rooms.length; j++) {
            let room = rooms[j];
            adults += room.rate.adults * room.rate.rooms;
            children += room.rate.children * room.rate.rooms;
          }
        }
      }
      if (adults) changeAdults('remove', adults);
      if (children) changeChildren('remove', children);
    }

    setHotels(prev => prev.filter(h => !(h.code === hotel.code && h.api === hotel.api)));
  }

  if (!Array.isArray(hotels) || hotels.length === 0) return <></>

  return <div>
    <table>
      <tbody>
      <tr>
        <th colSpan={2} style={{textAlign: 'center', color: '#1C7263'}}>{t('checkout.hotels')}</th>
      </tr>
      {hotels.map((hotel, hotelN) => {
        return <Fragment key={hotelN}>
          <tr>
            <td colSpan={2}>
              <div className="flex mb-5">
                {/*
								<div className={s.closeIcon} onClick={() => removeHotel(hotel)}><AiOutlineCloseCircle size={18}/></div>
*/}
                <HotelImage hotel={hotel} imagePath={hotel.images[0].path} width={80} height={80}/>
                <div className="w-full ml-5">
                  <div className="font-bold text-2xl">{hotel.name}</div>
                  <div className="w-full flex flex-nowrap">{generateStars(hotel)}</div>
                </div>
              </div>
            </td>
          </tr>
          <JoinRates rooms={hotel.rooms}/>
        </Fragment>
      })}
      </tbody>
    </table>
  </div>
}

export default HotelsSummary;
