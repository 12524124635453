import {User} from "../../types/user";
import {UseFormReturn} from "react-hook-form";

export function addUserToForm(user: User, form: UseFormReturn) {

  form.setValue('persons.0.name', user.name);
  if (user.surname) form.setValue('persons.0.surname', user.surname);
  form.setValue('persons.0.email', user.email);
  if (user.phone) form.setValue('persons.0.phone', user.phone);
  if (user.gender) form.setValue('persons.0.gender', user.gender);
  if (user.nationality) form.setValue('persons.0.gender', user.nationality);

  // Todo: add birthday with correct format
  if (user.birthday) {
    form.setValue('persons.0.birthday', user.birthday)
  }


  form.setValue('email', user.email);
  form.setValue('emergency_contact.name', user.name);
  if (user.surname) form.setValue('emergency_contact.surname', user.surname);
  form.setValue('emergency_contact.email', user.email);
  if (user.phone) form.setValue('emergency_contact.phone', user.phone);


  form.setValue('billing.name', user.name);
  if (user.surname) form.setValue('billing.surname', user.surname);
  form.setValue('billing.email', user.email);

  if (user.phone) {
    form.setValue('emergency_contact.phone', user.phone);
    form.setValue('billing.phone', user.phone);
  }
}