import React, {useState} from 'react';
import sBig from './DatePickerWithOutsideLabel.module.css';
import sSmall from './DatePickerWithOutsideLabel-small.module.css';
import DatePicker from "../DatePicker";

const DatePickerWithOutsideLabel = ({
                                      id,
                                      withBorder = true,
                                      small = false,
                                      externalFocused = false,
                                      placeholder = "",
                                      label,
                                      onChange,
                                      labelClassName = undefined,
                                      ...props
                                    }) => {
  const [focused, setFocused] = useState(false);
  const [date, setDate] = useState();

  const s = small ? sSmall : sBig;

  return (
    <div className={s.parent}>
      {label && <label className={`${s.label_outside} ${labelClassName}`} htmlFor={id}>{label}</label>}
      <div
        className={`${s.container} ${(focused || !!date) ? s.focused : ''} ${withBorder ? s.containerWithBorder : ''}`}>
        <div className={s.icon}>
          <svg width="18" height="20" viewBox="0 0 18 20" fill="none">
            <path
              d="M16 2H15V1C15 0.45 14.55 0 14 0C13.45 0 13 0.45 13 1V2H5V1C5 0.45 4.55 0 4 0C3.45 0 3 0.45 3 1V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 8V18H2V8H16ZM5 10C5.55 10 6 10.45 6 11C6 11.55 5.55 12 5 12C4.45 12 4 11.55 4 11C4 10.45 4.45 10 5 10ZM9 10C9.55 10 10 10.45 10 11C10 11.55 9.55 12 9 12C8.45 12 8 11.55 8 11C8 10.45 8.45 10 9 10ZM13 10C13.55 10 14 10.45 14 11C14 11.55 13.55 12 13 12C12.45 12 12 11.55 12 11C12 10.45 12.45 10 13 10Z"
              fill="#AEADAD"/>
          </svg>
        </div>
        <div className={s.input}>
          <DatePicker
            externalFocused={externalFocused}
            placeholder={placeholder}
            onFocus={() => setFocused(true)}
            onBlur={date => setFocused(!!date)}
            id={id}
            onChange={date => {
              setDate(date);
              if (date) setFocused(true);
              if (onChange) onChange(date);
            }}
            {...props}
          />
        </div>
      </div>
    </div>

  );
};


export default DatePickerWithOutsideLabel;
