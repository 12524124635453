import React from 'react';
import s from './NothingFoundContent.module.css';

const NothingFoundContent = ({icon, text, containerClassName = ""}) => {
  return (
    <div className={`${s.container} ${containerClassName}`}>
      {!!icon && <div className={s.icon}>{icon}</div>}
      <div className={s.text}>{text}</div>
    </div>
  );
};


export default NothingFoundContent;
