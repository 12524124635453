import React, {useState} from 'react';
import s from './Activity.module.css';
import {useTranslation} from "react-i18next";
import {ReactComponent as LikeCircleSVG} from "../../../../resources/svg/like_circle.svg";
import {ReactComponent as ShareCircleSVG} from "../../../../resources/svg/share_circle.svg";
import MiddleText from "../../../TextComponents/MiddleText";
import CurrencyNumber from "../../../CurrencyNumber";
import Button from "../../../Inputs/Button/Button";
import {ReactComponent as TravelSaveSVG} from "../../../../resources/svg/travel_save_check.svg";
import {ReactComponent as LocationSVG} from "../../../../resources/svg/location.svg";
import useActivity from "../../../../controllers/useActivity/useActivity";
import {Spoiler} from "@mantine/core";

const Activity = ({activity, onSelect, onMoreInfo}) => {
  const {t} = useTranslation();

  const [likeLoading, setLikeLoading] = useState(false);
  const [liked, setLiked] = useState(false);


  const {likeActivity, dislikeActivity} = useActivity();
  const stripHTML = content => content ? content.replace(/(<([^>]+)>)/gi, "") : '';

  const addressString = address => {
    let q = '';
    if (address.raw?.country?.name) q += address.raw.country.name;
    if (address.raw?.country?.destinations[0]?.name) q += ' ' + address.raw?.country?.destinations[0]?.name;
    if (address.address) q += ' ' + address.address;
    return q;
  }

  const openMaps = (address) => {
    let q = '';
    if (address.geolocation) {
      q = `${address.geolocation.latitude},${address.geolocation.longitude}`;
    } else {
      q = addressString(address);
    }
    window.open(`http://maps.google.com/?q=${q}`, '_blank');
  }

  const likeTheActivity = async () => {
    if (likeLoading) return;
    setLikeLoading(true);

    let f = liked ? dislikeActivity : likeActivity;

    const {error, success} = await f(activity);
    setLikeLoading(false);

    if (error) return alert(t(`errors.${error}`));

    if (success) setLiked(!liked);
  }


  return (
    <div className={s.activity}>
      <div className="w-full flex flex-wrap sm:flex-nowrap">
        {activity.image &&
          <div className={s.image} style={{backgroundImage: `url(${activity.image.resource})`}}/>}
        <div className="w-full xl:w-9/12 flex flex-wrap xl:flex-nowrap">
          <div className="w-full relative flex flex-col">
            <div className="w-full relative flex overflow-hidden mb-5">
              <div className="m-5">
                <div className="flex">
                  <MiddleText className={s.name}>{activity.name}</MiddleText>
                  <div className="top-0 right-0 flex justify-end pt-5">
                    <LikeCircleSVG className={`${liked ? s.circleLiked : s.circle} mr-5`}
                                   onClick={() => likeTheActivity()}
                                   style={{cursor: likeLoading ? 'default' : 'pointer'}}/>
                    <ShareCircleSVG/>
                  </div>

                </div>

                <div className="my-5">
                  {activity.hashtags &&
                    activity.hashtags.map((hashtag, i) =>
                      <div className={s.hashtag} key={i}>
                        <div>{hashtag}</div>
                      </div>)}
                </div>

                <div className="flex justify-around xl:justify-start">
                  <div className="xl:hidden flex items-center">
                    <div className={s.travel_save}>
                      <div>
                        <TravelSaveSVG/>
                      </div>
                    </div>
                    <CurrencyNumber className={s.final_price}>{activity.total_price}</CurrencyNumber>
                  </div>


                  {activity.address &&
                    <div className={s.address} onClick={() => openMaps(activity.address)}>
                      <LocationSVG fill='#757575'/>
                      {<div>{addressString(activity.address)}</div>}
                    </div>
                  }


                </div>

                <div className={s.summary}>
                  <Spoiler maxHeight={100} showLabel={t('show more')} hideLabel={t('hide')}>
                    {stripHTML(activity.summary || activity.description)}
                  </Spoiler>
                </div>


              </div>

            </div>
          </div>

          <div className={s.activity_right}>
            <div className={s.rate}>
              <div className="text-2xl">{t('activity_page.total_price_per_person')}</div>
              <CurrencyNumber className={s.final_price}>{activity.total_price}</CurrencyNumber>
            </div>
            <div className={s.select_button_container}>
              <div className="m-10">
                <Button className={s.moreInfoButton}
                        onClick={() => onMoreInfo && onMoreInfo(activity)}>{t("more info")}</Button>
              </div>
            </div>
            <div className={s.travel_saveRight}>
              <div>
                <TravelSaveSVG/>
              </div>
              <div>
                {t("hotel.travel_save")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Activity;
