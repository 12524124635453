import React, {Suspense, useEffect, useState} from 'react';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './css/react_dates_overrides.css';

import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

import {ErrorBoundary} from 'react-error-boundary';
import routes from "./routes/content";
import {BrowserRouter, useLocation, useRoutes} from "react-router-dom";
import './helpers/i18n';
import Loader from "./components/Loader";
import CriticalError from "./components/CriticalError";
import AllProviders from "./providers";

import UnderDevelopment from "./components/UnderDevelopment";

import 'dayjs/locale/es';
import 'dayjs/locale/en';

import dayjs from "dayjs";
import 'mapbox-gl/dist/mapbox-gl.css';
import {StartApp} from "./startApp";
import ErrorCallback from "./components/ErrorManager/ErrorCallback/ErrorCallback";

dayjs.extend(require('dayjs/plugin/localizedFormat'));
dayjs.extend(require('dayjs/plugin/isBetween'));
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.extend(require('dayjs/plugin/timezone'));


const RouteWrapper = () => {
  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return useRoutes(routes);
}


function App() {
  const [state, setState] = useState({error: false, load: true});
  const [loading, setLoading] = useState(true);

  const onLoad = () => setState(prev => ({...prev, load: true}));
  const onError = error => {
    setState(prev => ({...prev, load: false, error: error}));
  }


  return (
    <div className="App">
      {state.error && <CriticalError refresh={true} error={state.error}/>}
      {state.load && (
        <Suspense fallback={<Loader/>}>
          <AllProviders>
            <UnderDevelopment/>
            <BrowserRouter>
              <ErrorBoundary FallbackComponent={ErrorCallback}>
                <StartApp setLoading={setLoading}>
                  {loading ? <Loader/> : <RouteWrapper/>}
                </StartApp>
              </ErrorBoundary>
            </BrowserRouter>
          </AllProviders>
        </Suspense>)}
      {/*<RenderScriptTags onLoad={onLoad} onError={error => onError(error)} />*/}
    </div>
  );
}

export default App;
