import React, {createContext} from 'react';
import {UseFormReturn} from 'react-hook-form';
import {CheckRatesResult, Hotel, HotelForPurchase, HotelForPurchaseRoom} from "../../types/hotels/hotels";
import {CheckoutPerson} from "./CheckoutProvider";
import {Dayjs} from "dayjs";
import {CheckoutError, PaymentData} from "../../types/payment";
import {Police} from "../../types/insurance";
import {CreatePaymentIntentProps} from "../../controllers/usePurchase/usePurchase.types";
import {UseHotelTypes} from "../../controllers/useHotel/models";

interface ICheckoutContext {
  form: UseFormReturn;

  hotels: HotelForPurchase[],
  setHotels: (val: (HotelForPurchase[] | ((prevState: HotelForPurchase[]) => HotelForPurchase[]))) => void,

  activities: any,
  setActivities: any,

  persons: CheckoutPerson[],
  setPersons: (val: (CheckoutPerson[] | ((prevState: CheckoutPerson[]) => CheckoutPerson[]))) => void,

  selectedInsurance: Police | null,
  setSelectedInsurance: React.Dispatch<React.SetStateAction<Police | null>>,

  changeAdults: (action: 'add' | 'remove', index: number) => any,
  changeChildren: (action: 'add' | 'remove', index: number[] | number) => any,
  findAllCountryCodes: () => string[],
  findAllResidentsCountryCodes: () => string[],
  getFinalDates: () => { from: Dayjs, to: Dayjs },

  clearPurchaseData: () => void,
  checkPayingInformation: (data: any) => string | undefined,
  createPropsForCreatePaymentIntent: () => CreatePaymentIntentProps,
  createPurchaseData: ({data, hotels}: { data: { [p: string]: any }, hotels: HotelForPurchase[] }) => any,
  preparePayment: (payment_id: string, order_id: string) => Promise<{
    paymentData: PaymentData,
    ratesChanged: boolean
  }>, // throws an error

  replaceHotelRates: (newRates: CheckRatesResult[], types: UseHotelTypes) => HotelForPurchase[],
  getCleanFormData: () => { [p: string]: any },
  addNewRoomsToReserve: (hotel: Hotel | HotelForPurchase, room: HotelForPurchaseRoom[]) => void,

  errors: { [p: string]: any },
  setErrors: (val: (prevState: { [p: string]: any }) => { [p: string]: any }) => void,

  isShoppingCartEmpty: boolean,
  manageError: (error: CheckoutError) => boolean
}

export const CheckoutContext = createContext<ICheckoutContext | undefined>(undefined);
