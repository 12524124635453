import s from './HomePage.module.css';
import Header, {HeaderStyleType} from "../../components/Header/Header";
import SelectOperation from "../../components/Main/SelectOperation";
import {SmallFooter} from "./SmallFooter";
import {HomePageTitles} from "./HomePageTitles";


const HomePage = () => {

  return <>
    <div className={s.container}>
      <Header styleType={HeaderStyleType.white}/>

      <div className={s.overlay}/>
      <div className="top-0 w-full absolute min-h-screen flex items-center justify-center">
        <HomePageTitles>
          <div className={s.selectOperationContainer}><SelectOperation isHomePage={true}/></div>
        </HomePageTitles>
      </div>

    </div>

    <SmallFooter/>

    {/*<div className="my-40">*/}
    {/*  <CustomizedTripBanner/>*/}
    {/*</div>*/}

    {/*<div className="py-40" style={{background: '#F7F7F7'}}>*/}
    {/*  <TopHotels/>*/}
    {/*</div>*/}

    {/*<Footer/>*/}
  </>
}

export default HomePage;