import React, {useState, useRef} from 'react';
import s from './SelectLanguage.module.css';
import BubbleTriangle from "../BubbleTriangle";
import SelectCountry from "./SelectCountry";
import i18n from 'i18next';
import SpanishFlag from "./SpanishFlag";
import BritishFlag from "./BritishFlag";
import {useTranslation} from "react-i18next";
import useHotelsProvider from "../../providers/HotelsProvider/useHotelsProvider";

const SelectLanguage = props => {
  const {t} = useTranslation();
  const {clearCache: clearHotelsCache} = useHotelsProvider();

  const flagRef = useRef();

  const countries = [
    {lang: 'es-ES', flag: (<SpanishFlag/>)},
    {lang: 'en-US', flag: (<BritishFlag/>)}
  ];
  const selectedCountry = countries.find(country => country.lang === i18n.language);

  const onCountryChange = async (e, {lang}) => {
    if(localStorage){
      localStorage.clear();
    }
    await i18n.changeLanguage(lang);


    //clearHotelsCache();
    window.location.reload();
  }


  return (
    <div className={s.selectLanguage} ref={flagRef}>
      <div className={s.flag}>
        {selectedCountry?.flag}
      </div>
      <BubbleTriangle triggerRef={flagRef} containerClass={s.bubble}>
        <SelectCountry onCountryClick={onCountryChange} countries={countries} language={i18n.language} />
      </BubbleTriangle>
    </div>
  );
};


export default SelectLanguage;
