import s from './NumberOfNights.module.css';
import {Popover} from "@mantine/core";
import {FormEvent, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";
import moment from "moment";

export function NumberOfNights() {
  const {t} = useTranslation();
  const [isActive, setActive] = useState(false);
  const node = useRef<HTMLDivElement>(null);

  const form = useFormContext();

  const arrive_date = form.watch('arrive_date');
  const departure_date = form.watch('departure_date');

  const nights = useMemo(() => {
    return Math.abs((arrive_date ?? moment()).diff(departure_date ?? moment(), 'day'));
  }, [arrive_date, departure_date]);

  const onChange = (e: FormEvent<HTMLSelectElement>) => {
    const nights = e.currentTarget.value;
    form.setValue('departure_date', moment(arrive_date).add(nights, 'day'));
  }

  return <>
    <Popover
      opened={isActive}
      onClose={() => setActive(false)}
      position="bottom"
      target={<div ref={node} className={s.container} onClick={() => setActive(true)}>
        <div className="font-bold">{nights}</div>
        <div>{t('night', {count: nights})}</div>
      </div>}
      styles={() => ({
        inner: {
          padding: '2px'
        }
      })}
    >
      <div className={s.popup}>
        <select onChange={onChange}>
          {Array.from(Array(100).keys()).map((i) => <option selected={i + 1 === nights} key={i}
                                                            value={i + 1}>{i + 1}</option>)}
        </select>
      </div>
    </Popover>

  </>
}