import s from './LoadingSVG.module.css';
import {useState} from "react";
import {Loader} from "@mantine/core";

interface LoadingSVGProps {
  LoadingIcon?: any;
  onClick?: any;
  Icon: any;
  containerClassName?: string;
  className?: string;
  loaderProps?: any;
}

const LoadingSVG = ({LoadingIcon, containerClassName, className, loaderProps = {}, onClick, Icon}: LoadingSVGProps) => {
  const [loading, setLoading] = useState(false);

  const _onClick = async () => {
    if(loading)return;
    setLoading(true);
    await onClick();
    setLoading(false);
  }

  return <div className={containerClassName}>{loading ? (
    LoadingIcon ? <LoadingIcon/> : <Loader {...loaderProps}/>
  ) : <Icon className={className} onClick={() => _onClick()}/>}
  </div>
}

export default LoadingSVG;