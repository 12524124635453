import React, {useRef} from 'react';
import s from './ProfileMenu.module.css';
import {useTranslation} from "react-i18next";
import ArrowIcon from "./ArrowIcon";
import ProfileIcon from "./ProfileIcon";
import useAuth from "../../providers/AuthProvider/useAuth";
import BubbleTriangle from "../BubbleTriangle";
import ProfileOptions from "./ProfileOptions";

const ProfileMenu = props => {
  const {t} = useTranslation();
  const {getUser} = useAuth();
  const menuRef = useRef();


  return (
    <div ref={menuRef} className={s.profileMenu}>
      <div className={s.icon}><ProfileIcon width={18} height={18}/></div>
      <div className={s.name}>{getUser().name}</div>
      <div className={s.arrow}><ArrowIcon width={15} height={15}/></div>
      <BubbleTriangle triggerRef={menuRef} containerClass={s.bubble}>
        <ProfileOptions closeMenu={() => true}/>
      </BubbleTriangle>
    </div>
  );
};


export default ProfileMenu;
