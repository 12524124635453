import s from "../PurchasedInsurance.module.css";
import React from "react";
import {persons_keys} from "./util";
import {t} from "i18next";
import {FormattedInsuredPerson} from "./insuredPersons";

interface Props {
  persons: FormattedInsuredPerson[]
}

export function InsuredPersonsDesktop({persons}: Props) {
  return <>
    <div className="font-bold text-2xl my-5">{t('insurance page.persons')}</div>

    <table className={s.p_container}>
      <thead>
      <tr>
        {persons_keys.map((key, i) => <td key={i} className={s.p_key}>{t(`insurance table keys.${key}`)}</td>)}
      </tr>
      </thead>
      <tbody>
      {persons.map(p => {
        return <tr>
          {persons_keys.map((key, i) => <td key={i} className={s.p_value}>{p[key]}</td>)}
        </tr>
      })}
      </tbody>
    </table>
  </>
}