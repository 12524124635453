import {
  Hotel,
  HotelFacility,
  HotelRoom,
  HotelRoomSelectionResume,
  HotelRoomWithAllServices,
  SearchHotelDetailsURLParams,
  SearchHotelURLParams
} from "../../../types/hotels/hotels";
import {getParams, hashCode, objToParams, paramsToObj} from "../../../helpers/util";
import moment from "moment";
import {SearchHotelsFormValues} from "../../Main/Hotels/models";
import {CurrencyNumberText} from "../../CurrencyNumber/CurrencyNumber";


export interface HotelRoomWithHash extends Omit<HotelRoom, 'rates'> {
  rates: HotelRoom['rates'];
}

interface UniqueRate {
  hash: string;
  rate: HotelRoom['rates'][0];
}

function checkForBetterRate(searchRate: HotelRoom['rates'][0], rates: HotelRoom['rates']) {
  return !!rates.find(rate => {
    if (searchRate.rateType !== rate.rateType || searchRate.boardCode !== rate.boardCode) return false;
    if (Number(searchRate.net) > Number(rate.net)) return false;
  });
}


export interface RateAndRoom {
  rate: HotelRoom['rates'][0];
  room: HotelRoom;
}

export function getRateAndRoomFromHash(hotel: Hotel, hash: string): RateAndRoom | undefined {
  if (!hotel || !Array.isArray(hotel.rooms)) return undefined;

  for (let i = 0; i < hotel.rooms.length; i++) {
    const room = hotel.rooms[i];
    for (let j = 0; j < room.rates.length; j++) {
      const rate = room.rates[j];
      if (hashCode(rate.rateKey) + '' === hash) {
        return {rate, room};
      }
    }
  }
}

export function getRateAndRoomFromRateKey(hotel: Hotel, rateKey: string): RateAndRoom | undefined {
  if (!hotel || !Array.isArray(hotel.rooms)) return undefined;

  for (let i = 0; i < hotel.rooms.length; i++) {
    const room = hotel.rooms[i];
    for (let j = 0; j < room.rates.length; j++) {
      const rate = room.rates[j];
      if (rate.rateKey === rateKey) {
        return {rate, room};
      }
    }
  }
}

type RateWithTaxes = Pick<HotelRoom['rates'][0], 'taxes'>

export function getTotalClientTaxesFromRate(rate: RateWithTaxes) {
  if (!rate.taxes || !Array.isArray(rate.taxes?.taxes) || rate.taxes?.taxes?.length === 0) return 0
  let total = 0
  rate.taxes.taxes.forEach(tax => {
    if (!tax.included) {
      total += parseFloat(tax.amount)
    }
  })
  return total
}


interface MapPromotionsParams {
  promotions: {
    code: string;
    name: string;
  }[];
  t: any;
}

const PROMOTIONS = {
  '073': {text: 'no modifications allowed', color: '#ff0000'},
}

export function mapPromotions({promotions, t}: MapPromotionsParams): {
  name: string;
  color: string
}[] {
  return promotions
    .filter(promotion => !!PROMOTIONS[promotion.code as keyof typeof PROMOTIONS])
    .map(promotion => {
      let p = PROMOTIONS[promotion.code as keyof typeof PROMOTIONS];
      return {
        name: t('hotel promotion data.' + p.text),
        color: p.color ?? '#fff'
      }
    });
}


interface MapFacilitiesParams {
  facilities: HotelFacility[];
}

export function mapFacilities({facilities}: MapFacilitiesParams) {

}

export function isFacilitiyShown(facility: HotelFacility) {
  return true; // we can filter by code here
}


export function getRoomAllFacilities(room: HotelRoom) {
  let facilities: HotelFacility[] = [];
  if (room.roomFacilities) {
    facilities.push(...room.roomFacilities);
  }

  if (Array.isArray(room.roomStays)) {
    for (let i = 0; i < room.roomStays.length; i++) {
      const roomStay = room.roomStays[i];
      if (roomStay && Array.isArray(roomStay.roomStayFacilities)) {
        for (let j = 0; j < roomStay.roomStayFacilities.length; j++) {
          const facility = roomStay.roomStayFacilities[j];
          if (isFacilitiyShown(facility)) {
            facilities.push(facility);
          }
        }
      }
    }
  }


  return facilities;
}


export function hotelsObjToParams(obj: SearchHotelURLParams | SearchHotelDetailsURLParams, addDetails = false): any {
  const {rooms, ...rest} = obj
  let result: any = {...rest}

  rooms.forEach((room, i) => {
    result[`a${i + 1}`] = room.adults;
    result[`c${i + 1}`] = room.children;
    if (room.childrenAge && room.childrenAge?.length > 0) {
      result[`ca${i + 1}`] = room.childrenAge.join(',');
    }
  })


  if (addDetails) {
    const params = paramsToObj();
    result.code = params.code
    result.api = params.api
  }

  return objToParams(result);
}

export function hotelsParamsToObj(params?: string): SearchHotelURLParams {
  params = params ?? getParams();
  const obj: any = paramsToObj(params);

  let result: SearchHotelURLParams = {
    ...obj,
    from: moment(obj.from),
    to: moment(obj.to),
    rooms: []
  };

  let room = 1
  while (obj[`a${room}`]) {
    result.rooms.push({
      adults: parseInt(obj[`a${room}`]),
      children: parseInt(obj[`c${room}`]),
      childrenAge: obj[`ca${room}`] ? obj[`ca${room}`].split(',').map((age: string) => parseInt(age)) : []
    })
    room++
  }

  if (result.rooms.length === 0) {
    result.rooms.push({
      adults: 1,
      children: 0,
      childrenAge: []
    })
  }

  return result;
}


export function formDataToSearchHotelURLParams(data: SearchHotelsFormValues): SearchHotelURLParams {
  const searchParams = paramsToObj();
  const dest = data.hotel_destination?.raw;
  const small = data.hotel_destination?.smallText;


  let hotelUrlParams: SearchHotelURLParams = {
    to: moment(data.departure_date),
    from: moment(data.arrive_date),
    lng: dest?.lng ?? searchParams.lng,
    lat: dest?.lat ?? searchParams.lat,
    t: ((data.hotel_destination?.text || '') + (small ? ', ' + small : '')) || searchParams.t,
    rooms: data.rooms.map(room => ({
      adults: room.adults,
      children: room.children,
      childrenAge: room.childrenAge.map(a => a.age)
    }))
  }

  return hotelUrlParams as SearchHotelURLParams;
}

export function formDataToHotelDetailURLParams(data: SearchHotelsFormValues): SearchHotelDetailsURLParams {
  const dest = data.hotel_destination?.raw as unknown as { api: string, code: number };

  const hotelUrlParams: SearchHotelDetailsURLParams = {
    to: moment(data.departure_date),
    from: moment(data.arrive_date),
    api: dest?.api,
    code: dest?.code,
    rooms: data.rooms.map(room => ({
      adults: room.adults,
      children: room.children,
      childrenAge: room.childrenAge.map(a => a.age)
    }))
  }

  return hotelUrlParams;
}


interface GetRoomQuantityOptionsProps {
  room: HotelRoomWithAllServices;
  rate: HotelRoomWithAllServices['rates'][0];
  roomSelectionResume: HotelRoomSelectionResume;
  selectedRateKeys: string[];
}

export function getRoomQuantityOptions({
                                         rate,
                                         room,
                                         roomSelectionResume,
                                         selectedRateKeys
                                       }: GetRoomQuantityOptionsProps) {
  const maxRoomAllotment = Math.max(...room.rates.map(rate => rate.allotment));
  const maxRateAllotment = Math.min(Math.abs(rate.allotment / rate.rooms) + 1, 10);
  const roomSelectedRates = roomSelectionResume.totalRatesPerRooms[room.code] || 0;
  const rateSelectedRooms = selectedRateKeys.filter((a: string) => a === rate.rateKey).length * rate.rooms;

  return Array.from({length: maxRateAllotment}, (v, i) => {
    const totalRooms: number = i * rate.rooms;
    return {
      label: `${totalRooms}${i ? ' (' + CurrencyNumberText({
        children: Math.round(rate.finalPrice * (i)),
        options: {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }
      }) + ')' : ''}`,
      value: i,
      isDisabled: i ? totalRooms > maxRoomAllotment - roomSelectedRates * rate.rooms + rateSelectedRooms : false
    }
  });
}


interface GetDefaultSelectedRatesProps {
  searchParams: SearchHotelURLParams;
  hotel: Hotel,
}

export function getDefaultSelectedRates({searchParams, hotel}: GetDefaultSelectedRatesProps) {
  const selectedRates: Record<string, number> = {}
  searchParams.rooms.forEach(searchRoom => {
    for (let i = 0; i < hotel.rooms.length; i++) {
      const hotelRoom = hotel.rooms[i]
      for (let j = 0; j < hotelRoom.rates.length; j++) {
        const rate = hotelRoom.rates[j]
        if (rate.adults === searchRoom.adults && rate.children === searchRoom.children && (selectedRates[rate.rateKey] ?? 0) < rate.allotment * rate.rooms) {
          selectedRates[rate.rateKey] = (selectedRates[rate.rateKey] ?? 0) + 1
          return
        }
      }
    }
  })
  return selectedRates
}