import moment from "moment";
import {ReactComponent as StarSVG} from "../../resources/svg/star.svg";
import React from "react";
import config from "../../config";

import {
  ApitudeHotelImageTypeCodes,
  Hotel,
  HotelRoomWithAllServices,
  ProjectedHotelForList
} from "../../types/hotels/hotels";
import {ServiceCodes} from "../../types/hotels/serviceCodes";
import {HotelServicesList, hotelServicesList} from "./HotelDetails/services/lists";
import dayjs from "dayjs";


export const hotelDefaultImage = 'https://photos.hotelbeds.com/giata/bigger/01/013634/013634a_hb_l_001.jpg';

export enum HotelImageSize {
  PX320 = 'giata/',
  PX74 = 'giata/small',
  PX117 = 'giata/medium',
  PX800 = 'giata/bigger',
  PX1024 = 'giata/xl',
  PX2048 = 'giata/xxl',
  original = 'original'
}

export const buildImagePath = (hotel: {
  api: string
}, path: string, size: HotelImageSize = HotelImageSize.PX800) => {
  const baseURL: {
    [p: string]: string
  } = {
    'apitude': `https://photos.hotelbeds.com/${size}/`,
    'local': `${config.imagesURL}/`
  };
  if (baseURL[hotel.api]) return `${baseURL[hotel.api]}${path}`;
}

const hotelImagePriority = [
  ApitudeHotelImageTypeCodes.GeneralView,
  ApitudeHotelImageTypeCodes.Lobby,
  ApitudeHotelImageTypeCodes.Conferences,
  ApitudeHotelImageTypeCodes.Terrace,
  ApitudeHotelImageTypeCodes.Pool,
  ApitudeHotelImageTypeCodes.Restaurant,
  ApitudeHotelImageTypeCodes.Room,
  ApitudeHotelImageTypeCodes.Bar,
  ApitudeHotelImageTypeCodes.Beach,
  ApitudeHotelImageTypeCodes.SportsAndEntertainment
];

export const getBestHotelImage = (hotel: Pick<ProjectedHotelForList, 'images'>) => {
  let imageCodeIndex = 0;

  do {
    let imageCode = hotelImagePriority[imageCodeIndex];
    let images = hotel.images.filter(image => image.imageTypeCode === imageCode);

    if (!images.length) {
      imageCodeIndex++;
    } else {
      return images.sort((a, b) => a.visualOrder - b.visualOrder)[0];
    }
  } while (imageCodeIndex < hotelImagePriority.length);

  return hotel.images[0];
}


const buildStars = (hotel: {
  api: string,
  categoryCode: string
}) => {
  if (hotel.api === 'apitude') {
    let stars = hotel.categoryCode ? Number.parseInt(hotel.categoryCode.charAt(0)) : 0;
    if (!stars) stars = Number.parseInt(hotel.categoryCode.charAt(1));
    return stars;
  }
  return 0;
}
export const openHotel = ({api, code}: {
  api: string,
  code: string
}) => {
  let from = encodeURIComponent(moment().add('10', 'days').toISOString());
  let to = encodeURIComponent(moment().add('11', 'days').toISOString());
  window.open(`${window.location.origin}/i/hotel?to=${to}&from=${from}&adults=1&children=0&rooms=1&code=${code}&api=${api}`, '_blank');
}

export const findPhone = (hotel: Hotel, not_found: string) => {
  let phone = not_found;
  if (Array.isArray(hotel.phones)) {
    let phoneHotel = hotel.phones.find(phone => phone.phoneType === 'PHONEHOTEL');
    phone = phoneHotel ? phoneHotel.phoneNumber : hotel.phones[0].phoneNumber;
  }
  return phone;
}

export const generateStars = (hotel: {
  api: string,
  categoryCode: string
}) => {
  let stars = [];
  let n_starts = buildStars(hotel);


  for (let i = 0; i < n_starts; i++) {
    stars.push(<StarSVG key={i} width={15} height={15} style={{fill: '#6D6D6D'}}/>);
  }
  return stars;
}

export const createAllRoomServices = ({services, hotelFacilities, servicesDescriptions}: {
  services: any,
  hotelFacilities: any,
  servicesDescriptions: any
}) => {
  let r: any = [];
  if (Array.isArray(services)) r = [...services];
  if (Array.isArray(hotelFacilities)) r = [...r, ...hotelFacilities.filter(({facilityGroupCode}) => facilityGroupCode === 60).map(f => {
    let d = servicesDescriptions.find((s: any) => s.code === f.facilityCode && s.groupCode === f.facilityGroupCode);
    return d ? d.description : null;
  }).filter(a => a)];
  return r;
}

export const createFinalServices = () => {

}

export const createOutstandingServices = (services: ServiceCodes[]) =>
  services.map(code => hotelServicesList.find(s => s.codes.includes(code))).filter(a => a) as HotelServicesList[]


export function isHotelRefundable(hotel: any) {
  return dayjs().isBefore(dayjs(hotel.checkIn)) && hotel?.booking?.modificationPolicies?.cancellation && !hotel.refunded
}

export function doesRoomProvideMoreInfo(hotel: Hotel, room: HotelRoomWithAllServices) {
  if (room.roomFacilities?.length > 0 || room.roomStays?.length > 0) return true;
}