import React, {useState} from 'react';
import s from './PasswordInput.module.css';
import Input from "../Input";

const PasswordInput = React.forwardRef((props, ref) => {
  const [type, setType] = useState('password');

  const toggleType = () => setType(prev => prev === 'password' ? 'text' : 'password');

  return (
    <Input
      ref={ref}
      type={type}
      icons={{right: {
        icon: type === 'text' ? (<svg width="32" height="20" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M31.6594 9.05C26.5637 3.1 21.1652 0 16.0189 0C10.8727 0 5.47418 3.1 0.3784 9.05C-0.126133 9.6 -0.126133 10.45 0.3784 11C5.47418 16.9 10.8727 20 16.0189 20C21.1652 20 26.5637 16.9 31.6594 10.95C32.1135 10.4 32.1135 9.6 31.6594 9.05ZM16.0189 18C11.6295 18 6.73552 15.15 2.19473 10C6.73552 4.85 11.6295 2 16.0189 2C20.4084 2 25.3023 4.85 29.8431 10C25.3023 15.15 20.4084 18 16.0189 18ZM9.96453 10C9.96453 6.7 12.689 4 16.0189 4C19.3488 4 22.0733 6.7 22.0733 10C22.0733 13.3 19.3488 16 16.0189 16C12.689 16 9.96453 13.3 9.96453 10Z" fill="#032D23"/>
          </svg>
        ) : (<svg width="32" height="20" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd"
                d="M31.6594 9.05C26.5637 3.1 21.1652 0 16.0189 0C10.8727 0 5.47418 3.1 0.3784 9.05C-0.126133 9.6 -0.126133 10.45 0.3784 11C5.47418 16.9 10.8727 20 16.0189 20C21.1652 20 26.5637 16.9 31.6594 10.95C32.1135 10.4 32.1135 9.6 31.6594 9.05ZM16.0189 18C11.6295 18 6.73552 15.15 2.19473 10C6.73552 4.85 11.6295 2 16.0189 2C20.4084 2 25.3023 4.85 29.8431 10C25.3023 15.15 20.4084 18 16.0189 18ZM16.0189 4C12.689 4 9.96453 6.7 9.96453 10C9.96453 13.3 12.689 16 16.0189 16C19.3488 16 22.0733 13.3 22.0733 10C22.0733 6.7 19.3488 4 16.0189 4ZM16.0189 14C13.799 14 11.9827 12.2 11.9827 10C11.9827 7.8 13.799 6 16.0189 6C18.2389 6 20.0552 7.8 20.0552 10C20.0552 12.2 18.2389 14 16.0189 14Z"
                fill="#868686"/>
        </svg>),
          onClick: toggleType
        }}}
      {...props}
    />
  );
});


export default PasswordInput;
