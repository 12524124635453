import React from 'react';
import s from './UniqueExperienceSearchByName.module.css';
import {AiOutlineSearch} from "react-icons/ai";
import {Controller} from "react-hook-form";

const UniqueExperienceSearchByName = ({onChange: _onChange}) => {
	return (
		<div className={s.container}>
			<Controller
				name="name"
				render={({field: {onChange, value}}) => (
					<input value={value} onChange={e => {
						const v = e.target.value;
						onChange(v);
						_onChange && _onChange(v);
					}} type="text" className={s.input}
								 placeholder="Buscar por nombre..."/>
				)}/>
			<div className={s.icon}><AiOutlineSearch size={25}/></div>
		</div>
	);
};


export default UniqueExperienceSearchByName;