import React, {useState} from 'react'
import {GoogleMap, InfoWindow, Marker, useJsApiLoader} from '@react-google-maps/api';
import config from "../../config";


/*
const center = {
  lat: -3.745,
  lng: -38.523
};
*/

const MapContainer = ({
                        center,
                        zoom = 12,
                        marker,
                        containerStyle,
                        infoWindow = false
                      } = {}) => {
  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: config.google.maps_api_key
  })

  containerStyle = containerStyle || {width: "100%", height: "100vh"};

  const [map, setMap] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const [activeMarker, setActiveMarker] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(false);

  const onLoad = React.useCallback(map => {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setTimeout(() => {
      map.setCenter(center);
      map.setZoom(zoom);
    }, 1000);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, []);

  const onMarkerClick = (props, marker, e) => {
    setSelectedPlace(props);
    setActiveMarker(marker);
    setShowInfo(true);
  }

  const onClose = props => {
    if (showInfo) {
      setShowInfo(false);
      setActiveMarker(false);
    }
  }
  const onDragEnd = () => {
    //console.log(map.center.lat());
  }

  //console.log(center);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      onDragEnd={onDragEnd}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        zoomControl: false
      }}
    >
      {marker && <Marker
        position={marker.center || center}
        onClick={onMarkerClick}
        name={marker.name}
      />}
      {infoWindow &&
        <InfoWindow
          marker={activeMarker}
          visible={showInfo}
          position={center}
          onClose={onClose}
        >
          <div>
            <h4>{selectedPlace.name}</h4>
          </div>
        </InfoWindow>
      }
    </GoogleMap>
  ) : <></>
}

export default React.memo(MapContainer)
