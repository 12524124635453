import React from 'react';
import s from './SmallText.module.css';

const SmallText = ({className, children, ...props}) => {
    return (
      <span className={`${s.text} ${className}`} {...props}>{children}</span>
    );
};


export default SmallText;
