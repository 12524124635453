import {Modal} from "@mantine/core";
import {RegisterForm} from "./RegisterForm";
import React from "react";
import {useViewportSize} from "@mantine/hooks";
import s from './RegisterPage.module.css'

export function RegisterModal({visible, setVisible}) {
  const {width} = useViewportSize();
  const desktop = width >= 1024;


  return <Modal classNames={{close: s.modalClose}} size={desktop ? '800px' : 'full'} opened={visible}
                onClose={() => setVisible(false)}>
    <RegisterForm setVisible={setVisible}/>
    {/*
      <RegisterFooter/>
*/}
  </Modal>
}