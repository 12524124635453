import React from 'react';
import c from '../Checkout.module.css';
import {useTranslation} from "react-i18next";
import useCheckoutProvider from "../../../providers/CheckoutProvider/useCheckoutProvider";
import InputWithLabel from "../../Inputs/InputWithLabel";
import SelectAge from "../../Inputs/SelectAge";
import {useUtil} from "../../../hooks/useUtil";
import PersonData from "../PersonData";


const HotelsData = ({hotels}) => {
  const {t} = useTranslation();
  const {hashCode} = useUtil();
  let r = [];

  hotels.filter(({rooms}) => rooms.length > 1).forEach(hotel => {
    hotel.rooms.forEach(room => {
      let name = hashCode(hotel.api + hotel.code + room.code);
      r.push(<div className="my-5">
        <h3 className="text-2xl mb-5">{hotel.name} {room.name}</h3>
        <div className="flex items-center">
          <InputWithLabel
            label={t('name')}
            name={`hotel_room_${name}.name`}
            id={`hotel_room_${name}.name`}
            second_style={true}
            containerClassName="mr-5"
          />
          <InputWithLabel
            label={t('surname')}
            name={`hotel_room_${name}.surname`}
            id={`hotel_room_${name}.surname`}
            second_style={true}
          />
        </div>
      </div>);
    });

  });

  return <div>
    <div className="font-bold text-xl">{t('hotels')}</div>
    {r}
  </div>;
}

const ActivitiesData = ({activities}) => {
  const {hashCode} = useUtil();
  const {t} = useTranslation();


  let r = activities.map((activity, i) => {
    let activity_id = hashCode(activity.code + activity.api);
    return <div className="my-5">
      <h3 className="text-2xl mb-5">{activity.name}</h3>
      {activity.selectedRates.map(rate => {
        let _name = activity_id + '.' + hashCode(rate.rateKey);
        let _id = activity_id + '_' + hashCode(rate.rateKey);
        return <div>
          {rate.persons.map((age, ageIndex) => {
            let name = _name + '.' + ageIndex;
            let id = _id + '_' + ageIndex;
            return <div className="flex items-center">
              <InputWithLabel
                label={t('name')}
                name={`persons.${name}.name`}
                id={`person_${id}_name`}
                second_style={true}
                containerClassName="mr-5"
              />
              <InputWithLabel
                label={t('surname')}
                name={`persons.${name}.surname`}
                id={`person_${id}_surname`}
                second_style={true}
                containerClassName="mr-5"
              />
              <SelectAge
                name={`persons.${name}.age`}
                defaultValue={age * 1}
              />
            </div>
          })}
        </div>
      })}
      {i + 1 !== activities.length && <hr className="my-5"/>}
    </div>
  });

  return <div>
    <div className="font-bold text-xl">{t('activities')}</div>
    {r}
  </div>
}


const PersonsData = ({user}) => {
  const {t} = useTranslation();

  const {persons, selectedInsurance} = useCheckoutProvider();

  const n_adults = selectedInsurance ? persons.filter(p => p.type === 'adult').length : 1;
  const n_children = selectedInsurance ? persons.filter(p => p.type === 'child').length : 0;

  if (!n_adults) return <></>;

  return (
    <div className={c.box}>
      <h2>{t('checkout.persons data')}</h2>
      <h3 className="my-5">{t('adults', {count: n_adults})} {/*({n_adults})*/}</h3>
      <hr className="my-5"/>
      {[...Array(n_adults)].map((_, i) => <div key={i} className="my-5">
        <PersonData type={'adult'} holder={!i} id={i} user={user} n={i + 1}/>
        {(i < n_adults - 1 || n_children > 0) && <hr className="mt-5"/>}
      </div>)}

      {n_children > 0 && <h3 className="my-5">{t('children', {count: n_children})} {/*({n_children})*/}</h3>}

      {[...Array(n_children)].map((_, i) => <div key={i} className="my-5">
        <PersonData type={'child'} children={true} holder={false} id={i + n_adults} n={i + 1}/>
        {i < n_children - 1 && <hr className="mt-5"/>}
      </div>)}


    </div>
  );
};


export default PersonsData;
