import {Hotel, HotelFacility, HotelRoomWithAllServices} from "../../../types/hotels/hotels";
import {t} from "i18next";
import {facilityLocalMapper} from "./constants";
import {UseHotelTypes} from "../../../controllers/useHotel/models";
import {hotelServicesList} from "../HotelDetails/services/lists";
import {serviceCodes} from "./lists";
import {localizedFormatInOriginalTimezone} from "../../../helpers/formatters";


export interface FacilityFullInfo extends HotelFacility {
  description?: string;
  value?: string | number;
  icon?: any;
}

export interface FacilityDescriptions {
  code: number;
  groupCode: number;
  description: string;
}

export interface FacilityGroupDescription {
  code: number;
  description: string;
}


interface GetAllFacilitiesFromHotel {
  hotel: Hotel;
  room?: HotelRoomWithAllServices;
}

export function getAllFacilitiesFromHotel({hotel, room}: GetAllFacilitiesFromHotel) {
  let allFacilities: FacilityFullInfo[] = [];
  const addFacility = (facility: FacilityFullInfo) => {
    if (allFacilities.findIndex(f => f.facilityCode === facility.facilityCode && f.facilityGroupCode === facility.facilityGroupCode) === -1) {
      allFacilities.push(facility);
    }
  }

  if (Array.isArray(hotel.facilities) && !room) {
    for (let j = 0; j < hotel.facilities.length; j++) {
      addFacility(hotel.facilities[j]);
    }
  }

  if (Array.isArray(hotel.rooms)) {
    let roomFound = false;
    for (let j = 0; j < hotel.rooms.length; j++) {
      const currentRoom = hotel.rooms[j];
      if (room && currentRoom.code === room.code) {
        roomFound = true;
      } else if (room) {
        continue;
      }

      if (Array.isArray(currentRoom.roomFacilities)) {
        for (let k = 0; k < currentRoom.roomFacilities.length; k++) {
          addFacility(currentRoom.roomFacilities[k]);
        }
      }
      if (Array.isArray(currentRoom.roomStays)) {
        for (let k = 0; k < currentRoom.roomStays.length; k++) {
          const stay = currentRoom.roomStays[k];
          if (Array.isArray(stay.roomStayFacilities)) {
            for (let l = 0; l < stay.roomStayFacilities.length; l++) {
              addFacility(stay.roomStayFacilities[l]);
            }
          }
        }
      }
      if (roomFound) break;
    }
  }

  return allFacilities;
}


interface GetFullFacilityInfoProps {
  facility: FacilityFullInfo | string; // if its string === local facility
  hotelTypes: UseHotelTypes;
}

export function getFullFacilityInfo({facility, hotelTypes}: GetFullFacilityInfoProps) {
  if (typeof facility === 'string') {
    // this is temporal until we completely remove services
    return facilityLocalMapper.find(f => f.value === facility) as unknown as FacilityFullInfo;
  }

  const facilityTypes = hotelTypes.facilities.apitude;

  let value = '';
  if (facility.number) value = facility.number?.toString();
  else if (facility.amount) value = facility.amount?.toString() + facility?.currency ? ` ${facility.currency}` : '';
  //else if (facility.indLogic) value = t('Yes');
  else if (facility.distance) value = facility.distance?.toString();
  else if (facility.indYesOrNo) value = facility.indYesOrNo ? '' : t('No');

  if (facility.dateTo) value += ' ' + localizedFormatInOriginalTimezone(facility.dateTo);
  if (facility.timeTo || facility.timeFrom) value += ` ${facility.timeFrom || ''} ${facility.timeTo ? '-' : ''} ${facility.timeTo || ''}`


  let description = facilityTypes.find(d => d.code === facility.facilityCode && d.facilityGroupCode === facility.facilityGroupCode)?.description
  const facilityServiceCode = getFacilityServiceCode(facility);
  const facilityServiceData = facilityServiceCode ? hotelServicesList.find(service => service.codes.includes(facilityServiceCode)) : undefined;


  return {
    ...facility,
    description: description || facilityServiceData?.label,
    value,
    icon: facilityServiceData?.icon
  } as FacilityFullInfo
}


export interface FacilityGroup {
  name: string;
  facilities: FacilityFullInfo[];
}


interface CreateFacilityGroups {
  facilities: FacilityFullInfo[];
  hotelTypes: UseHotelTypes;
}

export function createFacilityGroups({facilities, hotelTypes}: CreateFacilityGroups) {
  const facilityGroupsDescriptions = hotelTypes.facilityGroups.apitude;

  let groups: FacilityGroup[] = [];

  facilityGroupsDescriptions.forEach(group => {
    if ([20, 30].includes(group.code)) return;
    const groupFacilities = facilities.filter(facility => facility.facilityGroupCode === group.code);
    if (!groupFacilities.length) return;
    groups.push({name: group.description, facilities: groupFacilities});
  });

  return groups;
}

// interface CreateFacilityGroupsOldProps {
//   facilities: (HotelFacility | string)[];
//   facilityDescriptions: FacilityDescriptions[];
//   groupDescriptions: FacilityGroupDescription[];
//   room?: HotelRoomWithAllServices;
//   services?: HotelServices[]; // Todo: this should be removed in the future
// }
//
// export function createFacilityGroupsOld({
//                                           facilities,
//                                           facilityDescriptions,
//                                           groupDescriptions,
//                                           services,
//                                           room
//                                         }: CreateFacilityGroupsOldProps) {
//   // local facilities don't have groups
//   if (typeof facilities[0] === 'string') return [];
//
//   let filteredFacilities = facilities as HotelFacility[];
//   if (room) {
//     filteredFacilities = filteredFacilities.filter(f => {
//       let is_facility_in_room = room.roomFacilities.find(_f => _f.facilityCode === f.facilityCode);
//       return !!is_facility_in_room;
//     });
//   }
//
//   let finalFacilities: FacilityFullInfo[] = [];
//   if (services) {
//     // this is temporal until we completely remove services
//     finalFacilities = facilityApitudeMapper.filter(service => services[service.key as any]) as unknown as FacilityFullInfo[];
//   } else if (Array.isArray(filteredFacilities)) {
//     for (let i = 0; i < filteredFacilities.length; i++) {
//       const item = getFullFacilityInfo({
//         facility: filteredFacilities[i],
//         descriptions: facilityDescriptions
//       });
//       if (item) finalFacilities.push(item);
//     }
//   }
//
//   let finalGroups: { name: string, facilities: FacilityFullInfo[] }[] = [];
//
//   groupDescriptions.forEach(group => {
//     if ([20, 30].includes(group.code)) return;
//     let facilities = finalFacilities
//       .filter(facility => facility.facilityGroupCode === group.code);
//     if (facilities.length > 0) {
//       finalGroups.push({name: group.description, facilities});
//     }
//
//   });
//
//   return finalGroups;
// }


export function getFacilityServiceCode(facility: HotelFacility) {
  return serviceCodes.find(({code, facilities}) => {
    for (const f of facilities) {
      if (f.code === facility.facilityCode && f.group === facility.facilityGroupCode) return true;
    }
    return false;
  })?.code
}