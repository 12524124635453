import React from 'react';
import config from "../../../config";

const AvisoLegalEN = () => {
  return (
    <>     <h1>Legal warning</h1>
      <p>This section includes information on the conditions of access and use of this website that must be known by the
        user.</p>
      <h2>1. Object</h2>
      <p>In order to comply with the provisions of article 10 of Law 34/2002, of July 11, on Information Society
        Services and Electronic Commerce, the general information data of this website are indicated below:</p>
      <p>Name: TRIPPY TREKKERS, S.L</p>
      <p>Address: Camino de Hoyarrasa 106, 28109 Alcobendas, Madrid.</p>
      <p>Registration data: It is registered in the Mercantile Registry of Madrid.</p>
      <p>Domain name: The domain name, owned by TRIPPY TREKKERS, S.L, is www.trippytrekkers.com CIF: B-B88592381 Email:
        <a href={`mailto:${config.trippy_trekkers.contact_email}`}>{config.trippy_trekkers.contact_email}</a> For the
        purposes of this document.
      </p>
      <p>This Legal Notice regulates the use of said domain.</p>

      <h2>2. Conditions of access and use of this website.</h2>
      <p>The use of this website implies acceptance by the User of the conditions of use included in this Notice as well
        as our policies on 'Data Protection and Privacy' and 'Cookie Policy'. In the event that certain services
        contained and/or tools offered through this "Portal" require the application of particular conditions, these
        will be made available to the User.</p>
      <p>On the other hand, TRIPPY TREKKERS, S.L warns that both the contents and services of this website and the
        conditions of use themselves may be modified without prior notice.</p>

      <p>The User undertakes to, in the sections where it is necessary to register in order to access them, provide
        truthful, accurate, and complete data about their identity. Furthermore, they undertake to keep the personal
        data provided to the domain holder up to date, thus being solely responsible for any falsehoods or inaccuracies
        they may make.</p>

      <p>It is informed that in the case of being a minor, permission must be obtained from their parents, guardians, or
        legal representatives in order to access the services provided. TRIPPY TREKKERS, S.L is not responsible in the
        event that the data on this matter is inaccurate or false.</p>

      <p>The "Portal" may only be used for legal purposes, therefore the user undertakes to make lawful and honest use
        of the portal and in accordance with these General Conditions of Use, not to use the services of the "Portal"
        for activities contrary to Spanish legislation, morality, and public order, assuming all responsibilities for
        damages and prejudices against the domain holder or third parties that may arise from illegal or unauthorized
        practices including, but not limited to:</p>

      <ol>
        <li>Carrying out any manipulation or alteration of this page without the prior consent of the domain holder, who
          assumes no responsibility that may arise from such manipulation or alteration by third parties.
        </li>
        <li>Carrying out any act that may damage, disable, overload, or deteriorate the Portal and the services and/or
          prevent the normal use and utilization by Users.
        </li>
        <li>Introducing and/or Using computer programs, data, defective files, viruses, malicious code, computer or
          telecommunications equipment or any other, regardless of their nature, that may cause damage to the Portal,
          any of the services, or any assets (physical or logical) of the information systems of the domain holder.
        </li>
        <li>Violating the rights of third parties to privacy, self-image, data protection, secrecy in communications,
          intellectual and industrial property.
        </li>
        <li>Concealing and falsifying the origin of email messages.</li>
        <li>Using false identities, impersonating the identity of others in the use of the Portal or in the use of any
          of the services.
        </li>
        <li>Reproducing, distributing, modifying, or copying the content of this page, unless authorized by the domain
          holder or legally authorized.
        </li>
        <li>Transmitting to unauthorized third parties the Usernames and access keys.</li>
      </ol>

      <h2>3. Links to other sites.</h2>
      <p>TRIPPY TREKKERS, S.L is not responsible for the Links to other Internet pages of third parties and their
        existence does not imply that TRIPPY TREKKERS, S.L approves or accepts their contents and services. These other
        web pages are not controlled by TRIPPY TREKKERS, S.L. If you access other web pages using the Links provided,
        the operators of said web pages may collect your personal information. Ensure that you agree with the Privacy
        Policies of these third-party web pages before providing any personal information.</p>
      <p>In general, the domain holder excludes its responsibility for damages and prejudices of any nature that may
        arise from the use of the website, as well as for damages and prejudices derived from the infringement of
        Intellectual and Industrial Property rights by users and/or the lack of truthfulness, accuracy, and timeliness
        of the contents, and no responsibilities can be demanded for the interruption of service, inadequate operation,
        or impossibility of access to the service</p>
      <p>The domain holder shall not be responsible for damages and prejudices caused by the presence of viruses or any
        other harmful software that may cause alterations in the User's computer system.</p>
      <p>The website, including but not limited to, its programming, designs, logos, text, and/or graphics are the
        property of the provider or, where applicable, have a license or express authorization from the authors.</p>

      <h2>4. Intellectual and industrial property.</h2>

      <p>TRIPPY TREKKERS, S.L. is the owner of all intellectual and industrial property rights to its website, as well
        as the elements contained therein (including, but not limited to, images, sound, audio, video, software, or
        texts; trademarks or logos, color combinations, structure and design, selection of materials used, computer
        programs necessary for its operation, access, and use, etc.), owned by TRIPPY TREKKERS, S.L.</p>
      <p>The user may view the elements of the portal and even print, copy, and store them on the hard drive of their
        computer or on any other physical medium as long as it is, solely and exclusively, for their personal and
        private use. Users must refrain from deleting, altering, bypassing, or manipulating any protection device or
        security system installed on the TRIPPY TREKKERS, S.L website.</p>
      <p>Likewise, no material published on this website may be reproduced, copied, or published without the written
        consent of TRIPPY TREKKERS, S.L.</p>
      <p>The user undertakes not to carry out any act against the intellectual or industrial property rights of the
        author.</p>

      <h2>5. Data protection and use of cookies.</h2>
      <p>You can consult our policy on the protection of personal data and the use of cookies in the following sections
        of our website: www.trippytrekkers.com</p>

      <h2>6. Modifications</h2>
      <p>TRIPPY TREKKERS, S.L. may make, at any time and without prior notice, modifications and updates to the
        information contained on its website, or to its configuration or presentation. TRIPPY TREKKERS, S.L makes every
        possible effort to ensure that the information provided through its website is clear, understandable, and
        adequate, as well as to avoid errors as much as possible and, if applicable, to repair or update them.</p>
      <p>TRIPPY TREKKERS, S.L does not guarantee that the service provided by the website will not suffer technical
        interruptions at any time, nor that it will be updated, as well as its contents at all times. However, TRIPPY
        TREKKERS, S.L undertakes to carry out all necessary work to rectify errors as soon as it becomes aware of them,
        provided that there are no reasons that make it impossible or difficult to do so.</p>
      <p>However, TRIPPY TREKKERS, S.L cannot guarantee the absence of errors or that the content of the information is
        permanently updated.</p>
      <p>Likewise, TRIPPY TREKKERS, S.L reserves the right to make modifications it deems appropriate to its portal
        without prior notice, being able to change, delete, or add both the contents and services provided through it,
        as well as the way in which they are presented or located on its portal.</p>

      <h2>7. Comment policy</h2>
      <p>This website may allow comments to establish a community of travelers that can be enriched with content and to
        make inquiries.</p>
      <p>Comments that are not related to the theme of this website, that include defamation, grievances, insults,
        personal attacks, or lack of respect towards the author or other members will not be admitted.</p>
      <p>Comments containing obviously misleading or false information will also be deleted, as well as comments
        containing personal information, such as private addresses or phone numbers, that violate the data protection
        policy of this website.</p>
      <p>Comments created solely for promotional purposes of a website, person, or group, as well as anything that could
        be considered spam in general, will also be disregarded.</p>
      <p>Comments that are obviously poorly written with multiple spelling mistakes or truncated words will not be
        published.</p>

      <h2>8. Applicable legislation</h2>

      <p>The applicable law in case of dispute or conflict of interpretation of the terms that make up this legal
        notice, as well as any issue related to the services of this website, will be Spanish law.</p>
      <p>Any dispute between TRIPPY TREKKERS, S.L and the user, regarding the issues contained in this legal notice,
        with express waiver of any other jurisdiction that may correspond to them, will be submitted to the Courts and
        Tribunals of the city of Alcobendas, Madrid, Spain.</p>

    </>
  );
};


export default AvisoLegalEN;