import React, {useMemo} from 'react';
import s from './HotelDetailsConditionsBox.module.css';
import sDetails from '../HotelDetailsInfo/HotelDetailsInfo.module.css';
import {useTranslation} from "react-i18next";

import {ReactComponent as EntrySVG} from "../../../../../resources/svg/hotel/entry.svg";
import {ReactComponent as ExitSVG} from "../../../../../resources/svg/hotel/exit.svg";
import {ReactComponent as VisaSVG} from "../../../../../resources/svg/icons/visa.svg";
import {ReactComponent as MasterCardSVG} from "../../../../../resources/svg/icons/mastercard.svg";
import {ReactComponent as PaymentCardsSVG} from "../../../../../resources/svg/icons/payment cards.svg";
import cn from "classnames";


const HotelDetailsConditionsBox = ({hotel}) => {
  const {t} = useTranslation();

  const formatTime = (t) => {
    if (typeof t !== 'string') return;
    let time = t.split(':');
    return `${time[0]}:${time[1]}`;
  }
  const formatFromTo = (from, to) => {
    let r = '';
    if (from) r += formatTime(from);
    if (from && to && from !== to) r += ' - ';
    if (to && from !== to) r += formatTime(to);
    return r;
  }


  let conditions = useMemo(() => {
    if (!Array.isArray(hotel.facilities)) return {};
    let r = {};
    r.entry = hotel.facilities.find((facility) => facility.facilityCode === 260 && facility.facilityGroupCode === 70);
    r.exit = hotel.facilities.find((facility) => facility.facilityCode === 390 && facility.facilityGroupCode === 70);

    return r;
  }, [hotel]);


  if (!conditions.entry && !conditions.exit) return null;

  return (
    <div
      className={cn(sDetails.boxContainerGreen, 'flex flex-wrap md:flex-nowrap md:flex-row gap-5 md:gap-40 justify-between md:justify-start')}>
      <div>
        <div className={sDetails.boxTitle}>{t('hotel.hotel conditions')}</div>
        <div>
          {conditions.entry &&
            <div className={s.el}>
              <EntrySVG className={s.icon}/>
              <div>
                <b>{t('hotel.conditions.entry')}: </b>
                {formatFromTo(conditions.entry?.timeFrom, conditions.entry?.timeTo)}
              </div>
            </div>
          }
          {conditions.exit &&
            <div className={s.el}>
              <ExitSVG className={s.icon}/>
              <div>
                <b>{t('hotel.conditions.exit')}: </b>
                {formatFromTo(conditions.exit?.timeFrom, conditions.exit?.timeTo)}
              </div>
            </div>
          }
        </div>
      </div>

      <div>
        <div className="flex flex-nowrap items-center">
          <PaymentCardsSVG className="mb-3"/>
          <div className={sDetails.boxTitle}>{t('hotel.conditions.accepted payment cards')}</div>
        </div>
        <div className={cn(s.el, "ml-10")}>
          <VisaSVG className={s.icon}/>
          <div>Visa</div>
        </div>
        <div className={cn(s.el, "ml-10")}>
          <MasterCardSVG className={s.icon}/>
          <div>Mastercard</div>
        </div>

      </div>

    </div>
  );
};


export default HotelDetailsConditionsBox;