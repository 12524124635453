import React from 'react';
import s from './BlogPosts.module.css';
import Slider from "../Slider";
import {useTranslation} from "react-i18next";
import Button from "../Inputs/Button/Button";

const BlogPosts = ({posts}) => {
  const {t} = useTranslation();
  return (
    <div style={{maxWidth: '1200px'}}>
      <Slider elements={posts.map((post, i) => <div key={i} className={s.post}>
        <div className={s.image}><img src={post.image}/></div>
        <div className="p-10">
          {post.tags?.length > 0 && <div className="flex">
            {post.tags.map(tag => <div className={s.tag} style={{background: tag.color}}>{tag.title}</div>)}
          </div>}
          <div className="text-2xl font-bold my-5">{post.title}</div>
          <div className="text-xl">{post.text}</div>
          <Button className={s.read_more} onClick={() => window.open(post.link, '_blank')}>{t('read more')}</Button>
        </div>
      </div>)}/>
    </div>
  );
};


export default BlogPosts;