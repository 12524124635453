import React from 'react';
import s from './Select.module.css';
import {Controller, useFormContext} from "react-hook-form";
import ReactSelect, {components as componentsSelect} from 'react-select';
import {useTranslation} from "react-i18next";

const Select = ({
                  name,
                  usePortal = true,
                  label = false,
                  options,
                  onChange: _onChange,
                  components,
                  styles = {},
                  containerClassName,
                  defaultValue,
                  placeholder,
                  ...props
                }) => {
  const {t} = useTranslation();
  const {control} = useFormContext();
  const NoOptionsMessage = props => {
    return <componentsSelect.NoOptionsMessage {...props}>No encontrado</componentsSelect.NoOptionsMessage>
  }
  let _props = {};
  if (usePortal) _props.menuPortalTarget = document.body;


  return (
    <div className={`${s.container} ${containerClassName ? containerClassName : ''}`}>
      {label && <label className="label" htmlFor={name}>{label}</label>}
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue || options[0]?.value}
        render={({field: {onChange, value, ref}}) =>
          <ReactSelect
            styles={styles}
            inputRef={ref}
            options={options}
            components={{...components, NoOptionsMessage}}
            value={options.find(c => c.value === value)}
            onChange={val => {
              onChange(val.value);
              _onChange && _onChange(val.value);
            }}
            placeholder={placeholder || t('select')}
            {..._props}
            {...props}
          />

        }/>
    </div>
  );
};


export default Select;
