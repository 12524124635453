import React from 'react';
import sc from '../Checkout.module.css';
import {useTranslation} from "react-i18next";
import Checkbox from "../../Inputs/Checkbox";
import useCheckoutProvider from "../../../providers/CheckoutProvider/useCheckoutProvider";
import {TextInput} from "../Inputs/TextInput/TextInput";

const Email = () => {
  const {t} = useTranslation();

  const {form} = useCheckoutProvider();

  const {register} = form;

  return (
    <div className={sc.box}>
      <h2>{t('checkout.email title')}</h2>
      <div className="mb-10">
        <TextInput
          label={t('email')}
          name={`email`}
          id={`checkout_information_email`}
          required
          withAsterisk={true}
          containerClassName="w-full"
        />
      </div>
      <Checkbox
        id={`checkout_email_checkbox`}
        label={t('checkout.email checkbox')}
        {...register('subscription')}
      />
    </div>
  );
};


export default Email;
