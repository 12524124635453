import React, {useEffect, useRef, useState} from 'react';
import s from './Input.module.css';
import {Controller, useFormContext} from "react-hook-form";

const DataElement = ({element, onMouseDown}) => (
  <div className={s.dataElement} onMouseDown={onMouseDown}>
    {element.icon && <div className={s.dataIcon}>{element.icon}</div>}
    <div className="inline-block">
      <div className={s.dataText}>{element.text}</div>
      <div className={s.dataSmallText}>{element.smallText}</div>
    </div>
  </div>
);

const InputWithLabel = ({
                          id,
                          name,
                          label,
                          small_label,
                          withAsterisk = false,
                          type,
                          icon,
                          onFocus,
                          onBlur,
                          onChange,
                          disabled,
                          autoSelect = false,
                          data,
                          Data,
                          second_style = false,
                          containerClassName,
                          onDataSelect,
                          alwaysActive = false,
                          ...props
                        } = {type: 'text'}) => {

  const [isActive, setIsActive] = useState(alwaysActive);
  const input = useRef();
  const Element = Data || DataElement;

  const {watch, setValue} = useFormContext();
  const value = watch(name);
  const selected = useRef(false);

  const _onFocus = e => {
    if (!alwaysActive) {
      setIsActive(true);
      e.target.classList.add(s.active);
    }
    onFocus && onFocus(e);
  }
  const _onBlur = e => {
    if (!alwaysActive) {
      setIsActive(false);
      if (e.target.value.length > 0) {
        e.target.classList.add(s.active);
      } else {
        e.target.classList.remove(s.active);
      }
    }
    onDataSelect && !selected.current && data[0] && onDataSelect(data[0], input);

    onBlur && onBlur(e);
  }

  useEffect(() => {
    if (typeof value === 'string' && value.length > 0 && input.current) input.current.classList.add(s.active);
  }, [value]);
  useEffect(() => {
    if (alwaysActive && input.current) input.current.classList.add(s.active);
  }, [input.current]);

  const dataClassName = `${s.data} ${isActive ? s.dataActive : ''}`;


  useEffect(() => {
    if (!data?.length || !autoSelect || !input.current) return;
    setValue(name, data[0]);
    //onDataSelect && onDataSelect(data[0], input);
  }, [data]);


  return (
    <Controller
      name={name}
      render={({field: {onChange: _onChange, value}}) => (
        <div className={`${s.container} ${second_style ? s.second_style : ''} ${containerClassName || ''}`}>
          {icon && (<div className={s.icon}>
            {icon}
          </div>)}
          <input disabled={disabled} ref={input} type={type} name={name} id={id}
                 onFocus={(e) => _onFocus(e)}
                 onBlur={e => _onBlur(e)}
                 onChange={e => {
                   onChange && onChange(e);
                   if (!data?.length) {
                     _onChange(e.target.value);
                   }
                 }}
                 value={typeof value === 'string' ? value : null} {...props} />
          {label && <label htmlFor={id}>{label}</label>}
          {small_label && <div className={s.small_label}>{small_label}</div>}

          {data && (
            <div className={dataClassName}>
              {data.map((el, i) => {
                  if (el.dataRender === 'custom') {
                    const Render = el.render;
                    return <Render key={i}/>
                  }
                  return <Element key={i} element={el} onMouseDown={e => {
                    _onChange(el);
                    selected.current = true;
                    onDataSelect(el, input);
                  }}/>
                }
              )}
            </div>)
          }
        </div>
      )}
    />
  )
}


export default InputWithLabel;
