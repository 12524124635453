import React from 'react';
import s from './WrittenSummary.module.css';
import sc from '../Checkout.module.css';
import useCheckoutProvider from "../../../providers/CheckoutProvider/useCheckoutProvider";
import {useTranslation} from "react-i18next";
import {simplifiedLocalizedFormatInOriginalTimezone} from "../../../helpers/formatters";

const WrittenSummary = props => {
  const {t} = useTranslation();
  const {form} = useCheckoutProvider();

  const {watch} = form;

  const travellers = watch('travellers');

  const formatDate = date => simplifiedLocalizedFormatInOriginalTimezone(date);

  if (!travellers) return <></>;

  return (
    <div className={sc.box}>
      <h2>{t('checkout.data written summary')}</h2>
      <div className="grid grid-cols-2">
        {travellers.map((traveller, i) => <div key={i} className={s.summary_text}>
          <div className={s.passenger_name}>{t('checkout.traveller')} {i + 1}</div>
          <table>
            {traveller.name && <tr>
              <th>{t('name')}:</th>
              <td>{traveller.name}</td>
            </tr>}

            {traveller.surname && <tr>
              <th>{t('surname')}:</th>
              <td>{traveller.surname}</td>
            </tr>}
            {traveller.type_of_document && traveller.number_of_document &&
              <tr>
                <th>{t('checkout.document')}:</th>
                <td>{traveller.type_of_document} {traveller.number_of_document}</td>
              </tr>}
            {traveller.emission_date &&
              <tr>
                <th>{t('checkout.emission date')}:</th>
                <td>{formatDate(traveller.emission_date)}</td>
              </tr>}
            {traveller.date_of_expiry && <tr>
              <th>{t('date of expiry')}:</th>
              <td>{formatDate(traveller.date_of_expiry)}</td>
            </tr>}

            {traveller.nationality && <tr>
              <th>{t('nationality')}:</th>
              <td>{traveller.nationality}</td>
            </tr>}
            {traveller.country &&
              <tr>
                <th>{t('country')}:</th>
                <td>{traveller.country}</td>
              </tr>}
            {traveller.gender &&
              <tr>
                <th>{t('gender.gender')}:</th>
                <td>{t(`gender.${traveller.gender}`)}</td>
              </tr>
            }
            {traveller.birthday &&
              <tr>
                <th>{t('checkout.birthday')}:</th>
                <td>{formatDate(traveller.birthday)}</td>
              </tr>}
            {traveller.phone_number &&
              <tr>
                <th>{t('checkout.small phone number')}:</th>
                <td>{traveller.phone_number}</td>
              </tr>}
          </table>
        </div>)}
      </div>
    </div>
  );
};


export default WrittenSummary;
