import React, {useEffect} from 'react';
import Title from "../../TextComponents/Title";
import MiddleText from "../../TextComponents/MiddleText";
import {useTranslation} from "react-i18next";
import ChooseActivityCategories from "../../ChooseActivityCategories/ChooseActivityCategories";
import Button from "../../Inputs/Button/Button";
import {ReactComponent as ArrowButtonSVG} from "../../../resources/svg/arrow_button_right.svg";
import {useNavigate} from "react-router-dom";
import bases from "../../../routes/bases";

const UniqueExperienceChooseActivity = props => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <div className="w-100 text-center">
      <Title className="mb-10 block">{t("search_experiences.choose your activities")}</Title>
      <br/>
      <MiddleText className="mb-10 md:mb-20 block">{t("search_experiences.find the best activities")}</MiddleText>
      <ChooseActivityCategories/>
      <div className="w-full flex justify-center mt-10">
        <div className="w-72">
          <Button onClick={() => navigate(bases.uniqueExperienceSelectCountries)}>
            {t('next')}
            <ArrowButtonSVG style={{fill: '#fff'}} className="inline-block ml-5"/>
          </Button>
        </div>
      </div>
    </div>
  );
};


export default UniqueExperienceChooseActivity;