import React, {useEffect} from 'react';
import s from './ChatList.module.css';
import useChatProvider from "../../../../providers/ChatProvider/useChatProvider";
import {useTranslation} from "react-i18next";
import ProfileImage from "../../../../resources/my_profile_menu/profile_image.png";
import useAuth from "../../../../providers/AuthProvider/useAuth";

const ChatList = props => {
  const {t} = useTranslation();
  const {chatList, setChatList, newChat} = useChatProvider();
  const {listChats} = useChatProvider();
  const {getUser} = useAuth();

  const loadChats = async () => {
    const {error, chats} = await listChats();
    setChatList(chats);
  }

  useEffect(() => {
    loadChats();
  }, []);

  const _newChat = () => {
    let user_id = prompt('user_id');
    newChat({user_id}, (r) => {
    });
  }

  const getSecondUser = (chat) => {
    if (chat.user.user_id === getUser().user_id) return chat.user2;
    return chat.user;
  }
  const selectChat = selected_chat => {
    setChatList(chats => chats.map(chat => chat.chat_id === selected_chat.chat_id ? {...chat, selected: true} : chat));
  }


  //if(!chatList || !chatList.length)return <div className={s.noChats}>{t('chat list.no chats found')}</div>
  if (!chatList || !chatList.length) return <div className={s.noChats} onClick={() => _newChat()}>NEW CHAT</div>

  return (
    <div className="flex bg-white h-full overflow-hidden"
         style={{width: '350px', height: '800px', borderRadius: '15px'}}>
      {chatList.map(chat => {
          let user = getSecondUser(chat);
          return <div
            className={`w-full h-40 p-5 hover:bg-gray-200 cursor-pointer ${chat.selected ? 'bg-green-500' : ''}`}
            onClick={() => selectChat(chat)}>
            <div className="float-left mr-10">
              <img style={{height: '70px', width: '70px'}} src={chat.avatar ? chat.avatar.url : ProfileImage}
                   alt={t('alt.profile_image')}
                   className="shadow-lg rounded-full max-w-full h-auto align-middle border-none"/>
            </div>
            <div className="flex justify-start flex-wrap pt-4">
              <div className={s.message_title}>{user.person.first_name}</div>
              <div className={s.last_message}>{chat.last_message || t('chat created')}</div>
            </div>
          </div>
        }
      )}
    </div>
  );
};


export default ChatList;
