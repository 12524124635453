import {Hotel, HotelRoomSelectionResume, HotelRoomWithAllServices} from "../../../../../types/hotels/hotels";
import s from './HotelDetailsRoomsTable.module.css';
import {RoomTypeWithImage} from "./RoomTypeWithImage";
import React from "react";
import {useUtil} from "../../../../../hooks/useUtil";
import {ReactComponent as PersonSVG} from "../../../../../resources/my_profile/person.svg";
import CurrencyNumber from "../../../../CurrencyNumber";
import {RateComments} from "./RateComments";
import {useTranslation} from "react-i18next";
import Select from "../../../../Inputs/Select";
import {getRoomQuantityOptions, mapPromotions} from "../../util";
import {firstCapitalize} from "../../../../../helpers/util";
import useHotelsProvider from "../../../../../providers/HotelsProvider/useHotelsProvider";
import {Tooltip} from "@mantine/core";
import {t} from "i18next";
import {localizedFormatInOriginalTimezone} from "../../../../../helpers/formatters";


const Persons = ({adults, children}: { adults: number, children: number }) => {
  const r = [];
  for (let i = 0; i < adults; i++) {
    r.push(<Tooltip label={t('adult')}><PersonSVG key={i} fill="#000"/></Tooltip>);
  }

  for (let i = 0; i < children; i++) {
    r.push(<Tooltip label={t('children')}><PersonSVG style={{transform: 'scale(0.6)'}} key={i} fill="#000"/></Tooltip>);
  }

  return <div className="flex justify-center items-center">
    {r}
  </div>
}

const CancellationPolicies = ({rate}: { rate: Hotel['rooms'][0]['rates'][0] }) => {
  const {t} = useTranslation();
  if (rate.rateClass === 'NRF') return <span style={{color: 'rgb(255, 0, 0)'}}>{t('hotel.non refundable')}</span>;


  if (Array.isArray(rate.cancellationPolicies) && rate.cancellationPolicies.length > 0) {
    const policy = rate.cancellationPolicies.reduce((policy, current) =>
      policy.from < current.from ? policy : current
    );

    return <div>
      <div style={{color: 'var(--color-primary)', marginBottom: '5px'}}>{t('hotel cancel')} <span
        style={{fontSize: '14px'}}><b>{t('free')}</b></span> {t('until')}
      </div>
      <div
        className="w-full flex justify-around">
        <div
          style={{width: '100px'}}>
          {localizedFormatInOriginalTimezone(policy.from)}
        </div>
      </div>
    </div>
  }

  if (rate.rateClass === 'NOR') {
    return <div style={{color: 'var(--color-primary)', marginBottom: '5px'}}>{t('hotel cancel')} <span
      style={{fontSize: '14px'}}><b>{t('free')}</b></span>
    </div>
  }


  return <></>
}

const Promotions = ({rate}: { rate: Hotel['rooms'][0]['rates'][0] }) => {
  const {t} = useTranslation();
  if (!Array.isArray(rate.promotions)) return <></>;
  const promotions = mapPromotions({
    promotions: rate.promotions,
    t
  });
  return <>
    {promotions.map(p => <div style={{color: p.color}}>{p.name}</div>)}
  </>
}


export interface RoomProps {
  roomSelectionResume: HotelRoomSelectionResume,
  hotel: Hotel,
  room: HotelRoomWithAllServices,
  setSelectedRoom: (room: HotelRoomWithAllServices) => void;
}

export const Room = ({hotel, room, roomSelectionResume, setSelectedRoom}: RoomProps) => {
  const {hashCode} = useUtil();

  const {selectedRateKeys, setSelectedRateKeys} = useHotelsProvider();

  return <>
    <div className={s.roomContainer}>
      <RoomTypeWithImage room={room} hotel={hotel} setSelectedRoom={setSelectedRoom}/>
      <div className={s.roomInfoContainer}>
        {Array.isArray(room.rates) && room.rates.map((rate, rateN) => {
          const roomQuantityOptions = getRoomQuantityOptions({rate, room, roomSelectionResume, selectedRateKeys});
          return <div key={rateN} className={s.roomInfo}>
            <div className={`${s.roomInfoElement} flex flex-nowrap items-center justify-center`} style={{width: '20%'}}>
              {/*<div className="text-2xl mr-3">{rate.adults}</div>*/}
              <Persons adults={rate.adults} children={rate.children}/>
            </div>

            <div className={`${s.price} ${s.roomInfoElement}`} style={{width: '20%'}}>
              <CurrencyNumber>{rate.finalPrice}</CurrencyNumber>
            </div>
            <div className={s.roomInfoElement}
                 style={{width: '20%'}}>{firstCapitalize(rate.boardName?.toLowerCase())}</div>

            <div className={s.roomInfoElement} style={{width: '20%'}}>
              <RateComments rate={rate}/>
              <CancellationPolicies rate={rate}/>
              <Promotions rate={rate}/>
            </div>

            <div className={s.roomInfoElement} style={{width: '20%'}}>
              {/*@ts-ignore*/}
              <Select
                name={hashCode(rate.rateKey) + ''}
                containerClassName={s.selectNRoomsContainer}
                options={roomQuantityOptions}
                onChange={(n: any) => {
                  setSelectedRateKeys((prev: string[]) => {
                    let selected = [...prev].filter(a => a !== rate.rateKey);
                    for (let i = 0; i < n; i++) {
                      selected.push(rate.rateKey);
                    }
                    return selected;
                  });
                }}
              />
            </div>
          </div>
        })}
      </div>
    </div>
  </>
}