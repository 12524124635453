import s from './SharePopup.module.css';
import {ReactComponent as ShareCircleSVG} from "../../resources/svg/share_circle.svg";
import {useState} from "react";
import {Modal} from "@mantine/core";
import {useTranslation} from "react-i18next";

interface SharePopupProps {
  url: string;
  title?: string;
}

const SharePopup = ({url, title}: SharePopupProps) => {
  const {t} = useTranslation();
  const [opened, setOpened] = useState(false);

  const open = () => {
    if (navigator.share) {
      navigator.share({
        title: title || t('share'),
        url: url
      }).then(() => {
      })
        .catch(console.error);
    } else {
      setOpened(true);
    }
  }

  return <>
    <div className={s.button}><ShareCircleSVG onClick={() => open()}/></div>
    <Modal opened={opened} onClose={() => setOpened(false)}>
      <div className={s.container}>
        SHARE!
      </div>
    </Modal>
  </>
}

export default SharePopup;