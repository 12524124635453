import s from './StarsFilter.module.css';
import {Controller} from "react-hook-form";
import React, {useMemo} from "react";
import {ReactComponent as StarSVG} from "../../../../../resources/svg/star.svg";

interface StarsFilterProps {
  getValues: any;
  onFilterChange: any;
  from?: number;
  to?: number;
  name: string;
  containerClassName?: string;
}

const StarsFilter = ({name, getValues, onFilterChange, from = 1, to = 5, containerClassName}: StarsFilterProps) => {
  const stars = useMemo(() => {
    let r = [];
    for (let i = from; i <= to; i++) {
      r.push(i);
    }
    return r;
  }, [from, to]);

  return <Controller
    name={name}
    defaultValue={0}
    render={({field: {onChange, value}}) => <div className={`flex justify-center items-center ${containerClassName}`}>
      {stars.map(star => {
        let min = getValues('min_stars');
        let disabled = min !== 0 && star < min;
        return <StarSVG key={star} onClick={() => {
          if (disabled) return;
          onChange(value === star ? 0 : star);
          onFilterChange();
        }} fill="#6D6D6D" className={disabled ? s.disabled_star : s.star}
                        style={{opacity: star <= value ? 1 : 0.2}}/>
      })}
    </div>}
  />
}

export default StarsFilter;