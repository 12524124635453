import React from 'react';
import s from './Select.module.css';
// @ts-ignore
import ReactSelect, {components as componentsSelect} from 'react-select';
import {Controller} from "react-hook-form";
import {useTranslation} from "react-i18next";


const styles = {
  control: (provided: any) => ({
    ...provided,
    fontSize: '1.25rem',
    backgroundColor: '#fff',
    color: '#000',
    border: 'none !important',
    boxShadow: 'none !important',
    "&:hover": {
      borderColor: 'transparent !important',
      boxShadow: 'none !important'
    },
    "&:active": {
      borderColor: 'transparent',
      boxShadow: 'none'
    }
  }), indicatorSeparator: (prev: any) => ({
    ...prev,
    background: 'transparent'
  }),
  indicatorsContainer: (prev: any) => ({
    ...prev,
    fill: '#000 !important'
  }),
  menuPortal: (base: any) => ({...base, zIndex: 9999})
};


const Select = ({
                  name,
                  label,
                  id,
                  icon,
                  withAsterisk = false,
                  iconRightPadding = 29,
                  components,
                  options,
                  containerClassName,
                  usePortal = true,
                  placeholder,
                  onChange: _onChange,
                  defaultValue,
                  second_style = false,
                  ...props
                }: any) => {
  const {t} = useTranslation();

  const NoOptionsMessage = (props: any) => {
    return <componentsSelect.NoOptionsMessage {...props}>No encontrado</componentsSelect.NoOptionsMessage>
  }

  let _props: any = {};

  if (usePortal) _props.menuPortalTarget = document.body;

  return (
    <Controller
      name={name}
      defaultValue={defaultValue || options[0]?.value}
      render={({field: {onChange, value, ref}}) =>
        <div className={`${s.container} ${second_style ? s.second_style : ''} ${containerClassName}`}>
          {label && <label htmlFor={id}>{label}</label>}
          <div>
            <ReactSelect
              portal={usePortal}
              inputRef={ref}
              inputProps={{id}}
              components={{...components, NoOptionsMessage}}
              value={options.find((c: any) => c.value === value) || options[0]}
              onChange={(val: any) => {
                onChange(val.value);
                _onChange && _onChange(val.value);
              }}
              options={options}
              placeholder={placeholder || t('select')}
              styles={styles}
              {..._props}
              menuPortalTarget={document.body}
              {...props}
            />
          </div>
        </div>
      }/>

  );
};


export default Select;
