import {useContext} from 'react';
import {CheckoutContext} from "./CheckoutContext";

export const useCheckoutProvider = () => {
  const context = useContext(CheckoutContext);
  if(!context) {
    throw new Error('useCheckoutProvider must be used within a CheckoutProvider');
  }
  return context;
}
export default useCheckoutProvider;
