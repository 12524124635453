import React, {useState} from 'react';
import s from './ResetPasswordPage.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import useAuth from "../../providers/AuthProvider/useAuth";
import {useUtil} from "../../hooks/useUtil/useUtil";
import {useForm} from "react-hook-form";
import Input from "../Inputs/Input";
import NormalText from "../TextComponents/NormalText";
import bases from "../../routes/bases";
import ShowErrors from "../ErrorManager/ShowErrors";
import Button from "../Inputs/Button/Button";
import SmallText from "../TextComponents/SmallText";
import MiddleText from "../TextComponents/MiddleText";
import Header from "../Header/Header";
import logoSrc from "../../resources/images/logo/logo_text.png";
import {Footer} from "../FooterV2/Footer";

const ResetPasswordPage = ({show = true, setPage}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {resetPassword} = useAuth();
  const {requiredMsg} = useUtil();
  const {register, handleSubmit, clearErrors, formState: {errors, isSubmitting}, setError} = useForm();

  const [success, setSuccess] = useState(false);

  const onSubmit = async data => {
    const {error, success} = await resetPassword(data.email);
    if (error || !success) return setError('server', {
      type: 'manual',
      error
    });
    setSuccess(true);
  }

  return (
    <>
      <Header/>
      <div style={{background: '#eff4f2'}}>

        <div className={s.bigContainer}>
          <div className="w-full mx-auto z-10 pt-5 px-10 sm:px-0" style={{maxWidth: 600}}>
            <div className="mt-20">
              <img src={logoSrc} className="m-auto mb-20" alt="Logo" width={300}/>
              {/*
              <Title className={s.titleLogin}>{t("resetPassword.title")}</Title>
*/}

              {!success ? (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Input
                      placeholder={t("email")}
                      {...register('email', {
                        required: requiredMsg('email')
                      })}
                    />

                    <div className="mt-10">
                      <ShowErrors defaultStyle={true} onlyOne={true} errors={errors?.server?.error}/>
                    </div>
                    <div className="mt-10">
                      <Button onClick={() => clearErrors('server')}
                              loading={isSubmitting}>{t("resetPassword.button")}</Button>
                    </div>

                    <div className="w-full text-center mt-12">
                      <NormalText className={s.missingAccount}>
                        {t('login.missing account') + " "}
                        <span className="underline cursor-pointer"
                              onClick={() => navigate(bases.register)}>{t('login.register')}</span>
                      </NormalText>
                    </div>
                  </form>
                ) :
                <MiddleText>{t("reset password done message")}</MiddleText>
              }
            </div>
            <div className="flex flex-wrap justify-around my-5">
              <SmallText className="underline">{t("terms and conditions")}</SmallText>
              <SmallText>{t("copyright trippy")}</SmallText>
            </div>

          </div>

          <div className={s.bannerContainer}>
            <div className={s.banner}>
              <div className={s.bannerText}>{t('login.bannerText')}</div>
              <div className={s.bannerQuote}>{t('login.bannerQuote')}</div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};


export default ResetPasswordPage;
