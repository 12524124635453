import React, {useEffect, useMemo} from 'react';
import s from './SelectOperation.module.css';
import Button from "./Button";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ReactComponent as HotelSVG} from "../../../resources/my_profile_menu/hotels.svg";
//import {ReactComponent as StarSVG} from "../../../resources/svg/star.svg";
import {ReactComponent as PlaneWithLoveSVG} from "../../../resources/svg/plane_with_love.svg";


import bases from "../../../routes/bases";

interface Props {
  isHomePage?: boolean;
  isComingFromHomePage?: boolean;
}

const SelectOperation = ({isHomePage, isComingFromHomePage}: Props) => {
  const {t} = useTranslation();
  const navigate = useNavigate();


  //const routes = ['flights', 'hotels', 'btrip', 'activities', 'unique'];
  //const routes = ['unique', 'btrip', 'hotels', 'activities'];
  const routes = ['syt', 'hotels'];

  const buttons = [
    /*    {icon: (<FlightSVG width={30} height={30}/>), text: t("flights")},*/
    // {icon: (<StarSVG width={40} height={40}/>), text: t("unique experiences")},
    {icon: (<PlaneWithLoveSVG width={40} height={40}/>), text: t("custom trips")},
    /*    {icon: (<PacketSVG width={30} height={30}/>), text: t("build your trip")},*/
    {icon: (<HotelSVG width={40} height={40}/>), text: t("accommodation")},
    /*
				{icon: (<ActivitySVG width={30} height={30}/>), text: t("activities")}
		*/
  ]


  const state = useMemo(() => {
    const current_path = routes.findIndex(v => window.location.pathname.search(v) !== -1);
    return {
      selected: current_path
    }
  }, [window.location.pathname])


  const onClick = (selected: number) => {
    navigate(bases.main + '/' + routes[selected], {state: {fromHomePage: isHomePage}});
  }

  useEffect(() => {
    window.history.replaceState({}, document.title)
  }, []);


  return (
    <div className={s.buttons}>
      {buttons.map((button, i) =>
        <Button key={i}{...button} selected={i === state.selected} onClick={() => onClick(i)}/>
      )}
    </div>
  );
};


export default SelectOperation;
