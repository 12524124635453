import {useInsurance} from "../../../controllers/useInsurance";
import useCheckoutProvider from "../../../providers/CheckoutProvider/useCheckoutProvider";
import {useEffect, useState} from "react";
import {Police} from "../../../types/insurance";

export function useInsuranceData() {
  const [insurances, setInsurances] = useState<Police[]>([]);
  const [loading, setLoading] = useState(false);
  const [lastCountryCodes, setLastCountryCodes] = useState<string[]>([]);
  const [error, setError] = useState<string | undefined>(undefined);

  const {getInsurances} = useInsurance();
  const {
    form,
    persons,
    hotels,
    activities,
    findAllCountryCodes,
    findAllResidentsCountryCodes,
    getFinalDates,
    setSelectedInsurance
  } = useCheckoutProvider();


  const form_changed = form.watch('persons');


  useEffect(() => {
    let countryCodes = findAllResidentsCountryCodes();
    if (countryCodes.length !== lastCountryCodes.length) {
      loadInsurances();
    } else {
      for (let i = 0; i < countryCodes.length; i++) {
        if (!lastCountryCodes.includes(countryCodes[i])) {
          loadInsurances();
          break;
        }
      }
    }
  }, [form_changed, findAllResidentsCountryCodes]);


  const loadInsurances = async () => {
    setLoading(true);
    setError(undefined);
    const dates = getFinalDates();
    if (!dates) return;
    const {from, to} = dates;
    const residenceCountries = findAllResidentsCountryCodes();
    const destinationCountries = findAllCountryCodes();
    if (!residenceCountries.length || !destinationCountries.length) {
      return;
    }

    setLastCountryCodes(residenceCountries);
    const {error, insurances} = await getInsurances({
      from, to, residenceCountries, destinationCountries, paxes: persons.length,
      purchases: [!!hotels?.length, !!activities?.length, false]
    });
    if (error) {
      setError(error);
    } else {
      setInsurances(insurances);
      //setSelectedInsurance(insurances[0]);
    }

    setLoading(false);
  };

  return {insurances, loading, error}
}