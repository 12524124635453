import React from 'react';
import s from './PurchasedHotel.module.css';
import {useTranslation} from "react-i18next";
import moment from "moment";

import {buildImagePath, generateStars, openHotel} from "../../../Hotels/util";
import MiddleText from "../../../TextComponents/MiddleText";

import {ReactComponent as DateSVG} from "../../../../resources/svg/date.svg";
import {ReactComponent as LocationSVG} from "../../../../resources/svg/locationIcon.svg";
import {openMapWithCoordinates} from "../../../../helpers/map";
import {BsCloudDownload} from 'react-icons/bs';
import AsyncIcon from "../../../AsyncIcon";
import {useHotel} from "../../../../controllers/useHotel";
import {useNavigate} from "react-router-dom";
import bases from "../../../../routes/bases";


const PurchasedHotel = ({hotel}) => {
  const {t} = useTranslation();
  const formatDate = date => moment(date).format('ll');

  const navigate = useNavigate();

  const {downloadVoucher} = useHotel();


  let mainImage = React.useMemo(() => {
    if (Array.isArray(hotel.images)) return buildImagePath(hotel, hotel.images[0].path);
  }, [hotel]);

  let stars = React.useMemo(() => generateStars(hotel), [hotel]);


  return (
    <div className={s.hotel}>
      <div className={s.image} style={{backgroundImage: `url(${mainImage})`}}>
        <div className={s.grayBg}/>
        <div className="flex flex-col items-center justify-center w-full h-full relative z-10">
          <div className={s.destinationName}>{hotel.destinationName}</div>
          <div className={s.date}>
            <DateSVG className="mr-5" width={28} height={28}/>
            <div>{`${formatDate(hotel.checkIn)} ${t('to date')} ${formatDate(hotel.checkOut)}`}</div>
          </div>
        </div>
      </div>
      <div className={s.info}>
        {stars.length > 0 && <div className={s.stars}>{stars}</div>}
        <MiddleText className={s.name}
                    onClick={() => openHotel({api: hotel.api, code: hotel.code})}>{hotel.name}</MiddleText>
        <div className={s.locationContainer}>
          <LocationSVG width={20} height={20}/>
          <div className="hover:underline cursor-pointer" onClick={() => openMapWithCoordinates({
            lat: hotel.latitude,
            lng: hotel.longitude
          })}>
            {hotel.address}
          </div>
        </div>
        <div
          className="text-xl mt-5 hover:underline cursor-pointer"
          onClick={() => navigate(`${bases.profile_router}${bases.purchasedHotel}/${hotel.ordered_id}/${hotel.code}/${hotel.api}`)}
        >{t('purchased details data.booking info')}</div>
      </div>
      <div className={s.icons}>
        <AsyncIcon Icon={BsCloudDownload} size={25} className="cursor-pointer" onClick={async () => {
          await downloadVoucher({
            code: hotel.code,
            ordered_id: hotel.ordered_id,
            api: hotel.api,
            title: hotel.name
          });
        }}/>
      </div>

    </div>
  );
};


export default PurchasedHotel;
