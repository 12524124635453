import React, {useEffect} from 'react';
import {AuthContext} from "./AuthContext";
import {useLocalStorage} from "../../hooks/useLocalStorage";

import logger from '../../helpers/Logger';
import {query} from "../../helpers/query";
import {goToLastURLIfExists} from "../../helpers/util";

const AuthProvider = props => {
  const [data, setData, clearData] = useLocalStorage("authData", {});

  const downloadUser = async () => {
    const {error, data} = await query('/api/me', {});
    if (error) {
      logger.error('download user failed');
      setData(prev => ({...prev, user: false}));
      return false;
    }
    setData(prev => ({
      ...prev,
      user: data.user
    }));
    //useEffect(() => {
    goToLastURLIfExists()
    //}, []);
  }

  const register = async user => {
    const {error, data} = await query('/api/register', user);
    return {error, success: data?.success};
  }

  const login = async ({email, password}) => {
    let {error, data} = await query('/api/login', {auth: email, password});

    error = error || data.error;

    if (error) return {error};
    if (data.success) {
      await downloadUser();
      return {success: true};
    } // no errors
  }

  const resetPassword = async email => {
    const {error, data} = await query('/api/password_recovery', {email});
    return {error, success: data?.success};
  }

  const verifyPasswordRecovery = async d => {
    const {error, data} = await query('/api/verify_password_recovery', d, 'POST');
    if (error) return {error};
    return {success: data?.success};
  }

  const logout = async () => {
    await query('/api/logout', {});
    clearData();
    window.location.reload()
  }


  const isAuthenticated = () => !!data?.user;
  const getUser = () => data.user;
  const getData = () => data;

  const getUserFullName = () => {
    return `${data.user.name} ${data.user.surname || ''}`
  }

  useEffect(() => {
    downloadUser();
  }, []);

  return (
    <AuthContext.Provider value={{
      verifyPasswordRecovery,
      isAuthenticated,
      getUser,
      downloadUser,
      login,
      logout,
      register,
      resetPassword,
      getUserFullName,
      getData
    }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
