import React from 'react';
import s from './ProfileOptions.module.css';
import NormalText from "../../TextComponents/NormalText";
import {useTranslation} from "react-i18next";
import useAuth from "../../../providers/AuthProvider/useAuth";
import {NavLink} from "react-router-dom";
import bases from "../../../routes/bases";

import {ReactComponent as MyProfileSVG} from "../../../resources/my_profile_menu/my_profile.svg";
import {ReactComponent as ExperiencesSVG} from "../../../resources/my_profile_menu/experiences.svg";
import {ReactComponent as ActivitiesSVG} from "../../../resources/my_profile_menu/activities.svg";
import {ReactComponent as HotelsSVG} from "../../../resources/my_profile_menu/hotels.svg";
import {ReactComponent as LikesSVG} from "../../../resources/my_profile_menu/likes.svg";
import {ReactComponent as PacketsSVG} from "../../../resources/my_profile_menu/packets.svg";
import {ReactComponent as PlaneSVG} from "../../../resources/my_profile_menu/plane.svg";
import {ReactComponent as SignOffSVG} from "../../../resources/my_profile_menu/sign_off.svg";
import {ReactComponent as TravelSaveSVG} from "../../../resources/svg/travel_save_check.svg";


const ProfileOptions = ({closeMenu}) => {
  const {t} = useTranslation();
  const {logout} = useAuth();


  const wrap = f => () => {
    f();
    closeMenu()
  }


  return (
    <div className={s.container}>
      <NavLink to={bases.profile_router}>
        <div className={s.option} onClick={() => closeMenu()}>
          <MyProfileSVG/>
          <NormalText>{t("profile options.my profile")}</NormalText>
        </div>
      </NavLink>

{/*      <div className={s.option}>
        <PlaneSVG/>
        <NormalText>{t("profile options.my flights")}</NormalText>
      </div>*/}
{/*      <div className={s.option}>
        <PacketsSVG/>
        <NormalText>{t("profile options.my packages")}</NormalText>
      </div>*/}
{/*      <div className={s.option}>
        <ActivitiesSVG/>
        <NormalText>{t("profile options.my activities")}</NormalText>
      </div>*/}
      <NavLink to={bases.profile_hotels}>
      <div className={s.option}>
        <HotelsSVG/>
        <NormalText>{t("profile options.my accommodations")}</NormalText>
      </div>
      </NavLink>
      <NavLink to={bases.profile_likes}>
        <div className={s.option}>
          <LikesSVG/>
          <NormalText>{t("profile options.my likes")}</NormalText>
        </div>
      </NavLink>
      <NavLink to={bases.profile_insurances}>
        <div className={s.option}>
          <TravelSaveSVG/>
          <NormalText>{t("profile options.my insurances")}</NormalText>
        </div>
      </NavLink>
      <div className={s.separator}/>
      <div className={s.option} onClick={wrap(logout)}>
        <SignOffSVG/>
        <NormalText>{t("profile options.sign off")}</NormalText>
      </div>
    </div>
  );
};


export default ProfileOptions;
