import React from 'react';
import s from './FilterUECountries.module.css';
import FilterContainer from "../FilterContainer";
import {useTranslation} from "react-i18next";
import CheckboxFilter from "../FilterContainer/CheckboxFilter";
import map_topojson from '../../../resources/maps/CountriesWorldHighMercator.json';


const FilterUECountries = ({onChange}) => {
	const {t} = useTranslation();

	const getCode = geo => {
		let code = geo.properties.ISO_A2;
		code = code === '-99' ? geo.properties.ISO_A2_EH : code;

		return code === '-99' ? geo.properties.FORMAL_EN : code;
	}

	return (
		<FilterContainer title={t('experience_details.filters.countries')}>
			<CheckboxFilter
				canSearch={true}
				onChange={() => onChange()}
				getData={async () => {
					// de momento eliminar los que se repiten
					let countries = map_topojson.objects.ne_50m_admin_0_countries.geometries.map(geo => ({code: getCode(geo), description: geo.properties.NAME_ES}));
					let countriesSet = new Set();
					countries.forEach(country => countriesSet.add(country.code));
					countries = [...countriesSet].map(code => countries.find(country => country.code === code));
					return {countries};
				}}
				name="countries"
			/>
		</FilterContainer>
	);
};


export default FilterUECountries;