import {query} from "../../helpers/query";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import {useCache} from "../useCache";
import {objIsEqual} from "../../helpers/util";

export const useActivity = () => {
  const [countries, setCountries] = useLocalStorage('activity_countries', []);
  const [activitiesCache, setActivitiesCache] = useCache({
    key: 'activitiesSearch'
  });


  const searchActivityBar = async ({q, signal}) => {
    const {error, data} = await query('/api/sb/activity', {q}, 'POST', {signal});
    return {error, results: data?.results};
  }

  const getCountries = async ({signal} = {}) => {
    if (!countries.length) {
      const {error, data} = await query('/api/activity/types/countries', {}, 'GET', {signal});
      if (error) return [];
      setCountries(data.countries);
      return data.countries;
    }
    return countries;
  }

  const getCategories = async ({signal} = {}) => {
    const {error, data} = await query('/api/activity/types/categories', {}, 'GET', {signal});
    return {error, categories: data?.categories};
  }

  const searchActivities = async ({body, page = 1, filters}) => {
    let request, cache = false;

    let search = {body, page};

    if (filters) {
      body.filters = filters;
      search.filters = filters;
    }

    if (activitiesCache && objIsEqual(activitiesCache.search, search)) {
      request = activitiesCache.data;
      cache = true;
    } else if (!filters) {
      request = await query(`/api/s/activities?page=${page}`, body);
    } else {
      request = await query(`/api/s/activities/filter?page=${page}`, body);
    }

    const {error, data} = request;

    let result = {
      error,
      activities: data?.activities,
      filters: data?.filters,
      total: data?.total,
      itemsPerPage: data?.itemsPerPage
    };

    if (!cache) {
      setActivitiesCache({
        search,
        data: request
      });
    }
    return result;
  }

  const getActivity = async body => {
    const {error, data} = await query('/api/activity', body);
    return {error, activity: data?.activity};
  }
  const getActivitiesByCode = async (activities) => {
    const {error, data} = await query('/api/activity/code', {activities}, 'POST');
    return {error, activities: data?.activities};
  }

  const myProfileFutureActivities = async () => {
    const {error, data} = await query('/api/user/activity', {date: 'future'}, 'POST');
    return {error, activities: data?.activities};
  }

  const myProfilePastActivities = async () => {
    const {error, data} = await query('/api/user/activity', {date: 'past'}, 'POST');
    return {error, activities: data?.activities};
  }

  const likeActivity = async activity => {
    const {error, data} = await query('/api/user/like', {
      code: activity.code,
      type: 'activity',
      provider: activity.provider || 'apitude'
    });
    return {error, success: data?.success};
  }

  const dislikeActivity = async activity => {
    const {error, data} = await query('/api/user/like', {
      code: activity.code,
      type: 'activity',
      provider: activity.provider || 'apitude'
    }, 'DELETE');
    return {error, success: data?.success};
  }

  const downloadVoucher = async ({title, code, purchase_id, api}) => {
    let r = await fetch('/api/user/activity_voucher', {
      method: 'POST',
      mode: 'cors',
      withCredentials: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({code, purchase_id, api})
    });
    let blob = await r.blob();
    /*    downloadjs(blob, title);*/
    blob = blob.slice(0, blob.size, 'application/pdf');
    let fileURL = URL.createObjectURL(blob);
    window.open(fileURL);
  }


  return {
    searchActivityBar,
    getCountries,
    searchActivities,
    getActivity,
    getActivitiesByCode,
    getCategories,
    myProfilePastActivities,
    myProfileFutureActivities,
    likeActivity, dislikeActivity,
    downloadVoucher
  };
}

export default useActivity;
