import React, {Fragment} from 'react';
import s from './HotelDetailsRoomsMobile.module.css';
import {useTranslation} from "react-i18next";
import {useUtil} from "../../../../../hooks/useUtil";
import Slider from "../../../../Slider";
import HotelDetailsRoomStays from "../HotelDetailsRoomStays";
import {ReactComponent as PersonSVG} from "../../../../../resources/my_profile/person.svg";
import CurrencyNumber from "../../../../CurrencyNumber";
import Select from "../../../../Inputs/Select";
import HotelImage from "../../../HotelImage/HotelImage";
import {Hotel, HotelRoom, HotelRoomSelectionResume, HotelRoomWithAllServices} from "../../../../../types/hotels/hotels";
import useHotelsProvider from "../../../../../providers/HotelsProvider/useHotelsProvider";
import {firstCapitalize} from "../../../../../helpers/util";
import {RoomSelectionResume} from "../HotelDetailsRoomsTable/RoomSelectionResume";
import {getRoomQuantityOptions} from "../../util";
import {localizedFormatInOriginalTimezone} from "../../../../../helpers/formatters";


const ImageRoom = ({room, hotel}: { room: HotelRoomWithAllServices, hotel: Hotel }) => {
  const {images} = hotel;
  let room_images = images.filter(image => image.roomCode === room.code);
  //let image = room_images[Math.floor(Math.random() * room_images.length)];
  const image = room_images.sort(image => image.order)[0];
  if (!image) return <></>
  return <div className={s.roomImage}>
    <HotelImage hotel={hotel} imagePath={image.path} alt={room.name}/>
  </div>
}

// const ShowRoomServices = ({services}) => {
//   const {t} = useTranslation();
//
//   return <div>
//     <Spoiler maxHeight={90} showLabel={t('show more')} hideLabel={t('hide')}>
//       {services.map(service => <div
//         className={s.serviceContainer}>
//         <ServiceTick/>{service} </div>)}
//     </Spoiler>
//   </div>
// }

const RateComments = ({rate}: { rate: HotelRoom['rates'][0] }) => {
  const {t} = useTranslation();
  if (!Array.isArray(rate.rateComments) || rate.rateComments.length === 0) return <></>;

  return <div>
    <div className={s.separator}/>
    <div className={s.title}>{t('hotel.rate comments')}</div>
    {rate.rateComments.map(comment => <div>{comment}</div>)}
  </div>
}

const CancellationPolicies = ({rate}: { rate: Hotel['rooms'][0]['rates'][0] }) => {
  const {t} = useTranslation();
  if (rate.rateClass === 'NRF') return <span style={{color: 'rgb(255, 0, 0)'}}>{t('hotel.non refundable')}</span>;


  if (Array.isArray(rate.cancellationPolicies)) {
    const policy = rate.cancellationPolicies.reduce((policy, current) =>
      policy.from < current.from ? policy : current
    );

    return <div className="text-center">
      <div style={{color: 'var(--color-primary)', marginBottom: '5px'}}>{t('hotel cancel')} <span
        style={{fontSize: '14px'}}><b>{t('free')}</b></span> {t('until')}
      </div>
      <div
        className="w-full flex justify-around">
        <div>{localizedFormatInOriginalTimezone(policy.from)}</div>
      </div>
    </div>
  }

  return <></>
}


// const RoomSelectionResume = ({selection}: { selection: HotelRoomSelectionResume }) => {
//   const {t} = useTranslation();
//   const {totalRooms, totalPrice} = selection || {};
//
//   if (!totalRooms) return <div className="text-xl">{t('hotel_rooms.select rooms')}</div>;
//
//   return <div className={s.resumeContainer}>
//     <div>
//       <div className="mb-3">
//         <span className="font-bold text-xl">{totalRooms}</span> {t('hotel_rooms.selected rooms', {count: totalRooms})}
//       </div>
//       <div className="font-bold text-2xl mb-3">
//         <CurrencyNumber className={s.price}>{totalPrice}</CurrencyNumber>
//       </div>
//       <div>{t('hotel_rooms.taxes included')}</div>
//     </div>
//     <div className="p-5">
//       <Button>{t("purchase data.reserve")}</Button>
//     </div>
//   </div>
// }

interface HotelDetailsRoomsTableProps {
  hotel: Hotel;
  roomSelectionResume: HotelRoomSelectionResume,
  setSelectedRoom: (room: HotelRoomWithAllServices) => void;
}


const HotelDetailsRoomsMobile = ({hotel, roomSelectionResume, setSelectedRoom}: HotelDetailsRoomsTableProps) => {
  const {rooms} = hotel;

  const {t} = useTranslation();
  const {hashCode} = useUtil();
  const {selectedRateKeys, setSelectedRateKeys} = useHotelsProvider();


  if (!Array.isArray(rooms)) return <></>;


  const elements = rooms.map((room, i) => <Fragment key={room.code}>
    <div className={s.roomContainer}>
      <ImageRoom room={room} hotel={hotel}/>
      <div className={s.roomHeader} style={{minHeight: 200}}>
        <div className={s.roomName}>{firstCapitalize(room.name)}</div>
        <div className="flex flex-nowrap items-center py-5">
          <PersonSVG fill="#000"/>
          {room.rates[0]?.adults} {t('adult', {count: room.rates[0]?.adults})}
        </div>
        {room.rates[0]?.children > 0 && <div className="flex flex-nowrap items-center py-5">
          <PersonSVG style={{transform: 'scale(0.6)'}} fill="#000"/>
          {room.rates[0]?.children} {t('children', {count: room.rates[0]?.children})}
        </div>}

        <HotelDetailsRoomStays room={room} hotel={hotel}/>
        <div className="flex justify-end">
          <div className={s.showServices}
               onClick={() => setSelectedRoom(room)}>{t('hotel_rooms.more info')}</div>
        </div>
      </div>


      {Array.isArray(room.rates) && room.rates.map((rate, rateN) => {
        const roomQuantityOptions = getRoomQuantityOptions({rate, room, roomSelectionResume, selectedRateKeys});
        return <>
          {!!rateN && <div className={s.separator}/>}
          <div className="p-5">

            <div className="flex items-center justify-around mb-10">
              <div className="pr-3 capitalize">{rate.boardName?.toLowerCase()}</div>
              <div className={s.price}>
                <CurrencyNumber>{rate.finalPrice}</CurrencyNumber>
              </div>
            </div>

            <CancellationPolicies rate={rate}/>
          </div>
          <RateComments rate={rate}/>
          <div className="flex flex-nowrap items-center justify-between px-5">
            <div>{t('hotel_rooms.number of rooms')}</div>
            <div className={s.selectContainer}>
              {/*@ts-ignore*/}
              <Select
                name={hashCode(rate.rateKey) + ''}
                options={roomQuantityOptions}
                onChange={(n: any) => {
                  setSelectedRateKeys((prev: string[]) => {
                    let selected = [...prev].filter(a => a !== rate.rateKey);
                    for (let i = 0; i < n; i++) {
                      selected.push(rate.rateKey);
                    }
                    return selected;
                  });
                }}
              />
            </div>
          </div>
        </>
      })}
    </div>
  </Fragment>);


  return (
    <div className={s.container}>
      <Slider
        navigation
        centeredSlides={false}
        spaceBetween={20}
        autoHeight={false}
        breakpoints={{
          1: {
            centeredSlides: true,
            spaceBetween: 20
          },
          900: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          // 768: {
          //   spaceBetween: 10
          // },
          // 1025: {
          //   spaceBetween: 15
          // },
          // 1280: {
          //   slidesPerView: "auto",
          //   spaceBetween: 20
          // }
        }}
        touchStartPreventDefault={false}
        elements={elements}
      />
      <RoomSelectionResume selection={roomSelectionResume}/>
    </div>
  );
};


export default HotelDetailsRoomsMobile;