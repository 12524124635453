import {Controller} from "react-hook-form";
import s from '../HotelPassengerNumber.module.css'
import React from "react";
import {ReactComponent as NPlusSVG} from "../../../../resources/svg/n_plus.svg";
import {ReactComponent as NMinusSVG} from "../../../../resources/svg/n_minus.svg";

interface HotelPassengerNumberInputProps {
  name: string
  text: string
}

export function HotelPassengerNumberCounter({name, text}: HotelPassengerNumberInputProps) {
  return <Controller
    render={({field}) => <div className={s.element}>
      <div style={{flex: '0 0 80px'}}>
        {text}
      </div>
      <div className="w-full flex items-center justify-end flex-nowrap">
        <button type="button" onClick={() => field.onChange(field.value - 1)}>
          <NMinusSVG/>
        </button>

        <div className="text-center" style={{flex: '0 0 20px'}}>{field.value}</div>

        <button type="button" onClick={() => field.onChange(field.value + 1)}>
          <NPlusSVG/>
        </button>
      </div>
    </div>}
    name={name}
  />
}