import React from 'react';

const BritishFlag = ({width, height}) => {
    return (
      <svg width="25" height="17" viewBox="0 0 25 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0V17H25V0H0Z" fill="#012169"/>
        <path d="M0 0L25 17L0 0ZM25 0L0 17L25 0Z" fill="black"/>
        <path d="M24.4 18.5L12.5 10.4L0.599994 18.5L-0.600006 15.5L9.69999 8.5L-0.600006 1.5L0.599994 -1.5L12.5 6.6L24.4 -1.5L25.6 1.5L15.3 8.5L25.6 15.5L24.4 18.5Z" fill="white"/>
        <path d="M-1.52588e-05 0L25 17L-1.52588e-05 0ZM25 0L-1.52588e-05 17L25 0Z" fill="black"/>
        <path d="M24.6 18L12.5 9.8L0.399991 18L-0.400009 16L10.6 8.5L-0.400009 1L0.399991 -1L12.5 7.2L24.6 -1L25.4 1L14.4 8.5L25.4 16L24.6 18Z" fill="#C8102E"/>
        <path d="M12.5 0V17V0ZM0 8.5H25H0Z" fill="black"/>
        <path d="M14.6 17H10.4V11.3H0V5.7H10.4V0H14.6V5.7H25V11.3H14.6V17Z" fill="white"/>
        <path d="M12.5 0V17V0ZM0 8.5H25H0Z" fill="black"/>
        <path d="M13.8 17H11.2V10.2H0V6.8H11.2V0H13.8V6.8H25V10.2H13.8V17Z" fill="#C8102E"/>
      </svg>

    );
};


export default BritishFlag;
