import dayjs, {Dayjs} from "dayjs";

export function getOriginalTimeZoneDayjs(date: string): Dayjs {
  const originalTimezone = date.slice(-6);
  return dayjs(date).utcOffset(originalTimezone)
}


// date example: 2024-07-12T23:59:00+02:00
export function localizedFormatInOriginalTimezone(date: string): string {
  return getOriginalTimeZoneDayjs(date).format('LLL')
}

export function simplifiedLocalizedFormatInOriginalTimezone(date: string): string {
  return getOriginalTimeZoneDayjs(date).format('L')
}