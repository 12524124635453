import React from 'react';
import s from './PurchasedActivity.module.css';
import {findImageResource, findMainDestination, findMapCoordinates, openActivity} from "../../../../helpers/activities";
import {ReactComponent as DateSVG} from "../../../../resources/svg/date.svg";
import moment from "moment";
import {useTranslation} from "react-i18next";
import MiddleText from "../../../TextComponents/MiddleText";
import {ReactComponent as LocationSVG} from "../../../../resources/svg/locationIcon.svg";
import {openMapWithCoordinates} from "../../../../helpers/map";
import {cutText, removeHTMLTags} from "../../../../helpers/util";
import AsyncIcon from "../../../AsyncIcon";
import {BsCloudDownload} from "react-icons/bs";
import useActivity from "../../../../controllers/useActivity/useActivity";

const PurchasedActivity = ({activity}) => {
  const {t} = useTranslation();
  const formatDate = date => moment(date).format('ll');

  const {downloadVoucher} = useActivity();


  let mainImage = React.useMemo(() => {
    return findImageResource(activity.media.images[0]);
  }, [activity]);
  let destination = React.useMemo(() => {
    let r = findMainDestination(activity) || {};
    r.coordinates = findMapCoordinates(activity);
    return r;
  }, [activity]);

  return (
    <div className={s.activity}>
      <div className={s.image} style={{backgroundImage: `url(${mainImage})`}}>
        <div className={s.grayBg}/>
        <div className="flex flex-col items-center justify-center w-full h-full relative z-10">
          <div className={s.destinationName}>{destination.city}</div>
          <div className={s.date}>
            <DateSVG className="mr-5" width={28} height={28}/>
            <div>{`${formatDate(activity.dateFrom)} ${t('to date')} ${formatDate(activity.dateTo)}`}</div>
          </div>
        </div>
      </div>
      <div className={s.info}>
        <MiddleText className={s.name}
                    onClick={() => openActivity({
                      api: activity.api,
                      code: activity.activityCode
                    })}>{activity.name}</MiddleText>
        {activity.description &&
          <div className={s.description}>{cutText(removeHTMLTags(activity.description), 300)}</div>}
        <div className={s.locationContainer}>
          <LocationSVG width={20} height={20}/>
          <div className="hover:underline cursor-pointer" onClick={() => openMapWithCoordinates({
            lat: destination?.coordinates?.center?.lat,
            lng: destination?.coordinates?.center?.lng,
            text: destination?.text
          })}>
            {t('purchased activity data.address')}, {destination?.text}
          </div>
        </div>
      </div>
      <div className={s.icons}>
        <AsyncIcon Icon={BsCloudDownload} size={25} className="cursor-pointer" onClick={async () => {
          await downloadVoucher({
            code: activity.activityCode,
            purchase_id: activity.purchase_id,
            api: activity.api,
            title: activity.name
          });
        }}/>
      </div>

    </div>
  );
};


export default PurchasedActivity;
