import React from 'react';
import s from './GoogleButton.module.css';
import useUser from "../../../controllers/useUser/useUser";

const GoogleButton = ({className = ''}) => {
  const {googleLogin} = useUser();

  const onMouseUp = () => {
    googleLogin();
  }


  return (
    <div className={`${s.button} ${className}`} onMouseUp={onMouseUp}>
      <div className={s.logo}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.92261 8.1498C8.08792 6.6168 9.93021 5.62501 12 5.62501C13.7028 5.62501 15.3037 6.28811 16.5079 7.49219L17.005 7.98935L20.9825 4.0119L20.4854 3.51474C18.2189 1.24824 15.2054 0 12 0C8.7947 0 5.78124 1.24824 3.51469 3.5147C3.30896 3.72048 3.11185 3.93254 2.92303 4.15018L6.92261 8.1498Z"
            fill="white"/>
          <path
            d="M19.8498 21.0769C20.0674 20.8881 20.2796 20.691 20.4853 20.4853C22.7518 18.2187 24 15.2053 24 12C24 11.2699 23.9339 10.5384 23.8035 9.82603L23.698 9.24951H11.2969V14.8745H17.6931C17.2507 15.7545 16.6179 16.5048 15.8556 17.0828L19.8498 21.0769Z"
            fill="white"/>
          <path
            d="M14.6064 17.8221C13.8052 18.1795 12.9214 18.3749 12 18.3749C9.93021 18.3749 8.08792 17.3831 6.92261 15.8501L2.92303 19.8497C3.11185 20.0673 3.30896 20.2794 3.51469 20.4852C5.78124 22.7517 8.79471 23.9999 12 23.9999C14.4336 23.9999 16.7562 23.2798 18.7256 21.9414L14.6064 17.8221Z"
            fill="white"/>
          <path
            d="M6.18109 14.603C5.82395 13.8078 5.62501 12.9267 5.62501 12C5.62501 11.0733 5.82395 10.1922 6.18109 9.39699L2.05852 5.27441C0.720142 7.24387 0 9.56644 0 12C0 14.4336 0.720142 16.7561 2.05852 18.7256L6.18109 14.603Z"
            fill="white"/>
        </svg>
      </div>
      <div className={s.text}>Google</div>
    </div>
  );
};


export default GoogleButton;
