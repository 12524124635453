import React, {useEffect, useState} from 'react';
import s from './UniqueExperienceHotels.module.css';
import {useTranslation} from "react-i18next";
import {useHotel} from "../../../controllers/useHotel";
import Slider from "../../Slider";
import SmallHotel from "../../Hotels/SmallHotel";
import Skeleton from "react-loading-skeleton";


const Loading = () => <div>
  <Skeleton count={1} height={200}/>
</div>

const UniqueExperienceHotels = ({hotels: _hotels, toLoad = false}) => {
  const {t} = useTranslation();

  const {getHotelsByCode} = useHotel();
  const [hotels, setHotels] = useState();
  const [error, setError] = useState(false);


  const loadHotels = async () => {
    const {error, hotels} = await getHotelsByCode(_hotels.map(h => ({
      code: h.hotel_code,
      id: h.hotel_id,
      api: h.api || h.type,
    })));
    if (error) return setError(error);


    setHotels(hotels);
  }

  useEffect(() => {
    if (_hotels && toLoad) {
      loadHotels();
    } else if (_hotels) {
      setHotels(_hotels);
    }
  }, [_hotels]);

  if (!Array.isArray(hotels) && !error) return <Loading/>;

  if (error) return <div className="text-2xl m-10">{t(`errors.${error}`)}</div>

  return (
    <div className={s.container}>
      <Slider elements={hotels.map((hotel, i) => <SmallHotel showMore={true} key={i} hotel={hotel}/>)}/>
    </div>
  );
};


export default UniqueExperienceHotels;