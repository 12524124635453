import React from 'react';
import {Hotel, HotelRoomSelectionResume, HotelRoomWithAllServices} from "../../../../../types/hotels/hotels";
import {RoomSelectionResume} from "./RoomSelectionResume";
import {RoomsHeader} from "./RoomsHeader";
import {Room} from "./Room";

interface HotelDetailsRoomsTableProps {
  hotel: Hotel;
  roomSelectionResume: HotelRoomSelectionResume,
  setSelectedRoom: (room: HotelRoomWithAllServices) => void;
}

const HotelDetailsRoomsTable = ({hotel, setSelectedRoom, roomSelectionResume}: HotelDetailsRoomsTableProps) => {
  const {rooms} = hotel;
  if (!Array.isArray(rooms)) return <></>;

  return <div className="flex flex-nowrap items-stretch">
    <div style={{width: '85%'}}>
      <RoomsHeader hotel={hotel}/>
      {Array.isArray(rooms) && rooms.map((room, roomN) => <Room
        key={roomN}
        roomSelectionResume={roomSelectionResume}
        hotel={hotel}
        room={room}
        setSelectedRoom={setSelectedRoom}
      />)}
    </div>
    <RoomSelectionResume selection={roomSelectionResume}/>
  </div>
};


export default HotelDetailsRoomsTable;