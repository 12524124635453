import React from 'react';
import s from './PurchasedInsurance.module.css';
import CurrencyNumber from "../../../CurrencyNumber";
import {BsCloudDownload} from "react-icons/bs";
import dayjs from "dayjs";

import {ReactComponent as DateSVG} from "../../../../resources/svg/date.svg";
import {useTranslation} from "react-i18next";
import {useInsurance} from "../../../../controllers/useInsurance";
import {InsuredPersons} from "./insuredPersons/insuredPersons";
import {ContractedInsurance} from "../../../../controllers/useInsurance/types";
import {Loader} from "@mantine/core";


interface Props {
  insurance: ContractedInsurance;
  containerClassName?: string;
}

const PurchasedInsurance = ({insurance, containerClassName = ''}: Props) => {
  const {t} = useTranslation();
  const [documentLoading, setDocumentLoading] = React.useState<Record<string, boolean>>({});

  const formatDate = (a: Date) => dayjs(a).format('LL');

  const {downloadDocument} = useInsurance();

  const downloadDocumentClicked = async (document_id: number) => {
    setDocumentLoading(prev => ({...prev, [document_id]: true}))
    try {
      await downloadDocument({document_id: String(document_id)})
    } catch (error) {
      alert(t('errors.unexpected error'))
    }
    setDocumentLoading(prev => ({...prev, [document_id]: false}))
  }

  return (
    <div key={insurance.id} className={`${s.container} ${containerClassName}`}>
      <div className={s.background}>
        <img src={insurance.background} alt="insurance background"/>
      </div>
      {/*<div className={s.logo}>*/}
      {/*  <img src={insurance.logo} alt="Insurance logo"/>*/}
      {/*</div>*/}
      <div className="p-5">
        <div className={s.name}>{insurance.name}</div>

        <div className={s.date}>
          <DateSVG style={{stroke: 'var(--color-primary)'}} className="mr-5" width={28} height={28}/>
          <div>{`${formatDate(insurance.from)} ${t('to date')} ${formatDate(insurance.to)}`}</div>
        </div>

        <div className="mt-5 mb-2">{t('insurance page.policy number')}: <b>{insurance.policyNumber}</b></div>
        <div className="mb-2">{t('insurance page.tracking number')}: <b>{insurance.tracking_number}</b></div>
        {/*<div*/}
        {/*  className="my-5">{t('insurance page.locations.title')}: <b>{t(`insurance page.locations.${insurance.location}`)}</b>*/}
        {/*</div>*/}
        <div>
          {t('insurance page.price')}:
          <b> {insurance.price ?
            <CurrencyNumber>{insurance.price}</CurrencyNumber> : t('insurance page.included')}</b>
        </div>


        <InsuredPersons insurance={insurance}/>

        {insurance.documents.length > 0 && <>
          <div className="font-bold text-2xl">{t('insurance page.documents')}</div>
          {insurance.documents.map((document, i) => <div key={i} className="mt-5">
              {documentLoading[document.id] ? <Loader color="teal" size="sm"/> :
                <div className="flex items-center font-bold cursor-pointer" style={{width: 'fit-content'}}
                     onClick={() => downloadDocumentClicked(document.id)}>
                  <BsCloudDownload/>
                  <div className="ml-5 hover:underline">{document.name}</div>
                </div>}
            </div>
          )}
        </>
        }
        {/*{insurance.company && <>*/}
        {/*  <hr className="my-5"/>*/}
        {/*  <div className="font-bold text-2xl">{t('insurance page.company')}</div>*/}
        {/*  <div className="mt-5">{insurance.company.name}</div>*/}
        {/*  <div className="mt-5">*/}
        {/*    <Spoiler maxHeight={200} showLabel={t('show more')}*/}
        {/*             hideLabel={t('hide')}>{insurance.company.legalDescription}</Spoiler>*/}
        {/*  </div>*/}
        {/*</>*/}
        {/*}*/}
      </div>
    </div>
  );
};


export default PurchasedInsurance;
