import {Hotel} from "../../../../../types/hotels/hotels";
import {useTranslation} from "react-i18next";
import s from "./HotelDetailsRoomsTable.module.css";
import React from "react";
import QuestionPopover from "../../../../QuestionPopover";

//const Arrow = () => <ArrowSVG className={s.arrow} width={23} height={23}/>;
const Arrow = () => <></>

export const RoomsHeader = ({hotel}: {
  hotel: Hotel
}) => {
  const {t} = useTranslation();

  return <div className={s.headerContainer}>
    <div className={s.th} style={{flex: '0 0 calc(17% + 1.25rem)'}}>
      <div>{t('hotel_rooms.type of room')}</div>
      <Arrow/>
    </div>
    <div className="w-full flex items-center">
      <div className={s.th} style={{width: '20%'}}>
        {t('hotel_rooms.persons')}
        <Arrow/>
      </div>
      <div className={s.th} style={{width: '20%'}}>
        {t('hotel_rooms.price')}
        <Arrow/>
      </div>
      <div className={s.th} style={{width: '20%'}}>
        {t('hotel_rooms.regime')}
        <Arrow/>
      </div>
      <div className={s.th} style={{width: '20%'}}>
        <div className="flex items-center justify-center">
          <div className="mr-2 text-center">{t('hotel_rooms.cancellation policies')}</div>
          <QuestionPopover><p className="text-xl">{t('hotel_rooms.destination dates')}</p></QuestionPopover>
          <Arrow/>
        </div>
      </div>
      <div className={s.th} style={{width: '20%'}}>
        {t('hotel_rooms.number of rooms')}
        <Arrow/>
      </div>
    </div>
  </div>
}
