import s from "./RegisterPage.module.css";
import logoSrc from "../../resources/images/logo/logo_text.png";
import Input from "../../components/Inputs/Input";
import PasswordInput from "../../components/Inputs/PasswordInput";
import Checkbox from "../../components/Inputs/Checkbox";
import ShowErrors from "../../components/ErrorManager/ShowErrors";
import Button from "../../components/Inputs/Button/Button";
import NormalText from "../../components/TextComponents/NormalText";
import FacebookButton from "../../components/Inputs/FacebookButton";
import GoogleButton from "../../components/Inputs/GoogleButton";
import bases from "../../routes/bases";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {useUtil} from "../../hooks/useUtil";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import useAuth from "../../providers/AuthProvider/useAuth";
import SmallText from "../../components/TextComponents/SmallText";
import {getGoToLoginPageURL} from "../../helpers/util";

export function RegisterForm({setVisible}) {
  const {register, formState: {errors}, setError, getValues} = useForm();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {register: registerUser} = useAuth();

  const {requiredMsg} = useUtil();

  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    let user = getValues();

    if (!user.acceptTerms) {
      alert(t('errors.account.accept terms'));
      await setLoading(false);
      return;
    }


    const {error, success} = await registerUser(user);
    setLoading(false);

    if (error) return setError('server', {
      type: 'manual',
      error
    });
    if (success) {
      alert(t('register.success'));
      //await downloadUser();
      navigate(`${bases.auth}/login`);
      setVisible(false)
    }
  }

  const goToLogin = () => {
    navigate(getGoToLoginPageURL());
    setVisible(false);
  }

  return <div>
    <div className={s.formContainer}>

      <img src={logoSrc} className="m-auto mb-20" alt="Logo" width={300}/>
      {/*
      <Title className={s.titleLogin}>{t("register.title")}</Title>
*/}
      <form>
        <div className="w-full flex flex-wrap sm:flex-nowrap">
          <Input
            placeholder={t("name")}
            containerClassName="w-full"
            {...register('name', {
              required: requiredMsg('name')
            })}
          />
          <Input
            placeholder={t("surname")}
            containerClassName="w-full mt-8 sm:mt-0 sm:ml-2"
            {...register('surname', {
              required: requiredMsg('surname')
            })}
          />
        </div>
        <Input
          placeholder={t("email")}
          containerClassName="mt-8"
          {...register('email', {
            required: requiredMsg('email')
          })}
        />
        <PasswordInput
          containerClassName="mt-8"
          placeholder={t("password")}
          {...register('password', {
            required: requiredMsg('password')
          })}
        />

        <div className="mt-8 flex">
          <Checkbox
            id="acceptTerms"
            label={t('register.acceptTerms')}
            {...register('acceptTerms')}
          />
        </div>

        <ShowErrors className={s.errors} defaultStyle={true} onlyOne={true} errors={errors?.server?.error}/>
        <div className="mt-10 mx-auto" style={{maxWidth: 300}}>
          <Button type="button" loading={loading}
                  onClick={() => onSubmit()}>{t("register.register_button")}</Button>
        </div>


        <div className="flex flex-nowrap mt-10">
          <div className={s.smallBar}/>
          <NormalText className="w-full text-center"
                      style={{color: '#979797'}}>{t("register.register alternatives")}</NormalText>
          <div className={s.smallBar}/>
        </div>

        <div className="flex p-5">
          <FacebookButton/>
          <GoogleButton className="ml-10"/>
        </div>

        <div className="w-full text-center mt-12">
          <NormalText className={s.missingAccount}>
            {t('register.already have account') + " "}
            <span className="underline cursor-pointer"
                  onClick={goToLogin}>{t('initiate a session')}</span>
          </NormalText>
        </div>
      </form>

    </div>

    <div className="flex flex-wrap justify-around my-5">
      <SmallText className="underline"><a href={`${window.location.origin}${bases.terms}`}
                                          target="_blank"
                                          rel="noreferrer">{t("terms and conditions")}</a></SmallText>
      <SmallText>{t("copyright trippy")}</SmallText>
    </div>
  </div>
}