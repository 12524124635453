import {useEffect} from "react";
import {useHotel} from "./controllers/useHotel";


interface Props {
  setLoading: (loading: boolean) => void,
  children: any
}

export function StartApp({setLoading, children}: Props) {
  const {getTypes} = useHotel();

  const loadData = async () => {
    setLoading(true);
    await getTypes()
    setLoading(false);
  }

  useEffect(() => {
    loadData();
  }, []);

  return <>{children}</>
}