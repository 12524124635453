import React, {useEffect, useState} from 'react';
import s from './SmallHotel.module.css';
import {useTranslation} from "react-i18next";
import {useHotel} from "../../../controllers/useHotel";
import {createOutstandingServices, generateStars} from "../util";
import Skeleton from "react-loading-skeleton";
import {Modal} from "@mantine/core";
import HotelDetails from "../HotelDetails";
import {langRender} from "../../../helpers/i18n";
import HotelImage from "../HotelImage/HotelImage";


const Loading = () => <div>
  <Skeleton count={1} height={200}/>
</div>

const SmallHotel = ({hotel: _hotel, toLoad = false, showMore = false, onShowMore}) => {
  const {t} = useTranslation();

  const {getHotel} = useHotel();

  const [hotel, setHotel] = useState(_hotel);
  const [error, setError] = useState();
  const [modal, setModal] = useState(false);

  const loadHotels = async () => {
    const {error, hotels} = await getHotel(_hotel);
    if (error) return setError(error);

    setHotel(hotels);
  }

  useEffect(() => {
    if (_hotel && toLoad) {
      loadHotels();
    } else if (_hotel) {
      setHotel(_hotel);
    }
  }, [_hotel]);

  const _onShowMore = () => {
    if (onShowMore) return onShowMore(hotel);
    setModal(true);
  }


  if (!hotel && !error) return <Loading/>;

  if (error) return <div className="text-2xl m-10">{t(`errors.${error}`)}</div>


  return (
    <>
      <Modal
        opened={modal}
        onClose={() => setModal(false)}
        closeOnEscape={true}
        closeOnClickOutside={true}
        size="full"
        styles={{
          modal: {
            background: 'rgb(246, 246, 246)'
          }
        }}
      >
        <HotelDetails hotel={hotel} canBuy={false} toLoad={false}/>
      </Modal>
      <div className={s.container}>
        <div className={s.image}>
          <HotelImage hotel={hotel} alt={`${hotel.name} main`} imagePath={hotel.images[0]?.path}/>
        </div>
        <div className="w-full pl-5">
          <div className={s.title}>{langRender(hotel.name)}</div>
          <div className="w-full flex flex-nowrap relative">
            {generateStars(hotel)}
          </div>

          <div className="w-full flex items-center">
            {createOutstandingServices(hotel.services).slice(0, 6).map((service, i) => <div className="mr-5 my-3"
                                                                                            key={i}>
              {service.icon}
            </div>)}
          </div>
          {showMore && <div className={s.show_more} onClick={() => _onShowMore()}>{t('hotel.show more')}</div>}
        </div>
      </div>
    </>

  );
};


export default SmallHotel;