import {Hotel} from "../types/hotels/hotels";
import {useCallback, useState} from "react";

interface Props {
  images: Hotel['images']; // or other images type later
  onChange?: (images: Hotel['images']) => void
}

export function useImages({images, onChange}: Props) {
  const [loadedImages, setLoadedImages] = useState<Hotel['images']>(images);

  const onFailed = useCallback((index: number) => {
    setLoadedImages(prev => {
      const newImages = [...prev];
      newImages.splice(index, 1);
      onChange?.(newImages);
      return newImages;
    });
  }, [setLoadedImages]);


  return {
    images: loadedImages,
    setImages: setLoadedImages,
    onFailed
  }

}