import moment from "moment";

export const findImageResource = image => {
  let im = image.urls.find(url => url.sizeType === 'LARGE2');
  if (im) return im.resource;

  // find larger
  let l = 0;
  let i = 0;
  image.urls.forEach((url, j) => {
    if (url.width + url.height > l) {
      l = url.width + url.height;
      i = j;
    }
  });
  return image[i].resource;
}

export const findMainDestination = activity => {
  let destination = {city: false, country: false};
  if (Array.isArray(activity.countries)) {
    activity.countries.forEach(c => {
      destination.country = c.name;
      if (Array.isArray(c.destinations) && c.destinations.length > 0) destination.city = c.destinations[0].name;
    });
  }

  if ((!destination.city || !destination.country) && activity?.address?.raw) {
    let country = activity?.address?.raw?.country;
    if (country && !destination.country) destination.country = country.name;
    if (!destination.city && Array.isArray(country?.destinations) && country.destinations.length > 0) destination.city = country.destinations[0].name;
  }

  destination.text = '';
  if (destination.country) destination.text = destination.country;
  if (destination.city) destination.text += ', ' + destination.city;

  return destination;
}
export const openActivity = ({api, code}) => {
  let today = encodeURIComponent(new Date().toISOString());
  window.open(`${window.location.origin}/i/activity?to=${today}&from=${today}&persons=${encodeURIComponent(JSON.stringify([18]))}&code=${code}&api=${api}`);
}

export const findMapCoordinates = activity => {
  if (!activity) return;
  let r = {
    marker: {name: activity?.address?.address},
    center: {
      lat: activity?.address?.geolocation?.latitude,
      lng: activity?.address?.geolocation?.longitude,
    }
  };
  if (!r.center.lat && Array.isArray(activity.routes) && Array.isArray(activity.routes[0]?.points)) {
    r.center = {
      lat: activity.routes[0].points[0]?.pointOfInterest?.geolocation?.latitude,
      lng: activity.routes[0].points[0]?.pointOfInterest?.geolocation?.longitude
    };
  }

  if (!r.marker.name) r.marker = null;

  return r;
}
