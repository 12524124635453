import React, {useEffect, useState} from 'react';
import s from './SuccessPurchase.module.css';
import {useTranslation} from "react-i18next";
import {ReactComponent as SuccessPurchaseSVG} from "../../../resources/svg/success_purchase.svg";
import {ReactComponent as InfoSVG} from "../../../resources/svg/small_info.svg";
import {Loader} from "@mantine/core";
import {useSearchParams} from "react-router-dom";
import {usePurchase} from "../../../controllers/usePurchase";
import useCheckoutProvider from "../../../providers/CheckoutProvider/useCheckoutProvider";


const LoadingResponse = () => {
  const {t} = useTranslation();
  return <div className={s.container}>
    <div className="w-full flex flex-col items-center justify-center">
      <div className={s.thanks}>{t('checkout.thanks for buying')}</div>
      <Loader color="teal" className="my-5"/>
    </div>
    <div className="text-2xl my-5">{t('checkout.payment processing')}</div>
  </div>
}


interface FailedResponseProps {
  errorMessage?: string
  trackingNumber?: string
}

const FailedResponse = ({errorMessage, trackingNumber}: FailedResponseProps) => {
  const {t} = useTranslation();
  return <div className={s.container} style={{color: '#EC1F46'}}>
    <div className="w-full flex justify-center mb-8">
      <InfoSVG width={48} height={48} style={{fill: '#707070'}}/>
    </div>
    <div className="text-2xl my-5">{t('checkout.tracking number')}</div>
    <div className={s.trackingNumber}>{trackingNumber}</div>
    <div className="text-4xl font-bold mb-5">{t('checkout.error title')}</div>
    {errorMessage && <div className="text-2xl">{t(`errors.${errorMessage}`)}</div>}
  </div>
}

interface SuccessResponseProps {
  trackingNumber: string
  order_id: string
}

const SuccessResponse = ({trackingNumber}: SuccessResponseProps) => {
  const {t} = useTranslation();

  return <div className={s.container}>
    <div className="w-full flex justify-center mb-8">
      <SuccessPurchaseSVG/>
    </div>
    <div className={s.thanks}>{t('checkout.thanks for buying')}</div>
    <div className="text-2xl my-5">{t('checkout.tracking number')}</div>
    <div className={s.trackingNumber}>{trackingNumber}</div>
    <div className="text-2xl my-5">{t('checkout.success email confirmation')}</div>
    {/*<div><a href={`mailto:${email}`} className="text-3xl hover:underline">{email}</a></div>*/}
  </div>
}

enum STATUS_CODES {LOADING, SUCCESS, ERROR}

const SuccessPurchase = () => {
  const [params] = useSearchParams();

  const {clearPurchaseData} = useCheckoutProvider();

  const [status, setStatus] = useState<STATUS_CODES>(STATUS_CODES.LOADING); // 0 - loading, 1 - success, 2 - error
  const [error, setError] = useState<string | undefined>();
  const [intID, setIntID] = useState<number>();
  const [trackingNumber, setTrackingNumber] = useState<string | undefined>();

  const {getPurchaseStatus, confirmPaymentForDebug} = usePurchase();

  useEffect(() => {
    clearPurchaseData();
  }, []);

  let order_id = params.get('order_id');

  const checkStatus = async () => {
    try {
      const {loading, success, errors, tracking_number} = await getPurchaseStatus({order_id: order_id!});
      setTrackingNumber(tracking_number);

      if (error) {
        return setError(error);
      }

      if (loading) return setStatus(STATUS_CODES.LOADING);
      if (success) {
        clearPurchaseData();
        clearInterval(intID);
        return setStatus(STATUS_CODES.SUCCESS);
      }

      if (errors?.length > 0) {
        setStatus(STATUS_CODES.ERROR);
        return setError(errors[0].message);
      }

      setStatus((error || !success) ? STATUS_CODES.ERROR : STATUS_CODES.SUCCESS);
      setError(error);
    } catch (error) {
      setStatus(STATUS_CODES.ERROR);
      setError((error as Error).message);
    }
  }


  const startCheckStatus = async () => {
    if (intID) {
      clearInterval(intID);
    }
    if (status === STATUS_CODES.SUCCESS) return;

    setIntID(window.setInterval(async () => {
      await checkStatus();
    }, 2000));
  }

  useEffect(() => {
    startCheckStatus();
    return () => {
      clearInterval(intID);
    }
  }, []);

  useEffect(() => {
    if (status !== STATUS_CODES.LOADING) {
      clearInterval(intID);
    }
  }, [status]);

  if (!order_id) return <FailedResponse errorMessage={'order not found'}/>

  if (status === STATUS_CODES.ERROR) return <FailedResponse trackingNumber={trackingNumber} errorMessage={error}/>

  if (status === STATUS_CODES.LOADING) return <LoadingResponse/>

  return <SuccessResponse order_id={order_id} trackingNumber={trackingNumber!}/>
};


export default SuccessPurchase;
