import React, {useState} from 'react';
import s from './Footer.module.css';

import Logo from '../../resources/images/logo/logo_no_text.png'
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import bases from "../../routes/bases";

import {ReactComponent as MessageBoxSVG} from "../../resources/svg/message_box.svg";
import {ReactComponent as FacebookSVG} from "../../resources/svg/social_media/facebook.svg";
import {ReactComponent as InstagramSVG} from "../../resources/svg/social_media/instagram.svg";
import {ReactComponent as YoutubeSVG} from "../../resources/svg/social_media/youtube.svg";
import {ReactComponent as EmailIconSVG} from "../../resources/svg/footer/email.svg";
import {ReactComponent as SocialMediaSVG} from "../../resources/svg/footer/social_media.svg";
import {ReactComponent as ArrowSVG} from "../../resources/svg/arrow_button_right.svg";


const Section = ({title, links}) => {
  const [visible, setVisible] = useState(false);

  return (
    <div className={`${s.section} ${visible ? s.section_visible : ''}`}>
      <div onClick={() => setVisible(prev => !prev)} className={s.section_title}>
        {title}
        <div className={s.section_arrow}>
          <ArrowSVG style={{fill: '#000'}} width={20} height={20}/>
        </div>
      </div>
      <div className={s.section_links}>
        {links.map((link, i) => <NavLink key={i} to={link.url || bases.main}>{link.text}</NavLink>)}
      </div>
    </div>
  );
}

const Footer = props => {
  const {t} = useTranslation();

  return (
    <div className={s.container}>
      <div className="flex flex-wrap lg:flex-nowrap 2xl:w-10/12 m-auto p-5 sm:p-20">
        <div className={s.logo_container}><img src={Logo}/></div>
        <div className="w-full flex justify-between items-baseline flex-wrap sm:flex-nowrap">
          <Section title={t('footer.sections')} links={[
            {text: t('footer.unique experiences')},
            {text: 'Trippy Divers'},
            {text: t('footer.who are we')},
            {text: t('footer.contact us'), url: bases.contact_us},
            {text: t('footer.my profile')},
          ]}/>

          <Section title={t('footer.important information')} links={[
            {text: t('footer.frequent questions')},
            {text: t('footer.travel community')},
            {text: t('footer.terms and conditions'), url: bases.terms},
            {text: t('footer.disclaimer')},
            {text: t('footer.cancellation policies'), url: bases.terms},
            {text: t('footer.privacy'), url: bases.terms},
          ]}/>

          <div className="w-full">
            <div className={s.section_title2}>{t('footer.contact')}</div>
            <div className="mt-5">
              <div className={s.email}>
                <MessageBoxSVG style={{fill: '#4F4F4F'}}/>
                <a href="mailto:contacto@trippy_trekkers.com">contacto@trippy_trekkers.com</a>
              </div>
            </div>
            <div className="flex w-full my-10">
              <div className={s.text}>{t('footer.follow us')}</div>
              <div className="flex w-full">
                <FacebookSVG className="mr-5 cursor-pointer" width={30} height={30}/>
                <YoutubeSVG className="mr-5 cursor-pointer" width={30} height={30}/>
                <InstagramSVG className="cursor-pointer" width={30} height={30}/>
              </div>
            </div>

            <div>
              <div className={s.text}>{t('footer.newsletter')}</div>
              <div className={s.input_container}>
                <input
                  type="email"
                  placeholder="E-mail"
                />
                <div>
                  <EmailIconSVG height={50} width={25}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full items-center flex flex-wrap sm:flex-nowrap justify-around items-center pb-10">
        <div className={s.rights_reserved}>{t('footer.rights reserved')}</div>
        <div className="order-1 sm:order-2 mt-10 sm:mt-0">
          <SocialMediaSVG/>
        </div>
      </div>
    </div>
  );
};


export default Footer;
