import React, {Fragment} from 'react';
import {useTranslation} from "react-i18next";

const PurchasedHotelDetailsGuests = ({hotel}) => {
  const {t} = useTranslation();

  let guests = 0;

  return (
    <div className="mt-10">
      <div className="font-bold text-3xl my-5">{t('purchased details data.guests')}</div>
      <div className="flex flex-wrap" style={{width: 'fit-content', minWidth: '450px'}}>
        {Array.isArray(hotel.rooms) && hotel.rooms.map((room, i) => <Fragment key={i}>
          {room.paxes.map((p, j) => {
            guests++;
            return <div key={j} className="w-1/2 pr-5 text-2xl">
              <div className="text-3xl my-5">{t('purchased details data.guest')} {guests}</div>
              <div className="mb-2">
                <b>{t('purchased details data.guest type')}: </b>{p.type === 'AD' ? t('adult') : t('children')}</div>
              {p.name && <div className="mb-2"><b>{t('name')}: </b> {p.name}</div>}
              {p.surname && <div className="mb-2"><b>{t('surname')}: </b> {p.surname}</div>}
            </div>
          })}
        </Fragment>)}
      </div>
    </div>
  );
};


export default PurchasedHotelDetailsGuests;