import config from '../config';
import logger from "./Logger";

export const query = async (query, data, method = 'POST', {headers = {}, signal} = {}) => {
  method = method.toUpperCase();
  let options = {
    method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      ...headers
    },
    mode: 'cors',
    withCredentials: true,
    signal
  };
  if (method !== 'GET') options.body = JSON.stringify(data);

  let url = `${config.serverURL}${query}`;
  if (method === 'GET') {
    url += '?' + new URLSearchParams(data).toString();
  }

  try {
    let r = await fetch(url, options);
    let data = await r.json();
    if (!r.ok) {
      return {error: data.error || data.message};
    }
    return {data, error: data?.error};
  } catch (err) {
    logger.error(err);
    return {error: 'failed to fetch'}
  }
}
