import React from 'react';
import config from "../../../config";

const PoliticaPrivacidadES = props => {
  return (
    <>
      <h1>POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS</h1>
      <h2>
        1. Recogida y uso de datos. Finalidades principales del tratamiento.
      </h2>
      <p>
        <b></b>
      </p>
      <p>
        En cumplimiento con lo dispuesto en la Ley Orgánica 3/2018 de 5 de
        diciembre de Protección de Datos Personales y garantía de los derechos
        digitales, el Reglamento (UE) 2016/679 de 27 de abril de 2016, general de
        protección de datos (RGPD) y su normativa de desarrollo en materia de
        protección de datos, TRIPPY TREKKERS, S.L, le informa de que los datos
        personales del Usuario, obtenidos o recabados a través del sitio web
        www.trippytrekkers.com (en adelante, el Portal), serán tratados por TRIPPY
        TREKKERS, S.L para el cumplimiento, entre otras, de las siguientes
        finalidades:
      </p>
      <p>
        1. Posibilitar la prestación de los servicios ofrecidos en el Portal.
      </p>
      <p>
        2. Gestionar su registro como usuario en nuestra plataforma.
      </p>
      <p>
        3. Gestionar la contratación de productos y/o servicios.
      </p>
      <p>
        4. Atender solicitudes de consulta, información y/o quejas/reclamaciones.
      </p>
      <p>
        5. Informar a los Usuarios sobre novedades, ofertas de productos y otras
        cuestiones de interés.
      </p>
      <p>
        Siempre que se solicite, y el Usuario facilite, una dirección de correo
        electrónico, teléfono móvil u otro medio de comunicación electrónica, el
        Usuario expresamente acepta y autoriza que dichos medios sean utilizados
        preferentemente como canal de comunicación, como canal para cumplir todas o
        alguna de las finalidades anteriormente informadas, así como canal a través
        del cual poderle informar de cualesquiera otros cambios relevantes que se
        produzcan en el Portal.
      </p>
      <h2>
        <b> 2.</b>
        <b> Legitimación</b>
        <b> para el tratamiento de sus datos de carácter personal</b>
      </h2>
      <ol>
        <li>Ejecución del contrato</li>
        <li>Consentimiento.</li>
        <li>
          Interés legítimo.
        </li>
        <li>
          Cumplimiento de obligaciones legales.
        </li>
      </ol>
      <p>
        <b>3. Derechos del usuario.</b>
      </p>
      <p>
        <b></b>
      </p>
      <p>
        El Usuario tiene reconocidos los derechos de acceso, rectificación,
        cancelación y oposición (A.R.C.O). También tiene derecho a solicitar la
        limitación de su tratamiento y derecho a la portabilidad de los datos.
        Podrá ejercitar sus derechos, dirigiendo una comunicación postal, y
        fotocopia del DNI, a la siguiente dirección C/ Camino de Hoyarrasa 106,
        28109 Alcobendas, Madrid, o bien, enviar un correo electrónico a <a
        href={`mailto:${config.trippy_trekkers.contact_email}`}>{config.trippy_trekkers.contact_email}</a>
      </p>
      <p>
        <b> 4. Responsable de tratamiento de datos.</b>
      </p>
      <p>
        <b></b>
      </p>
      <p>
        El responsable de los tratamientos de datos referentes a los distintos
        procesos con respecto la gestión de nuestros usuarios, clientes o
        proveedores es TRIPPY TREKKERS, S.L, con domicilio en la calle Camino de
        Hoyarrasa 106, 28109 Alcobendas, Madrid, con CIF B -B88592381. Inscrita en
        el Registro Mercantil Madrid. Correo electrónico <a
        href={`mailto:${config.trippy_trekkers.contact_email}`}>{config.trippy_trekkers.contact_email}</a>
      </p>
      <p>
        Los datos personales que se puedan recoger serán tratados con absoluta
        confidencialidad, comprometiéndonos a guardar secreto respecto de los
        mismos y garantizando el deber de guardarlos adoptando todas las medidas
        necesarias que eviten su alteración, pérdida y tratamiento o acceso no
        autorizado, de acuerdo con lo establecido en la legislación aplicable.
      </p>
      <p>
        La página de Internet de TRIPPY TREKKERS, S.L. puede contener enlaces
        (links) a otras páginas de terceros. Por lo tanto, TRIPPY TREKKERS, S.L. no
        podrá asumir responsabilidades por el contenido que pueda aparecer en
        páginas de terceros. Los textos, imágenes, sonidos, animaciones, software y
        el resto de los contenidos incluidos en este Sitio Web son propiedad
        exclusiva de TRIPPY TREKKERS, S.L. o sus licenciantes. Cualquier acto de
        transmisión, distribución, cesión, reproducción, almacenamiento o
        comunicación pública total o parcial, debe contar con el consentimiento
        expreso de TRIPPY TREKKERS, S.L.
      </p>
      <p>
        <b>5. Recopilación y tipos de datos de carácter personal.</b>
      </p>
      <p>
        <b></b>
      </p>
      <p>
        Los datos de carácter personal que maneja TRIPPY TREKKERS, S.L han sido
        obtenidos a través de los siguientes medios informados libremente por el
        interesado: (Añadir los que correspondan según la actividad).
      </p>
      <ol>
        <li>Formulario de contacto</li>
        <li>Vía correo electrónico</li>
        <li>Vía telefónica</li>
        <li>Formulario de…..</li>
      </ol>
      <p>
        Las categorías de datos personales que TRIPPY TREKKERS trata sobre sus
        clientes y proveedores son:
      </p>
      <ol>
        <li>Datos de identificación.</li>
        <li>Direcciones postales o electrónicas.</li>
        <li>Información comercial.</li>
      </ol>

      <p>
        No se tratan categorías de datos especialmente protegidos.
      </p>
      <p>
        <b>6. Plazos de conservación.</b>
      </p>
      <p>
        <b></b>
      </p>
      <p>
        Los datos personales relativos a personas físicas vinculadas a clientes
        potenciales, clientes y proveedores que TRIPPY TREKKERS, S.L recopile
        mediante los distintos formularios de contacto y/o recogida de información
        se conservarán mientras no se solicite su supresión por el interesado. Los
        datos proporcionados por nuestros clientes y proveedores se conservarán
        mientras se mantenga la relación mercantil entre las partes respetando en
        cualquier caso los plazos mínimos legales de conservación según la materia.
      </p>
      <p>
        Mantendremos sus datos de carácter personal mientras exista una relación o
        mientras no ejerza su derecho de supresión, cancelación y/o limitación del
        tratamiento de sus datos. En estos casos, bloquearemos sus datos, sin darle
        ningún uso, y se conservarán mientras pueda ser necesaria para el ejercicio
        o defensa de reclamaciones o pueda derivarse algún tipo de responsabilidad
        judicial, legal o contractual de su tratamiento, que deba ser atendida y
        para lo cual sea necesaria su recuperación.
      </p>
      <p>
        <b>7. Destinatarios de la comunicación de datos de carácter personal.</b>
      </p>
      <p>
        <b></b>
      </p>
      <p>
        Los datos de carácter personal que nos proporcione se comunicarán a
        terceros (operadores turísticos en destino, hoteles, cruceros, líneas
        aéreas).
      </p>
      <p>
        También se comunicarán a su solicitud y por obligación legal, cuando sea
        necesario, a la Administración Pública, a los Jueces y Tribunales, o a
        destinatarios en cumplimiento de una obligación legal. Fuera de estos
        supuestos, sus datos serán comunicados a terceras entidades solicitando de
        forma previa su consentimiento.
      </p>
      <p>
        <b>8. Exactitud y veracidad de los datos.</b>
      </p>
      <p>
        El usuario es el único responsable de garantizar la corrección y
        autenticidad de todos aquellos datos que comunique a través de la página
        web <a href="http://www.trippytrekkers.com/">www.trippytrekkers.com</a>, y
        mantendrá actualizada la información que facilite a TRIPPY TREKKERS, S.L ,
        de forma que responda en todo momento a su situación real, siendo el único
        responsable de las manifestaciones falsas o inexactas que realice, así como
        de los perjuicios que cause por ello a TRIPPY TREKKERS, S.L. o a terceros.
      </p>
      <p>
        TRIPPY TREKKERS, S.L. no puede asumir ninguna responsabilidad derivada del
        uso incorrecto, inapropiado o ilícito de la información aparecida en la
        página web www.trippytrekkers.com.
      </p>
      <p>
        <b>9. Aceptación y consentimiento.</b>
      </p>
      <p>
        El usuario declara haber sido informado de las condiciones sobre protección
        de datos de carácter personal, aceptando y consintiendo el tratamiento de
        los mismos por parte del Propietario de la web en la forma y para las
        finalidades indicadas en esta política de privacidad.
      </p>
      <p>
        <b>10. Revocabilidad.</b>
      </p>
      <p>
        El consentimiento prestado, tanto para el tratamiento como para la cesión
        de los datos de los interesados, es revocable en cualquier momento
        comunicándolo al Propietario de la web en los términos establecidos en esta
        Política para el ejercicio de los derechos ARCO. Esta revocación en ningún
        caso tendrá carácter retroactivo.
      </p>
      <p>
        <b>11. Cambios en la política de privacidad.</b>
      </p>
      <p>
        El Propietario de la web se reserva el derecho a modificar la presente
        política para adaptarla a novedades legislativas o jurisprudenciales, así
        como a prácticas de la industria
      </p>
      <p>
        <b></b>
      </p>
      <p>
        <b> 12. Ley aplicable y jurisdicción.</b>
      </p>
      <p>
        Las presentes condiciones generales se rigen por la Legislación española y
        europea en materia de protección de datos de carácter personal y de
        privacidad.
      </p>
      <p>
        Cualquier controversia entre TRIPPY TREKKERS, S.L y el usuario acerca de la
        política de privacidad y protección de datos, con renuncia expresa a
        cualquier otro fuero que pudiera corresponderles, se someterá a los
        Juzgados y Tribunales de la ciudad de Alcobendas, Madrid, España.
      </p></>
  );
};


export default PoliticaPrivacidadES;