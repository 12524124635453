import React from 'react';
import s from './CheckboxWithContainer.module.css';
import {Controller} from "react-hook-form";

const CheckboxWithContainer = ({
                                 id,
                                 labelStyle,
                                 name,
                                 label,
                                 containerClassName,
                                 onChange,
                                 defaultValue = false,
                                 ...props
                               }) => {

  return (
    <Controller
      defaultValue={defaultValue}
      name={name}
      render={({field: {onChange: _onChange, value}}) => {

        const onClick = e => {
          e.preventDefault();
          const nv = !value;
          _onChange(nv);
          onChange && onChange(nv);
        }

        return (
          <div onClick={e => onClick(e)}
               className={`${s.container} ${value ? s.checkedContainer : ''} ${containerClassName ? containerClassName : ''}`}>
            <input onClick={e => onClick(e)} type="checkbox" checked={value} id={id} {...props} />
            {label && <label style={labelStyle} htmlFor={id}>{label}</label>}
          </div>
        )
      }}/>
  );
};


export default CheckboxWithContainer;
