import React, {useEffect, useState} from "react";
import {buildImagePath, HotelImageSize} from "../util";

interface HotelImageProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  hotel: { api: string },
  imagePath: string,
  maxSize?: HotelImageSize,
  onFailed?: () => void,
  Renderer?: React.ComponentType<any>
}

const sizes = Object.values(HotelImageSize);


const HotelImage = ({hotel, imagePath, onFailed, maxSize = HotelImageSize.PX1024, ...props}: HotelImageProps) => {
  const [size, setSize] = useState<HotelImageSize>(maxSize);
  const [tried, setTried] = useState<HotelImageSize[]>([maxSize]);

  const onError = () => {
    const index = sizes.indexOf(size);
    if (index === -1) return;

    if (index - 1 >= 0) {
      setSize(sizes[index - 1] as HotelImageSize);
    } else {
      const restSizes = tried.filter(size => size !== maxSize);
      if (restSizes.length) {
        setSize(restSizes[0]);
        setTried(prev => [...prev, restSizes[0]])
      } else {
        onFailed?.();
      }
    }
  }

  useEffect(() => {
    setSize(maxSize);
    setTried([maxSize]);
  }, [imagePath]);

  return <img onError={onError} {...props} src={buildImagePath(hotel, imagePath, size)}/>
}

export default HotelImage;