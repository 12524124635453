import React from 'react';
import s from './HotelDetailsInfo.module.css';
import HotelDetailsConditionsBox from "../HotelDetailsConditionsBox";
import {useTranslation} from "react-i18next";
import HotelServiceList from "../HotelServiceList";
import {langRender} from "../../../../../helpers/i18n";
import {Spoiler} from "@mantine/core";
import CurrencyNumber from "../../../../CurrencyNumber";
import HamburgerIcon from "../../../../ProfileMenu/HamburgerIcon";
import {useViewportSize} from "@mantine/hooks";
import cn from "classnames";
import bases from "../../../../../routes/bases";
import {useNavigate} from "react-router-dom";
import {firstCapitalize} from "../../../../../helpers/util";
import {HotelDetailsMap} from "./HotelDetailsMap";


const HotelDetailsMenu = ({hotel, offsetScroll} = {offsetScroll: 0}) => {
  const {t: _t} = useTranslation();
  const t = (key) => _t(`hotel details menu.${key}`);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const {width} = useViewportSize();
  const isDesktop = width >= 768;

  const navigate = useNavigate();


  const onLocationClick = () => window.open(`https://maps.google.com?ll=${hotel.latitude},${hotel.longitude}`, '_blank');
  const anchorClick = (id) => {
    const el = window.document.getElementById(id);
    if (!el) return;
    window.scrollTo({top: el.offsetTop + offsetScroll, behavior: 'smooth'})
  };
  const showMenu = menuOpen || isDesktop;

  return <div className={cn(s.boxContainer, 'mt-10', {'flex items-center justify-between': !menuOpen})}>
    <div className={cn('md:hidden', {'mb-5': menuOpen})}>
      <HamburgerIcon className={s.hamburgerShowMenu}
                     onClick={() => setMenuOpen(prev => !prev)}
                     open={menuOpen}/>
    </div>
    {/* @ts-ignore*/}
    <div
      className={cn("md:w-full flex-nowrap justify-between", {'float-right md:float-none inline-flex items-center': !menuOpen}, {'flex w-full items-start': menuOpen})}>
      {showMenu &&
        <div className="flex flex-wrap flex-col md:flex-row md:flex-nowrap gap-5 mb-5 md:mb-0">
          <div className={s.menuItem} onClick={() => anchorClick('hotel_my_rooms')}>{t('rooms')}</div>
          <div className={s.menuItem} onClick={() => anchorClick('hotel_my_services')}>{t('services')}</div>
          <div className={s.menuItem} onClick={onLocationClick}>{t('location')}</div>
          {/*
          <div className={s.menuItem}>{t('review')}</div>
*/}
          <div className={s.menuItem} onClick={() => navigate(bases.terms)}>{t('politics')}</div>
        </div>}
      <div className="inline-block mr-20">
        {!!hotel.minFinalRate && <div className="text-xl">{t('from')}</div>}
        {!!hotel.minFinalRate &&
          <div className={s.big_price}><CurrencyNumber>{hotel.minFinalRate}</CurrencyNumber></div>}
      </div>
    </div>
  </div>
}

const IssuesBox = ({hotel}) => {
  const {t} = useTranslation();

  if (!Array.isArray(hotel.issues) || hotel.issues.filter(issue => issue.name?.length > 0).length === 0) return <></>;
  return <div className={s.boxContainerGreen}>
    <div className={s.boxTitle}>{t('hotel.issues')}</div>
    {hotel.issues.map((issue, i) => <div key={i} className="ml-5 text-xl text-justify">{issue.name}</div>)}
  </div>
}

const InterestPoints = ({hotel}) => {
  const {t} = useTranslation();
  if (!Array.isArray(hotel.interestPoints) || hotel.interestPoints.length === 0) return <></>;

  return (
    <div className={`${s.boxContainerGreen} w-full sm:w-1/2 xl:w-full`}>
      <div className={s.boxTitle}>{t('hotel.interest points')}</div>
      {hotel.interestPoints.map((point, i) => <div key={i}
                                                   className="text-xl mb-5 ml-5">{firstCapitalize(point.poiName?.toLowerCase())} ({point.distance} {t('meters')})</div>)}
    </div>
  );
}

const HotelDetailsInfo = ({hotel, searchParams}) => {
  const {t} = useTranslation();

  return (
    <>
      <HotelDetailsMenu hotel={hotel} offsetScroll={-100}/>
      <div className="w-full flex flex-wrap xl:flex-nowrap">
        <div className="w-full xl:w-8/12 xl:pr-20">
          <div className={s.boxContainer}>
            <div className={s.boxTitle}>{t('description')}</div>
            <div className={s.description}>
              <Spoiler styles={{control: {color: 'var(--color-yellow)'}}} maxHeight={100}
                       showLabel={t('show more')}
                       hideLabel={t('hide')}>
                {langRender(hotel.description)}
              </Spoiler>
            </div>
          </div>

          <HotelServiceList hotel={hotel}/>
          <HotelDetailsConditionsBox hotel={hotel}/>


          {/*
					{width > 1280 ? <InterestPoints hotel={hotel}/> : <></>}
*/}

        </div>

        <div className="w-full xl:w-4/12 flex flex-wrap justify-start flex-col">
          {/*
					{width <= 1280 ? <InterestPoints hotel={hotel}/> : <></>}
*/}
          {hotel.latitude && hotel.longitude &&
            <div className={s.mapContainer}>
              <HotelDetailsMap hotel={hotel} searchParams={searchParams}/>
            </div>
          }
          <InterestPoints hotel={hotel}/>
          <IssuesBox hotel={hotel}/>
        </div>
      </div>
    </>
  );
};


export default HotelDetailsInfo;