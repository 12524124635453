import {SortSelect} from "../../Inputs/SortSelect/SortSelect";
import {t} from "i18next";
import useHotelsProvider from "../../../../providers/HotelsProvider/useHotelsProvider";


export function SortInput() {
  const {updateHotels, sort, setSort} = useHotelsProvider();


  const onChange = (value: string) => {
    setSort(value);
    updateHotels({sort: value});
  }

  const items = [
    {label: t('hotel.sort by.recommended'), value: 'recommended'},
    {label: t('hotel.sort by.price up'), value: 'price_up'},
    {label: t('hotel.sort by.price down'), value: 'price_down'},
    {label: t('hotel.sort by.rating'), value: 'rating'},
    {label: t('hotel.sort by.distance'), value: 'distance'},
  ]

  return <div style={{marginBottom: "35px"}}>
    <SortSelect defaultValue={sort} onChange={onChange}
                title={t('hotel.sort by.title')} items={items}/>
  </div>
}