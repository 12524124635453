import CondicionesGeneralesES from "./CondicionesGeneralesES";
import CondicionesGeneralesEN from "./CondicionesGeneralesEN";

const CondicionesGenerales = ({lang}) => {
  if (lang === 'es-ES') {
    return <CondicionesGeneralesES/>
  } else {
    return <CondicionesGeneralesEN/>
  }
};


export default CondicionesGenerales;