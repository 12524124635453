import s from "./HomePage.module.css";
import {useTranslation} from "react-i18next";
import cn from "classnames";

interface Props {
  className?: string;
  children?: any;
}

export function HomePageTitles({className, children}: Props) {
  const {t: _t} = useTranslation();
  const t = (a: string) => _t(`home page data.${a}`);

  return <div className={cn([s.contentContainer, className])}>
    <div className={s.title}>{t('start your adventure')}</div>
    <div className={s.subTitle}>{t('subtitle')}</div>
    {!!children && children}
  </div>
}