import {StartYourTripData, StartYourTripFormValues} from "./types";

//export const regimeKeys: (keyof StartYourTripFormValues['regime'])[] = ['accommodation_and_breakfast', 'accommodation_only', 'all_included', 'full_board', 'half_board'];
//export const hotelTypes: (keyof StartYourTripFormValues['type'])[] = ['hotel', 'hotel', 'camping', 'apartment'];


const keyMapper = (keys: { [x: string]: boolean }) => {
  let result = [];
  for (let key in keys) {
    if (keys[key]) result.push(key);
  }
  return result;
}

export function prepareDataForServer(data: StartYourTripFormValues) {
  let rooms: StartYourTripData['rooms'] = [];
  let i = 0;
  do {
    // @ts-ignore
    const room = data[`hotel_passenger_number_${i}`];
    if (Array.isArray(room)) {
      const childrens = room.find((person: any) => person.name === 'children')?.value;
      let children_ages = [];
      for (let j = 0; j < childrens; j++) {
        // @ts-ignore
        let age = data[`child_age_${i}_${j + 1}`];
        if (age) children_ages.push(age);
      }

      rooms.push({
        adults: room.find((person: any) => person.name === 'adults')?.value,
        children: childrens,
        children_ages: children_ages,
      })
    }
    i++;
    // @ts-ignore
  } while (data[`hotel_passenger_number_${i}`]);


  let result: StartYourTripData = {
    activity_categories: data.activity_categories,
    other_activities: data.other_activities,
    contact: data.contact,
    country_codes: data.country_codes,
    subcontinents: data.subcontinents,
    country_destinations: data.country_destinations,
    date_from: data.date_from.toISOString(),
    date_to: data.date_to.toISOString(),
    describe_your_group: data.describe_your_group,
    insurance_type: data.insurance_type.basic ? 'basic' : 'cancellation',
    know_destination: !!data.know_destination,
    need_activities: !!data.need_activities,
    need_home: !!data.need_home,
    need_insurance: !!data.need_insurance,
    origin: {
      lat: data.origin.raw.lat,
      lng: data.origin.raw.lng,
      label: data.origin.raw.label,
      country_code: data.origin.raw.country_code
    },
    person: {
      ...data.person,
      from_budget: Number(data.person.from_budget),
      to_budget: Number(data.person.to_budget)
    },
    regime: keyMapper(data.regime),
    stars: data.stars,
    hotel_types: keyMapper(data.type),
    rooms
  }

  return result;
}