import React from 'react';
import s from './Button.module.css';
import cn from "classnames";

const Button = ({selected, icon, text, onClick}) => {

  //const pointClassName = `${s.point} ` + (selected ? s.point_selected : '');

  return (
    <div className={cn([s.container, {[s.selected]: selected}])} onClick={onClick}>
      <div className={s.icon}>{icon}</div>
      <div className={s.text}>{text}</div>
      {/*      <div className={pointClassName} />*/}
    </div>
  );
}


export default Button;
