import React, {useState} from 'react';
import s from './FilterContainer.module.css';
import {ReactComponent as ArrowSVG} from "../../../resources/svg/show_more_arrow.svg";
import cn from "classnames";


const FilterContainer = ({title, children, initialVisible = false, alwaysVisible = false}) => {
  const [visible, setVisible] = useState(initialVisible || alwaysVisible);

  const onVisibilityChange = () => {
    if (alwaysVisible) return;
    setVisible(prev => !prev);
  }

  return (
    <div className={cn(s.container, {[s.container_show]: alwaysVisible || visible})}>
      <div className={cn(s.title, {[s.show_title]: alwaysVisible || visible})} onClick={onVisibilityChange}>
        <div className="w-full">{title}</div>
        {!alwaysVisible && <ArrowSVG width={14}/>}
      </div>
      <div className={cn(s.children, {[s.children_show]: alwaysVisible || visible})}>
        {children}
      </div>
    </div>
  );
};


export default FilterContainer;
