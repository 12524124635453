import React from 'react';
import s from './ChildrenAgeSelectPassengerNumber.module.css';
import {useTranslation} from "react-i18next";
import {Controller} from "react-hook-form";

const ChildrenAgeSelectPassengerNumber = ({name, value, i}) => {
  const {t} = useTranslation();

  const options = React.useMemo(() => {
    let child_age_options = [];
    for (let i = 1; i <= 17; i++) {
      child_age_options.push({
        label: `${i} ${t('years', {count: i})}`,
        value: i
      });
    }
    return child_age_options;
  }, []);

  return <div className="flex flex-nowrap w-full items-center pb-5">
    <div className={s.dataText}>{t('children age')} {i}</div>
    <div className={s.childrenSelect}>
      <Controller
        name={name}
        defaultValue={value || options[0]?.value}
        render={({field: {onChange, value, ref}}) => (
          <select
            ref={ref}
            className={s.selectChildrenContainer}
            name={name}
            value={value}
            onChange={e => {
              console.log(e.target.value);
              onChange(e.target.value * 1)
            }
            }
          >
            {options.map((option, index) => <option key={index} value={option.value}>{option.label}</option>)}
          </select>
        )}/>
    </div>
  </div>
};


export default ChildrenAgeSelectPassengerNumber;