import React, {useState} from 'react';
import s from './Image.module.css';

import {ReactComponent as notFoundSVG} from '../../resources/svg/not_found_image.svg';


interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
	src?: string;
	images?: string[];
	onError?: () => void;
	node?: (src: any) => React.ReactNode;
}

const Image = ({src: imageSrc, images, onError,node, ...props}: ImageProps) => {
	const [src, setSrc] = useState<any>(imageSrc || notFoundSVG);
	const [fallbackImages, setFallbackImages] = useState<string[]>(images || []);

	const onErrorCallback = () => {
		if (fallbackImages && fallbackImages.length > 0) {
			setSrc(fallbackImages[0]);
			setFallbackImages(fallbackImages.slice(1));
		} else {
			setSrc(notFoundSVG);
		}

		if (onError) onError();
	}

	if(node)return <>{node(src)}</>;

	return (
		<img src={src} onError={onErrorCallback} {...props}/>
	);
};


export default Image;