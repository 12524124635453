import React, {useMemo} from 'react';
import FilterContainer from "../FilterContainer";
import {useTranslation} from "react-i18next";
import {getPartsOfTheWorld} from "../../../helpers/uniqueExperiences";
import CheckboxFilter from "../FilterContainer/CheckboxFilter";

const FilterUEPartsOfTheWorld = ({onChange}) => {
    const {t} = useTranslation();
    let groups = useMemo(() => getPartsOfTheWorld(), [t]);


    return (
        <FilterContainer title={t('experience_details.filters.parts of the world')}>
            <CheckboxFilter
                onChange={() => onChange()}
                getData={async () => ({partsOfTheWorld: groups.map(g => ({code: g.id, description: g.name}))})}
                name="partsOfTheWorld"
            />
        </FilterContainer>
    );
};


export default FilterUEPartsOfTheWorld;