import React from 'react';
import s from './Input.module.css';
import cn from "classnames";

export const Input = React.forwardRef(
  ({
     containerClassName = '',
     type = 'text',
     icon,
     name,
     label,
     ...props
   }, ref) => {

    return (
      <div className={`w-full ${containerClassName}`}>
        {label && <label className={s.label} htmlFor={name}>{label}</label>}
        <div className={cn(s.container, {[s.containerWithIcon]: !!icon})}>
          {icon &&
            <div className={s.icon}>{icon}</div>}
          <input ref={ref} type={type} name={name} {...props} />
        </div>
      </div>
    );
  });


export default Input;
