import React from 'react';
import s from './Hotel.module.css';
import {useTranslation} from "react-i18next";
import Button from "../../../Inputs/Button/Button";
import CurrencyNumber from "../../../CurrencyNumber";
import BigNumber from "bignumber.js";
import {ReactComponent as StarSVG} from "../../../../resources/svg/star.svg";
import {ReactComponent as LikeCircleSVG} from "../../../../resources/svg/like_circle.svg";
import {ReactComponent as LocationSVG} from "../../../../resources/svg/locationIcon.svg";
import {createOutstandingServices, generateStars, getBestHotelImage} from "../../../Hotels/util";
import SharePopup from "../../../SharePopup/SharePopup";
import config from "../../../../config";
import bases from "../../../../routes/bases";
import LoadingSVG from "../../../LoadingSVG/LoadingSVG";
import {useNavigate} from "react-router-dom";
import useHotelsProvider from "../../../../providers/HotelsProvider/useHotelsProvider";
import useAuth from "../../../../providers/AuthProvider/useAuth";
import {ProjectedHotelForList} from '../../../../types/hotels/hotels';
import cn from "classnames";
import HotelImage from "../../../Hotels/HotelImage/HotelImage";
import {useImages} from "../../../../hooks/useImages";
import {getGoToLoginPageURL} from "../../../../helpers/util";
import {TopHotel} from "../../../Hotels/components/TopHotel/TopHotel";

const Text = ({children}: {
  children: any
}) => (
  <div className={s.middleText}>{children}</div>
)

interface ShareLikeButtonsProps {
  hotel: Pick<ProjectedHotelForList, 'is_liked' | 'code' | 'api'>;
  onLikeClick: () => Promise<void>;
}

const ShareLikeButtons = ({hotel, onLikeClick}: ShareLikeButtonsProps) => <div
  className="absolute top-0 right-0 pt-5 pr-5">
  <div className="flex justify-end">
    <LoadingSVG
      Icon={LikeCircleSVG}
      className={hotel.is_liked ? s.circleLiked : s.circle}
      containerClassName={s.circleContainer}
      onClick={onLikeClick}
    />
    <SharePopup url={`${config.pageURL}${bases.hotelDetails}?code=${hotel.code}&api=${hotel.api}`}/>
  </div>
</div>


interface HotelProps {
  hotel: ProjectedHotelForList;
  checkIn: any;
  checkOut: any;
  onMoreInfo: any;
  className?: string;
  onLike?: (liked?: ProjectedHotelForList) => void;
}

const Hotel = ({hotel, className, checkIn, checkOut, onLike, onMoreInfo}: HotelProps) => {
  const {t} = useTranslation();
  const {likeTheHotel} = useHotelsProvider();
  const navigate = useNavigate();
  const {isAuthenticated} = useAuth();

  const {images, onFailed} = useImages({
    images: hotel.images
  });


  const onLikeClick = async () => {
    if (!isAuthenticated()) return navigate(getGoToLoginPageURL());

    const result = await likeTheHotel(hotel);
    if (result?.error) return alert(`${t('errors.' + result.error)}`);
    onLike?.(hotel)
  }


  // let services = React.useMemo(() => buildServicesList({
  //   services: hotel.services,
  //   facilities: hotel.facilities,
  //   descriptions: hotel?.services?.descriptions
  // }).list, [hotel]);


  let stars = [];
  let n_starts = hotel.categoryCode ? Number(hotel.categoryCode.charAt(0)) : 0;

  for (let i = 0; i < n_starts; i++) {
    stars.push(<StarSVG width={16} height={16} key={i} style={{fill: 'rgb(251, 188, 4)'}} className="mr-2"/>);
  }

  let n_nights = null;
  let rate_per_night = null;
  if (checkIn) {
    n_nights = checkOut.diff(checkIn, 'days');
    if (hotel.minRate && n_nights > 0) {
      rate_per_night = (new BigNumber(hotel.minFinalRate)).dividedBy(n_nights).toFixed(2)
    }
  }

  const formatAddress = () => {
    let r = '';
    if (hotel.address) r += hotel.address;
    return r;
  }


  return (
    <div className={cn(s.hotel, className)}>
      <div className={s.imageContainer}>
        <HotelImage
          hotel={hotel}
          imagePath={getBestHotelImage({images})?.path}
          onFailed={() => {
            const path = getBestHotelImage({images})?.path;
            if (path) {
              let index = images.findIndex(image => image.path === path);
              onFailed(index);
            }
          }}
          //images={hotel.images.map(image => buildImagePath(hotel, getBestHotelImage(hotel).path)!)}
        />
        <ShareLikeButtons hotel={hotel} onLikeClick={onLikeClick}/>
      </div>

      <div className="w-full p-10">
        <div className="flex justify-between items-center mb-5">
          <div className="flex">
            {generateStars(hotel)}
          </div>
          <TopHotel exclusiveDeal={hotel.exclusiveDeal}/>
        </div>

        <div className={s.hotelName}>{hotel.name}</div>

        <div className="w-full flex mt-5 items-center">
          <LocationSVG className="mr-3" fill="#757575" width={20} height={20}/>
          <Text>{formatAddress()}</Text>
        </div>

        {hotel.services.length > 0 && <>
          <hr className="my-10"/>
          <div className="flex items-center gap-5">
            {createOutstandingServices(hotel.services).map(service =>
              <div className={s.service} title={t(service!.label)}>{service!.icon}</div>
            )}
          </div>
        </>
        }
        <hr className="my-10"/>

        <div className="w-full flex flex-nowrap">
          <div className={s.rate}>
            {!!hotel.minFinalRate && <CurrencyNumber className={s.final_price}>{hotel.minFinalRate}</CurrencyNumber>}
            {!!rate_per_night && <div className={s.price_per_night}>
              (<CurrencyNumber>{rate_per_night}</CurrencyNumber>
              {` ${t('per night')}`})
            </div>}
          </div>
          <div style={{width: 250}}>
            <Button onClick={() => onMoreInfo && onMoreInfo({hotel})}
                    className={s.moreInfoButton}>{t("more info")}</Button>
          </div>
        </div>

      </div>

    </div>
  );
}


export default Hotel;
