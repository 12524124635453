import {HotelRoom, HotelRoomSelectionResume} from "../../../../../types/hotels/hotels";
import {useTranslation} from "react-i18next";
import CurrencyNumber from "../../../../CurrencyNumber";
import s from "./HotelDetailsRoomsTable.module.css";
import Button from "../../../../Inputs/Button/Button";
import React from "react";
import {firstCapitalize} from "../../../../../helpers/util";
import {Modal} from "@mantine/core";
import {localizedFormatInOriginalTimezone} from "../../../../../helpers/formatters";
import cn from "classnames";

const CancellationPolicyModal = ({visible, onClose, policies}: {
  policies: HotelRoom['rates'][0]['cancellationPolicies'];
  visible: boolean;
  onClose: () => void;
}) => {
  const {t} = useTranslation();
  return <Modal opened={visible} onClose={onClose}>
    <div className="font-bold text-5xl mb-14 text-center">{t('hotel_rooms.cancellation policies')}</div>
    <table className="w-full text-center text-2xl">
      <thead>
      <tr>
        <th>Desde</th>
        <th>A devolver</th>
      </tr>
      </thead>
      <tbody>
      {policies.map((policy, i) => <tr key={i}>
        <td>{localizedFormatInOriginalTimezone(policy.from)}</td>
        <td><CurrencyNumber>{policy.finalPrice}</CurrencyNumber></td>
      </tr>)}
      </tbody>
    </table>
  </Modal>
}


const CancellationPolicy = ({rate, room}: HotelRoomSelectionResume['rooms'][0]) => {
  const {t} = useTranslation();
  const [modal, setModal] = React.useState(false);

  if (rate.rateClass === 'NRF') return <span style={{color: 'rgb(255, 0, 0)'}}>{t('hotel.non refundable')}</span>;

  if (Array.isArray(rate.cancellationPolicies)) {
    if (rate.cancellationPolicies.length > 1) {
      return <>
        <CancellationPolicyModal visible={modal} onClose={() => setModal(false)}
                                 policies={rate.cancellationPolicies}/>
        <div className="flex justify-center items-center cursor-pointer" onClick={() => setModal(true)}>
          <div className={s.summaryCancellationCircle}>+</div>
          <div className="hover:underline">{t('hotel_rooms.cancellation policies')}</div>
        </div>
      </>
    }


    const policy = rate.cancellationPolicies.reduce((policy, current) =>
      policy.from < current.from ? policy : current
    );

    return <div className="w-full flex flex-col">
      <div className="flex w-full">
        <div style={{width: '100px'}}
             className="text-left capitalize whitespace-nowrap">{t('hotel.cancellation policy data.to return')}</div>
        <div className="text-right w-full"><CurrencyNumber>{policy.finalPrice}</CurrencyNumber></div>
      </div>
      <div className="flex w-full">
        <div className="capitalize text-left whitespace-nowrap"
             style={{width: '100px'}}>{t('hotel.cancellation policy data.after')}</div>
        <div className="text-right w-full">{localizedFormatInOriginalTimezone(policy.from)}</div>
      </div>
    </div>
  }

  return <></>
}


export const RoomSelectionResume = ({selection}: {
  selection: HotelRoomSelectionResume
}) => {
  const {t} = useTranslation();
  const {totalRooms, totalPrice, rooms, totalTaxes} = selection || {};

  if (!totalRooms) return <div className="text-xl">{t('hotel_rooms.select rooms')}</div>;


  return <div className={s.roomSelectionResumeContainer}>
    <div className={s.summaryHeader}>
      {t('hotel_rooms.summary')}
    </div>
    <div className={s.roomSelectionResume}>
      <div>
        <div className="mb-3">
                    <span
                      className="font-bold text-xl">{totalRooms}</span> {t('hotel_rooms.selected rooms', {count: totalRooms})}
        </div>
        <div className="font-bold text-2xl mb-3">
          <CurrencyNumber className={s.price}>{totalPrice}</CurrencyNumber>
        </div>
        {!totalTaxes ? <div>{t('hotel_rooms.taxes included')}</div> : <div className="flex">
          <CurrencyNumber className={cn(s.price, "text-xl")}>{totalTaxes}</CurrencyNumber>
          <div className="pl-2">{t('hotel_rooms.taxes in hotel')}</div>
        </div>}
      </div>
      <div className="pt-20 xl:px-10 text-center">
        {/*@ts-ignore*/}
        <Button className={s.selectButton}>{t("purchase data.reserve")}</Button>
      </div>
      {rooms && rooms.length > 0 && <div className="pt-10" style={{fontSize: '12px'}}>
        {rooms.map(({room, rate, quantity}, i) => <div key={i}>
          <hr className="my-5"/>
          <div className={s.summaryRoomName}>{`${quantity} x`} {room.name}</div>
          <ul className={s.summaryRoomList}>
            <li>{firstCapitalize(rate.boardName?.toLowerCase())}</li>
            <li><CurrencyNumber>{rate.finalPrice * quantity}</CurrencyNumber></li>
          </ul>

          <div className="text-center mt-5 px-1">
            <CancellationPolicy rate={rate} room={room} quantity={quantity}/>
          </div>
        </div>)}
      </div>}
    </div>
  </div>
}