import {useLocalStorage} from "../../hooks/useLocalStorage";
import React, {useEffect} from "react";
import moment from "moment";
import {useCurrency} from "../../controllers/useCurrency";
import {CurrencyContext} from "./CurrencyContext";


const CurrencyProvider = props => {
  const [exchanges, setExchanges] = useLocalStorage('currency_exchanges', {});
  const [selectedCurrency, setSelectedCurrency] = useLocalStorage('selected_currency', 'EUR');
  const {requestExchanges} = useCurrency();


  const loadExchanges = async () => {
    //if (!exchanges || !exchanges.timestamp || moment().isAfter(moment(exchanges.timestamp))) {
    const {error, exchanges: newExchanges} = await requestExchanges();
    if (error) {
      // notify
      return [];
    }
    let r = {
      exchanges: newExchanges,
      timestamp: moment().unix()
    };
    setExchanges(r);
    return r;
    //}
  }

  useEffect(() => {
    loadExchanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return <CurrencyContext.Provider value={{
    exchanges, selectedCurrency, setSelectedCurrency
  }}>
    {props.children}
  </CurrencyContext.Provider>
}

export default CurrencyProvider;
