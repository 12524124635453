import React from 'react';
import s from './SelectCountry.module.css';
import {useTranslation} from "react-i18next";

const SelectCountry = ({language, countries, onCountryClick}) => {
  const {t} = useTranslation();
  return (
    <div className={s.container}>
      <div className={s.title}>{t("choose your language")}</div>
      {countries.map((country, i) => (
        <div key={i} className={s.option} onClick={e => onCountryClick(e, country)}>
          <div className={s.flag}>{country.flag}</div>
          <div className={s.text}>{t('languageList.' + country.lang)}</div>
        </div>
      ))}
    </div>
  );
};


export default SelectCountry;
