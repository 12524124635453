import cn from "classnames";
import CurrencyNumber from "../../CurrencyNumber";
import React from "react";
import s from './MapHotel.module.css';
import {ReactComponent as PinSVG} from '../../../resources/svg/map/pin.svg';

interface Props {
  price?: number | string | null
  selected?: boolean
  onClick?: () => void
}

export function MapMarker({selected, price, onClick}: Props) {
  return <div className={cn(s.pin, {[s.selectedPin]: selected})} onClick={onClick}>
    <PinSVG/>
    {price && <div><CurrencyNumber
      options={{maximumFractionDigits: 0}}>{price}</CurrencyNumber></div>}
  </div>
}