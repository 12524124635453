import {Controller, useFieldArray} from "react-hook-form";
import s from "../HotelPassengerNumber.module.css";
import React, {useEffect} from "react";
import {t} from "i18next";

interface Props {
  children: number
  name: string
}

export function HotelPassengerNumberChildrenAges({name, children}: Props) {
  const {fields, append, remove} = useFieldArray({
    name
  })

  const options = React.useMemo(() => {
    let child_age_options = [];
    for (let i = 1; i <= 17; i++) {
      child_age_options.push({
        label: `${i} ${t('years', {count: i})}`,
        value: i
      });
    }
    return child_age_options;
  }, []);

  useEffect(() => {
    if (fields.length !== children) {
      if (fields.length < children) {
        for (let i = fields.length; i < children; i++) {
          append({age: 0})
        }
      } else {
        for (let i = fields.length; i > children; i--) {
          remove(i - 1)
        }
      }
    }
  }, [children]);

  return <div style={{fontSize: '14px'}} className="p-2 text-center">
    <div className="pb-1 text-left">{t('children ages')}</div>
    {fields.map((field, index) => (
      <Controller
        name={`${name}.${index}.age`}
        render={({field}) => (
          <select
            ref={field.ref}
            className={s.selectChildrenContainer}
            name={name}
            value={field.value}
            onChange={e => {
              field.onChange(Number(e.target.value))
            }
            }
          >
            {options.map((option, index) => <option key={index} value={option.value}>{option.label}</option>)}
          </select>
        )}
      />
    ))}

  </div>
}