import React, {useState} from 'react';
import s from './AsyncIcon.module.css';

import {AiOutlineLoading3Quarters} from 'react-icons/ai';

const AsyncIcon = ({size, Icon, onClick: _onClick, ...props}) => {
  const [loading, setLoading] = useState();

  const onClick = async () => {
    setLoading(true);
    await _onClick();
    setLoading(false);
  }

  if (loading) return <AiOutlineLoading3Quarters size={size}/>

  return <Icon size={size} {...props} onClick={onClick}/>
};


export default AsyncIcon;
