import {t} from "i18next";
import React from "react";
import {ReactComponent as AirConditioningSVG} from "../../../resources/svg/services/air_conditioning.svg";
import {ReactComponent as WifiSVG} from "../../../resources/svg/services/wifi.svg";
import {ReactComponent as CrippleSVG} from "../../../resources/svg/services/cripple.svg";
import {ReactComponent as RoomServiceSVG} from "../../../resources/svg/services/room_service.svg";
import {ReactComponent as FridgeSVG} from "../../../resources/svg/services/fridge.svg";
import {ReactComponent as ParkingSVG} from "../../../resources/svg/services/parking.svg";
import {FaSwimmingPool} from "react-icons/fa";
import {BiDrink, BiRestaurant} from "react-icons/bi";
import {CgGym, CgScreen} from "react-icons/cg";
import {BsWater} from "react-icons/bs";
import {RiTempHotLine} from "react-icons/ri";
import {MdEmojiTransportation, MdLocalDrink, MdLuggage, MdOutlinePets} from "react-icons/md";
import {GrCurrency, GrElevator} from "react-icons/gr";
import {GiCigarette} from "react-icons/gi";
import {ReactComponent as DryerSVG} from "../../../resources/svg/services/dryer.svg";


export const facilityApitudeMapper = [
  {icon: <WifiSVG/>, label: t('service.free wifi'), key: 'wifi', codes: [261]},
  {icon: <CrippleSVG/>, label: t('service.creeple'), key: 'disabilities', codes: [250]},
  {icon: <RoomServiceSVG/>, label: t('service.room service'), key: 'room_service', codes: [270]},
  {icon: <FridgeSVG/>, label: t('service.fridge'), key: 'fridge', codes: [130, 135]},
  {
    icon: <AirConditioningSVG/>,
    label: t('service.air conditioning'),
    key: 'air_conditioning',
    codes: [10, 170, 180, 575]
  },
  {icon: <ParkingSVG/>, label: t('service.parking'), key: 'parking', codes: [320]}
]

const iconProps = {size: 20};
const secondIconProps = {width: 20, height: 20};

export const facilityLocalMapper = [
  {label: t('to.hotel.facilities.pool'), value: 'pool', group: 'building', icon: <FaSwimmingPool {...iconProps}/>},
  {
    label: t('to.hotel.facilities.parking'), value: 'parking', group: 'building', icon:
      <ParkingSVG {...secondIconProps}/>
  },
  {label: t('to.hotel.facilities.wifi'), value: 'wifi', group: 'services', icon: <WifiSVG {...secondIconProps}/>},
  {
    label: t('to.hotel.facilities.restaurant'), value: 'restaurant', group: 'hostelry', icon:
      <BiRestaurant {...iconProps}/>
  },
  {label: t('to.hotel.facilities.bar'), value: 'bar', group: 'hostelry', icon: <BiDrink {...iconProps}/>},
  {label: t('to.hotel.facilities.gym'), value: 'gym', group: 'building', icon: <CgGym {...iconProps}/>},
  {label: t('to.hotel.facilities.spa'), value: 'spa', group: 'building', icon: <BsWater {...iconProps}/>},
  {label: t('to.hotel.facilities.sauna'), value: 'sauna', group: 'building', icon: <RiTempHotLine {...iconProps}/>},
  {
    label: t('to.hotel.facilities.airport shuttle'),
    value: 'airport shuttle',
    group: 'other',
    icon: <MdEmojiTransportation {...iconProps}/>
  },
  {
    label: t('to.hotel.facilities.24/7 hour reception'),
    value: '24/7 hour reception',
    group: 'services',
    icon: <RoomServiceSVG {...secondIconProps}/>
  },
  {
    label: t('to.hotel.facilities.disability access'),
    value: 'disability',
    group: 'building',
    icon: <CrippleSVG {...secondIconProps}/>
  },
  {label: t('to.hotel.facilities.elevator'), value: 'elevator', group: 'building', icon: <GrElevator {...iconProps}/>},
  {
    label: t('to.hotel.facilities.smoking area'),
    value: 'smoking area',
    group: 'building',
    icon: <GiCigarette {...iconProps}/>
  },
  {
    label: t('to.hotel.facilities.air conditioning'),
    value: 'air conditioning',
    group: 'services',
    icon: <AirConditioningSVG {...secondIconProps}/>
  },
  {label: t('to.hotel.facilities.pets'), value: 'pets', group: 'other', icon: <MdOutlinePets {...iconProps}/>},
  {label: t('to.hotel.facilities.tv'), value: 'tv', group: 'building', icon: <CgScreen {...iconProps}/>},
  {label: t('to.hotel.facilities.dryer'), value: 'dryer', group: 'building', icon: <DryerSVG {...secondIconProps}/>},
  {
    label: t('to.hotel.facilities.luggage storage'),
    value: 'luggage storage',
    group: 'services',
    icon: <MdLuggage {...iconProps}/>
  },
  {
    label: t('to.hotel.facilities.currency exchange'),
    value: 'currency exchange',
    group: 'services',
    icon: <GrCurrency {...iconProps}/>
  },
  {label: t('to.hotel.facilities.minibar'), value: 'minibar', group: 'hostelry', icon: <MdLocalDrink {...iconProps}/>},
  {label: t('to.hotel.facilities.fridge'), value: 'fridge', group: 'building', icon: <FridgeSVG {...secondIconProps}/>},
];

export const facilityGroups = [
  {label: t('to.hotel.facilities.groups.building'), value: 'building'},
  {label: t('to.hotel.facilities.groups.services'), value: 'services'},
  {label: t('to.hotel.facilities.groups.hostelry'), value: 'hostelry'},
  {label: t('to.hotel.facilities.groups.other'), value: 'other'},
]