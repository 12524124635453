// Todo: check all the types and HotelRoomWithAllServices
import {useTranslation} from "react-i18next";
import {useHotel} from "../../../../../controllers/useHotel";
import React, {useEffect, useMemo, useState} from "react";
import {Hotel, HotelRoomWithAllServices} from "../../../../../types/hotels/hotels";
import ListFacilities from "../ListFacilities/ListFacilities";
import {createFacilityGroups, getAllFacilitiesFromHotel} from "../../../facilities/util";
import {UseHotelTypes} from "../../../../../controllers/useHotel/models";


interface Props {
  hotel: Hotel,
  room: HotelRoomWithAllServices;
}

export const ShowRoomServices = ({room, hotel}: Props) => {
  const {t} = useTranslation();

  const {getTypes} = useHotel();

  const [hotelTypes, setHotelTypes] = useState<UseHotelTypes>();

  const loadFacilityGroups = async () => {
    const allTypes = await getTypes();
    if (!allTypes) return;
    setHotelTypes(allTypes);
  }

  useEffect(() => {
    loadFacilityGroups();
  }, []);

  let groups = useMemo(() => hotelTypes ? createFacilityGroups({
    facilities: getAllFacilitiesFromHotel({hotel, room}),
    hotelTypes
  }) : [], [hotelTypes, room, hotel]);


  if (!Array.isArray(groups) || groups.length === 0) return <div>{t('hotel.no facilities found')}</div>;

  return (
    <div>
      <ListFacilities groups={groups}/>
    </div>
  );
}