import React from 'react';
import MyProfileData from "../MyProfileData";
import MyProfileHotels from "../MyProfileHotels";

const MyProfile = () => {
  return (
    <div className="flex gap-5 xl:flex-nowrap flex-wrap w-full">
      <div className="w-auto" style={{maxHeight: '800px'}}>
        <MyProfileData/>
      </div>
      <div className="w-full hidden md:block">
        <MyProfileHotels/>
      </div>
    </div>
  );
}


export default MyProfile;
