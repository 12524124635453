import React from 'react';
import sc from '../Checkout.module.css';
import {useTranslation} from "react-i18next";
import {TextInput} from "../Inputs/TextInput/TextInput";

const EmergencyContact = props => {
  const {t} = useTranslation();

  return (
    <div className={sc.box}>
      <h2>{t('checkout.emergency contact')}</h2>
      <div className="w-full flex flex-wrap md:flex-nowrap mb-10">
        <TextInput
          label={t('name')}
          name={`emergency_contact.name`}
          id={`checkout_emergency_contact_name`}
          containerClassName="md:mr-5"
          withAsterisk={true}
          required
        />
        <TextInput
          label={t('surname')}
          name={`emergency_contact.surname`}
          id={`checkout_emergency_contact_surname`}
          second_style={true}
        />
      </div>
      <div className="w-full flex flex-wrap md:flex-nowrap mb-10">
        <TextInput
          label={t('checkout.kin')}
          name={`emergency_contact.kin`}
          id={`checkout_emergency_contact_kin`}
          second_style={true}
          containerClassName="md:mr-5"
        />
        <TextInput
          label={t('checkout.phone number')}
          name={`emergency_contact.phone`}
          id={`checkout_emergency_contact_phone`}
          second_style={true}
          withAsterisk={true}
          required
        />
      </div>
      <TextInput
        type="email"
        label={t('email')}
        name={`emergency_contact.email`}
        id={`checkout_emergency_contact_email`}
        second_style={true}
        withAsterisk={true}
        required
      />
    </div>
  );
};


export default EmergencyContact;
