import React, {useEffect} from 'react';
import s from './Main.module.css';
import {Outlet, useLocation} from 'react-router-dom';
import SelectOperation from "./SelectOperation";
import Header, {HeaderStyleType} from "../Header/Header";
import {SmallFooter} from "../../pages/HomePage/SmallFooter";
import cn from "classnames";
import {HomePageTitles} from "../../pages/HomePage/HomePageTitles";

const Main = props => {
  const [animationState, setAnimationState] = React.useState(0);
  const location = useLocation();
  let selection = location.pathname.substring(3);
  const index = selection.indexOf('/');
  selection = selection.substring(0, index === -1 ? selection.length : index) || 'hotels';

  const isComingFromHomePage = location.state?.fromHomePage;

  useEffect(() => {
    setTimeout(() => setAnimationState(1), 100);
    setTimeout(() => setAnimationState(2), 9000);
  }, []);


  return (<>
      <div className={cn([
        s.container,
        {[s[selection]]: animationState},
        {[s.startAnimation]: animationState}
      ])}>
        <div className={s.overlay}/>

        <Header styleType={HeaderStyleType.white}/>

        {animationState !== 2 && <HomePageTitles className={s.homePageTitles}/>}
        <div className={s.selectOperationContainer}>
          <SelectOperation isComingFromHomePage={isComingFromHomePage}/>
        </div>
        <div className={s.outletContainer}>
          <Outlet/>
        </div>
        <SmallFooter/>

      </div>
      {/*

      <div className="my-40">
        <CustomizedTripBanner/>
      </div>


      <div className="py-40" style={{background: '#F7F7F7'}}>
        <TopHotels/>
      </div>

*/}

    </>
  );
};


export default Main;
