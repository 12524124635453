import React, {useState} from 'react';
import s from './ActivityDetailsImages.module.css';
import GalleryPopup from "../../../GalleryPopup";
import config from "../../../../config";

const ActivityDetailsImages = ({activity, removeFailedImage}) => {
	const [imageIndex, setImageIndex] = useState(0);
	const [isGalleryOpen, setIsGalleryOpen] = useState(false);
	const findImageResource = image => {
		if(!Array.isArray(image.urls))return config.imagesURL + '/' + image;
		let im = image.urls.find(url => url.sizeType === 'LARGE2');
		if (im) return im.resource;

		// find larger
		let l = 0;
		let i = 0;
		image.urls.forEach((url, j) => {
			if (url.width + url.height > l) {
				l = url.width + url.height;
				i = j;
			}
		});
		return image[i].resource;
	}

	return (
		<>
			<GalleryPopup
				images={activity.images.map(image => findImageResource(image))}
				index={imageIndex}
				setIndex={setImageIndex}
				isOpen={isGalleryOpen}
				setIsOpen={setIsGalleryOpen}
			/>
			<div className={s.gallery}>
				<div className={s.chosen_image}>
					<img onClick={() => setIsGalleryOpen(true)} className={s.mainImage}
							 src={findImageResource(activity.images[imageIndex])} alt={"activity main image"}/>
				</div>
				<div className={s.rest_images}>
					{activity.images.map((image, i) =>
						<img onClick={() => setImageIndex(i)} onError={() => removeFailedImage(i)} className={s.thumbnailImage}
								 key={i}
								 src={findImageResource(image)} alt="activity image"/>
					)}
				</div>
			</div>
		</>
	);
};


export default ActivityDetailsImages;