import React, {useEffect, useRef, useState} from 'react';
import bigStyle from './InputWithOutsideLabel.module.css';
import smallStyle from './InputWithOutsideLabel-small.module.css';

import {Controller, useFormContext} from "react-hook-form";

import {ReactComponent as WarningSVG} from "../../../resources/svg/warning.svg";

const DataElement = ({s, element, onMouseDown}) => (
  <div className={s.dataElement} onMouseDown={onMouseDown}>
    {element.icon && <div className={s.dataIcon}>{element.icon}</div>}
    <div className="inline-block">
      <div className={s.dataText}>{element.text}</div>
      <div className={s.dataSmallText}>{element.smallText}</div>
    </div>
  </div>
);

const InputWithOutsideLabel = ({
                                 id,
                                 name,
                                 label,
                                 type,
                                 icon,
                                 warning,
                                 withBorder = true,
                                 containerClassName,
                                 disabled,
                                 labelClassName,
                                 onChange,
                                 onFocus,
                                 onBlur,
                                 initialValue = '',
                                 data,
                                 placeholder,
                                 Data,
                                 onDataSelect,
                                 small = false,
                                 ...props
                               } = {type: 'text'}) => {

  const [isActive, setIsActive] = useState(false);
  const [warningVisible, setWarningVisible] = useState(false);
  const selected = useRef(false);

  const s = small ? smallStyle : bigStyle;

  const input = useRef();
  const Element = Data || DataElement;

  const {setValue} = useFormContext();


  const _onFocus = e => {
    setIsActive(true);
    e.target.classList.add(s.active);
    onFocus && onFocus();
  }
  const _onBlur = e => {
    setIsActive(false);
    if (e.target.value.length > 0) {
      e.target.classList.add(s.active);
    } else {
      e.target.classList.remove(s.active);
    }
    onDataSelect && !selected.current && data[0] && onDataSelect(data[0], input);
    onBlur && onBlur();
  }

  const dataClassName = `${s.data} ${isActive ? s.dataActive : ''}`;

  useEffect(() => {
    if (!data?.length || !input.current) return;
    selected.current = false;
    setValue(name, data[0]);
    //onDataSelect && onDataSelect(data[0], input);
  }, [data]);

  // useEffect(() => {
  //   const target = input.current;
  //   target.addEventListener('focus', _onFocus, false);
  //   target.addEventListener('blur', _onBlur, false);
  //   target.value = initialValue;
  //   return () => {
  //     target.removeEventListener('focus', _onFocus);
  //     target.removeEventListener('blur', _onBlur);
  //   }
  // }, []);

  return (
    <Controller
      name={name}
      render={({field: {onChange: _onChange, value}}) => (
        <div className={`${containerClassName} ${disabled ? s.disabled : ''}`}>
          {label && <label className={`${s.label_outside} ${labelClassName || ''}`} htmlFor={id}>{label}</label>}
          <div className={`${s.container} ${withBorder ? s.containerWithBorder : ''}`}>
            {icon && (<div className={s.icon}>
              {icon}
            </div>)}
            <input disabled={disabled} ref={input} type={type} name={name} id={id}
                   onFocus={(e) => _onFocus(e)}
                   onBlur={e => _onBlur(e)}
                   onChange={e => {
                     onChange && onChange(e);
                     if (!data.length) {
                       _onChange(e.target.value);
                     }
                   }}
                   value={typeof value === 'string' ? value : null} {...props} />
            {/*<input disabled={disabled} placeholder={placeholder} ref={input} type={type} name={name}*/}
            {/*       id={id} {...props} />*/}

            {warning && <div onMouseOver={() => setWarningVisible(true)} onMouseOut={() => setWarningVisible(false)}
                             className={s.warning}>
              <WarningSVG/>
              {warningVisible && <div className={s.warning_content} dangerouslySetInnerHTML={{__html: warning}}/>}
            </div>}

            {data && (
              <div className={dataClassName}>
                {data.map((el, i) => {
                  if (el.dataRender === 'custom') {
                    const Render = el.render;
                    return <Render key={i}/>
                  }
                  return <Element key={i} element={el} s={s} onMouseDown={e => {
                    _onChange(el);
                    selected.current = true;
                    onDataSelect(el, input);
                  }}/>
                })}
                {/*{data.map((el, i) =>*/}
                {/*  (<Element key={i} element={el} s={s} onMouseDown={() => {*/}
                {/*    _onChange(el);*/}
                {/*    selected.current = true;*/}
                {/*    onDataSelect(el, input);*/}
                {/*  }}/>))}*/}
              </div>)
            }
          </div>
        </div>
      )}
    />
  )
}


export default InputWithOutsideLabel;
