import React from 'react';
import s from './LoginPage.module.css';
import {useTranslation} from "react-i18next";

import logoSrc from "../../resources/images/logo/logo_text.png";
import Input from "../../components/Inputs/Input";
import Checkbox from "../../components/Inputs/Checkbox";
import NormalText from "../../components/TextComponents/NormalText";
import Button from "../../components/Inputs/Button/Button";
import GoogleButton from "../../components/Inputs/GoogleButton";
import FacebookButton from "../../components/Inputs/FacebookButton";
import SmallText from "../../components/TextComponents/SmallText";
import useAuth from "../../providers/AuthProvider/useAuth";
import {useForm} from "react-hook-form";
import {useUtil} from "../../hooks/useUtil";
import PasswordInput from "../../components/Inputs/PasswordInput";
import ShowErrors from "../../components/ErrorManager/ShowErrors";
import bases from "../../routes/bases";
import {useNavigate} from "react-router-dom";
import Header from "../../components/Header/Header";
import {Footer} from "../../components/FooterV2/Footer";
import {getLastURL} from "../../helpers/util";

const LoginPage = ({show = true, setPage}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {login} = useAuth();
  const {requiredMsg} = useUtil();
  const {register, handleSubmit, formState: {errors, isSubmitting}, setError, clearErrors} = useForm();

  const onSubmit = async user => {
    const {error, success} = await login(user);
    if (error) return setError('server', {
      type: 'manual',
      message: error?.message
    });
    if (success) {
      const prevURL = getLastURL()
      navigate(prevURL || bases.main);
    }
  }

  return (
    <>
      <Header/>
      <div style={{background: '#eff4f2'}}>
        <div className={s.bigContainer}>
          <div className="w-full mx-auto z-10 pt-5 px-10 sm:px-0" style={{maxWidth: 600}}>
            <div className={s.formContainer}>
              <img src={logoSrc} className="m-auto mb-20" alt="Logo" width={300}/>
              {/*<Title className={s.titleLogin}>{t("login.title")}</Title>*/}
              <form onSubmit={handleSubmit(onSubmit)}>
                <Input
                  placeholder={t("email")}
                  {...register('email', {
                    required: requiredMsg('email')
                  })}
                />
                <PasswordInput
                  containerClassName="mt-8"
                  placeholder={t("password")}
                  {...register('password', {
                    required: requiredMsg('password')
                  })}
                />
                <div className="flex mt-10">
                  <div className="w-full text-left">
                    <Checkbox id="loginPage_remember_me" label={t("remember me")}/>
                  </div>
                  <div className="w-full text-right">
                    <NormalText onClick={() => navigate(bases.resetPassword)}
                                className="cursor-pointer hover:underline">{t("password forgotten")}</NormalText>
                  </div>
                </div>

                <div>
                  <ShowErrors defaultStyle={true} onlyOne={true} errors={errors?.server?.message}/>
                </div>
                <div className="mt-10 mx-auto" style={{maxWidth: 300}}>
                  <Button onClick={() => clearErrors('server')}
                          loading={isSubmitting}>{t("login.login_button")}</Button>
                </div>

                <div className="flex flex-nowrap mt-10">
                  <div className={s.smallBar}/>
                  <NormalText className="w-full px-5"
                              style={{color: '#979797'}}>{t("login.login alternatives")}</NormalText>
                  <div className={s.smallBar}/>
                </div>

                <div className="flex p-5">
                  <FacebookButton/>
                  <GoogleButton className="ml-10"/>
                </div>

                <div className="w-full text-center mt-12">
                  <NormalText className={s.missingAccount}>
                    {t('login.missing account') + " "}
                    <span className="underline cursor-pointer"
                          onClick={() => navigate(bases.register)}>{t('login.register')}</span>
                  </NormalText>
                </div>
              </form>
            </div>
            <div className="flex flex-wrap justify-around my-5">
              <SmallText className="underline">{t("terms and conditions")}</SmallText>
              <SmallText>{t("copyright trippy")}</SmallText>
            </div>

          </div>

          <div className={s.bannerContainer}>
            <div className={s.banner}>
              <div className={s.bannerText}>{t('login.bannerText')}</div>
              <div className={s.bannerQuote}>{t('login.bannerQuote')}</div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};


export default LoginPage;
