import {Hotel, SearchHotelURLParams} from "../../../../../types/hotels/hotels";
import React, {useMemo, useState} from "react";
import {MapHotelsToLoad} from "../../../../MainSearch/SearchHotels/MapHotels/MapHotelsToLoad";
import MapBox from "../../../../MapBox/MapBox";
import {MapMarker} from "../../../MapHotel/MapMarker";

interface Props {
  hotel: Hotel
  searchParams?: SearchHotelURLParams
}

export function HotelDetailsMap({hotel, searchParams}: Props) {
  const [visible, setVisible] = useState(false)
  const [viewState, setViewState] = useState({
    longitude: hotel.longitude,
    latitude: hotel.latitude,
    zoom: 16
  });

  const markers = useMemo(() => ([{
    key: hotel.code,
    longitude: hotel.longitude,
    latitude: hotel.latitude,
    render: <MapMarker selected={true}/>
  }]), [hotel])

  const canSearch = !!searchParams

  const onMapClick = () => {
    if (!canSearch) return
    setVisible(true)
  }

  return <div className="relative">
    {canSearch && hotel &&
      <MapHotelsToLoad selectedHotel={hotel} hideFullScreenControl={true} visible={visible} setVisible={setVisible}
                       searchParams={searchParams}/>}

    <MapBox
      onClick={onMapClick}
      containerStyle={{
        borderRadius: '15px',
        overflow: 'hidden'
      }}
      markers={markers}
      viewState={viewState}
      setViewState={setViewState}
      height={"238px"}
      width={'100%'}
      hideFullScreenControl={canSearch}
    />


    {/*<MapContainer*/}
    {/*  containerStyle={{*/}
    {/*    width: '100%',*/}
    {/*    height: '100%',*/}
    {/*    borderRadius: '15px',*/}
    {/*    maxHeight: '300px'*/}
    {/*  }}*/}
    {/*  marker={{name: hotel.name}}*/}
    {/*  center={{*/}
    {/*    // @ts-ignore*/}
    {/*    lat: hotel.latitude * 1,*/}
    {/*    // @ts-ignore*/}
    {/*    lng: hotel.longitude * 1*/}
    {/*  }}*/}
    {/*  zoom={16}*/}
    {/*/>*/}
    {/*{canSearch &&*/}
    {/*  <div className={s.openHotelMaps} onClick={() => setVisible(true)}>{t('hotel.nearby hotels')}</div>}*/}
  </div>


}