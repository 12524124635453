import React from 'react';

const Loader = props => {
  return (
    <div>Cargando...</div>
  );
};


export default Loader;
