import OriginalButton, {ButtonProps} from "../../../Inputs/Button/Button";
import cn from "classnames";
import s from './Button.module.css';

interface Props extends ButtonProps {

}

export function Button({className, children, ...props}: Props) {
  return (
    <OriginalButton className={cn([s.button, className])} {...props} >
      {children}
    </OriginalButton>
  )
}