import s from './SelectSubContinent.module.css';
import cn from "classnames";
import React, {useEffect, useMemo, useState} from "react";
import {getContinentImages, getPartsOfTheWorld} from "../../../helpers/uniqueExperiences";
import {t} from "i18next";
import Slider from "../../Slider/Slider";
import {Controller, Navigation, Scrollbar} from 'swiper/modules';
import type {Swiper as SwiperClass} from "swiper/types";


interface Props {
  className?: string;
  onChange: (codes: string[], selected: any[]) => void;
}

export function SelectSubContinent({className, onChange}: Props) {
  const [selectedContinent, setSelectedContinent] = useState<string>()
  const [selected, setSelected] = useState<any[]>([]);
  const [controlledSwiper, setControlledSwiper] = useState<SwiperClass>(null);

  const subcontinents = React.useMemo(() => getPartsOfTheWorld(), [t]);
  const continents = React.useMemo(() => getContinentImages(), []);

  const toggleSubcontinent = (subcontinent: any) => {
    const index = selected?.findIndex((s) => s.id === subcontinent.id);
    if (index === -1) {
      setSelected(prev => [...prev, subcontinent]);
    } else {
      setSelected(prev => {
        const newSelected = [...prev];
        newSelected.splice(index, 1);
        return newSelected;
      });
    }
  }


  useEffect(() => {
    let codes: string[] = [];
    if (!selected) return onChange([], []);
    subcontinents.forEach((group) => {
      selected.forEach(s => {
        if (s.id.includes(group.id)) {
          codes = [...codes, ...group.countryCodes];
        }
      })
    });
    onChange(codes, selected);
  }, [selected]);

  const groups: any = useMemo(() => {
    const result: any[] = [];
    subcontinents.filter(s => s.image).forEach((s) => {
      if (!result.find((g: any) => g.continent === s.continent)) {
        result.push({continent: s.continent, subcontinents: []});
      }
      result.find((g: any) => g.continent === s.continent).subcontinents.push(s);
    });
    return result
  }, [subcontinents]);

  return <div>
    {!selectedContinent ? <div className={s.continentContainer}>
        {continents.map((continent: any, i: number) => <div className={s.continent} key={continent.id}
                                                            onClick={() => {
                                                              setSelectedContinent(continent.id)
                                                            }}>
          <div className={cn([s.image, {[s.selected]: selectedContinent === continent.id}])}>
            <img src={continent.image} alt={continent.id}/>
          </div>
          <div
            className="text-center text-2xl lg:text-3xl mt-8 font-semibold">{t(`continentList.${continent.id}`)}</div>
        </div>)}
      </div>
      :

      <Slider
        modules={[Scrollbar, Navigation, Controller]}
        /*activeSlide={groups.findIndex((g: any) => g.continent === selectedContinent)}*/
        controller={{control: controlledSwiper}}
        onSlideChange={(swiper: any) => {
          setSelectedContinent(groups[swiper.activeIndex].continent);
        }}
        freeMode
        loop
        onSwiper={(swiper: SwiperClass) => {
          setControlledSwiper(swiper)
          const index = groups.findIndex((g: any) => g.continent === selectedContinent) + 1
          swiper.slideTo(index >= groups.length ? 0 : index, 0);
        }}
        navigation
        spaceBetween={40}
        centeredSlides={true}
        slidesPerView={1}
        breakpoints={{}}
        elements={groups.map((group: any, i: number) => <div key={i}>
          <div
            className={s.continentName}>
            {t(`continentList.${group.continent}`)}
            <div className={s.separator}/>

          </div>
          <div className={cn([s.container, className])}>
            {group.subcontinents.map((subcontinent: any, i: number) => <div key={i}
                                                                            onClick={() => toggleSubcontinent(subcontinent)}
                                                                            className={s.continent}>
              <div className={cn([s.image, {[s.selected]: selected.find(s => s?.name === subcontinent.name)}])}>
                <img src={subcontinent.image} alt={subcontinent.name}/>
              </div>
              <div className="text-center text-2xl lg:text-3xl mt-8 font-semibold">{subcontinent.name}</div>
            </div>)}
          </div>
        </div>)}
      />
    }
  </div>
}