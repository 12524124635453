import React from 'react';
import Header from "../../Header/Header";
import {Navigate, Outlet} from "react-router-dom";
import useAuth from "../../../providers/AuthProvider/useAuth";
import bases from "../../../routes/bases";
import MyProfileMenu from "../MyProfileMenu";
import {Footer} from "../../FooterV2/Footer";

const MyProfileRouter = props => {
  const {isAuthenticated} = useAuth();

  if (!isAuthenticated()) {
    return <Navigate to={bases.main}/>
  }

  return (
    <>
      <Header/>
      <div className="min-h-screen">
        <div className="flex flex-wrap md:flex-nowrap">
          <MyProfileMenu/>
          <div className="flex 2xl:p-20 p-10 w-full"
               style={{borderTopLeftRadius: '15px', background: '#F6F6F6'}}>
            <Outlet/>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};


export default MyProfileRouter;
