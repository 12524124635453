import React, {useEffect} from 'react';
import Header from "../Header/Header";
import {Outlet} from "react-router-dom";
import {Footer} from "../FooterV2/Footer";

const CheckoutRouter = props => {

  useEffect(() => {
    document.body.style.backgroundColor = '#F6F6F6';
  }, []);

  return (
    <div>
      <Header/>
      <div className="min-h-screen"><Outlet/></div>
      <Footer/>
    </div>
  );
};


export default CheckoutRouter;
