import React, {useEffect, useState} from 'react';
import s from './HotelDetailsImages.module.css';
import GalleryPopup from "../../../../GalleryPopup";
import {buildImagePath, HotelImageSize} from "../../../util";
import {useHotel} from "../../../../../controllers/useHotel";
import {useTranslation} from "react-i18next";

import {ReactComponent as ShowMoreArrowSVG} from "../../../../../resources/svg/show_more_arrow.svg";
import Checkbox from "../../../../Inputs/Checkbox";
import {Hotel} from "../../../../../types/hotels/hotels";
import LoadingSVG from "../../../../LoadingSVG/LoadingSVG";
import SharePopup from "../../../../SharePopup/SharePopup";
import {ReactComponent as LikeSVG} from "../../../../../resources/svg/like_circle.svg";
import HotelImage from "../../../HotelImage/HotelImage";
import {useViewportSize} from "@mantine/hooks";
import {useImages} from "../../../../../hooks/useImages";

interface ImageType {
  code: string;
  description: string;
  selected: boolean;
}


interface ImageTypesProps {
  imageTypes: ImageType[],
  onSelect: (imageTypeCode: string) => void
}

const ImageTypes = ({imageTypes, onSelect}: ImageTypesProps) => {
  const {t} = useTranslation();
  const [visible, setVisible] = useState(false);

  return <div className={`${s.imageTypesContainer} ${visible ? s.imageTypesContainerVisible : ''}`}>
    <div className={s.imageTypesTitle} onClick={() => setVisible(prev => !prev)}>
      <div>{t('hotel details images.filter pictures')}</div>
      <ShowMoreArrowSVG width={14}/>
    </div>
    <div className={s.imageTypes}>
      {imageTypes.map((image, i) => <Checkbox
        key={image.code}
        // @ts-ignore
        small={true}
        id={image.code}
        label={image.description}
        checked={image.selected}
        onChange={() => onSelect(image.code)}
        containerClassName={!i ? 'mt-5' : ''}
      />)}
    </div>
  </div>
}


interface HotelDetailsImagesProps {
  hotel: Hotel,
  setHotel?: (hotel: Hotel) => void,
  onLikeClick: () => Promise<void>
}

const HotelDetailsImages = ({hotel, setHotel, onLikeClick}: HotelDetailsImagesProps) => {
  const {t} = useTranslation();
  const [imageIndex, setImageIndex] = useState(0);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);

  const {images: selectedImages, setImages: setSelectedImages, onFailed} = useImages({images: hotel.images});
  const [imageTypes, setImageTypes] = useState<ImageType[]>([]);

  const {getImageTypeDescription, getTypes} = useHotel();

  const {width} = useViewportSize();
  const desktop = width >= 1280;

  const loadImageTypes = async () => {
    const allTypes = await getTypes();
    if (allTypes && allTypes.imageTypes) {
      const imageTypes = allTypes.imageTypes[hotel.api as keyof typeof allTypes.imageTypes];
      if (Array.isArray(imageTypes)) {
        const r = imageTypes.filter(({code}) => hotel.images.some(image => image.imageTypeCode === code));
        // @ts-ignore
        r.unshift({code: 'all', description: t('hotel.all images'), selected: true});
        setImageTypes(r as ImageType[]);
      }
    }
  }

  const onChooseImageType = (code: string) => {
    setSelectedImages(hotel.images.filter(image => image.imageTypeCode === code || code === 'all'));
    setImageTypes(imageTypes.map(type => ({...type, selected: type.code === code})));
    setImageIndex(0);
  }

  useEffect(() => {
    setSelectedImages(hotel.images);
    loadImageTypes();
  }, [hotel]);

  return (
    <>
      <GalleryPopup
        images={selectedImages.map(image => buildImagePath(hotel, image.path, HotelImageSize.PX1024))}
        titles={selectedImages.map(image => getImageTypeDescription({code: image.imageTypeCode, api: hotel.api}))}
        index={imageIndex}
        setIndex={setImageIndex}
        isOpen={isGalleryOpen}
        setIsOpen={setIsGalleryOpen}
      />
      <div className="w-full">
        <div className={s.gallery}>
          <div className={s.chosen_image}>
            <div className="absolute top-0 right-0 pt-5 pr-5">
              <div className="flex justify-end">
                <LoadingSVG
                  Icon={LikeSVG}
                  className={hotel?.is_liked ? s.circleLiked : s.circle}
                  containerClassName={"cursor-pointer mr-5"}
                  onClick={onLikeClick}
                />
                <SharePopup url={window.location.href}/>
              </div>
            </div>
            <HotelImage
              hotel={hotel}
              onFailed={() => {
                onFailed(imageIndex)
                setImageIndex(0);
              }}
              imagePath={selectedImages[imageIndex]?.path}
              className={s.mainImage}
              onClick={() => setIsGalleryOpen(true)}
              alt="hotel chosen image"
            />
            {/*<img className={s.mainImage} onClick={() => setIsGalleryOpen(true)}*/}
            {/*     src={buildImagePath(hotel, selectedImages[imageIndex]?.path, HotelImageSize.PX1024)}*/}
            {/*     alt="hotel chosen image"/>*/}
            {Array.isArray(imageTypes) && imageTypes.length > 0 && desktop &&
              <ImageTypes imageTypes={imageTypes} onSelect={onChooseImageType}/>}

          </div>
          {desktop && <div className={s.rest_images}>
            {selectedImages.map((image, i) =>
              <HotelImage
                key={i}
                hotel={hotel}
                imagePath={image.path}
                onFailed={() => {
                  onFailed(i)
                  setImageIndex(0);
                }}
                onClick={() => setImageIndex(i)}
                className={s.thumbnailImage}
                maxSize={HotelImageSize.PX800}
              />
            )}
          </div>}
        </div>
        {/*
				{Array.isArray(imageTypes) && imageTypes.length > 0 && <div className="w-full flex flex-wrap">
					{imageTypes.map(({selected, description, code}, i) => <div key={i} onClick={() => onChooseImageType(code)}
																																		 className={`${s.typeDescription} ${selected ? s.typeSelected : ''}`}>{description}</div>)}
				</div>}
*/}

      </div>


    </>
  );
};


export default HotelDetailsImages;