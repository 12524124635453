import React, {useMemo} from 'react';
import s from './SelectNationality.module.css';
import {useTranslation} from "react-i18next";
import Flag from 'react-flagpack'
import {components} from "react-select";
import {useFormContext} from "react-hook-form";
import Select from "../Select/Select";


const IconOption = props => (
  <components.Option {...props}>
    <div className="inline-block mr-5">{props.data.icon}</div>
    {props.data.label}
  </components.Option>
);

const SelectNationality = ({name = 'nationality', id, containerClassName, ...props}) => {
  const {t} = useTranslation();

  const {watch} = useFormContext();

  const code = watch(name);

  const codes = useMemo(() => ["AD", "AE", "AFRUN", "AG", "AI", "AL", "AM", "AMS", "AO", "AQ", "AR", "AS", "AT", "AU", "AW", "AX", "AZ", "BA", "BB", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BL", "BM", "BN", "BO", "BQ-BO", "BQ-SA", "BQ-SE", "BR", "BS", "BT", "BW", "BY", "BZ", "CA", "CC", "CD", "CF", "CG", "CH", "CI", "CK", "CL", "CM", "CN", "CO", "CR", "CU", "CV", "CW", "CX", "CY", "CZ", "DE", "DJ", "DK", "DM", "DO", "EC", "EE", "EG", "EH", "ER", "ES", "ET", "EU", "FI", "FJ", "FK", "FM", "FO", "FR", "GA", "GB-ENG", "GB-SCT", "GB-UKM", "GB-WLS", "GB-NIR", "GD", "GE", "GF", "GG", "GH", "GI", "GL", "GM", "GN", "GP", "GQ", "GR", "GS", "GT", "GU", "GW", "GY", "HK", "HM", "HN", "HR", "HT", "HU", "ID", "IE", "IL", "IM", "IN", "IO", "IQ", "IR", "IS", "IT", "JE", "JM", "JO", "JP", "KE", "KG", "KH", "KI", "KM", "KN-SK", "KP", "KR", "KW", "KY", "KZ", "LA", "LB", "LC", "LI", "LK", "LR", "LS", "LT", "LU", "LV", "LY", "MA", "MC", "MD", "ME", "MF", "MG", "MH", "MK", "ML", "MM", "MN", "MO", "MP", "MQ", "MR", "MS", "MT", "MU", "MV", "MW", "MX", "MY", "MZ", "NA", "NC", "NE", "NF", "NG", "NI", "NL", "NO", "NP", "NR", "NU", "NZ", "OM", "PA", "PE", "PF", "PG", "PH", "PK", "PL", "PM", "PN", "PR", "PS", "PT", "PW", "PY", "QA", "RAINBOW", "RE", "RO", "RS", "RU", "RW", "SA", "SB", "SC", "SD", "SE", "SG", "SH", "SI", "SJ", "SK", "SL", "SM", "SN", "SO", "SR", "SS", "ST", "SV", "SX", "SY", "SZ", "TC", "TD", "TF", "TG", "TH", "TJ", "TK", "TL", "TM", "TN", "TO", "TR", "TT", "TV", "TW", "TZ", "UA", "UG", "UM", "UNASUR", "US", "UY", "UZ", "VA", "VC", "VE", "VG", "VI", "VN", "VU", "WF", "WS", "YE", "ZA", "ZM", "ZW", "AF", "BV"], []);

  const options = useMemo(() => {
    let list = t('countryList', {returnObjects: true});
    let r = [];
    for (let code in list) {
      if (!codes.includes(code)) continue;
      r.push({
        value: code,
        label: list[code],
        icon: <Flag size='m' code={code}/>
      });
    }
    return r;
  }, [t]);


  return (
    <div className={`${s.container} ${containerClassName || ''}`}>
      <Select
        icon={code ? <Flag code={code || codes[0]}/> : <></>}
        name={name}
        id={id || name}
        label={t('input.select nationality')}
        components={{Option: IconOption}}
        options={options}
        iconRightPadding={34}
        isSearchable={true}
        {...props}
      />
    </div>
  );
};


export default SelectNationality;
