import React, {useEffect, useState} from 'react';
import s from './SearchHotels.module.css';
import {useUtil} from "../../../hooks/useUtil";
import Hotel from "./Hotel/Hotel";
import {useTranslation} from "react-i18next";
import FilterHotels from "./FilterHotels";
import useHotelsProvider from "../../../providers/HotelsProvider/useHotelsProvider";
import Skeleton from "react-loading-skeleton";
import bases from "../../../routes/bases";
import Button from "../../Inputs/Button/Button";
import {FormProvider} from "react-hook-form";
import {useViewportSize} from "@mantine/hooks";
import {ReactComponent as ExclamationSVG} from "../../../resources/svg/icons/exclamation.svg";
import NoHotelsFound from "./NoHotelsFound/NoHotelsFound";
import {Hotel as HotelType, SearchHotelURLParams} from "../../../types/hotels/hotels";
import {hotelsObjToParams, hotelsParamsToObj} from "../../Hotels/HotelDetails/util";
import MapHotels from "./MapHotels/MapHotels";
import InfiniteScroll from "react-infinite-scroll-component";
import {SearchHotelsFormValues} from "../../Main/Hotels/models";


enum VIEWS {
  MAP,
  LIST
}

const Loading = () => (
  <div className="ml-10">
    <Skeleton count={1} height={50}/>
    <br/>
    <Skeleton count={4} height={200}/>
  </div>
)

const ShowError = () => {
  const {t} = useTranslation();
  return <div className={s.errorContainer}>
    <ExclamationSVG style={{width: '6rem', height: '6rem', color: 'red'}}/>
    <div className="text-3xl my-5">{t('errors.unexpected error')}</div>
    <div className="text-2xl hover:underline cursor-pointer text-blue-700"
         onClick={() => window.location.reload()}>{t('reload page')}</div>
  </div>;
}

const SearchHotels = () => {
  const {setParams} = useUtil();
  const {t} = useTranslation();

  const [fVisible, setFVisible] = useState(false);
  const [error, setError] = useState(false);

  const [view, setView] = useState<VIEWS>(VIEWS.LIST);
  const [visible, setVisible] = useState(12);

  const {width} = useViewportSize();
  const desktop = width >= 1280;

  const {
    hotels,
    loading,
    pagination,
    updateHotels,
    filterForm,
    count: hotelCount
  } = useHotelsProvider();

  const [searchParams, setSearchParams] = useState<SearchHotelURLParams>(() => hotelsParamsToObj());

  const onSearch = (data: SearchHotelURLParams, rawData: SearchHotelsFormValues) => {
    const raw = rawData?.hotel_destination?.raw;
    if (raw?.type === 'hotel') {
      // @ts-ignore
      hotelDetails({hotel: {code: raw?.code, api: raw?.api}});
    } else {
      setParams(hotelsObjToParams(data));
      setSearchParams(data);
    }
  }


  const downloadData = async () => {
    setError(false);
    const result = await updateHotels();
    if (result?.error) setError(true);
  }

  useEffect(() => {
    downloadData();
  }, [searchParams]);

  const hotelDetails = ({hotel}: {
    hotel: Pick<HotelType, 'code' | 'api'>
  }) => {
    let params: SearchHotelURLParams = {
      ...searchParams,
      code: hotel.code,
      api: hotel.api
    };

    window.open(bases.hotelDetails + '?' + hotelsObjToParams(params), '_blank');
    //navigate(bases.hotelDetails + '?' + hotelsObjToParams(params));
  }

  const renderRightBar = () => {
    if (error) return <ShowError/>
    if (loading >= 2 || !Array.isArray(hotels)) return <Loading/>
    if (!hotels.length) return <NoHotelsFound/>

    if (view === VIEWS.MAP) return <MapHotels
      center={[parseFloat(searchParams.lng), parseFloat(searchParams.lat)]} hotels={hotels}
      searchParams={searchParams}/>

    return <InfiniteScroll
      dataLength={visible}
      next={() => setVisible(prev => prev + 12)}
      hasMore={visible < hotels?.length}
      loader={<Skeleton count={4} height={200}/>}
      //endMessage={<p>No more data to load.</p>}
    >
      <div className={s.hotels}>
        {hotels.slice(0, visible).map(hotel => <Hotel className={s.hotel} onMoreInfo={hotelDetails} key={hotel.code}
                                                      checkIn={searchParams.from}
                                                      checkOut={searchParams.to}
                                                      hotel={hotel}/>)}
      </div>
      {/*{hotels.length < pagination.total && <div className="m-auto my-20" style={{width: '250px'}}>*/}
      {/*  <Button onClick={() => updateHotels({loadMore: true})} type="button">{t('load more')}</Button>*/}
      {/*</div>}*/}
    </InfiniteScroll>
  }

  const totalHotelsFound = view === VIEWS.MAP ? hotelCount : pagination?.total;


  return (
    <div className="w-full sm:px-20 pt-20 pb-10 min-h-screen" style={{background: '#EFF4F2'}}>
      <FormProvider {...filterForm}>
        <div className={s.container}>
          <div className={`${s.leftBar} ${(desktop || fVisible) ? s.leftBarVisible : ''}`}>
            {!error && <>
              <FilterHotels searchParams={searchParams} onSearch={onSearch} desktop={desktop}
                            fVisible={fVisible}
                            setFVisible={setFVisible}/>
            </>}
          </div>

          <div className={s.rightBar}>
            <div className={s.rightBarTopButtonsContainer}>
              {!error && !!hotelCount &&
                <div
                  className={s.totalHotelsText}>{t('total hotels found', {count: totalHotelsFound})}</div>}
              <div className="flex justify-center xl:m-0 gap-5" style={{width: 300}}>
                <Button className={s.mapListButtons} disabled={view === VIEWS.LIST}
                        onClick={() => {
                          setVisible(12)
                          setView(VIEWS.LIST)
                        }}>{t('list')}</Button>
                <Button className={s.mapListButtons} disabled={view === VIEWS.MAP}
                        onClick={() => setView(VIEWS.MAP)}>{t('map')}</Button>
              </div>
            </div>
            {renderRightBar()}
          </div>
        </div>

      </FormProvider>
    </div>
  );
};


export default SearchHotels;
