import React from 'react';
import s from '../UniqueExperienceRequestBudged.module.css';
import {useTranslation} from "react-i18next";
import CheckboxWithContainer from "../../../Inputs/CheckboxWithContainer";
import {useFormContext} from "react-hook-form";
import Textarea from "../../../Inputs/Textarea";
import moment from "moment/moment";

const UEBInsurance = props => {
  const {t} = useTranslation();
  const form = useFormContext();

  const {setValue, getValues} = form;

  const onArriveDateChange = departure_name => date => {
    let to = getValues(departure_name);
    if (to <= date) setValue(departure_name, moment(date).add(1, 'day'));
  }

  const isOutsideRange = arrive_name => day => {
    let from = getValues(arrive_name) || moment().subtract(1, 'day');
    return day <= from;
  }

  return (
    <div>
      <div className={s.title}>{t('experience_details.rbd.insurance')}</div>
      <div className="flex xl:flex-nowrap flex-wrap">
        <div className="flex w-full">
          <CheckboxWithContainer onChange={v => setValue('insurance_type.cancellation', !v)}
                                 containerClassName="w-full xl:mr-10 mr-0"
                                 label={t('experience_details.rbd.basic insurance')}
                                 name="insurance_type.basic"/>
          <CheckboxWithContainer onChange={v => setValue('insurance_type.basic', !v)}
                                 containerClassName="w-full"
                                 label={t('experience_details.rbd.cancellation insurance')}
                                 name="insurance_type.cancellation"/>
        </div>
      </div>
      <div className="mt-10">
        <div className={s.label}>{t('experience_details.rbd.describe your group')}</div>
        <div className="relative">
          <Textarea
            name="describe_your_group"
            placeholder={t('experience_details.rbd.describe your group')}
          />
          <div className={s.small_help}>{t('experience_details.rbd.describe your group help')}</div>
        </div>
        <div className="relative mt-10">
          <Textarea
            name="others"
            placeholder={t('experience_details.rbd.contact us others textarea')}
          />
          <div className={s.small_help}>{t('experience_details.rbd.additional comments')}</div>
        </div>
      </div>
      {/*<div className="mt-10">
				<div className={s.label}>{t('experience_details.rbd.dates description')}</div>
				<div className="flex flex-wrap md:flex-nowrap mt-10">
					<div className="w-full mr-0 md:mr-10">
						<DatePickerWithOutsideLabel
							label={t("experience_details.rbd.date from")}
							labelClassName={s.date_label}
							name="date_from"
							id="date_from"
							onChange={onArriveDateChange('date_to')}
						/>
					</div>
					<div className="w-full">
						<DatePickerWithOutsideLabel
							labelClassName={s.date_label}
							label={t("experience_details.rbd.date to")}
							id="date_to"
							isOutsideRange={isOutsideRange('date_from')}
							name="date_to"
						/>
					</div>
				</div>
			</div>*/}

    </div>
  );
};


export default UEBInsurance;