import {useTranslation} from "react-i18next";
import moment from "moment";
import {getURLParams, hashCode, objToParams as objToParamsNew, paramsToObj} from "../../helpers/util";

export const useUtil = () => {
  const {t} = useTranslation();
  const requiredMsg = a => t(`validate.${a} is required`);

  const formError = setError => message => setError('other', {message: message});
  const findParam = name => {
    try {
      let items = window.location.search.substring(1).split('&');

      for (let i = 0; i < items.length; i++) {
        let data = items[i].split('=');
        if (data[0] === name) return data[1];
      }
    } catch (err) {
    }
    return false;
  }

  const objToParams = data => {
    return objToParamsNew(data);
  }

  const setParams = (data, preservaOld = false) => {
    let urlParams;

    if (typeof data === 'string') data = paramsToObj(data);

    if (preservaOld) {
      let old = getURLParams();
      data = {...old, ...data};
    }
    urlParams = objToParams(data);

    window.history.replaceState({}, null, window.location.pathname + '?' + urlParams);
  }

  // end hotels

  // activities

  const activities_paramsToObj = params => {
    params = params || paramsToObj();
    return {
      to: moment(params.to),
      from: moment(params.from),
      destination: {
        lat: params.lat,
        lng: params.lng
      },
      text: params.t,
      persons: JSON.parse(params.persons)
    }
  }

  const activities_objToParams = data => {
    let result = {
      from: data.from.toISOString(),
      to: data.to.toISOString(),
      lat: data.destination.lat,
      lng: data.destination.lng,
      t: data.text,
      persons: JSON.stringify(data.persons)
    };

    return objToParams(result);
  }

  const activity_pointOfInterestToText = obj => {
    let r = '';
    if (obj.description) r += obj.description + ', ';
    if (obj.address && obj.address !== obj.description) r += obj.address + ', ';
    if (obj.city && r.indexOf(obj.city) === -1) r += obj.city;

    // missing country, we have only country code

    if (r.substring(r.length - 2) === ', ') return r.substring(0, r.length - 2);

    return r;
  }

  // end activities

  // start unique experiences

  const experience_objToParams = data => {
    let result = {
      departure: data.departure?.toISOString()
    };

    if (data.origin) {
      result.t = data.origin.text;
      result.lat = data.origin.raw.lat;
      result.lng = data.origin.raw.lng;
    }
    if (Array.isArray(data.categories) && data.categories.length) {
      result.categories = data.categories.join(',');
    }
    if (Array.isArray(data.map_codes) && data.map_codes.length) {
      result.mc = data.map_codes.join(',');
      result.mt = data.map_type;
    }

    return objToParams(result);
  }

  const experience_paramsToObj = params => {
    params = params || paramsToObj();
    let r = {};
    if (params.categories) r.categories = params.categories.split(',');
    if (params.t && params.lat && params.lng) {
      r.origin = {
        text: params.t,
        raw: {
          lat: params.lat,
          lng: params.lng
        }
      }
    }
    if (params.mc && params.mt) {
      r.map_codes = params.mc.split(',');
      r.map_type = params.mt;
    }
    if (params.departure) r.departure = moment(params.departure);

    return r;
  }

  // end unique experiences

  return {
    requiredMsg,
    hashCode: hashCode,
    formError,
    findParam,
    setParams,
    activities_paramsToObj,
    activities_objToParams,
    objToParams,
    activity_pointOfInterestToText,
    experience_objToParams,
    experience_paramsToObj
  }
};


export default useUtil;
