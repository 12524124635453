import React, {useEffect} from 'react';
import Button from "../../Inputs/Button/Button";
import bases from "../../../routes/bases";
import {ReactComponent as ArrowButtonSVG} from "../../../resources/svg/arrow_button_right.svg";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Title from "../../TextComponents/Title";
import PartsOfTheWorldMap from "../../Maps/PartsOfTheWorldMap";
import {useFormContext} from "react-hook-form";

const UniqueExperienceChooseCountry = props => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const form = useFormContext();
  useEffect(() => window.scrollTo(0, 0), []);


  const onMapChange = ({codes, mapType}) => {
    form.setValue('map_codes', codes);
    form.setValue('map_type', mapType);
  }


  return (
    <div className="w-full">
      <Title className="w-full mb-10 block text-center">{t("search_experiences.parts of the world")}</Title>
      <PartsOfTheWorldMap buttonsContainerStyle={{top: '-100px'}} onChange={d => onMapChange(d)}/>
      <div className="w-full flex justify-center mt-10">
        <div className="w-72 mr-10">
          <Button onClick={() => navigate(bases.uniqueExperienceSelectCategories)}>
            {t('prev')}
            <ArrowButtonSVG style={{fill: '#fff'}} className="inline-block ml-5 transform rotate-180"/>
          </Button>
        </div>

        <div className="w-72">
          <Button onClick={() => navigate(bases.uniqueExperienceBeforeSearch)}>
            {t('next')}
            <ArrowButtonSVG style={{fill: '#fff'}} className="inline-block ml-5"/>
          </Button>
        </div>
      </div>
    </div>
  );
};


export default UniqueExperienceChooseCountry;