const config = {
  pageURL: 'https://trippytrekkers.com',
  //pageURL: 'http://localhost:3000',
  serverURL: '',
  imagesURL: 'https://trippy-trekkers.s3.eu-west-3.amazonaws.com',
  trippy_trekkers: {
    contact_email: 'bookings@trippytrekkers.com',
    phone_number: '0034 660 033 859',
    emergency_number: '0034 650 255 919'
  },
  google: {
    client_id: '1029945810633-adlb5fru202rpidiggs4paimphlcor91.apps.googleusercontent.com',
    scope: 'openid profile email',
    callback_url: '/login/google/callback',
    maps_api_key: 'AIzaSyALSc_QJP-f6MMWezzW5dE_SeEwUOcGcuk'
  },
  stripe: {
    public_key: 'pk_live_51MKoBSCSnk8xjf6EjQMERuUqS6zhjn2rDbgoR5n2XQdx6kmgeLQUidpuYNj3CqkHxW9RqOmXEy5nCyz3GDm36hex00HhWYMFGs',
    //public_key: 'pk_test_51MKoBSCSnk8xjf6EoGzEYuFriJ3Qq9GdOpumlrShiIuDYTSRAykA68TCa97WQzpVnZclEkwEJ8WSg6B5Rb580kxZ00qKxxS8Eq',
    callback_url: '/p/checkout/success'
  },
  instagram: 'https://www.instagram.com/trippytrekkers_',
  facebook: 'https://www.facebook.com/trippytrekkers',
  trippyDivers: 'https://trippydivers.com'
};

export default config;