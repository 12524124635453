import React from 'react';
import s from './BuildYourTripStep1.module.css';
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import bases from "../../../../routes/bases";

const BuildYourTripStep1 = props => {
  const {t} = useTranslation();
  const {setValue} = useFormContext();
  const navigate = useNavigate();

  const onChoose = box => {
    setValue('known_destination', box === 1);
    navigate(bases.btrip_step2);
  }

  return (
    <div className="w-full flex flex-wrap lg:flex-nowrap">
      <div className={s.box1} onClick={() => onChoose(1)}>
        <div>
          {t('btrip.known destination question')}
        </div>
      </div>

      <div className={s.box2} onClick={() => onChoose(2)}>
        <div>
          {t('btrip.unknown destination question')}
        </div>
      </div>
    </div>
  );
};


export default BuildYourTripStep1;
