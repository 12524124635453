import React, {useMemo, useState} from 'react';
import s from './UniqueExperience.module.css';
import {useTranslation} from "react-i18next";
import config from "../../../../config";
import MiddleText from "../../../TextComponents/MiddleText";
import {ReactComponent as TravelSaveSVG} from "../../../../resources/svg/travel_save_check.svg";
import CurrencyNumber from "../../../CurrencyNumber";
import {ReactComponent as LikeCircleSVG} from "../../../../resources/svg/like_circle.svg";
import {ReactComponent as ShareCircleSVG} from "../../../../resources/svg/share_circle.svg";
import {ReactComponent as UELogoSVG} from "../../../../resources/svg/unique_experiences/logo_for_search.svg";

import Button from "../../../Inputs/Button/Button";
import {cutText} from "../../../../helpers/util";
import {buildHighlightData} from "../../../../helpers/uniqueExperiences";
import {langRender} from "../../../../helpers/i18n";
import {useUniqueExperiences} from "../../../../controllers/useUniqueExperiences";

const HightLights = ({highlights}) =>
  <div className="w-full flex-wrap whitespace-nowrap m-auto flex">
    {highlights.map((h, i) => <div key={i} className={s.highlight_item}>
      <div className={s.highlight_icon}>{h.icon}</div>
      <div className="whitespace-nowrap">{h.n} {h.text}</div>
    </div>)}
  </div>


const UniqueExperience = ({experience, onMoreInfo}) => {
  const {t} = useTranslation();

  const [likeLoading, setLikeLoading] = useState(false);
  const [liked, setLiked] = useState(false);

  const {likeExperience, dislikeExperience} = useUniqueExperiences();


  const mainImage = useMemo(() => {
    let image;
    if (Array.isArray(experience.packages)) {
      for (let i = 0; i < experience.packages.length; i++) {
        let p = experience.packages[i];
        if (!Array.isArray(p.daysSummary)) continue;
        for (let j = 0; j < p.daysSummary.length; j++) {
          let d = p.daysSummary[j];
          if (d.images?.image) {
            image = d.images?.image;
            break;
          }
        }
        if (image) break;
      }
    }
    return config.imagesURL + '/' + image;
  }, [experience]);

  const minRate = useMemo(() => {
    let result = Number.MAX_VALUE;
    if (Array.isArray(experience.packages)) {
      experience.packages.forEach(p => {
        if (Array.isArray(p.distributions)) {
          let min = Math.min(...p.distributions.filter(d => !isNaN(d.price_per_person)).map(d => d.price_per_person));
          if (result > min) result = min;
        }
      });
    }
    return result;
  }, [experience]);

  let hightlightData = useMemo(() => buildHighlightData({experience, t}), [experience]);


  const likeTheExperience = async () => {
    if (likeLoading) return;
    setLikeLoading(true);

    let f = liked ? likeExperience : dislikeExperience;

    const {error, success} = await f(experience);
    setLikeLoading(false);

    if (error) return alert(t(`errors.${error}`));

    if (success) setLiked(!liked);
  }


  return (
    <div className={s.experience}>
      {mainImage && <div className={s.imageContainer}>
        <div className={s.logo}><UELogoSVG/></div>

        <div className={s.image} style={{backgroundImage: `url(${mainImage})`}}/>
      </div>}

      <div className="w-full xl:w-9/12 flex flex-wrap xl:flex-nowrap">
        <div className="w-full relative">

          <div className="m-5">
            <div className="flex w-full justify-between">
              <div className="flex items-center">
                <MiddleText>{langRender(experience.title)}</MiddleText>
                <font className={s.creator}>{t('experience_details.by')}: {experience.creator}</font>
              </div>
              <div className="top-0 right-0 flex justify-end pt-5 pr-5">
                <LikeCircleSVG className={liked ? s.circleLiked : s.circle}
                               style={{marginRight: "20px", cursor: 'pointer'}}
                               onClick={() => likeTheExperience()}
                />
                <ShareCircleSVG/>
              </div>
            </div>
            <div>
              {Array.isArray(experience.hashtags) &&
                <div className={s.hashtags}>{experience.hashtags.map(hashtag => <div>{hashtag}</div>)}</div>}

              <div className="xl:hidden flex items-center">
                <div className={s.travel_save}>
                  <div>
                    <TravelSaveSVG/>
                  </div>
                </div>
                <div className={s.price_title}>
                  {t('experience_details.price per person')}:
                  <CurrencyNumber className={s.price}>{minRate}</CurrencyNumber>
                </div>
              </div>

              <div className={s.description}>{cutText(langRender(experience.external_description), 200)}</div>
              <HightLights highlights={hightlightData}/>
            </div>


          </div>
        </div>

        <div className={s.experience_right}>
          <div className={s.price_title_right}>
            {t('experience_details.price per person')}:
            <CurrencyNumber className={s.price}>{minRate}</CurrencyNumber>
          </div>
          <div className={s.select_button_container}>
            <div className="xl:m-10 ml-5 mb-5">
              <Button className={s.moreInfoButton}
                      onClick={() => onMoreInfo && onMoreInfo(experience)}>{t("more info")}</Button>
            </div>
          </div>

          <div className={s.travel_saveRight}>
            <div>
              <TravelSaveSVG/>
            </div>
            <div>
              {t("hotel.travel_save")}
            </div>
          </div>
        </div>

      </div>


    </div>
  );
};


export default UniqueExperience;