import React, {useEffect, useState} from 'react';
import {useInsurance} from "../../../controllers/useInsurance";
import Loader from "../../Loader";
import PurchasedInsurance from "./PurchasedInsurance";
import sc from '../MyProfileCommon.module.css';
import {useTranslation} from "react-i18next";
import NothingFoundContent from "../NothingFoundContent";
import {ReactComponent as TravelSaveSVG} from "../../../resources/svg/travel_save_check.svg";


const MyProfileInsurances = props => {
  const {t} = useTranslation();

  const [inactiveInsurances, setInactiveInsurances] = useState([]);
  const [insurances, setInsurances] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const {getContractedInsurances} = useInsurance();


  const loadInsurances = async () => {
    setLoading(true);

    const {error, insurances} = await getContractedInsurances(true);
    if (!error) {
      setInsurances(insurances);
    }


    const {error: error2, insurances: inactiveInsurances} = await getContractedInsurances(false);
    if (!error2) {
      setInactiveInsurances(inactiveInsurances);
    }

    if (error || error2) {
      alert(t('errors.unknown error'))
    }


    setLoading(false);
  }

  useEffect(() => {
    loadInsurances();
  }, []);

  if (loading) return <Loader/>

  return (
    <div className="w-full">
      <div className={sc.headerContainer}>
        <h3 className={sc.headerTitle}>{t('my profile insurances.active insurances')}</h3>
      </div>

      {Array.isArray(insurances) && insurances.length > 0 ? insurances.map(insurance => <PurchasedInsurance
        containerClassName="my-5"
        insurance={insurance}/>) : <NothingFoundContent
        icon={<TravelSaveSVG width={50} height={50} style={{fill: '#757575'}}/>}
        text={t('my profile insurances.no active insurances')}
        containerClassName="my-10"
      />}

      {Array.isArray(inactiveInsurances) && inactiveInsurances.length > 0 && <>
        <h3 className={sc.headerTitle}>{t('my profile insurances.inactive insurances')}</h3>
        {inactiveInsurances.map(insurance =>
          <PurchasedInsurance
            containerClassName="my-5"
            insurance={insurance}/>)}
      </>
      }

    </div>
  );
};


export default MyProfileInsurances;