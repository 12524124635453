import React from 'react';
import {useTranslation} from "react-i18next";
import SelectWithLabel from "../SelectWithLabel";
import {ReactComponent as SexSVG} from "../../../resources/my_profile/sex.svg";

const SelectGender = ({id, Input, name = 'gender', ...props}) => {
  const {t} = useTranslation();

  // don't use react memo | label can't update if the language is changed
  const options = [
    {label: t('gender.man'), value: 'man'},
    {label: t('gender.woman'), value: 'woman'},
    {label: t('gender.other'), value: 'other'}
  ];

  Input = Input ?? SelectWithLabel;


  return <Input
    icon={<SexSVG style={{fill: '#757575'}}/>}
    name={name}
    label={t('input.gender')}
    id={id}
    options={options}
    {...props}
  />
};


export default SelectGender;
