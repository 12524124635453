import s from './SearchHotelsLocation.module.css';
import {useTranslation} from "react-i18next";
import React, {useEffect, useRef, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import LocationInput from "../../../Inputs/LocationInput";
import DatePickerWithOutsideLabel from "../../../Inputs/DatePickerWithOutsideLabel";
import InputWithOutsideLabel from "../../../Inputs/InputWithOutsideLabel";
import {AiOutlineSearch} from 'react-icons/ai';
import {SearchHotelsFormValues} from "../../../Main/Hotels/models";
import {SearchHotelURLParams} from "../../../../types/hotels/hotels";
import {NumberOfNights} from "./NumberOfNights/NumberOfNights";
import {loadDefaultData, useUtilFunctions} from "./util";
import moment from "moment/moment";
import HotelPassengerNumberInput from "../../../Inputs/HotelPassengerNumber/HotelPassengerNumberV2";

export enum SearchHotelsLocationRenderStyle {
  horizontal, vertical
}

interface SearchHotelsLocationProps {
  searchParams: SearchHotelURLParams;
  onSearch: (params: SearchHotelURLParams, rawData: SearchHotelsFormValues) => Promise<void>;
  renderStyle?: SearchHotelsLocationRenderStyle;
  hotelPassengerNumberContainerClassName?: string;
  withBorder?: boolean;
  smallComponents?: boolean;
  disableLocation?: boolean;
  showNumberOfNights?: boolean;
}

const SearchHotelsLocation = ({
                                searchParams,
                                showNumberOfNights,
                                onSearch,
                                withBorder = true,
                                disableLocation,
                                smallComponents = false,
                                renderStyle = SearchHotelsLocationRenderStyle.vertical,
                                hotelPassengerNumberContainerClassName
                              }: SearchHotelsLocationProps) => {
  const {t} = useTranslation();
  const [focusSecondDate, setFocusSecondDate] = useState(false);

  const form = useForm();
  const parentRef = useRef<HTMLDivElement>(null);

  const {isOutsideRange, onSubmit, onArriveDateChange, isDayHighlighted, isOutsideRangeStart} = useUtilFunctions({
    form,
    setFocusSecondDate,
    onSearch
  });


  useEffect(() => {
    loadDefaultData({form, searchParams});
  }, []);


  const isVertical = renderStyle === SearchHotelsLocationRenderStyle.vertical;

  // @ts-ignore
  return <FormProvider {...form}>
    <div className={`${isVertical ? s.containerVertical : s.containerHorizontal}`} ref={parentRef}>
      <LocationInput
        // @ts-ignore
        Input={InputWithOutsideLabel}
        withBorder={withBorder}
        label={null}
        id="hotel_search_page_destination"
        name="hotel_destination"
        small={smallComponents}
        disabled={disableLocation}
      />
      <DatePickerWithOutsideLabel
        label={undefined}
        withBorder={withBorder}
        placeholder={t('date')}
        name="arrive_date"
        calendarInfo={t("input.arrive_date")}
        id="hotel_page_arrive_date"
        onChange={onArriveDateChange}
        isOutsideRange={isOutsideRangeStart}
        small={smallComponents}
        isDayHighlighted={isDayHighlighted}
      />
      <DatePickerWithOutsideLabel
        label={undefined}
        withBorder={withBorder}
        placeholder={t('date')}
        name="departure_date"
        id="hotel_page_departure_date"
        onChange={() => setFocusSecondDate(false)}
        isOutsideRange={isOutsideRange}
        externalFocused={focusSecondDate}
        small={smallComponents}
        calendarInfo={t("input.departure_date")}
        isDayHighlighted={isDayHighlighted}
        initialVisibleMonth={() => {
          return moment(form.getValues('arrive_date')).isValid() ? moment(form.getValues('arrive_date')) : undefined
        }}
      />
      {showNumberOfNights && <NumberOfNights/>}
      <div className={hotelPassengerNumberContainerClassName}>
        <HotelPassengerNumberInput
          name="rooms"
          className="border-2 border-black"
          withBorder={withBorder}
          // label={undefined}
          /*@ts-ignore*/
          // small={smallComponents}
        />
      </div>
      <div className={s.searchButton} onClick={() => onSubmit(form.getValues() as SearchHotelsFormValues)}>
        <AiOutlineSearch size={19} fill={'#1C7263'}/>
        <div className="ml-5">Buscar</div>
      </div>
    </div>
  </FormProvider>

}

export default SearchHotelsLocation;