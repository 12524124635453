import {persons_keys} from "./util";
import {t} from "i18next";
import s from "../PurchasedInsurance.module.css";
import React from "react";
import {FormattedInsuredPerson} from "./insuredPersons";

interface Props {
  persons: FormattedInsuredPerson[]
}

export function InsuredPersonsMobile({persons}: Props) {
  return <>
    <div className="font-bold text-2xl my-5">{t('insurance page.persons')}</div>
    <hr className="mb-5"/>
    {persons.map((p, i: number) => {
      return <table key={i} className={s.p_container_mobile}>
        {persons_keys.map((key, i) => <tr>
          <th>{t(`insurance table keys.${key}`)}</th>
          <td>{p[key]}</td>
        </tr>)}
      </table>
    })}
  </>
}