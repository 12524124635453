import React from 'react';
import s from './UniqueExperienceFeatures.module.css';
import Slider from "../../Slider";
import SmallActivity from "../../SmallActivity";
import SmallFeature from "../../SmallFeature";

const UniqueExperienceFeatures = ({features}) => {
	return (
		<div>
			<Slider elements={features.map((feature, i) => <SmallFeature key={i} feature={feature}/>)}/>
		</div>
	);
};


export default UniqueExperienceFeatures;