import React, {useRef, useEffect, useState} from 'react';
import s from './BubbleTriangle.module.css';

const BubbleTriangle =({children, containerClass, triggerRef}) => {
  const className = `${s.bubble} ` + containerClass;

  const [state, setState]  = useState({
    visible: false
  });
  const setVisible = visible => setState(prev => ({...prev, visible}));

  const node = useRef(null);

  const listenerHide = e => {
    if(node.current && (!node.current?.contains(e.target) && !triggerRef?.current.contains(e.target))){
      setVisible(false);
    }
  }
  const listenerToggle = e => {
    if(node.current && node.current?.contains(e.target))return;
    setState(prev => ({...prev, visible: !prev.visible}));
  }


  useEffect(() => {
    const current = triggerRef.current;
    document.body.addEventListener('click', listenerHide);
    if(current)current.addEventListener('click', listenerToggle);
    return () => {
      document.body.removeEventListener('click', listenerHide);
      if(current)current.removeEventListener('click', listenerToggle);
    };
  }, []);


  return state.visible ? (
    <div ref={node} className={className}>
      {children}
    </div>
  ) : <></>;
};


export default BubbleTriangle;
