import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";

import {ReactComponent as EmailSVG} from "../../../resources/my_profile/email.svg";
import {ReactComponent as PhoneSVG} from "../../../resources/my_profile/phone.svg";
import {ReactComponent as CakeSVG} from "../../../resources/my_profile/cake.svg";
import IdentificationDocumentInput from "../IdentificationDocumentInput";
import SelectNationality from "../../Inputs/SelectNationality";
import useCheckoutProvider from "../../../providers/CheckoutProvider/useCheckoutProvider";
import {TextInput} from "../Inputs/TextInput/TextInput";
import {SelectGender} from "../Inputs/SelectGender";
import dayjs from "dayjs";

const PersonData = ({type, holder = false, children = false, id, user, n}) => {
  const {t} = useTranslation();

  const {form} = useCheckoutProvider();

  useEffect(() => {
    console.log(user)
    form.setValue(`persons.${id}.gender`, holder ? user?.gender ?? 'man' : 'man');
    form.setValue(`persons.${id}.nationality`, holder ? user?.nationality ?? 'ES' : 'ES');


    form.setValue(`persons.${id}.document.type`, 'nif');
    form.setValue(`persons.${id}.holder`, holder);
    form.setValue(`persons.${id}.children`, children);
  }, [user]);

  return (
    <div>
      {type === 'adult' && <h3 className="my-5">{t('adult')} {n}</h3>}
      {type === 'child' && <h3 className="my-5">{t('children')} {n}</h3>}
      <div className="flex items-center flex-wrap md:flex-nowrap">
        <TextInput
          withAsterisk={true}
          minLength={2}
          required
          label={t('name')}
          name={`persons.${id}.name`}
          id={`person_${id}_name`}
          containerClassName="md:mr-5 mb-5 md:mb-0"
        />
        <TextInput
          withAsterisk={true}
          minLength={2}
          required
          label={t('surname')}
          name={`persons.${id}.surname`}
          id={`person_${id}_surname`}
          containerClassName="md:mr-5 mb-5 md:mb-0"
        />
        <SelectGender
          name={`persons.${id}.gender`}
          id={`person_${id}_gender`}
          second_style={true}
        />
      </div>
      <IdentificationDocumentInput
        name={`persons.${id}.document`}
        id={`person_${id}_document`}
        /*withAsterisk={holder}*/
        withAsterisk={true}
        containerClassName="my-5"
        second_style={true}
      />

      {type === 'adult' &&
        <div className="flex items-center flex-wrap md:flex-nowrap">
          <TextInput
            type="email"
            icon={<EmailSVG style={{fill: '#757575'}}/>}
            withAsterisk={holder}
            required={holder}
            label={t('email')}
            name={`persons.${id}.email`}
            id={`person_${id}_email`}
            second_style={true}
            containerClassName="md:mr-5"
          />
          <TextInput
            icon={<PhoneSVG style={{fill: '#757575'}}/>}
            withAsterisk={holder}
            required={holder}
            label={t('phone')}
            name={`persons.${id}.phone`}
            id={`person_${id}_phone`}
            second_style={true}
          />
        </div>
      }
      <div className="flex items-center mt-10 flex-wrap md:flex-nowrap">
        <SelectNationality
          required
          second_style={true}
          name={`persons.${id}.nationality`}
          id={`person_${id}_nationality`}
          containerClassName="md:mr-5 mb-5 md:mb-0"
        />
        <TextInput
          icon={<CakeSVG style={{fill: '#757575'}}/>}
          label={t("edit profile.birthday")}
          name={`persons.${id}.birthday`}
          id={`person_${id}_birthday`}
          type="date"
          withAsterisk={true}
          required
          second_style={true}
          min={children ? dayjs().subtract(18, 'year').add(1, 'day').format('YYYY-MM-DD') : dayjs().subtract(100, 'year').format('YYYY-MM-DD')}
          max={children ? dayjs().format('YYYY-MM-DD') : dayjs().subtract(18, 'year').format('YYYY-MM-DD')}
        />

      </div>
    </div>
  );
};


export default PersonData;