import React, {useRef, useState} from 'react';
import s from './SelectCurrency.module.css';
import {useTranslation} from "react-i18next";
import BubbleTriangle from "../BubbleTriangle";
import useCurrencyProvider from "../../providers/CurrencyProvider/useCurrencyProvider";
import Flag from 'react-flagpack';

const SelectCurrency = props => {
	const {t} = useTranslation();
	const coinRef = useRef();
	const {exchanges, selectedCurrency, setSelectedCurrency} = useCurrencyProvider();

	const [search, setSearch] = useState('');

	const excluded = ['GB'];


	const onCurrencyClick = (e, code) => {
		e.preventDefault();
		setSelectedCurrency(code);
	}

	if (!exchanges.exchanges) return <div/>
	return (
		<div ref={coinRef} className={s.selectCurrency}>
			<div className={s.currency}>{selectedCurrency}</div>
			<BubbleTriangle triggerRef={coinRef} containerClass={s.bubble}>
				<div className={s.container}>
					<div className={s.title}>{t('choose your currency')}</div>
					<input className={s.searchInput} placeholder={t('search')} value={search}
								 onChange={e => setSearch(e.target.value)}/>
					{exchanges.exchanges.filter(currency => {
						if (search.length === 0) return true;
						return currency.code.toLowerCase().search(search.toLowerCase()) !== -1;
					}).map((currency, i) => <div key={i} className={s.option}
																			 onClick={e => onCurrencyClick(e, currency.code)}>
{/*						{!excluded.includes(currency.code_2) && <div className={s.flag}>
							<Flag size="m" code={currency.code_2}/>
						</div>}*/}
						<div className={s.text}>{currency.code}</div>
					</div>)}
				</div>
			</BubbleTriangle>
		</div>
	);
};


export default SelectCurrency;
