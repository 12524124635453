import React, {useCallback, useEffect, useState} from 'react';
import sc from '../MyProfileCommon.module.css';
import {useTranslation} from "react-i18next";
import {ReactComponent as SearchSVG} from "../../../resources/svg/serach.svg";
import Loader from "../../Loader";
import {useHotel} from "../../../controllers/useHotel";
import PurchasedHotel from "./PurchasedHotel";
import NothingFoundContent from "../NothingFoundContent";

import {ReactComponent as BedSVG} from '../../../resources/svg/bed.svg';

const MyProfileHotels = () => {
  const {t} = useTranslation();

  const [futureHotels, setFutureHotels] = useState();
  const [pastHotels, setPastHotels] = useState();
  const [refundedHotels, setRefundedHotels] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [hotelName, setHotelName] = useState()


  const {myProfilePastHotels, myProfileFutureHotels, myProfileRefundedHotels} = useHotel();

  const stopWithError = error => {
    setLoading(false);
    setError(error);
  }

  const loadHotels = async () => {
    setLoading(true);
    const {error, hotels: pastHotels} = await myProfilePastHotels();
    if (error) return stopWithError(error);
    if (Array.isArray(pastHotels)) setPastHotels(pastHotels);

    const {error: error2, hotels: futureHotels} = await myProfileFutureHotels();
    if (error2) return stopWithError(error2);
    if (Array.isArray(futureHotels)) setFutureHotels(futureHotels);

    const {error: error3, hotels: refundedHotels} = await myProfileRefundedHotels();
    if (error3) return stopWithError(error3);
    if (Array.isArray(refundedHotels)) setRefundedHotels(refundedHotels);

    setLoading(false);
  }

  useEffect(() => {
    loadHotels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const filterHotels = useCallback((hotels) => {
    if (!Array.isArray(hotels)) return [];
    if (!hotelName) return hotels;
    return hotels.filter(hotel => hotel.name.toLowerCase().includes(hotelName.toLowerCase()));
  }, [hotelName]);

  const filteredFutureHotels = filterHotels(futureHotels);
  const filteredPastHotels = filterHotels(pastHotels);
  const filteredRefundedHotels = filterHotels(refundedHotels);

  return (
    <div className="w-full">
      <div className={sc.headerContainer}>
        <h3 className={sc.headerTitle}>{t('my profile hotels.future hotels')}</h3>
        <div className={sc.searchBarContainer}>
          <input
            placeholder={t('my profile hotels.search bar text') + '...'}
            spellCheck="false"
            onChange={e => setHotelName(e.target.value)}
          />
          <button>
            <SearchSVG/>
          </button>
        </div>
      </div>

      {loading ? <Loader/> : <div>
        {Array.isArray(filteredFutureHotels) && filteredFutureHotels.length > 0 ? filterHotels(filteredFutureHotels).map((hotel, i) =>
            <div
              key={i}
              className="my-5">
              <PurchasedHotel hotel={hotel} key={hotel.code}/>
            </div>)
          : <NothingFoundContent
            icon={<BedSVG width={50} height={50} style={{fill: '#757575'}}/>}
            text={t('my profile hotels.no future hotels')}
            containerClassName="my-10"
          />
        }

        <h3 className={sc.headerTitle}>{t('my profile hotels.past hotels')}</h3>
        {Array.isArray(filteredPastHotels) && filteredPastHotels.length > 0 ? filterHotels(filteredPastHotels).map((hotel, i) =>
            <div key={i}
                 className="my-5">
              <PurchasedHotel hotel={hotel} key={hotel.code}/>
            </div>)
          : <NothingFoundContent
            icon={<BedSVG width={50} height={50} style={{fill: '#757575'}}/>}
            text={t('my profile hotels.no past hotels')}
            containerClassName="my-10"
          />
        }

        {Array.isArray(filteredRefundedHotels) && filteredRefundedHotels.length > 0 &&
          <h3 className={sc.headerTitle}>{t('my profile hotels.refunded hotels')}</h3>}
        {Array.isArray(filteredRefundedHotels) && filteredRefundedHotels.length > 0 && filterHotels(filteredRefundedHotels).map((hotel, i) => <>
            <div key={i}
                 className="my-5">
              <PurchasedHotel hotel={hotel} key={hotel.code}/>
            </div>
          </>
        )}
      </div>}
    </div>
  );
}


export default MyProfileHotels;
