import React, {useEffect, useState} from 'react';
import s from './SearchActivities.module.css';
import Skeleton from "react-loading-skeleton";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import SearchActivitiesLocation from "../../SearchActivitiesLocation";
import {useUtil} from "../../../hooks/useUtil";
import useActivitiesProvider from "../../../providers/ActivitiesProvider/useActivitiesProvider";
import {FormProvider, useForm} from 'react-hook-form'
import Title from "../../TextComponents/Title";
import Button from "../../Inputs/Button/Button";
import bases from "../../../routes/bases";
import Activity from "./Activity";
import FilterActivities from "./FilterActivities";
import {useViewportSize} from "@mantine/hooks";
import {paramsToObj} from "../../../helpers/util";

const Loading = () => (
  <div className="ml-10">
    <Skeleton count={1} height={50}/>
    <br/>
    <Skeleton count={4} height={200}/>
  </div>
)

const SearchActivities = props => {
  const {t} = useTranslation();

  const navigate = useNavigate();
  const {activities_paramsToObj, setParams, activities_objToParams} = useUtil();


  const [fVisible, setFVisible] = useState(false);
  const {width} = useViewportSize();
  const desktop = width >= 1280;


  const {
    loading,
    activities,
    updateActivities,
    canLoadMore
  } = useActivitiesProvider();

  const downloadData = async data => {
    return await updateActivities();
  }

  const onSubmit = async data => {
    const small = data.activity_destination.smallText;
    let obj = {
      from: data.arrive_date,
      to: data.departure_date,
      destination: {
        lat: data.activity_destination?.lat || data.activity_destination?.raw?.lat,
        lng: data.activity_destination?.lng || data.activity_destination?.raw?.lng,
      },
      persons: [],
      text: `${data.activity_destination.name} ${small ? ', ' + small : ''}`
    };

    if (!Array.isArray(data.activity_passenger_number)) return;
    for (let i = 1; i <= data.activity_passenger_number.find(a => a.name === 'persons').value; i++) {
      let age = data[`person_age_${i}`] * 1;
      if (!Number.isNaN(age)) obj.persons.push(age);
    }

    setParams(activities_objToParams(obj));
    await downloadData(obj);
  }

  useEffect(() => {
    let data = activities_paramsToObj();
    downloadData(data);
  }, []);


  const form = useForm();
  const {handleSubmit, isSubmitting} = form;

  const onMoreInfo = activity => {
    const params = paramsToObj();
    const search = new URLSearchParams({
      ...params,
      code: activity.code,
      api: activity.api
    });


    navigate(bases.activityDetails + '?' + search.toString());
  }


  return (
    <div className={s.container}>
      <div className={`${s.leftBar} ${(desktop || fVisible) ? s.leftBarVisible : ''}`}>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={s.searchContainer}>
              <Title className={s.searchTitle}>{t('activities')}</Title>
              <SearchActivitiesLocation outsideLabel={true} defaultValue={activities_paramsToObj()}/>
              <div className="mt-10">
                <Button loading={isSubmitting} className={s.button}>
                  {t('search')}
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
        <FilterActivities desktop={desktop} fVisible={fVisible} setFVisible={setFVisible}/>
      </div>
      <div className={s.rightBar}>
        {loading >= 2 || !Array.isArray(activities) ? <Loading/> : <>
          <div className={s.activities}>
            {(activities?.length === 0) && <div className={s.nothingFound}>{t('activity_page.nothing found')}</div>}
            {activities.map(activity => <Activity onMoreInfo={onMoreInfo} key={activity.code}
                                                  activity={activity}/>)}
          </div>
          {canLoadMore && <div className="m-auto mb-20" style={{width: '250px'}}>
            <Button onClick={() => updateActivities({loadMore: true})} type="button">{t('load more')}</Button>
          </div>}
        </>}
      </div>
    </div>

  );
};


export default SearchActivities;
