import React, {useRef} from 'react';
import s from './RangeSlider.module.css';
import {Controller} from "react-hook-form";
import CurrencyNumber from "../../CurrencyNumber";

const RangeSlider = ({
                       name,
                       label,
                       min,
                       onlyMax = false,
                       max,
                       containerClassName = '',
                       defaultValue,
                       labelRenderer,
                       onMouseUp: _onMouseUp,
                       onChange: _onChange
                     }) => {
  const input1 = useRef();
  const input2 = useRef();

  const createLabel = () => {
    if (!input1.current || !input2.current) return '';
    if (labelRenderer) return labelRenderer(input2.current.value, input2.current.value);
    let values = [input1.current.value, input2.current.value];
    if (typeof label === 'function') return label(values);
    return <>
      <CurrencyNumber options={{minimumFractionDigits: 0, maximumFractionDigits: 0}}>{values[0] ?? min}</CurrencyNumber>
      <font className="mx-5">-</font>
      <CurrencyNumber options={{minimumFractionDigits: 0, maximumFractionDigits: 0}}>{values[1] ?? max}</CurrencyNumber>
    </>
  }

  const onMouseUp = e => {
    _onMouseUp && _onMouseUp(e);
  }


  return (
    <Controller
      name={name}
      defaultValue={defaultValue || [min, max]}
      render={({field: {onChange, value}}) => (
        <div className={containerClassName}>
          <span className="w-full text-center block text-2xl mb-5">{createLabel()}</span>
          <div className={s.container}>
            <div className={s.runnableTrack}/>
            <input
              ref={input1}
              type="range"
              defaultValue={min}
              min={min}
              style={{display: onlyMax ? 'none' : 'inline-block'}}
              max={max}
              onMouseUp={onMouseUp}
              onInput={e => {
                if (e.target.value * 1 >= value[1] * 1) e.target.value = value[1] * 1 - 1;
                onChange([e.target.value * 1, value[1] * 1])
              }}
            />
            <input
              ref={input2}
              type="range"
              min={min}
              onMouseUp={onMouseUp}
              defaultValue={max}
              max={max}
              onInput={e => {
                if (e.target.value * 1 < value[0] * 1) e.target.value = value[0] * 1 + 1;
                onChange([value[0] * 1, e.target.value * 1])
              }}
            />
          </div>
        </div>
      )}/>
  );
};


export default RangeSlider;
