import React, {useEffect, useState} from 'react';
import s from './HotelDetailsRooms.module.css';
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import {useViewportSize} from "@mantine/hooks";
import HotelDetailsRoomsTable from "../HotelDetailsRoomsTable";
import HotelDetailsRoomsMobile from "../HotelDetailsRoomsMobile";
import {
  Hotel,
  HotelForPurchaseRoom,
  HotelRoomSelectionResume,
  HotelRoomWithAllServices,
  SearchHotelURLParams
} from "../../../../../types/hotels/hotels";
import {getDefaultSelectedRates, getRateAndRoomFromRateKey, getTotalClientTaxesFromRate} from "../../util";
import useHotelsProvider from "../../../../../providers/HotelsProvider/useHotelsProvider";
import {hashCode, paramsToObj} from "../../../../../helpers/util";
import {Modal} from "@mantine/core";
import HotelRoomDetails from "../../../HotelRoomDetails/HotelRoomDetails";


interface HotelDetailsRoomsProps {
  hotel: Hotel,
  onReserve?: (data: { rooms: HotelForPurchaseRoom[] }) => void
  searchParams: SearchHotelURLParams;
}

const HotelDetailsRooms = ({hotel, onReserve, searchParams}: HotelDetailsRoomsProps) => {
  const {t} = useTranslation();
  const [selectedRoom, setSelectedRoom] = useState<HotelRoomWithAllServices | undefined>();


  const {selectedRateKeys, setSelectedRateKeys} = useHotelsProvider();

  const {width} = useViewportSize();
  const desktop = width >= 1280;


  const form = useForm();
  const {setValue, handleSubmit} = form;


  useEffect(() => {
    const {rooms} = hotel;
    if (Array.isArray(rooms) && rooms.length > 0 && rooms[0]?.rates?.length > 0) {
      const defaultSelectedRates = getDefaultSelectedRates({searchParams, hotel})
      const keys = Object.keys(defaultSelectedRates)
      keys.forEach(key => {
        setValue(hashCode(key) + '', defaultSelectedRates[key]);
      })
      setSelectedRateKeys(keys);
    }
  }, [hotel]);


  const onSubmit = (rates: { [p: string]: number }) => {
    let params = paramsToObj() as any;

    let finalRooms: HotelForPurchaseRoom[] = [];
    if (rates) {
      hotel.rooms.forEach(room => {
        room.rates.forEach(rate => {
          let quantity = rates[hashCode(rate.rateKey)];
          if (!quantity) return;
          if (quantity) {
            for (let i = 0; i < quantity; i++) {
              finalRooms.push({
                code: room.code,
                name: room.name,
                roomCode: room.roomCode,
                rate: {
                  finalPrice: rate.finalPrice,
                  rateKey: rate.rateKey,
                  rateType: rate.rateType,
                  net: rate.net,
                  allotment: rate.allotment,
                  adults: rate.adults,
                  children: rate.children,
                  rooms: rate.rooms,
                  taxes: getTotalClientTaxesFromRate(rate),
                  from: params.from,//moment(params.from),
                  to: params.to//moment(params.to),
                }
              });
            }
          }
        });
      });
    }

    onReserve && onReserve({rooms: finalRooms});
  }


  const roomSelectionResume: HotelRoomSelectionResume = React.useMemo(() => {
    let resume: HotelRoomSelectionResume = {
      totalRooms: 0,
      totalPrice: 0,
      totalTaxes: 0,
      totalRatesPerRooms: {},
      selectedRateKeys,
      rooms: []
    };

    if (selectedRateKeys) {
      hotel.rooms.forEach(room => {
        resume.totalRatesPerRooms[room.code] = 0;
        room.rates.forEach(rate => {
          let quantity = selectedRateKeys.filter((a: string) => a === rate.rateKey).length;
          if (!quantity) return;

          const roomAndRate = getRateAndRoomFromRateKey(hotel, rate.rateKey);
          if (roomAndRate) resume.rooms.push({...roomAndRate, quantity});
          if (quantity) {
            resume.totalRooms += quantity * rate.rooms;
            resume.totalPrice += quantity * rate.finalPrice;
            resume.totalTaxes += quantity * getTotalClientTaxesFromRate(rate);
            resume.totalRatesPerRooms[room.code] += quantity;
          }
        });
      });
    }
    return resume;
  }, [hotel, selectedRateKeys]);


  if (!Array.isArray(hotel?.rooms) || hotel?.rooms.length === 0) return <></>;


  return (
    <div>
      <Modal size={width > 1000 ? "80%" : '98%'} opened={!!selectedRoom} onClose={() => setSelectedRoom(undefined)}>
        {/*<HotelDetailsShowRoomFacilities room={room} hotel={hotel}/>*/}
        {selectedRoom && <HotelRoomDetails hotel={hotel} room={selectedRoom!}/>}
      </Modal>

      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <p className={s.title} id="hotel_my_rooms">{t('hotel_rooms.rooms title')}</p>
          {desktop ?
            <HotelDetailsRoomsTable setSelectedRoom={setSelectedRoom} hotel={hotel}
                                    roomSelectionResume={roomSelectionResume}/> :
            <HotelDetailsRoomsMobile setSelectedRoom={setSelectedRoom} hotel={hotel}
                                     roomSelectionResume={roomSelectionResume}/>}
        </form>
      </FormProvider>
    </div>
  );
};


export default HotelDetailsRooms;
