import React from 'react';
import s from './SmallFeature.module.css';
import {useTranslation} from "react-i18next";
import Image from "../Image/Image";
import CurrencyNumber from "../CurrencyNumber";
import config from "../../config";
import {Spoiler} from "@mantine/core";
import {langRender} from "../../helpers/i18n";

const SmallFeature = ({feature}) => {
	const {t} = useTranslation();

	return (
		<div className={s.container}>
			<div className={s.image}>
				<Image src={config.imagesURL + '/' + feature.image} alt={`${langRender(feature.name)} main`}/>
			</div>
			<div className="w-full pl-5">
				<div className={s.title}>{langRender(feature.name)}</div>
				<Spoiler
					styles={{control: {color: '#757575'}}}
					showLabel={t('show more')}
					hideLabel={t('hide')}
				>{langRender(feature.description)})</Spoiler>
				{feature.price && <CurrencyNumber className={s.price}>{feature.price}</CurrencyNumber>}
			</div>
		</div>
	);
};


export default SmallFeature;