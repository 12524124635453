import React from 'react';

import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Scrollbar} from 'swiper/modules';

// import 'swiper/swiper.min.css';
// import 'swiper/modules/pagination/pagination.min.css';
// import "swiper/modules/scrollbar/scrollbar.min.css";
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import './swiper_override.css';


const Slider = ({elements, small = false, ...props}) => {
  return (
    <Swiper
      modules={[Scrollbar, Navigation]}
      scrollbar={{
        hide: false
      }}
      spaceBetween={0}
      slidesPerView={1}
      breakpoints={{
        640: {
          centeredSlides: true,
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 2
        },
        1025: {
          slidesPerView: small ? 2 : 3,
        },
        1280: {
          slidesPerView: 3
        }
      }}
      {...props}
    >
      {elements.map((el, i) => <SwiperSlide key={i}>{el}</SwiperSlide>)}
    </Swiper>
  );
};


export default Slider;