import React from 'react';
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";
import InputWithNumericOptions from "../../Inputs/InputWithNumericOptions/InputWithNumericOptions";
import {ReactComponent as PersonSVG} from "../../../resources/my_profile/person.svg";
import ChildrenAgeSelectPassengerNumber from "../../ChildrenAgeSelectPassengerNumber";

const HotelPassengerNumberStartYourTrip = ({
                                             id = "hotel_passenger_number",
                                             name = "hotel_passenger_number",
                                             defaultValue,
                                             containerClassName,
                                             label,
                                             index,
                                             style,
                                             ...props
                                           }) => {
  const {t} = useTranslation();
  const {setValue} = useFormContext();


  const onChange = (data, newData, node) => {
    const {element, add} = data;


    if (element.name !== 'children') {

      return
    }


    if (add) {
      let children_id = element.value + 1;
      let name = `child_age_${index}_${children_id}`;
      if (element.value >= 0 && !newData.find(d => d.separator)) newData.push({separator: true});

      newData.push({
        children_id,
        element: props => <ChildrenAgeSelectPassengerNumber {...props} name={name} i={element.value + 1}/>
      });
    } else {
      newData = newData.filter(d => (d.children_id && d.children_id < element.value) || !d.children_id);
      if (element.value <= 1) newData = newData.filter(d => !d.separator);
    }


    setValue(id, newData);

  }

  let defaultOptions = [
    {name: 'adults', text: t('adults'), value: 1, min: 1},
    {name: 'children', text: t('children', {count: 1}), value: 0, min: 0},
  ]


  return (
    <div className={containerClassName} style={style}>
      <InputWithNumericOptions
        label={label || t('input.passenger_number')}
        id={id}
        name={name}
        icon={<PersonSVG style={{fill: '#979797'}}/>}
        defaultOptions={defaultOptions}
        onChange={onChange}
        {...props}
      />
    </div>
  );
};


export default HotelPassengerNumberStartYourTrip;