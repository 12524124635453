import React, {useMemo} from 'react';
import {useTranslation} from "react-i18next";
import Select from "../Inputs/Select/Select";
import {TextInput} from "../Inputs/TextInput/TextInput";

const IdentificationDocumentInput = ({
                                       name,
                                       id,
                                       containerClassName = '',
                                       second_style = false,
                                       withAsterisk = true,
                                       ...props
                                     }) => {
  const {t} = useTranslation();

  const options = useMemo(() => [
    {value: 'nif', label: t('document_id_types.nif')},
    {value: 'nie', label: t('document_id_types.nie')},
    {value: 'passport', label: t('document_id_types.passport')},
    {value: 'foreign_id', label: t('document_id_types.foreign_id')},
  ], []);

  return (
    <div className={`flex items-center ${containerClassName}`}>
      <Select
        withAsterisk={withAsterisk}
        required={withAsterisk}
        name={`${name}.type`}
        id={`${id}_type`}
        label={t('document_id_type')}
        options={options}
        containerClassName="mr-5"
        second_style={second_style}
      />
      <TextInput
        withAsterisk={withAsterisk}
        required={withAsterisk}
        label={t('document_number')}
        name={`${name}.number`}
        id={`${id}_number`}
        second_style={second_style}
      />
    </div>
  );
};


export default IdentificationDocumentInput;