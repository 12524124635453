import React from 'react';
import s from './CriticalError.module.css';

// NO TERMINADO

const CriticalError = ({error, refresh}) => {
  return (
    <div>
      <p>{error}</p>
    </div>
  );
};


export default CriticalError;
