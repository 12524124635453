import React, {useMemo} from 'react';
import s from './ReviewsList.module.css';
import Slider from "../Slider";
import {ReactComponent as StarSVG} from "../../resources/svg/star.svg";
import SpanishFlag from "../SelectLanguage/SpanishFlag";

const ReviewsList = ({sliderOptions = {}}) => {
	const reviews = useMemo(() => {
		let reviews = [];
		for (let i = 0; i < 7; i++) {
			reviews.push({
				name: 'Andrey',
				country: "España",
				stars: 5,
				text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris"
			});
		}
		return reviews;
	}, []);

	let stars = [];
	for (let i = 0; i < 5; i++) {
		stars.push(<StarSVG key={i} width={15} height={15} style={{fill: '#FBBC04'}}/>);
	}


	return (
		<div style={{maxWidth: '1200px'}}>
			<Slider
				breakpoints={{
					640: {
						centeredSlides: true,
						slidesPerView: 2,
					},
					768: {
						slidesPerView: 2,
						spaceBetween: 2
					},
					1025: {
						slidesPerView: 4
					},
					1280: {
						slidesPerView: 4
					}
				}}
				{...sliderOptions}
				elements={reviews.map((review, i) => <div key={i} className={s.review}>
					<div className="text-2xl font-bold">{review.name}</div>
					<div className="flex flex-nowrap items-center"><SpanishFlag height={24} width={24}/><font
						className="text-xl ml-5">{review.country}</font></div>
					<div className="my-5 flex flex-nowrap">{stars}</div>
					<div className="text-xl">{review.text}</div>
				</div>)}/>
		</div>
	);
};


export default ReviewsList;