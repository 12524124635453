import React, {useEffect, useState} from 'react';
import s from './MyProfileEdit.module.css';
import {useTranslation} from "react-i18next";
import InputWithLabel from "../../Inputs/InputWithLabel";
import {FormProvider, useForm} from "react-hook-form";
import {ReactComponent as PersonSVG} from "../../../resources/my_profile/person.svg";
import {ReactComponent as InstagramSVG} from "../../../resources/my_profile/instagram.svg";
import {ReactComponent as CakeSVG} from "../../../resources/my_profile/cake.svg";
import {ReactComponent as EmailSVG} from "../../../resources/my_profile/email.svg";
import {ReactComponent as PhoneSVG} from "../../../resources/my_profile/phone.svg";
import {ReactComponent as LockSVG} from "../../../resources/my_profile/lock.svg";
import {NavLink, useNavigate} from "react-router-dom";
import bases from "../../../routes/bases";
import Button from "../../Inputs/Button/Button";
import useUser from "../../../controllers/useUser/useUser";
import SelectNationality from "./components/SelectNationality";
import SelectGender from "../../Inputs/SelectGender";

import {validate} from "../../../helpers/validate";
import {parseError} from "../../ErrorManager/util";

const TextLabel = ({children}) => <p className={s.text_label}>{children}</p>


const MyProfileEdit = props => {
  const {t} = useTranslation();
  const form = useForm();

  const {setValue} = form;
  const navigate = useNavigate();


  const {getUser, updateUser} = useUser();
  const [user, setUser] = useState();


  const loadUser = async () => {
    const {error, user} = await getUser();
    if (error) return alert(error);
    setUser(user);
  }
  useEffect(() => {
    loadUser();
  }, []);

  useEffect(() => {
    if (!user) return;
    const {...rest} = user;

    for (let key in rest) {
      setValue(key, rest[key]);
    }
  }, [user]);

  const onSubmit = async data => {
    let l_error = validate.user(data);
    if (l_error !== false) return alert(parseError(l_error));

    const {error} = await updateUser({
      birthday: data.birthday ? new Date(data.birthday).toISOString() : null,
      //birthday: data.birthday ? data.birthday.toISOString() : null,
      ...data
    });

    if (error) return alert(parseError(error));
    alert(`${t('saved')}!`);
    navigate('/p')
  }


  const {handleSubmit} = form;

  return (
    <div className="w-full flex-wrap lg:flex-nowrap flex p-10 px-8"
         style={{background: '#1C7263', maxWidth: '800px', borderRadius: '15px'}}>
      <div className="md:mx-10 w-full">
        <FormProvider {...form}>
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <TextLabel>{t('edit profile.personal data')}</TextLabel>
            <div className="flex flex-wrap md:flex-nowrap w-full">
              <InputWithLabel
                icon={<PersonSVG style={{fill: '#757575'}}/>}
                label={t('edit profile.name')}
                id="edit_profile_name"
                name="name"
                containerClassName="mb-8 md:mr-5"
                required
              />
              <InputWithLabel
                icon={<PersonSVG style={{fill: '#757575'}}/>}
                label={t('edit profile.surname')}
                id="edit_profile_surname"
                containerClassName="mb-8"
                name="surname"
                required
              />
            </div>
            <div>
              <InputWithLabel
                icon={<PersonSVG style={{fill: '#FBBC04'}}/>}
                label={t('edit profile.trippy member name')}
                id="edit_profile_trippy_member_name"
                name="trippy_member_name"
              />
            </div>
            <div className="w-full mt-8 flex flex-wrap md:flex-nowrap">
              <InputWithLabel
                icon={<InstagramSVG style={{fill: '#FBBC04'}}/>}
                label="Instagram"
                id="edit_profile_instagram"
                containerClassName="md:mr-5 mb-5 md:mb-0"
                name="instagram"
              />
              {/*              <DatePickerWithLabel
                icon={<CageSVG style={{fill: '#757575'}}/>}
                label={t("edit profile.birthday")}
                id="edit_profile_birthday"
                name="birthday"
              />*/}
              <InputWithLabel
                icon={<CakeSVG style={{fill: '#757575'}}/>}
                label={t("edit profile.birthday")}
                id="edit_profile_birthday"
                name="birthday"
                type="date"
                alwaysActive={true}
              />
            </div>
            <div className="w-full my-10 flex flex-wrap md:flex-nowrap">
              <SelectGender id="edit_profile_gender" containerClassName="mb-5 md:mb-0 md:mr-5"/>
              <SelectNationality/>
            </div>
            <TextLabel>{t('edit profile.contact data')}</TextLabel>
            <div className="mb-10">
              <InputWithLabel
                icon={<EmailSVG style={{fill: '#757575'}}/>}
                label={t('email')}
                id="edit_profile_email"
                name="email"
                containerClassName="mb-8"
                type="email"
                required
              />
              <InputWithLabel
                icon={<PhoneSVG style={{fill: '#757575'}}/>}
                label={t('phone')}
                id="edit_profile_phone"
                name="phone"
              />
            </div>
            <TextLabel>{t('edit profile.change password')}</TextLabel>
            <div>
              <InputWithLabel
                icon={<LockSVG style={{fill: '#757575'}}/>}
                label={t('current password')}
                type="password"
                id="edit_profile_current_password"
                containerClassName="mb-8"
                name="current_password"
              />
              <InputWithLabel
                icon={<LockSVG style={{fill: '#757575'}}/>}
                label={t('new password')}
                type="password"
                id="edit_profile_new_password"
                name="new_password"
              />
            </div>
            {/*						<div className="my-10">
							<NavLink style={{marginRight: '15px', display: 'block'}} to={bases.profile_edit}>
								<Button className={s.visited_countries}>{t('visited countries')}</Button>
							</NavLink>
						</div>*/}
            <div className="flex ml-auto mr-0 mt-10" style={{maxWidth: '400px'}}>
              <NavLink style={{marginRight: '15px', display: 'block', width: '100%'}} to={bases.profile_router}>
                <Button type="button" className={s.visited_countries}>{t('cancel')}</Button>
              </NavLink>
              <Button className={s.visited_countries}>{t('save')}</Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};


export default MyProfileEdit;
