import React, {useMemo} from 'react';
import s from './FlightsSummary.module.css';
import {useTranslation} from "react-i18next";
import CurrencyNumber from "../../../CurrencyNumber";

const FlightsSummary = ({flights}) => {
  const {t} = useTranslation();
  const totalFlightsPrice = useMemo(() => {
    if (!Array.isArray(flights)) return false;
    let data = {
      total: 0,
      fees_supplier: 0,
      fees_ticketing: 0
    };
    flights.forEach(flight => {
        data.total += flight.price.total * 1;
        if (Array.isArray(flight.price.fees)) {
          flight.price.fees.forEach(fee => {
            if (fee.type === 'SUPPLIER') data.fees_supplier += fee.amount * 1;
            if (fee.type === 'TICKETING') data.fees_ticketing += fee.amount * 1;
          });
        }
      }
    );

    return data;
  }, [flights]);

  return <></>;
  return <table>
    <tbody>
    <tr>
      <th colSpan={2} style={{textAlign: 'center'}}>{t('checkout.flights')}</th>
    </tr>
    {totalFlightsPrice && <>
      <tr>
        <td>{t('checkout.flight fees supplier')}</td>
        <th>{<CurrencyNumber>{totalFlightsPrice.fees_supplier}</CurrencyNumber>}</th>
      </tr>
      <tr>
        <td>{t('checkout.flight fees ticketing')}</td>
        <th>{<CurrencyNumber>{totalFlightsPrice.fees_ticketing}</CurrencyNumber>}</th>
      </tr>
      <tr>
        <td>{t('checkout.total')}</td>
        <th>{<CurrencyNumber>{totalFlightsPrice.total}</CurrencyNumber>}</th>
      </tr>
    </>}
    </tbody>
  </table>;
}


export default FlightsSummary;
