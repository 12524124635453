import React from "react";
import TextareaOriginal from "../../../Inputs/Textarea";

export default function Textarea({style, ...props}: any) {
  return <TextareaOriginal style={{
    border: 0,
    padding: '30px',
    borderRadius: '12px',
    ...(style || {})
  }} {...props}/>
}