import React, {useEffect, useState} from 'react';
import {SingleDatePicker} from 'react-dates';
import {Controller} from "react-hook-form";
import s from './DatePicker.module.css'
import {INFO_POSITION_TOP} from "react-dates/lib/constants";

const DatePicker = ({id, name, onBlur, onFocus, externalFocused, onChange, initialDate, calendarInfo, ...props}) => {
  //const {width, height} = useViewportSize();
  const [focused, setFocused] = useState(false);


  const onFocusChange = ({focused, date}) => {
    setFocused(focused);
    if (focused && onFocus) onFocus(date);
    if (!focused && onBlur) onBlur(date);
  }

  useEffect(() => {
    if (externalFocused === undefined) return;
    setFocused(externalFocused);
  }, [externalFocused]);


  return (
    <Controller
      defaultValue={initialDate}
      name={name}
      render={({field: {onChange: _onChange, value}}) => (
        <SingleDatePicker
          readOnly={true}
          withPortal={true}
          id={id}
          date={value}
          focused={focused}
          onDateChange={date => {
            _onChange(date);
            if (onChange) onChange(date);
          }}
          onFocusChange={({focused}) => onFocusChange({focused, date: value})}
          displayFormat="DD MMM YYYY"
          //numberOfMonths={width < 768 ? 1 : 2}
          numberOfMonths={1}
          renderCalendarInfo={() => calendarInfo ? <div className={s.calendarInfo}>{calendarInfo}</div> : <> </>}
          calendarInfoPosition={INFO_POSITION_TOP}
          hideKeyboardShortcutsPanel={true}
          {...props}
        />
      )}
    />
  )
};


export default DatePicker;
