import {ReactComponent as CitySVG} from "../resources/svg/city.svg";
import {ReactComponent as DaySVG} from "../resources/svg/date.svg";
import {ReactComponent as HotelSVG} from "../resources/my_profile_menu/hotels.svg";
import {ReactComponent as ActivitySVG} from "../resources/my_profile_menu/activities.svg";
import {ReactComponent as CarSVG} from "../resources/svg/car.svg";
import {ReactComponent as PacketSVG} from "../resources/my_profile_menu/packets.svg";

import NorthAmericaImage from "../resources/images/maps/subcontinents/northernAmerica.png";
import SouthAmericaImage from "../resources/images/maps/subcontinents/southamerica.png";
import CentralAmericaImage from "../resources/images/maps/subcontinents/centralAmerica.png";
import NorthAfricaImage from "../resources/images/maps/subcontinents/northernAfrica.png";
import CentralAfricaImage from "../resources/images/maps/subcontinents/middleAfrica.png";
import WesternAfricaImage from "../resources/images/maps/subcontinents/westernAfrica.png";
import EasternAfricaImage from "../resources/images/maps/subcontinents/easternAfrica.png";
import SouthernAfricaImage from "../resources/images/maps/subcontinents/southernAfrica.png";
import NorthEuropeImage from "../resources/images/maps/subcontinents/northernEurope.png";
import SouthernEuropeImage from "../resources/images/maps/subcontinents/southernEurope.png";
import EasternEuropeImage from "../resources/images/maps/subcontinents/easternEurope.png";
import WesternEuropeImage from "../resources/images/maps/subcontinents/westernEurope.png";
import CentralAsiaImage from "../resources/images/maps/subcontinents/centralAsia.png";
import EasternAsiaImage from "../resources/images/maps/subcontinents/easternAsia.png";
import SouthEasternAsiaImage from "../resources/images/maps/subcontinents/southEasternAsia.png";
import SouthernAsiaImage from "../resources/images/maps/subcontinents/southernAsia.png";
import WesternAsiaImage from "../resources/images/maps/subcontinents/westernAsia.png";
import AustraliaAndZelandImage from "../resources/images/maps/subcontinents/australia_new_zeland.png";


import AmericaImage from '../resources/images/maps/continents/america.png';
import AfricaImage from '../resources/images/maps/continents/africa.png';
import AsiaImage from '../resources/images/maps/continents/asia.png';
import EuropeImage from '../resources/images/maps/continents/europe.png';
import OceaniaImage from '../resources/images/maps/continents/oceania.png';


import config from "../config";
import {t} from "i18next";

export const buildHighlightData = ({experience, t}) => {
  let cities = experience.places.reduce((acc, place, i) => {
    acc.add(place.address.label);
    return i === experience.places.length - 1 ? acc.size : acc;
  }, new Set());
  let days = Math.max(...experience.places.map(a => a.end));


  let hightlights = [
    {icon: <CitySVG width={24} height={24}/>, text: t('experience_details.city', {count: cities}), n: cities},
    {icon: <DaySVG width={24} height={24}/>, text: t('experience_details.day', {count: days}), n: days},
    {
      icon: <HotelSVG width={24} height={24}/>,
      text: t('experience_details.hotel', {count: experience.hotels.length}),
      n: experience.hotels.length
    },
    {
      icon: <ActivitySVG width={24} height={24}/>,
      text: t('experience_details.activity', {count: experience.activities.length}),
      n: experience.activities.length
    },
    {
      icon: <CarSVG width={24} height={24}/>,
      text: t('experience_details.transport', {count: experience.transports.length}),
      n: experience.hotels.length
    },
    {
      icon: <PacketSVG width={22} height={22}/>,
      text: t('experience_details.feature', {count: experience.features.length}),
      n: experience.features.length
    }
  ];
  return hightlights;
}

export const getPartsOfTheWorld = () => [
  {
    id: 'north america',
    name: t('map territories.north america'),
    countryCodes: ["BM", "CA", "GL", "PM", "US"],
    image: NorthAmericaImage,
    continent: 'america'
  },
  {
    id: 'south america',
    name: t('map territories.south america'),
    countryCodes: ["AR", "BO", "BV", "BR", "CL", "CO", "EC", "FK", "GF", "GY", "PY", "PE", "GS", "SR", "UY", "VE"],
    image: SouthAmericaImage,
    continent: 'america'
  },
  {
    id: 'central america',
    name: t('map territories.central america'),
    countryCodes: ["BZ", "CR", "SV", "GT", "HN", "MX", "NI", "PA", "AI", "AG", "AW", "BS", "BB", "BQ", "VG", "KY", "CU", "CW", "DM", "DO", "GD", "GP", "HT", "JM", "MQ", "MS", "PR", "BL", "KN", "LC", "MF", "VC", "SX", "TT", "TC", "VI"],
    image: CentralAmericaImage,
    continent: 'america'
  },
  {
    id: 'north africa',
    name: t('map territories.north africa'),
    countryCodes: ["DZ", "EG", "LY", "MA", "SD", "TN", "EH"],
    image: NorthAfricaImage,
    continent: 'africa'
  },
  {
    id: 'central africa',
    name: t('map territories.central africa'),
    countryCodes: ["TD", "CF", "CG", "CM", "GQ", "AO", "GA", "CD", "ST", "ZM"],
    image: CentralAfricaImage,
    continent: 'africa'
  },
  {
    id: 'western africa',
    name: t('map territories.western africa'),
    countryCodes: ["BJ", "BF", "CV", "CI", "GM", "GH", "GN", "GW", "LR", "ML", "MR", "NE", "NG", "SH", "SN", "SL", "TG"],
    image: WesternAfricaImage,
    continent: 'africa'
  },
  {
    id: 'eastern africa',
    name: t('map territories.eastern africa'),
    countryCodes: ["IO", "BI", "KM", "DJ", "ER", "ET", "TF", "KE", "MG", "MW", "MU", "YT", "MZ", "RE", "RW", "SC", "SO", "SS", "UG", "TZ", "ZW"],
    image: EasternAfricaImage,
    continent: 'africa'
  },
  {
    id: 'south africa',
    name: t('map territories.south africa'),
    countryCodes: ["BW", "SZ", "LS", "NA", "ZA", 'Republic of Somaliland'],
    image: SouthernAfricaImage,
    continent: 'africa'
  },
  {
    id: 'north europe',
    name: t('map territories.north europe'),
    countryCodes: ["AX", "GG", "JE", "DK", "EE", "FO", "FI", "IS", "IE", "IM", "LV", "LT", "NO", "SJ", "SE", "GB"],
    image: NorthEuropeImage,
    continent: 'europe'
  },
  {
    id: 'southern europe',
    name: t('map territories.southern europe'),
    countryCodes: ["AL", "AD", "BA", "HR", "GI", "GR", "VA", "IT", "MT", "ME", "MK", "PT", "SM", "RS", "SI", "ES", "XK"],
    image: SouthernEuropeImage,
    continent: 'europe'
  },
  {
    id: 'eastern europe',
    name: t('map territories.eastern europe'),
    countryCodes: ["BY", "BG", "CZ", "HU", "PL", "MD", "RO", "RU", "SK", "UA"],
    image: EasternEuropeImage,
    continent: 'europe'
  },
  {
    id: 'western europe',
    name: t('map territories.western europe'),
    countryCodes: ["AT", "BE", "FR", "DE", "LI", "LU", "MC", "NL", "CH"],
    image: WesternEuropeImage,
    continent: 'europe'
  },
  {
    id: 'central asia',
    name: t('map territories.central asia'),
    countryCodes: ["KZ", "KG", "TJ", "TM", "UZ"],
    image: CentralAsiaImage,
    continent: 'asia'
  },
  {
    id: 'eastern asia',
    name: t('map territories.eastern asia'),
    countryCodes: ["CN", "HK", "MO", "KP", "JP", "MN", "KR"],
    image: EasternAsiaImage,
    continent: 'asia'
  },
  {
    id: 'south east asia',
    name: t('map territories.south east asia'),
    countryCodes: ["BN", "KH", "ID", "LA", "MY", "MM", "PH", "SG", "TH", "TL", "VN", "PG", "CN-TW"],
    image: SouthEasternAsiaImage,
    continent: 'asia'
  },
  {
    id: 'south asia',
    name: t('map territories.south asia'),
    countryCodes: ["AF", "BD", "BT", "IN", "IR", "MV", "NP", "PK", "LK"],
    image: SouthernAsiaImage,
    continent: 'asia'
  },
  {
    id: 'western asia',
    name: t('map territories.western asia'),
    countryCodes: ["AM", "AZ", "BH", "CY", "GE", "IQ", "IL", "JO", "KW", "LB", "OM", "QA", "SA", "PS", "SY", "TR", "AE", "YE"],
    image: WesternAsiaImage,
    continent: 'asia'
  },
  {
    id: 'australia and new zealand',
    name: t('map territories.australia and new zealand'),
    countryCodes: ["AU", "CX", "CC", "HM", "NZ", "NF"],
    image: AustraliaAndZelandImage,
    continent: 'oceania'
  },
  {
    id: 'melanesia',
    name: t('map territories.melanesia'),
    countryCodes: ["FJ", "NC", "PG", "SB", "VU"],
    continent: 'oceania'
  },
  {
    id: 'micronesia',
    name: t('map territories.micronesia'),
    countryCodes: ["GU", "KI", "MH", "FM", "NR", "MP", "PW", "UM"],
    continent: 'oceania'
  },
  {
    id: 'polynesia',
    name: t('map territories.polynesia'),
    countryCodes: ["AS", "CK", "PF", "NU", "PN", "WS", "TK", "TO", "TV", "WF"],
    continent: 'oceania'
  },
  {
    id: 'antarctica',
    name: t('map territories.antarctica'),
    countryCodes: ["AQ"],
    continent: 'oceania'
  }
]

export const getContinentImages = () => {
  return [
    {id: 'america', image: AmericaImage},
    {id: 'africa', image: AfricaImage},
    {id: 'asia', image: AsiaImage},
    {id: 'europe', image: EuropeImage},
    {id: 'oceania', image: OceaniaImage}
  ]
}


const getCode = (geo) => {
  let code = geo.properties.ISO_A2;
  code = code === '-99' ? geo.properties.ISO_A2_EH : code;

  return code === '-99' ? geo.properties.FORMAL_EN : code;
}

export const partsOfTheWorldExcel = (groups, a) => {
  let geo = a.objects[Object.keys(a.objects)[0]].geometries;


  let r = groups.map(g => {
    return {
      nombre: g.name,
      countries: g.countryCodes.map(code => {
        let country = geo.find(g => {
          return getCode(g) === code
        });
        if (!country) return null;
        return {
          nombre: country.properties.NAME_ES,
          ISO_A2: getCode(country),
          ISO_A3: country.properties.ISO_A3,
        }
      }).filter(a => a)
    }
  });
  return r;

}


export const buildImages = experience => {
  let images = new Set();
  if (Array.isArray(experience.packages)) {
    experience.packages.forEach(p => {
      if (Array.isArray(p.distributions)) {
        let min = Math.min(...p.distributions.filter(d => !isNaN(d.price_per_person)).map(d => d.price_per_person));
        if (experience.min_price > min) experience.min_price = min;
      }

      if (Array.isArray(p.daysSummary)) {
        p.daysSummary.forEach(day => {
          if (day?.images?.image) images.add(day.images.image);
          if (Array.isArray(day?.images?.others)) images.add(...day.images.others);
        });
      }
    });
  }
  return [...images].map(a => config.imagesURL + '/' + a);
}


