import React from 'react';
import s from './ActivitiesSummary.module.css';
import cs from '../CheckoutSummary.module.css';

import {useTranslation} from "react-i18next";
import CurrencyNumber from "../../../CurrencyNumber";
import useCheckoutProvider from "../../../../providers/CheckoutProvider/useCheckoutProvider";
import {AiOutlineCloseCircle} from "react-icons/ai";

const ActivitiesSummary = ({activities}) => {
	const {t} = useTranslation();
	const {setActivities} = useCheckoutProvider();

	const findImageResource = image => {
		let im = image.urls.find(url => url.sizeType === 'LARGE2');
		if (im) return im.resource;

		// find larger
		let l = 0;
		let i = 0;
		image.urls.forEach((url, j) => {
			if (url.width + url.height > l) {
				l = url.width + url.height;
				i = j;
			}
		});
		return image[i].resource;
	}

	const JoinRates = () => {
		let n = {};
		let total = 0;
		activities.forEach(activity => {
			if (!n[activity.name]) n[activity.name] = {price: 0, number: 0};
			activity.selectedRates.forEach(rate => {
				n[activity.name].price += rate.price * 1;
				n[activity.name].number++;
				total += rate.price * 1;
			});
		});

		let r = [];
		for (let name in n) {
			r.push(<tr>
				<td className={cs.summary_table_title}>{name} X {n[name].number}</td>
				<td className={cs.summary_table_price}><CurrencyNumber>{n[name].price}</CurrencyNumber></td>
			</tr>);
		}
		/*    r.push(<tr className="h-20">
					<th className="uppercase">{t('checkout.total')}</th>
					<th><CurrencyNumber>{total}</CurrencyNumber></th>
				</tr>);*/
		return r;
	}

	const removeActivity = activity => {
		if (!window.confirm(t('checkout.remove.activity'))) return;
		setActivities(prev => prev.filter(h => !(h.code === activity.code && h.api === activity.api)));
	}

	if(!Array.isArray(activities) || !activities.length)return <></>;
	
	return (
		<div>
			<table>
				<tbody>
				<tr>
					<th colSpan={2} style={{textAlign: 'center'}}>{t('checkout.activities')}</th>
				</tr>
				{activities.map((activity, activityN) => {
					return <>
						<tr>
							<td colSpan={2}>
								<div className="flex mb-5">
									<div className={s.closeIcon} onClick={() => removeActivity(activity)}><AiOutlineCloseCircle
										size={18}/></div>
									<img src={(findImageResource(activity.images[0]))} width={80} height={80}/>
									<div className="w-full ml-5">
										<div className="font-bold text-2xl">{activity.name}</div>
										{/*
                    <div className="w-full flex flex-nowrap">{generateStars(hotel)}</div>
*/}
									</div>
								</div>
							</td>
						</tr>
					</>
				})}
				{<JoinRates/>}

				</tbody>
			</table>
		</div>
	);
};


export default ActivitiesSummary;
