import React from "react";
import {AiFillCar} from "react-icons/ai";
import {IoAirplane, IoBoat, IoBus, IoTrain} from "react-icons/io5";
import {SiTokyometro} from "react-icons/si";

const size = 25;

export const icons = {
  airplane: <IoAirplane size={size}/>,
  car: <AiFillCar size={size}/>,
  bus: <IoBus size={size}/>,
  train: <IoTrain size={size}/>,
  boat: <IoBoat size={size}/>,
  other: <SiTokyometro size={size}/>
}