import React, {useEffect, useState} from 'react';
import s from './FilterUniqueExperiences.module.css';
import Skeleton from "react-loading-skeleton";
import {useTranslation} from "react-i18next";
import useUniqueExperienceProvider from "../../../providers/UniqueExperienceProvider/useUniqueExperienceProvider";
import {Controller} from "react-hook-form";
import {Affix} from "@mantine/core";
import FilterButton from "../../Filters/FilterButton";
import FilterContainer from "../../Filters/FilterContainer";
import {ReactComponent as StarSVG} from "../../../resources/svg/star.svg";
import RangeSlider from "../../Inputs/RangeSlider";
import UniqueExperienceSearchByName from "../UniqueExperienceSearchByName";
import FilterUEPartsOfTheWorld from "../../Filters/FilterUEPartsOfTheWorld";
import FilterUECountries from "../../Filters/FilterUECountries";
import FilterUECategories from "../../Filters/FilterUECategories";
import {useUtil} from "../../../hooks/useUtil";
import {getPartsOfTheWorld} from "../../../helpers/uniqueExperiences";
import Button from "../../Inputs/Button/Button";


const Loading = () => <div className="mt-10">
  <Skeleton count={4} height={200}/>
</div>


const FilterUniqueExperiences = ({desktop, fVisible, setFVisible}) => {
  const {t} = useTranslation();

  const {allowedFilters, filterForm, updateExperiences, loading} = useUniqueExperienceProvider();
  const [changed, setChanged] = useState(false);
  const {getValues} = filterForm;

  const {experience_paramsToObj} = useUtil();

  const partOfTheWorldGroups = React.useMemo(() => getPartsOfTheWorld(), [t]);


  const onFilterChange = () => {
    setChanged(true);
  }
  const onFilter = () => {
    setChanged(false);
    updateExperiences({onlyFilters: true});
  }

  useEffect(() => {
    let obj = experience_paramsToObj();

    if (Array.isArray(obj.categories)) {
      filterForm.setValue('categories', obj.categories.reduce((t, curr) => ({...t, [curr]: true}), {}));
    }
    if (Array.isArray(obj.map_codes)) {
      let groups = [];
      partOfTheWorldGroups.forEach(group => {
        for (let i = 0; i < group.countryCodes.length; i++) {
          if (!obj.map_codes.includes(group.countryCodes[i])) return;
        }
        groups.push(group.id);
      });

      filterForm.setValue('partsOfTheWorld', groups.reduce((t, curr) => ({...t, [curr]: true}), {}));
      filterForm.setValue('countries', obj.map_codes.reduce((t, curr) => ({...t, [curr]: true}), {}));
    }


  }, []);

  if (!allowedFilters || (loading === 1 || loading === 3)) return <Loading/>


  return (
    <div className="w-full">
      <Affix position={{right: 40, bottom: 80}}>
        <FilterButton changed={changed} onFilter={() => onFilter()} visible={fVisible}
                      setVisible={setFVisible}/>
      </Affix>
      <UniqueExperienceSearchByName onChange={() => onFilterChange()}/>
      <FilterUEPartsOfTheWorld onChange={() => onFilterChange()}/>
      <FilterUECountries onChange={() => onFilterChange()}/>
      <FilterUECategories onChange={() => onFilterChange()}/>
      <FilterContainer initialVisible={false} title={t('filter.min stars')}>
        <Controller
          name="min_stars"
          defaultValue={0}
          render={({field: {onChange, value}}) => <div className="flex p-8 items-center text-2xl">
            {[1, 2, 3, 4, 5].map((star, i) => {
              let max = getValues('max_stars');
              let disabled = max !== 0 && star > max;
              return <StarSVG key={i} onClick={() => {
                if (disabled) return;
                onChange(value === star ? 0 : star);
                onFilterChange();
              }} fill="#FBBC04" className={disabled ? s.disabled_star : s.star}
                              style={{opacity: star <= value ? 1 : 0.2}}/>
            })}
          </div>}
        />
      </FilterContainer>

      <FilterContainer initialVisible={false} title={t('filter.max stars')}>
        <Controller
          name="max_stars"
          defaultValue={0}
          render={({field: {onChange, value}}) => <div className="flex p-8 items-center text-2xl">
            {[1, 2, 3, 4, 5].map(star => {
              let min = getValues('min_stars');
              let disabled = min !== 0 && star < min;
              return <StarSVG onClick={() => {
                if (disabled) return;
                onChange(value === star ? 0 : star);
                onFilterChange();
              }} fill="#FBBC04" className={disabled ? s.disabled_star : s.star}
                              style={{opacity: star <= value ? 1 : 0.2}}/>
            })}
          </div>}
        />
      </FilterContainer>

      <FilterContainer initialVisible={false} title={t("filter.price per night")}>
        <RangeSlider
          name="experience_range_price"
          min={allowedFilters.minRate}
          max={allowedFilters.maxRate}
          defaultValue={[allowedFilters.minRate, allowedFilters.maxRate]}
          onMouseUp={onFilterChange}
        />
      </FilterContainer>

      {changed && desktop && <div className="w-1/2 m-auto mt-10">
        <Button type="button" onClick={() => {
          onFilter();
        }}>{t('filter.refresh hotels')}</Button>
      </div>}
    </div>
  );
};


export default FilterUniqueExperiences;