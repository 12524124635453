import React, {useEffect, useState} from 'react';
import s from './SearchUniqueExperiences.module.css';
import {useViewportSize} from "@mantine/hooks";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import FilterUniqueExperiences from "../../UniqueExperiences/FilterUniqueExperiences";
import Button from "../../Inputs/Button/Button";
import Skeleton from "react-loading-skeleton";
import UniqueExperience from "./UniqueExperience";
import {useUtil} from "../../../hooks/useUtil";
import useUniqueExperienceProvider from "../../../providers/UniqueExperienceProvider/useUniqueExperienceProvider";
import bases from "../../../routes/bases";
import {FormProvider} from "react-hook-form";


const Loading = () => (
  <div className="ml-10">
    <Skeleton count={1} height={50}/>
    <br/>
    <Skeleton count={4} height={200}/>
  </div>
)

const SearchUniqueExperiences = props => {
  const {t} = useTranslation();

  const navigate = useNavigate();
  const {experience_paramsToObj} = useUtil();

  const {filterForm} = useUniqueExperienceProvider();


  const [fVisible, setFVisible] = useState(false);
  const {width} = useViewportSize();
  const desktop = width >= 1280;


  const {
    loading,
    experiences,
    canLoadMore,
    updateExperiences
  } = useUniqueExperienceProvider();

  const onMoreInfo = (experience) => {
    navigate(bases.uniqueExperienceDetails + '/' + experience._id);
  }

  useEffect(() => {
    let data = experience_paramsToObj();
    updateExperiences(data);
  }, []);


  return (
    <div className={s.container}>
      <FormProvider {...filterForm}>
        <div className={`${s.leftBar} ${(desktop || fVisible) ? s.leftBarVisible : ''}`}>
          {/*
				<SearchUniqueExperiencesDestination/>
*/}
          <FilterUniqueExperiences desktop={desktop} fVisible={fVisible} setFVisible={setFVisible}/>
        </div>
        <div className={s.rightBar}>
          {loading >= 2 || !Array.isArray(experiences) ? <Loading/> : <>
            <div className={s.uniqueExperiences}>
              {(experiences?.length === 0) && <div className="text-3xl">{t('search_experiences.nothing found')}</div>}
              {experiences.map(experience => <UniqueExperience onMoreInfo={onMoreInfo} key={experience._id}
                                                               experience={experience}/>)}
            </div>
            {canLoadMore && <div className="m-auto mb-20" style={{width: '250px'}}>
              <Button onClick={() => updateExperiences({loadMore: true})} type="button">{t('load more')}</Button>
            </div>}
          </>}
        </div>
      </FormProvider>
    </div>
  );
};


export default SearchUniqueExperiences;