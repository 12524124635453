import React, {useEffect, useState} from 'react';
import s from './PartsOfTheWorldMap.module.css';
import {ComposableMap, Geographies, Geography, ZoomableGroup} from "react-simple-maps";
import {FloatingTooltip} from "@mantine/core";
import {useTranslation} from "react-i18next";
import Button from "../../Inputs/Button/Button";
import {getPartsOfTheWorld} from "../../../helpers/uniqueExperiences";
import {useViewportSize} from "@mantine/hooks";

import map_topojson from '../../../resources/maps/CountriesWorldHighMercator.json';


const PartsOfTheWorldMap = ({onChange, buttonsContainerStyle = {}}) => {
    const {t} = useTranslation();

    const [tooltip, setTooltip] = useState('');
    const [chosenCodes, setChosenCodes] = useState([]);
    const [hoveredCodes, setHoveredCodes] = useState([]);
    const [mapType, setMapType] = useState('world');

    const {width} = useViewportSize();
    const isDesktop = width >= 1280;

    const groups = React.useMemo(() => getPartsOfTheWorld(), [t]);


    useEffect(() => {
        onChange && onChange({codes: chosenCodes, mapType})
    }, [chosenCodes, mapType]);

    const getCode = geo => {
        let code = geo.properties.ISO_A2;
        code = code === '-99' ? geo.properties.ISO_A2_EH : code;

        return code === '-99' ? geo.properties.FORMAL_EN : code;
    }

    const onClick = geo => {
        let code = getCode(geo);
        if (mapType === 'country') return setChosenCodes(prev => prev.includes(code) ? prev.filter(c => c !== code) : [...prev, code]);

        for (let i = 0; i < groups.length; i++) {
            let group = groups[i];
            if (group.countryCodes.indexOf(code) !== -1) {

                setChosenCodes(prev => {
                    let newCodes = [...prev];
                    let exists = false;

                    group.countryCodes.forEach((code, i) => {
                        const index = newCodes.indexOf(code);
                        if (!i) exists = index !== -1;
                        if (exists && index !== -1) {
                            newCodes.splice(index, 1);
                        } else if (index === -1) {
                            newCodes.push(code);
                        }
                    });
                    return newCodes;
                });
                break;
            }
        }
    }
    const onMouseEnter = geo => {
        let code = getCode(geo);

        if (mapType === 'country') {
            setHoveredCodes([code]);
            setTooltip(geo.properties.NAME_ES);
        } else {
            for (let i = 0; i < groups.length; i++) {
                let group = groups[i];
                setTooltip(group.name);
                if (group.countryCodes.indexOf(code) !== -1) {
                    setHoveredCodes(prev => {
                        let n = new Set(prev);
                        group.countryCodes.forEach(code => n.add(code));
                        return [...n];
                    });
                    break;
                }
            }
        }
    }

    const selectAll = () => {
        if (isAllSelected()) return setChosenCodes([]);
        setChosenCodes(prev => {
            let r = [];
            if (mapType === 'country') return map_topojson.objects.ne_50m_admin_0_countries.geometries.map(geo => getCode(geo));

            groups.forEach(group => {
                group.countryCodes.forEach(code => {
                    let d = document.querySelector(`#svg_map_${code}`);
                    if (d) d.classList.add('svg_map_chosen');
                    r.push(code);
                })
            });
            return r;
        });
    }

    const isAllSelected = () => {
        if (mapType === 'country') return chosenCodes.length === map_topojson.objects.ne_50m_admin_0_countries.geometries.length;
        return chosenCodes.length === groups.reduce((a, b) => a + b.countryCodes.length, 0);
    }

    const toggleMapType = () => {
        //setChosenCodes([]);
        setHoveredCodes([]);
        setMapType(prev => prev === 'world' ? 'country' : 'world');
    }

    return (
        <div className="w-full relative pt-20">
            <div className={s.buttonsContainer} style={buttonsContainerStyle}>
                <Button type="button" className={s.button}
                        onClick={() => selectAll()}>
                    {isAllSelected() ? t('map territories.deselect all') : t('map territories.select all')}
                </Button>
                <Button type="button" className={s.button}
                        onClick={() => toggleMapType()}>
                    {mapType === 'world' ? t('map territories.select country') : t('map territories.select territory')}
                </Button>
            </div>
            <FloatingTooltip className="w-full" disabled={!tooltip} label={tooltip}>
                <div data-tip="">
                    <ComposableMap height={300} projection="geoMercator" projectionConfig={{
                        scale: 70
                    }}>
                        <ZoomableGroup translateExtent={[[50, -50], [700, 400]]} zoom={1.5}
                                       maxZoom={isDesktop ? 3 : 10}>
                            <Geographies geography={map_topojson}>
                                {({geographies}) =>
                                    geographies.map((geo) => {
                                        let code = getCode(geo);
                                        let default_color = chosenCodes.includes(code) || hoveredCodes.includes(code) ? '#0a6552' : "#D6D6DA";
                                        return (
                                            <Geography
                                                key={geo.rsmKey}
                                                stroke={"#999"}
                                                strokeWidth={0.1}
                                                geography={geo}
                                                onMouseEnter={() => onMouseEnter(geo)}
                                                onMouseLeave={() => {
                                                    setHoveredCodes([]);
                                                    setTooltip(false);
                                                }}
                                                onClick={() => onClick(geo)}
                                                style={{
                                                    default: {
                                                        fill: default_color,
                                                        outline: "none"
                                                    },
                                                    hover: {
                                                        fill: "#0a6552",
                                                        outline: "none"
                                                    },
                                                    pressed: {
                                                        fill: "#999",
                                                    }
                                                }}
                                            />
                                        )
                                    })
                                }
                            </Geographies>
                        </ZoomableGroup>
                    </ComposableMap>
                </div>
            </FloatingTooltip>
        </div>
    );
};


export default PartsOfTheWorldMap;