import React from 'react';
import useCurrencyProvider from "../../providers/CurrencyProvider/useCurrencyProvider";

const createValue = (exchanges, selectedCurrency, options, children) => {
  let number = children * 1;
  if (isNaN(number)) {
    number = children;
  } else if (selectedCurrency.toLowerCase() !== 'eur') {
    number *= exchanges.exchanges.find(exchange => exchange.code === selectedCurrency).value
  }
  return new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: selectedCurrency,
    ...options
  }).format(number);
}

export const CurrencyNumberText = ({currency = '€', className = '', options = {}, children, text = false}) => {
  const {exchanges, selectedCurrency} = useCurrencyProvider();

  return createValue(exchanges, selectedCurrency, options, children);
}

const CurrencyNumber = ({currency = '€', className = '', options = {}, children}) => {
  const {exchanges, selectedCurrency} = useCurrencyProvider();

  const value = children * 1;

  let t = isNaN(value) ? '' : createValue(exchanges, selectedCurrency, options, value);
  return (
    <div className={`inline-block ${className}`}>{t}</div>
  );
};


export default CurrencyNumber;
