import {HotelRoom, ServerFullHotel} from "../../types/hotels/hotels";
import {HotelRoomWithHash} from "../../components/Hotels/HotelDetails/util";
import {UseHotelTypes} from "./models";
import {getFullFacilityInfo} from "../../components/Hotels/facilities/util";


function filterRates(hotelRooms: HotelRoom[]/*, neededAlotment: number*/): HotelRoomWithHash[] {
  // Remove opaque rates or rates that cannot be booked
  // Opaque rates only should be used when they are combined with other products like fly ticket, transfers, car rental…

  return hotelRooms.map(room => {
    if (!room.rates) return room;
    //const rates = room.rates.filter(rate => (/*rate.packaging ||*/ rate.rateType === 'BOOKABLE' && rate.paymentType === 'AT_WEB'));
    const rates = room.rates.filter(rate => (/*rate.packaging ||*/ rate.rateType === 'RECHECK' && rate.paymentType === 'AT_WEB'));

    let finalRates: HotelRoom['rates'] = [];
    rates.forEach(rate => {
      const dublicate = finalRates.find(r => {
        if (r.rateKey === rate.rateKey) return false;
        if (
          r.adults === rate.adults &&
          r.children === rate.children &&
          r.rooms === rate.rooms &&
          r.boardCode === rate.boardCode &&
          r.boardName === rate.boardName &&
          r.rateType === rate.rateType
        ) {
          return r.rateType === 'RECHECK'
          if (r.rateClass === 'NRF' && r.rateClass === rate.rateClass) return true;


          // Todo: A lo mejor eliminar las tarifas con mismas condiciones y peores condiciones de cancelacion

          // if(r.rateClass === rate.rateClass){
          //   if(r.cancellationPolicies.length !== rate.cancellationPolicies.length) return false;
          //
          //   const longestCancellationPolicies = r.cancellationPolicies.length > rate.cancellationPolicies.length ? r.cancellationPolicies : rate.cancellationPolicies;
          //   const cancellationPolicies = r.cancellationPolicies.length > rate.cancellationPolicies.length ? rate.cancellationPolicies : r.cancellationPolicies;
          //   let difference = false;
          //
          //   for(let i =0;i<longestCancellationPolicies.length;i++){
          //     if(longestCancellationPolicies[i].from !== cancellationPolicies[i]?.from) {
          //       difference = true;
          //       break;
          //     }
          //   }
          // }
        }
        return false
      });

      if (!dublicate) finalRates.push(rate);
    });


    return {
      ...room,
      rates: finalRates
    }
  });
}


export function addFinalPrice(hotel: ServerFullHotel, hotelTypes: UseHotelTypes) {
  hotel.minFinalRate = hotel.minRate * hotelTypes.commission
  if (Array.isArray(hotel.rooms)) {
    hotel.rooms = hotel.rooms.map(room => ({
      ...room,
      rates: Array.isArray(room.rates) ? room.rates.map(rate => ({
        ...rate,
        finalPrice: parseFloat(rate.net) * hotelTypes.commission,
        cancellationPolicies: rate.cancellationPolicies ? rate.cancellationPolicies.map(policy => ({
          ...policy,
          finalPrice: parseFloat(policy.amount) * hotelTypes.commission
        })) : rate.cancellationPolicies
      })) : room.rates
    }))
  }

  return hotel
}

function addDescriptionsToFacilities(hotel: ServerFullHotel, hotelTypes: UseHotelTypes) {
  if (Array.isArray(hotel.facilities)) {
    hotel.facilities = hotel.facilities.map(facility => getFullFacilityInfo({facility, hotelTypes}))
  }

  if (Array.isArray(hotel.rooms)) {
    hotel.rooms.map(room => {
      if (Array.isArray(room.roomFacilities)) {
        room.roomFacilities = room.roomFacilities.map(facility => getFullFacilityInfo({facility, hotelTypes}))
      }
      if (Array.isArray(room.roomStays)) {
        room.roomStays.map(stay => {
          if (Array.isArray(stay.roomStayFacilities)) {
            stay.roomStayFacilities = stay.roomStayFacilities.map(facility => getFullFacilityInfo({
              facility,
              hotelTypes
            }))
          }
        })
      }

      if (Array.isArray(room.roomStays)) {
        room.roomStays = room.roomStays.map(stay => {
          if (Array.isArray(stay.roomStayFacilities)) {
            stay.roomStayFacilities = stay.roomStayFacilities.map(facility => getFullFacilityInfo({
              facility,
              hotelTypes
            }))
          }
          return stay;
        })
      }
      return room;
    })
  }

  return hotel;
}


export function formatHotelFromServer(hotel: any, hotelTypes: UseHotelTypes, filter_rates = true) {
  if (!hotel) return hotel;
  // It's now done in the server
  // if (Array.isArray(hotel.rooms) && filter_rates) {
  //   hotel.rooms = filterRates(hotel.rooms).filter(room => room?.rates?.length > 0);
  // }
  addDescriptionsToFacilities(hotel, hotelTypes);
  addFinalPrice(hotel, hotelTypes)


  return hotel;
}