import React, {useEffect} from 'react';
import s from './BuildYourTripStep2.module.css';

import {ReactComponent as InfoSVG} from "../../../../resources/svg/small_info.svg";
import {ReactComponent as SuccessSVG} from "../../../../resources/svg/success_small.svg";
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";
import PlaneDatePicker from "../../../Inputs/PlaneDatePicker";
import Button from "../../../Inputs/Button/Button";
import {ReactComponent as ArrowButtonSVG} from "../../../../resources/svg/arrow_button_right.svg";
import {useNavigate} from "react-router-dom";
import bases from "../../../../routes/bases";

const Tick = (props) => <div className={s.tick}>
  <SuccessSVG style={{fill: '#fff'}}/>
</div>


const BuildYourTripStep2 = props => {
  const {t} = useTranslation();
  const {register, watch, setValue, getValues} = useFormContext();
  const navigate = useNavigate();

  const dates_days_number = watch('dates_days_number');
  const flight_date_type = watch('flight_date_type');

  useEffect(() => {
    if (dates_days_number && isNaN(dates_days_number)) {
      setValue('dates_days_number', dates_days_number.replaceAll(/[\D*]/g, ''));
    }
  }, [dates_days_number]);

  const canGoNext = React.useCallback(() => {
    if (!flight_date_type) return false;
    if (flight_date_type === 'days') return (String(dates_days_number).length > 0);

    let dates = getValues('dates_days_range');
    if (!dates) return false;
    return (dates.startDate && dates.endDate);
  }, [flight_date_type, dates_days_number]);


  const goNext = () => {
    if (!canGoNext()) return alert(t('btrip.missing selection'));
    navigate(bases.btrip_step3);
  }


  return (
    <div className="w-full">
      <div className="flex flex-wrap lg:flex-nowrap justify-center">
        <div className="lg:mr-20">
          <div className="flex ml-5">
            <InfoSVG style={{fill: '#757575'}} className="mr-5"/>
            <div className={s.box_title}>{t('btrip.per days number')}</div>
          </div>
          <div className={`${s.box} ${flight_date_type === 'days' ? s.chosen_box : ''}`}>
            <div className="relative h-full" onClick={() => setValue('flight_date_type', 'days')}>
              <Tick/>
              <input
                {...register('dates_days_number')}
                type="text"
                maxLength={2}
                defaultValue={22}
                autoComplete="off"
                className={s.days_number_input}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="flex ml-5">
            <InfoSVG style={{fill: '#757575'}} className="mr-5"/>
            <div className={s.box_title}>
              {t('btrip.per range dates')}
            </div>
          </div>
          <div className={`${s.box} ${flight_date_type === 'range' ? s.chosen_box : ''}`}
               onClick={() => setValue('flight_date_type', 'range')}>
            <div className="relative h-full">
              <Tick/>
              <div className="h-full">
                <PlaneDatePicker name="dates_days_range"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex justify-center mt-10">
        <div className="w-72 mr-10">
          <Button onClick={() => navigate(bases.btrip_step1)}>
            <ArrowButtonSVG style={{fill: '#fff', transform: 'rotate(-180deg)'}} className="inline-block mr-5"/>
            {t('prev')}
          </Button>
        </div>
        <div className="w-72">
          <Button disabled={!canGoNext()} onClick={() => goNext()}>
            {t('next')}
            <ArrowButtonSVG style={{fill: '#fff'}} className="inline-block ml-5"/>
          </Button>
        </div>
      </div>
    </div>
  );
};


export default BuildYourTripStep2;
