import {useViewportSize} from "@mantine/hooks";
import {InsuredPersonsDesktop} from "./InsuredPersonsDesktop";
import {InsuredPersonsMobile} from "./InsuredPersonsMobile";
import {ContractedInsurance} from "../../../../../controllers/useInsurance/types";
import dayjs from "dayjs";

interface Props {
  insurance: ContractedInsurance
}

export interface FormattedInsuredPerson {
  name: string;
  surname: string;
  documentNumber: string;
  country?: string;
  birthdate?: string;
}

export function InsuredPersons({insurance}: Props) {
  const {width} = useViewportSize();
  const persons = insurance.insuredList.map(p => {
    const {name, surname, documentNumber, country, birthdate} = p;
    return {
      name,
      surname,
      documentNumber,
      country,
      birthdate: birthdate ? dayjs(birthdate).format('YYYY-MM-DD') : undefined
    };
  })

  return width > 1024 ? <InsuredPersonsDesktop persons={persons}/> : <InsuredPersonsMobile persons={persons}/>
}