import React, {useState} from 'react';
import s from '../UniqueExperienceRequestBudged.module.css';
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";
import HotelPassengerNumberStartYourTrip from "../../../Hotels/HotelPassengerNumberStartYourTrip";
import {ReactComponent as DeleteSVG} from "../../../../resources/svg/delete_small_circle.svg";
import {ReactComponent as RoundPlusSVG} from "../../../../resources/svg/round_plus.svg";
import UEBPassengerNumber from "../../../UEBPassengerNumber";

const UEBDistribution = props => {
    const {t} = useTranslation();

    const [roomsNumber, setRoomsNumber] = useState([0]);

    const form = useFormContext();

    const {setValue, watch, register, unregister} = form;
    const addRoom = e => {
        e.preventDefault();
        setRoomsNumber(prev => [...prev, (prev[prev.length - 1] + 1) || 0]);
    }

    const removeRoom = (e, id) => {
        e.preventDefault();
        setRoomsNumber(prev => prev.filter(i => i !== id));
        unregister(`room_distribution_${id}`);
        unregister(`hotel_passenger_number_${id}`);
    }


    return <div>
        <div className={s.title}>{t('experience_details.rbd.distribution')}</div>
        <table className={s.table}>
            <thead>
            <tr>
                <th>{t('start your trip.room')}</th>
                <th>{t('start your trip.distribution')}</th>
            </tr>
            </thead>
            <tbody>
            {roomsNumber.map((id, i) =>
              <tr>
                  <td className={s.table_room_td}>
                      <div>{i + 1}</div>
                  </td>
                  <td>
                      <UEBPassengerNumber style={{zIndex: 100 - i}} name={`passenger_number_${id}`}
                                                         id={`passenger_number_${id}`}
                                                         label={t('hotel.persons')}/>
                  </td>
                  {roomsNumber.length > 1 &&
                    <td><DeleteSVG className={s.delete_svg} width={34} height={34} onClick={e => removeRoom(e, id)}/></td>}
              </tr>
            )}
            </tbody>
        </table>

        <div className="w-full flex cursor-pointer" onClick={e => addRoom(e)}>
            <RoundPlusSVG style={{fill: '#1C7263'}} className="mx-5"/>
            <div className="text-2xl" style={{color: '#1C7263'}}>{t('start your trip.add room')}</div>
        </div>
    </div>

};


export default UEBDistribution;