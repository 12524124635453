import React, {useState} from 'react';
import s from './SmallActivity.module.css';
import {useTranslation} from "react-i18next";
import config from "../../config";
import Image from "../Image/Image";
import {cutText} from "../../helpers/util";
import {Modal} from "@mantine/core";
import ActivityDetailsLocalInfo from "../Activities/ActivityDetails/ActivityDetailsLocalInfo";
import {langRender} from "../../helpers/i18n";

const SmallActivity = ({activity, toLoad = false, showMore = false, onShowMore}) => {
	const {t} = useTranslation();
	const [modal, setModal] = useState(false);

	if (toLoad) {
		alert('SmallActivity to load not implemented!');
		return <></>
	}

	// solo funciona para actividades locales
	let image = Array.isArray(activity.images) ? config.imagesURL + '/' + activity.images[0] : '';

	const _onShowMore = () => {
		if(onShowMore)return onShowMore(activity);
		setModal(true);
	}
	return (
		<>
			<Modal
				opened={modal}
				onClose={() => setModal(false)}
				closeOnEscape={true}
				closeOnClickOutside={true}
				size="full"
				styles={{
					modal: {
						background: 'rgb(246, 246, 246)'
					}
				}}
			>
				<ActivityDetailsLocalInfo activity={activity} />
			</Modal>
		<div className={s.container}>
			<div className={s.image}>
				<Image src={image} alt={`${langRender(activity.name)} main`}/>
			</div>
			<div className="w-full pl-5">
				<div className={s.title}>{langRender(activity.name)}</div>
				<div>{cutText(langRender(activity.description), 200)}</div>

				{showMore && <div className={s.show_more} onClick={() => _onShowMore()}>{t('activity_details.show more')}</div>}
			</div>
		</div>
		</>
	);
};


export default SmallActivity;