import React, {useMemo} from 'react';
import s from '../UniqueExperienceRequestBudged.module.css';
import {useTranslation} from "react-i18next";
import InputWithOutsideLabel from "../../../Inputs/InputWithOutsideLabel";
import {ReactComponent as BagSVG} from "../../../../resources/svg/shopping_bag.svg";
import {useFormContext} from "react-hook-form";
import {ActivityElement, HotelElement, TransportElement} from "./UEBPackageElements";

import {ReactComponent as PlaneSVG} from "../../../../resources/svg/plane.svg";
import {ReactComponent as DateSVG} from "../../../../resources/svg/date.svg";
import {ReactComponent as LocationSVG} from "../../../../resources/svg/location.svg";
import {langRender} from "../../../../helpers/i18n";
import {Modal} from "@mantine/core";
import HotelDetails from "../../../Hotels/HotelDetails";
import ActivityDetailsLocalInfo from "../../../Activities/ActivityDetails/ActivityDetailsLocalInfo";



const PackageData = ({experience, p, hotels, activities}) => {
	const {t} = useTranslation();
	const [details, setDetails] = React.useState(false);


	const findHotel = hotel => hotels.find(h => h._id === hotel.hotel_id || h.code === hotel.code);
	const findActivity = activity => activities.find(a => a._id === activity.activity_id);

	let data = useMemo(() => {
		if (!p || !experience) return [];
		let from = Math.min(...experience.places.map(p => p.start));
		let to = Math.max(...experience.places.map(p => p.end));
		let r = [];

		for (let day = from; day < to; day++) {
			let elements = [];
			let addresses_on_day = experience.places.filter(p => day >= p.start && day <= p.end).map(p => p?.address?.label);
			// remove repeated words
			for(let i = 0;i<addresses_on_day.length;i++){
				let s = addresses_on_day[i].split(',');
				s.forEach((word, index) => {
					for(let j = 0;j<addresses_on_day.length;j++){
						if(j === i)continue;
						if(addresses_on_day[j].includes(word))s.splice(index);
					}
				});
				addresses_on_day[i] = s.join(',');
			}

			let title = [...new Set(addresses_on_day.filter(a => a.trim()))].join(', ');

			if (Array.isArray(p?.hotels)) {
				experience.hotels.filter(h => p.hotels.includes(h._id)).forEach(hotel => {
					if (hotel.day === day) {
						let hotelData = findHotel(hotel);
						if (hotelData) {
							elements.push({
								type: 'hotel',
								data: {...hotel,...hotelData}
							});
						}
					}
				});
			}
			if (Array.isArray(p?.activities)) {
				experience.activities.filter(a => p.activities.includes(a._id)).forEach(activity => {
					if (activity.day === day) {
						let activityData = findActivity(activity);
						if (activityData) {
							elements.push({
								type: 'activity',
								data: activityData
							});
						}
					}
				});
			}

			if (Array.isArray(p?.transports)) {
				experience.transports.filter(t => t.day === day).forEach(transport => {
					elements.push({
						type: 'transport',
						data: transport
					});
				});
			}


			r.push({
				day,
				title,
				elements
			})
		}

		return r;
	}, [experience, p]);

	let f_data = data.filter(d => d.elements.length > 0);

	return <>
		<Modal
			size="full"
			opened={!!details}
			onClose={() => setDetails(false)}
		>
			{details.type === 'hotel' && <HotelDetails hotel={details.data} canBuy={false} toLoad={false} />}
			{details.type === 'activity' && <ActivityDetailsLocalInfo activity={details.data} />}
		</Modal>
		<div className="m-10 p-10">
			{f_data.map((d, i) => {
				let description;
				if (Array.isArray(p?.daysSummary) && p.daysSummary[d.day - 1]) description = p.daysSummary[d.day - 1].description;

				return <div className="w-full relative p-10">
					<div className={s.day_container}>
						<div className={!i ? s.planeIcon : s.dateIcon} style={{flex: '0 0 30px', width: '21px', height: '20px'}}>
							{!i ? <PlaneSVG style={{fill: '#757575'}}/> : <DateSVG className={s.dateIcon}/>}
						</div>
						<div className={s.day}>{t('experience_details.rbd.day')} {d.day}</div>
						{d.title && <div className={s.day_title}>
							<LocationSVG/>
							<div className="ml-5">{d.title}</div>
						</div>}
					</div>

					<div className={`${i < f_data.length - 1 ? s.leftBarContainer : ''}`}>
						{/*					{description && <div className={s.day_data_description}>{description}</div>}*/}
						<div className={s.day_data_container}>
							{d.elements.map(el => {
								switch (el.type) {
									case 'hotel':
										return <HotelElement onClick={() => setDetails(el)} hotel={el.data}/>
									case 'activity':
										return <ActivityElement onClick={() => setDetails(el)} activity={el.data}/>
									case 'transport':
										return <TransportElement transport={el.data}/>
								}
							})}
						</div>
					</div>
				</div>
			})}</div>
	</>
}


const UEBPackage = ({experience, hotels, activities}) => {
	const {t} = useTranslation();

	const form = useFormContext();

	const {setValue, watch} = form;


	const selected_package_id = watch('selected_package');
	let selected_package = experience.packages.find(p => p._id === selected_package_id);

	return (
		<div>
			<div className={s.title}>{t('experience_details.rbd.let\'s begin')}</div>
			<InputWithOutsideLabel
				initialValue={selected_package?.name}
				label={t("experience_details.rbd.choose package")}
				id="package"
				name="package"
				icon={<BagSVG style={{fill: '#979797'}}/>}
				data={experience.packages.map(a => ({...a, text: langRender(a.name)}))}
				onDataSelect={(data, input) => {
					if (input.current) {
						input.current.value = data.text;
						setValue('selected_package', data._id)
					}
				}}
				spellCheck={false}
				autoComplete="new-password"
				labelClassName={s.date_label}
				containerClassName="w-full mr-10"
			/>

			{selected_package && <>
				<div className="w-11/12 m-auto pt-5">
					<div className="text-2xl">{selected_package.description}</div>
				</div>
				<PackageData hotels={hotels} activities={activities} experience={experience} p={selected_package}/>
			</>}


		</div>
	);
};


export default UEBPackage;