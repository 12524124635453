import React, {useEffect, useRef, useState} from 'react';
import sBig from './InputWithNumericOptions.module.css';
import sSmall from './InputWithNumericOptions-small.module.css';
import {Controller} from "react-hook-form";

import {ReactComponent as NPlusSVG} from "../../../resources/svg/n_plus.svg";
import {ReactComponent as NMinusSVG} from "../../../resources/svg/n_minus.svg";
import {Popover} from "@mantine/core";
import {CallbackType, ElementPropsData, InputWithNumericOptionsValue} from "./models";

interface ElementProps {
  element: InputWithNumericOptionsValue;
  onClick: ({element, add}: ElementPropsData) => void;
  s: any; // style
}

const Element = ({element, onClick, s}: ElementProps) => (
  <div className={s.dataElement}>
    <div style={{flex: '0 0 80px'}}>
      {element.text}
      {element.smallText && <span> {element.smallText}</span>}
    </div>
    <div className={s.controllerContainer}>
      <button type="button" onClick={() => onClick({element})}>
        <NMinusSVG/>
      </button>

      <div className={s.value}>{element.value}</div>

      <button type="button" onClick={() => onClick({element, add: true})}>
        <NPlusSVG/>
      </button>
    </div>
  </div>
)

const Separator = () => <div className="w-full h-2 bg-gray-200 my-2"/>

interface InputWithNumericOptionsProps {
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  className?: any;
  hideNoValue?: boolean;
  onChange?: CallbackType;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  icon?: any;
  label?: string;
  id?: string;
  name: string;
  defaultOptions: any[];
  small?: boolean;

  [key: string]: any;
}

const InputWithNumericOptions = ({
                                   onFocus,
                                   className,
                                   hideNoValue,
                                   onChange: onExternalChange,
                                   onBlur,
                                   icon,
                                   label,
                                   id,
                                   name,
                                   defaultOptions,
                                   small = false,
                                   ...props
                                 }: InputWithNumericOptionsProps) => {
  const node = useRef<HTMLDivElement>(null);
  const input = useRef<HTMLInputElement>(null);
  const [isActive, setActive] = useState(false);

  const s = small ? sSmall : sBig;

  const onContainerClick = (e: any) => {
    e.preventDefault();
    setActive(prev => !prev);
  }

  const extractValue = (options: InputWithNumericOptionsValue[]) => {
    if (!options) return '';
    return options.filter(a => !a.separator && !a.element && (hideNoValue ? a.value : true)).reduce(((result, {
      text,
      inputText,
      value
    }) => result += ` ${value} ${inputText ?? text}`), '');
  }

  useEffect(() => {
    const current = node.current;
    //document.body.addEventListener('click', listenerHide);
    if (current) current.addEventListener('click', onContainerClick);
    return () => {
      //document.body.removeEventListener('click', listenerHide);
      if (current) current.removeEventListener('click', onContainerClick);
    }
  }, []);

  return (
    <Controller
      defaultValue={
        defaultOptions.map(({text, value = 0, name, min = 0, max = 100, ...rest}) =>
          ({name, text, value, min, max, ...rest}))
      }
      name={name}
      render={({field: {onChange, value}}: { field: { value: InputWithNumericOptionsValue[], onChange: any } }) => (
        <Popover
          opened={isActive}
          onClose={() => setActive(false)}
          // @ts-ignore
          position="bottom-start"
          width={node?.current?.offsetWidth}
          className="w-full"
          target={<div ref={node} className={`${s.container} ${!label ? s.containerSmaller : ''}`}>
            {icon && (<div className={s.icon}>
              {icon}
            </div>)}
            <input ref={input} className={`${className} ${s.active}`} type="text"
                   readOnly name={name}
                   id={id} value={extractValue(value)} {...props} />
            {label && <label htmlFor={id}>{label}</label>}
          </div>}
        >
          <div className={`${s.data} ${(isActive || 1) ? s.dataActive : ''}`}>
            {value.map((el, j) => {
                let FinalElement = el.separator ? Separator : el.element ? el.element : Element;

                return <FinalElement s={s} key={j} element={el} onClick={((data) => {
                  const {element, add} = data;
                  if (element.separator) return;

                  let newData = value.map(el => {
                    if (el.name !== element.name) return el;
                    return {
                      ...el,
                      value: add ?
                        (el.value! + 1 > el.max! ? el.max! : el.value! + 1) :
                        (el.value! - 1 < el.min! ? el.min! : el.value! - 1)
                    };
                  });
                  onChange(newData);
                  onExternalChange && onExternalChange(data, newData, node.current, j);
                }) as ElementProps['onClick']}/>
              }
            )}
          </div>
        </Popover>
      )}/>
  );
}

export default InputWithNumericOptions;
