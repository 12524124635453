import React from 'react';
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";
import es from "../../StartYourTripRouter/StartYourTripRouter.module.css";
import Textarea from "../../../Inputs/Textarea";
import {Button} from "../../Inputs/Button/Button";
import {ChooseDestination} from "./ChooseDestination";

const StartYourTripMap = ({onNext, onPrev, map, setMap}) => {
  const {t} = useTranslation();
  const form = useFormContext();

  const {setValue, getValues, watch} = form;

  const know_destination = watch('know_destination');
  const country_destinations = watch('country_destinations');
  const country_codes = watch('country_codes');


  const canContinue = () => {
    if (!know_destination) return country_destinations?.length > 0;
    if (know_destination === 1) return country_codes?.length > 0;
    return false;
  }


  return (
    <div>
      <div className={es.title}>{t('start your trip.parts of the world')}</div>
      <div className={es.subtitle}>{t('start your trip.parts of the world subtitle')}</div>
      <div className="px-5 sm:px-0">
        <div className="w-full flex flex-nowrap m-auto mt-20 mb-10">
          <Button className={`uppercase mr-10 ${know_destination ? es.normal_button : es.yellow_button}`}
                  onClick={e => {
                    e.preventDefault();
                    setValue('know_destination', 0)
                  }}>{t('start your trip.known destination')}</Button>

          <Button className={`uppercase ${know_destination ? es.yellow_button : es.normal_button}`}
                  onClick={e => {
                    e.preventDefault();
                    setValue('know_destination', 1)
                  }}>{t('start your trip.not known destination')}</Button>
        </div>
        {know_destination ? <ChooseDestination/>
          : <Textarea
            className={es.textarea}
            name="country_destinations"
            id="start_your_trip_country_destinations"
            placeholder={t('start your trip.destinations input')}
            rows={3}
          />
        }</div>
      <div className="flex justify-center mt-20">
        <div className={`${es.button} mr-10`}>
          <Button onClick={e => onPrev(e)}>{t('prev')}</Button>
        </div>
        <div className={`${es.button}`}>
          <Button disabled={!canContinue()} onClick={e => onNext(e)}>{t('next')}</Button>
        </div>
      </div>
    </div>
  );
};


export default StartYourTripMap;
