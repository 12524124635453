import React from 'react';
import PoliticasCookiesES from "./PoliticasCookiesES";
import PoliticasCookiesEN from "./PoliticasCookiesEN";

const PoliticasCookies = ({lang}) => {
  if (lang === 'es-ES') {
    return <PoliticasCookiesES/>
  } else {
    return <PoliticasCookiesEN/>
  }
};


export default PoliticasCookies;