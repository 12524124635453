import {query} from "../../helpers/query";

export const useUniqueExperiences = () => {
	const getExperience = async ({id}) => {
		const {error, data} = await query(`/api/unique-experiences/${id}`, false, 'POST');
		let e = data?.experience;
		if(!e)return {error};
		e.hotels = e.hotels || [];
		e.packages = e.packages || [];
		e.places = e.places || [];
		e.activities = e.activities || [];
		e.transports = e.transports || [];
		e.features = e.features || [];
		e.images = e.images || [];

		return {error, experience: e};
	}

	const searchExperiences = async ({body, page = 1, filters}) => {
		const {error, data} = await query(`/api/s/unique-experiences?page=${page}`, body);
		let result = {error, experiences: data?.experiences, filters: data?.filters, total: data?.total, itemsPerPage: data?.itemsPerPage};
		return result;
	}

	const requestBudget = async ({body}) => {
		const {error, data} = await query(`/api/unique-experiences/budget`, body, 'POST');
		return {error, success: data?.success};
	}

	const likeExperience = async experience => {
		const {error, data} = await query(`/api/user/like`, {
			code: experience._id,
			type: 'experience'
		});
		return {error, success: data?.success};
	}
	const dislikeExperience = async experience => {
		const {error, data} = await query(`/api/user/like`, {
			code: experience._id,
			type: 'experience'
		}, 'DELETE');

		return {error, success: data?.success};
	}


	return {
		getExperience,
		searchExperiences,
		requestBudget,
		likeExperience,
		dislikeExperience
	}
}