import React from 'react';
import config from "../../../config";

const AvisoLegalES = () => {
  return (
    <>      <h1>AVISO LEGAL</h1>
      <p>En este apartado se incluye información sobre las condiciones de acceso y utilización de este sitio web que
        deben ser conocidas por el usuario.</p>
      <h2>1. Objeto</h2>
      <p>Para dar cumplimiento a lo establecido en el artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la
        Sociedad de la Información y de Comercio Electrónico, a continuación, se indican los datos de información
        general de este sitio Web:</p>
      <p>Denominación: TRIPPY TREKKERS, S.L </p>
      <p>Dirección: Camino de Hoyarrasa 106, 28109 Alcobendas, Madrid.</p>
      <p>Datos registrales: Se encuentra inscrita en el Registro Mercantil de Madrid.</p>
      <p>Nombre de dominio: el nombre de dominio, titularidad de TRIPPY TREKKERS, S.L, es www.trippytrekkers.com C.I.F.:
        B-B88592381 Correo electrónico:
        <a href={`mailto:${config.trippy_trekkers.contact_email}`}>{config.trippy_trekkers.contact_email}</a>
      </p>
      <p>Este Aviso Legal regula la utilización de dicho dominio.</p>

      <h2>2. Condiciones de acceso y utilización de este sitio Web.</h2>
      <p>La utilización de este sitio web implica la aceptación por parte del Usuario de las condiciones de uso
        incluidas en este Aviso como también nuestras políticas sobre ‘Protección de Datos y Privacidad’ y ‘Política de
        uso de Cookies’. En el caso de que ciertos servicios contenidos y/o herramientas ofrecidas a través de este
        “Portal” requirieran de la aplicación condiciones particulares estas se pondrán a disposición del Usuario.</p>
      <p>Por otra parte, TRIPPY TREKKERS, S.L advierte que, tanto los contenidos y servicios de esta página web como las
        propias condiciones de utilización, pueden ser modificados sin notificación previa.</p>

      <p>El Usuario se compromete a que, en los apartados en que sea necesario que se registre para poder acceder a los
        mismos, facilitar datos veraces , exactos y completos sobre su identidad. Además, se compromete a mantener
        actualizados los datos personales que pudieran ser proporcionados a titular del dominio, por lo tanto, único
        responsable de las falsedades o inexactitudes que realice.</p>

      <p>Se informa que en caso de ser menor de edad deberá obtener el permiso de sus padres, tutores o representantes
        legales para poder acceder a los servicios prestados. TRIPPY TREKKERS , S.L no se responsabiliza en el caso de
        que los datos sobre este tema sean inexactos o falsos.</p>

      <p>El “Portal” sólo puede ser utilizado con propósitos legales por tanto el usuario se obliga a hacer un uso
        lícito y honrado del portal y conforme a las presentes Condiciones Generales de Uso, a No utilizar los servicios
        del “Portal” para la realización de actividades contrarias a las legislación española, a la moral y al orden
        público, asumiendo por parte del usuario todas las responsabilidades de daños y perjuicios frente al titular del
        dominio o terceros que pudieran derivarse de prácticas ilegales o no permitidas entre otras y a título
        enunciativo y no limitativo:</p>

      <ol>
        <li>Realizar sin previo consentimiento por parte del titular del dominio cualquier manipulación o alteración de
          esta página, no asumiendo el titular del dominio ninguna responsabilidad que pudiera derivarse, de dicha
          manipulación o alteración por terceros.
        </li>
        <li>Realizar cualquier acto que pueda dañar, inutilizar, sobrecargar, o deteriorar el Portal y los servicios y/o
          impedir el normal uso y utilización por parte de los Usuarios.
        </li>
        <li>Introducir y/o Utilizar programas de ordenador, datos, archivos defectuosos, virus, código malicioso,
          equipos informáticos o de telecomunicaciones o cualquier otro, independientemente de su naturaleza que pueda
          causar daños en el Portal, en cualquiera de los servicios, o en cualesquiera activos (físicos o lógicos) de
          los sistemas de información de titular del dominio.
        </li>
        <li>Violar los derechos de terceros a la intimidad, la propia imagen, la protección de datos al secreto en las
          comunicaciones, a la propiedad intelectual e industrial.
        </li>
        <li>Ocultar y falsear el origen de mensajes de correo electrónico.</li>
        <li>Utilizar identidades falsas, suplantar la identidad de otros en la utilización del Portal o en la
          utilización de cualquiera de los servicios.
        </li>
        <li>Reproducir, distribuir, modificar o copiar el contenido de esta página, salvo que de disponga de la
          autorización del titular del dominio o esté legalmente autorizado.
        </li>
        <li>Transmitir a terceros no autorizados los nombres de Usuario y las claves de acceso.</li>
      </ol>

      <h2>3. Enlaces a otros sitios.</h2>
      <p>TRIPPY TREKKERS, S.L no responde de los Enlaces (LINKS) a otras páginas de Internet de terceros y su existencia
        no implica que TRIPPY TREKKERS, S.L apruebe o acepte sus contenidos y servicios. Estas otras páginas web no
        están controladas por TRIPPY TREKKERS, S.L. Si accede a otras páginas web utilizando los Links proporcionados,
        los operadores de dichos sitios web podrán recoger su información personal. Asegúrese que está conforme con las
        Políticas de Privacidad de estas terceras páginas web antes de facilitar ningún tipo de información
        personal.</p>
      <p>Con carácter general, el titular del dominio, excluye su responsabilidad por los daños y perjuicios de
        cualquier naturaleza e índole que pudieran derivarse del uso del sitio web de, así como a los daños y perjuicios
        derivados de la infracción de los derechos de propiedad Intelectual e Industrial por parte de los usuarios y/o
        la falta de veracidad, exactitud, y actualidad de los contenidos, ni le podrán ser exigidas responsabilidades
        por la interrupción del servicio, inadecuado funcionamiento o imposibilidad de acceso al servicio</p>
      <p>El titular del dominio no será responsable por los daños y perjuicios causados por la presencia de virus o
        cualquier otro software lesivo que pueda producir alteraciones en el sistema informático del Usuario.</p>
      <p>El sitio web, incluyendo a título enunciativo, pero no limitativo, su programación, diseños, logotipos, texto
        y/o gráficos son propiedad del prestador o en su caso dispone de licencia o autorización expresa por parte de
        los autores.</p>

      <h2>4. Propiedad intelectual e industrial.</h2>

      <p>TRIPPY TREKKERS, S.L. es titular de todos los derechos de propiedad intelectual e industrial de su página web,
        así como de los elementos contenidos en la misma (a título enunciativo, imágenes, sonido, audio, vídeo, software
        o textos; marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales usados,
        programas de ordenador necesarios para su funcionamiento, acceso y uso, etc.), titularidad de TRIPPY TREKKERS,
        S.L.</p>
      <p>El usuario podrá visualizar los elementos del portal e incluso imprimirlos, copiarlos y almacenarlos en el
        disco duro de su ordenador o en cualquier otro soporte físico siempre y cuando sea, única y exclusivamente, para
        su uso personal y privado. Los usuarios deberán abstenerse de suprimir, alterar, eludir o manipular cualquier
        dispositivo de protección o sistema de seguridad que estuviera instalado en la página web de TRIPPY TREKKERS,
        S.L.</p>
      <p>Asimismo, ningún material publicado en esta web podrá ser reproducido, copiado o publicado sin el
        consentimiento por escrito de TRIPPY TREKKERS, S.L.</p>
      <p>El usuario se compromete a no realizar ningún acto en contra de los derechos de propiedad intelectual o
        industrial del autor.</p>

      <h2>5. Protección de datos y uso de cookies.</h2>
      <p>Puede consultar nuestra política sobre protección de datos de carácter personal y uso de cookies en los
        siguientes apartados de nuestra página web: www.trippytrekkers.com</p>

      <h2>6. Modificaciones</h2>
      <p>TRIPPY TREKKERS, S.L. podrá realizar, en cualquier momento y sin necesidad de previo aviso, modificaciones y
        actualizaciones sobre la información contenida en su sitio web, o en su configuración o presentación. TRIPPY
        TREKKERS, S.L realiza todos los esfuerzos posibles para que la información suministrada a través de su página
        web sea clara, comprensible y adecuada, así como para evitar errores en la medida de lo posible y, en su caso,
        repararlos o actualizarlos. </p>
      <p>TRIPPY TREKKERS, S.L no garantiza que el servicio prestado por el sitio web no sufra interrupciones técnicas en
        ningún momento, ni que se encuentre actualizado, así como sus contenidos en todo momento. Sin embargo, TRIPPY
        TREKKERS, S.L se compromete a llevar a cabo todas aquellas labores necesarias para subsanar los errores, tan
        pronto tenga conocimiento de ello, siempre que no concurran causas que lo hagan imposible o de difícil
        ejecución.</p>
      <p>Sin embargo, TRIPPY TREKKERS, S.L no puede garantizar la inexistencia de errores ni que el contenido de la
        información se encuentre permanentemente actualizado.</p>
      <p>Asimismo, TRIPPY TREKKERS, S.L se reserva el derecho de efectuar sin previo aviso las modificaciones que
        considere oportunas en su portal, pudiendo cambiar, suprimir o añadir tanto los contenidos y servicios que se
        presten a través de la misma, como la forma en la que éstos aparezcan presentados o localizados en su
        portal.</p>

      <h2>7. Política de comentarios</h2>
      <p>Esta web puede permitir la realización de comentarios para establecer una comunidad de viajeros que se pueda
        enriquecer con contenidos y realizar consultas.</p>
      <p>No se admitirán comentarios que no estén relacionados con la temática de esta web, que incluyan difamaciones,
        agravios, insultos, ataques personales o faltas de respeto en general hacia el autor o hacia otros miembros.</p>
      <p>También serán suprimidos los comentarios que contengan información que sea obviamente engañosa o falsa, así
        como los comentarios que contengan información personal, como, por ejemplo, domicilios privado o teléfonos y que
        vulneren la política de protección de datos de este sitio web.</p>
      <p>Se desestimarán, igualmente, aquellos comentarios creados sólo con fines promocionales de una web, persona o
        colectivo y todo lo que pueda ser considerado spam en general.</p>
      <p>No se publicarán comentarios que estén manifiestamente mal redactados con múltiples faltas de ortografía o
        palabras recortadas.</p>

      <h2>8. Legislación aplicable</h2>

      <p>La ley aplicable en caso de disputa o conflicto de interpretación de los términos que conforman este aviso
        legal, así como cualquier cuestión relacionada con los servicios de la presente web, será la ley española.</p>
      <p>Cualquier controversia entre TRIPPY TREKKERS, S.L y el usuario, sobre las cuestiones contenidas en el presente
        aviso legal, con renuncia expresa a cualquier otro fuero que pudiera corresponderles, se someterá a los Juzgados
        y Tribunales de la ciudad de Alcobendas, Madrid, España.</p>
    </>
  );
};


export default AvisoLegalES;