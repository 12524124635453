import React from 'react';
import s from './FacebookButton.module.css';
import useUser from "../../../controllers/useUser/useUser";

const FacebookButton = props => {
  const {facebookLogin} = useUser();


    return (
      <div className={s.button} onClick={() => facebookLogin()}>
        <div className={s.logo}>
          <svg width="12" height="24" viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.80918 3.985H12V0.169C11.622 0.117 10.3221 0 8.80827 0C5.64953 0 3.48571 1.987 3.48571 5.639V9H0V13.266H3.48571V24H7.75935V13.267H11.1041L11.635 9.001H7.75835V6.062C7.75935 4.829 8.09133 3.985 9.80918 3.985Z" fill="white"/>
          </svg>
        </div>
        <div className={s.text}>Facebook</div>
      </div>
    );
};


export default FacebookButton;
