import {Marker} from "@urbica/react-map-gl";
import s from './MapBox.module.css';

interface Props {
  longitude: number,
  latitude: number,
  pointCount: number;
}

export function ClusterMarker({longitude, latitude, pointCount}: Props) {
  return <Marker longitude={longitude} latitude={latitude}>
    <div className={s.clusterMarkerContainer}>{pointCount}</div>
  </Marker>
}