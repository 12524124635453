import React, {forwardRef, useMemo} from 'react';
import s from './StartYourTripInsurance.module.css';
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";
import es from "../../StartYourTripRouter/StartYourTripRouter.module.css";
import {Button} from "../../Inputs/Button/Button";
import CheckboxWithContainer from "../../Inputs/CheckboxWithContainer";
import Textarea from "../../Inputs/Textarea/Textarea";
import Input from "../../Inputs/Input/Input";
import {Group, Select, Text} from "@mantine/core";
import useCurrencyProvider from "../../../../providers/CurrencyProvider/useCurrencyProvider";
import {useViewportSize} from "@mantine/hooks";

const SelectCurrencyItem = forwardRef(({value, currency, ...others}, ref) => <div ref={ref} {...others}>
  <Group nowrap>
    {/*<Flag size="m" code={currency.code_2}/>*/}
    <Text>{currency.code}</Text>
  </Group>
</div>);

const StartYourTripInsurance = ({onNext, onPrev}) => {
  const {t} = useTranslation();
  const form = useFormContext();

  const {setValue, watch, register} = form;

  const need_insurance = watch('need_insurance');
  const from_budget = watch('person.from_budget');
  const to_budget = watch('person.to_budget');
  const {width} = useViewportSize();

  const {exchanges, selectedCurrency, setSelectedCurrency} = useCurrencyProvider();

  const excluded = ['GB'];

  const exchangesList = useMemo(() => {
    if (!Array.isArray(exchanges.exchanges)) return [];
    return exchanges.exchanges.filter(c => !excluded.includes(c.code_2))
      .map(currency => ({label: currency.code, value: currency.code, currency}));
  }, [exchanges]);

  const canContinue = () => {
    return !(!from_budget || !to_budget || !selectedCurrency);
  }

  const onInsuranceCancellationChange = (v) => {
    setValue('insurance_type.cancellation', !v)
    setValue('insurance_type.basic', v)
  }

  const onInsuranceBasicChange = (v) => {
    setValue('insurance_type.basic', !v)
    setValue('insurance_type.cancellation', v)
  }


  return (
    <div>
      <input type="checkbox" checked={need_insurance} className="invisible" {...register('need_insurance')} />
      <div className={es.title}>{t('start your trip.budged and insurance')}</div>
      <div className={es.subtitle}>{t('start your trip.budged and insurance subtitle')}</div>

      <div className="px-5 sm:px-0">
        <div className="mt-20">
          <div className={es.label} style={{textAlign: 'left'}}>{t('start your trip.budget')}</div>
          <div className="w-full flex flex-wrap sm:flex-nowrap items-center">
            <div className="w-full flex flex-nowrap gap-5 sm:gap-0">
              <Input
                {...register("person.from_budget")}
                containerClassName="sm:mr-10 sm:mb-0 mb-10"
                placeholder={t('start your trip.from')}
                type="number"
              />
              <Input
                {...register("person.to_budget")}
                containerClassName="sm:mr-10 sm:mb-0 mb-10"
                placeholder={t('start your trip.to')}
                type="number"
              />
            </div>
            <Select
              itemComponent={SelectCurrencyItem}
              data={exchangesList}
              searchable
              value={selectedCurrency}
              onChange={(v) => setSelectedCurrency(v)}
              placeholder={t('start your trip.currency')}
              styles={{
                root: {
                  width: width >= 640 ? '150px' : '100%',
                },
                input: {
                  height: width >= 1024 ? '57px' : '47px',
                  padding: '39px 24px',
                  borderRadius: '12px',
                  border: 0
                }
              }}
            />
          </div>
        </div>
        <div className="mt-10">
          <div className={es.label} style={{textAlign: 'left'}}>{t('start your trip.insurance')}</div>
          <div className="flex flex-wrap sm:flex-nowrap w-full">
            <CheckboxWithContainer onChange={v => onInsuranceCancellationChange(v)}
                                   containerClassName="w-full mb-7 sm:mb-0 sm:mr-10 "
                                   label={t('start your trip.basic insurance')}
                                   name="insurance_type.basic"
                                   labelStyle={{textAlign: 'left'}}
            />
            <CheckboxWithContainer onChange={v => onInsuranceBasicChange(v)}
                                   containerClassName="w-full"
                                   label={t('start your trip.cancellation insurance')}
                                   name="insurance_type.cancellation"
                                   labelStyle={{textAlign: 'left'}}
            />
          </div>
        </div>

        <div className="mt-10">
          <div className={es.label} style={{textAlign: 'left'}}>{t('start your trip.data of interest')}</div>
          <div className="relative">
            <Textarea
              name="describe_your_group"
              style={{paddingTop: width > 640 ? '50px' : '60px'}}
            />
            <div className={s.small_help}>
              <div>{t('start your trip.describe your group')}</div>
              <div>{t('start your trip.data of interest help')}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center mt-20">
        <div className={`${es.button} mr-10`}>
          <Button className={es.back_button} onClick={e => onPrev(e)}>{t('prev')}</Button>
        </div>
        <div className={`${es.button}`}>
          <Button disabled={!canContinue()} onClick={e => onNext(e)}>{t('next')}</Button>
        </div>
      </div>
    </div>
  );
};


export default StartYourTripInsurance;
