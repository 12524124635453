import {useState} from "react"
import {Modal} from "@mantine/core";
import Image from "../../../Image/Image";
import TripAdvisorReviewImage from '../../../../resources/images/tripadvisor.png'
import s from './TripAdvisorStars.module.css'
import cn from "classnames";
import {IoClose} from "react-icons/io5";


interface Props {
  stars: number
  code: number
}

export function TripAdvisorStars({stars, code}: Props) {
  const [opened, setOpened] = useState(false)

  return <>
    <Modal size="xl" padding={0} opened={opened} withCloseButton={false} onClose={() => setOpened(false)}>
      <div onClick={() => setOpened(false)} className={s.closeButton}><IoClose/></div>
      <iframe
        width="100%"
        height={600}
        src={`https://www.tripadvisor.com/WidgetEmbed-cdspropertydetail?display=true&partnerId=6CA95E2409844D6183003CEFCDDC2BDE&lang=es&locationId=${code}`}
      />
    </Modal>
    <div className="flex items-center gap-2 cursor-pointer" onClick={() => setOpened(true)}>
      <Image width={34} src={TripAdvisorReviewImage} alt="tripadvisor review"/>
      {Array.from({length: 5}).map((_, index) => <div key={index}
                                                      className={cn(s.review, {[s.checked]: index < stars})}/>)}
    </div>
  </>
}