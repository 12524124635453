import React from 'react';
import s from './ActivityDetailsLocalInfo.module.css';
import ss from '../ActivityDetails.module.css';
import {useTranslation} from "react-i18next";
import ActivityDetailsImages from "../ActivityDetailsImages";
import MapContainer from "../../../MapContainer/MapContainer";

import {ReactComponent as PhoneSVG} from "../../../../resources/svg/phone_call.svg";
import {Accordion, Title} from "@mantine/core";
import {langRender} from "../../../../helpers/i18n";
import {AiOutlineMail} from "react-icons/ai";
import {MdOutlineWebAsset} from "react-icons/md";


// es el sustituto de ActivityDetails para actividades locales
const ActivityDetailsLocalInfo = ({activity}) => {
  const {t} = useTranslation();

  let map_address = React.useMemo(() => {
    return {
      marker: {name: activity.address.label},
      center: {
        lat: activity.address.lat,
        lng: activity.address.lng
      }
    }
  }, [activity]);

  return (
    <div className={ss.activity_container}>
      <div className={ss.activity}>
        <div className="w-full flex flex-wrap lg:flex-nowrap justify-center">
          <ActivityDetailsImages activity={activity} removeFailedImage={() => true}/>

          <div className="xl:ml-10 w-full lg:w-4/12 flex flex-col mt-10 lg:mt-0 mx-10 xl:mx-0">
            <div className={ss.name}>
              {langRender(activity.name)}
            </div>
            <div className="flex lg:flex-col flex-wrap sm:flex-nowrap h-full items-center">
              <div className={ss.contact_data_container}>
                <div className="text-3xl font-bold mb-5">{t('activity_details.contact data')}</div>
                <div className={s.contact_data}>
                  {activity.phone && <>
                    <PhoneSVG style={{fill: '#757575'}}/>
                    <div><a href={`tel:${activity.phone}`}>{activity.phone}</a></div>
                  </>}
                  {activity.email && <>
                    <AiOutlineMail size={17} style={{fill: '#757575'}}/>
                    <div><a href={`mailto:${activity.email}`}>{activity.email}</a></div>
                  </>}
                  {activity.web && <>
                    <MdOutlineWebAsset size={17} style={{fill: '#757575'}}/>
                    <div><a href={activity.web} target="_blank" rel="noreferrer">{activity.web}</a></div>
                  </>}
                </div>
              </div>
              {map_address &&
                <div className={ss.mapContainer}>
                  <MapContainer
                    containerStyle={{
                      width: '100%',
                      height: '100%'
                    }}
                    marker={map_address.marker}
                    center={map_address.center}
                    zoom={17}
                  />
                </div>
              }
            </div>
          </div>
        </div>


        <div className="w-full flex mt-14 flex-wrap xl:flex-nowrap">
          <div className="w-full xl:w-8/12 xl:pr-20">
            {activity.description && <Title order={4} className="my-5">{t('description')}</Title>}
            <div className={s.description}>{langRender(activity.description)}</div>

            <div>
              <Title order={4} className="my-5">{t('experience_details.frequent questions')}</Title>
              <Accordion>
                <Accordion.Item label={t('activity_details.instructions')}
                                className={s.description}>{langRender(activity.instructions)}</Accordion.Item>
                <Accordion.Item label={t('activity_details.observations')}
                                className={s.description}>{langRender(activity.not_include)}</Accordion.Item>
                <Accordion.Item label={t('activity_details.excluded')}
                                className={s.description}>{langRender(activity.include)}</Accordion.Item>
                <Accordion.Item label={t('activity_details.notes')}
                                className={s.description}>{langRender(activity.notes)}</Accordion.Item>
              </Accordion>
            </div>
          </div>

          <div className="w-full sm:w-4/12">
            <div className={s.right_box}>
              <h3>{t('activity_details.schedule')}</h3>
              <pre>{langRender(activity.schedule)}</pre>
            </div>
          </div>

        </div>

      </div>


    </div>
  );
};


export default ActivityDetailsLocalInfo;