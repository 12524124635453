import s from './SortSelect.module.css';
import {useState} from "react";
import {ReactComponent as ArrowSVG} from "../../../../resources/svg/show_more_arrow.svg";
import cn from "classnames";


interface Props {
  title: string;
  items: { label: string, value: string }[];
  defaultValue?: string;
  onChange?: (value: string) => void;
}

export function SortSelect({title, items, defaultValue, onChange}: Props) {
  const [selected, setSelected] = useState(defaultValue ?? items[0].value);
  const [open, setOpen] = useState(false);

  return <div className={s.container} onClick={() => setOpen(prev => !prev)}>
    <div className={s.titleContainer}>
      <div className="flex items-center">
        <div className={s.title}>{title}:</div>
        <div style={{fontSize: '12px'}}>{items.find(item => item.value === selected)?.label}</div>
      </div>
      <ArrowSVG width={14}/>
    </div>
    <div className={cn(s.items, {[s.itemsVisible]: open})}>
      {items.map(item => <div className={cn({[s.selected]: selected === item.value})} key={item.value}
                              onClick={() => {
                                setSelected(item.value)
                                onChange?.(item.value);
                              }}>
        {item.label}
      </div>)}
    </div>
  </div>
}