import React, {useEffect, useState} from 'react';
import s from './PurchasedHotelDetails.module.css';
import {useTranslation} from "react-i18next";
import Loader from "../../../Loader";
import {useHotel} from "../../../../controllers/useHotel";
import {useNavigate, useParams} from "react-router-dom";
import Button from "../../../Inputs/Button/Button";

import {ReactComponent as DownloadSVG} from "../../../../resources/svg/download.svg";
import HotelDetailsImages from "../../../Hotels/HotelDetails/components/HotelDetailsImages";
import HotelDetailsInfo from "../../../Hotels/HotelDetails/components/HotelDetailsInfo";
import PurchasedHotelDetailsGuests from "../PurchasedHotelDetailsGuests";
import PurchasedHotelDetailsRooms from "./PurchasedHotelDetailsRooms";
import {langRender} from "../../../../helpers/i18n";
import {generateStars, isHotelRefundable} from "../../../Hotels/util";
import {ReactComponent as LocationSVG} from "../../../../resources/svg/locationIcon.svg";
import {Modal} from "@mantine/core";
import ReturnConfirmation from "../../../ReturnConfirmation";
import {parseError} from "../../../ErrorManager/util";
import useAuth from "../../../../providers/AuthProvider/useAuth";
import useHotelsProvider from "../../../../providers/HotelsProvider/useHotelsProvider";
import {PiKeyReturnBold} from "react-icons/pi";
import {getGoToLoginPageURL} from "../../../../helpers/util";

const PurchasedHotelDetails = ({hotel: _hotel}) => {
  const {t} = useTranslation();
  const {purchasedHotelDetails} = useHotel();
  const {ordered_id, hotel_code, api} = useParams();
  const {isAuthenticated} = useAuth();
  const navigate = useNavigate();
  const {likeTheHotel} = useHotelsProvider();
  const [refundedSuccess, setRefundedSuccess] = useState(false);

  const {downloadVoucher} = useHotel();


  const [hotel, setHotel] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [returnModal, setReturnModal] = useState(false);

  const loadHotel = async () => {
    setLoading(true);
    const {error, hotel} = await purchasedHotelDetails({
      ordered_id, code: hotel_code, api
    });
    if (error) return setError(error);

    setHotel(hotel);
    setLoading(false);
  }

  useEffect(() => {
    if (_hotel) return setHotel(_hotel);
    loadHotel();
  }, []);

  const onLikeClick = async () => {
    if (!hotel) return;
    if (!isAuthenticated()) return navigate(getGoToLoginPageURL());

    const result = await likeTheHotel(hotel);
    if (result?.error) return alert(parseError(result.error));
    if (result?.success) {
      setHotel(prev => ({
        ...prev,
        is_liked: !prev?.is_liked
      }));
    }
  }


  if (error) return <div className={s.error}>{parseError(error)}</div>;


  if (loading || !hotel) return <Loader/>;


  return (
    <>
      <Modal size="xl" opened={returnModal} onClose={() => {
        if (refundedSuccess) {
          loadHotel()
        }
        setReturnModal(false)
      }
      }>
        <ReturnConfirmation onSuccess={() => setRefundedSuccess(true)} ordered_id={ordered_id}/>
      </Modal>
      <div className="w-full m-5">
        <div className="w-full flex flex-wrap md:flex-nowrap justify-between items-center">
          <div className={s.reserve_number}>
            {t('purchased details data.reserve number')} {hotel.booking.reference}
          </div>
          <div className="flex flex-wrap w-full md:w-auto md:flex-nowrap items-center md:justify-end gap-5">
            <div>{isHotelRefundable(hotel) &&
              <Button className={s.refundButton} onClick={() => setReturnModal(true)}>
                <PiKeyReturnBold size={24}/>
                {t('purchased details data.refund')}
              </Button>}
            </div>


            <div><Button loadButton={true} className={s.pdf_button} onClick={async () => {
              await downloadVoucher({
                code: hotel_code * 1,
                ordered_id,
                api: hotel.api,
                title: hotel.name
              });
            }}>
              <div className="flex flex-nowrap"><DownloadSVG/>
                {t('purchased details data.download pdf')}</div>
            </Button></div>
          </div>
        </div>

        <div className={s.name}>
          {langRender(hotel.name)}
        </div>

        {hotel.address && <div className="w-full flex justify-content items-center mb-10 mt-2">
          <LocationSVG className="mr-3" fill="#9D9D9D" width={20} height={20}/>
          {/*@ts-ignore*/}
          <div style={{color: '#9D9D9D', fontSize: '12px'}}>{hotel?.address}</div>
          <div className="flex flex-nowrap items-center ml-5 gap-x-1">
            {generateStars(hotel)}
          </div>
        </div>}

        <div className="w-full flex flex-wrap lg:flex-nowrap justify-center mt-5">
          <HotelDetailsImages onLikeClick={onLikeClick} hotel={hotel}
          />
        </div>

        <HotelDetailsInfo hotel={hotel}/>
        <PurchasedHotelDetailsRooms hotel={hotel}/>
        <PurchasedHotelDetailsGuests hotel={hotel}/>


      </div>
    </>

  );
};


export default PurchasedHotelDetails;