import React from 'react';
import s from './UniqueExperiences.module.css';
import {FormProvider, useForm} from "react-hook-form";
import {Outlet, useNavigate} from "react-router-dom";
import {useUtil} from "../../../hooks/useUtil";
import bases from "../../../routes/bases";

const UniqueExperiences = props => {
  const form = useForm({
    defaultValues: {
      categories: []
    }
  });

  const {handleSubmit} = form;
  const navigate = useNavigate();

  const {experience_objToParams} = useUtil();

  const onSubmit = data => {
    let params = experience_objToParams(data);
    navigate(bases.searchUniqueExperience + '?' + params);
  }
  return (
    <div className={s.bigContainer}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Outlet/>
        </form>
      </FormProvider>
    </div>
  );
};


export default UniqueExperiences;