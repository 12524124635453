import React, {useEffect, useState} from 'react';
import s from './ReturnConfirmation.module.css';
import {useTranslation} from "react-i18next";
import {usePurchase} from "../../controllers/usePurchase";
import Loader from "../Loader";
import {Button, Table, Title} from "@mantine/core";
import CurrencyNumber from "../CurrencyNumber";
import {parseError} from "../ErrorManager/util";
import {RefundAmount} from "../../controllers/usePurchase/usePurchase.types";
import {ReactComponent as SuccessPurchaseSVG} from "../../resources/svg/success_purchase.svg";


const ConfirmModal = () => {
  const {t} = useTranslation();

  return (
    <div className="text-center">
      <Title order={3}>{t('return confirmation data.completed refund title')}</Title>
      <div className="w-full flex justify-center my-10">
        <SuccessPurchaseSVG/>
      </div>
      <div className="text-2xl">{t('return confirmation data.completed refund message')}</div>
    </div>
  );
}

interface Props {
  ordered_id: string,
  modal?: boolean,
  onSuccess: () => void
}

const ReturnConfirmation = ({ordered_id, onSuccess, modal = false}: Props) => {
  const {t} = useTranslation();
  const {getCancellationPolicies, confirmRefund} = usePurchase();

  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [refundAmounts, setRefundAmounts] = useState<RefundAmount[]>();
  const [returned, setReturned] = useState(false);
  const [refundLoading, setRefundLoading] = useState(false);

  const load = async () => {
    setLoading(true);
    try {
      const {total, refundAmounts} = await getCancellationPolicies({ordered_id});
      setTotal(total);
      setRefundAmounts(refundAmounts);
    } catch (error: any) {
      setLoading(false);
      alert(parseError(error));
    }
    setLoading(false);
  }

  useEffect(() => {
    load();
  }, []);


  const confirmReturnClicked = async () => {
    try {
      setRefundLoading(true)
      const {success} = await confirmRefund(ordered_id);
      if (success) {
        setReturned(true);
        onSuccess()
      }
    } catch (error: any) {
      alert(parseError(error));
    }
    setRefundLoading(false)
  }

  if (loading) return <Loader/>

  const totalRefund = refundAmounts?.reduce((acc, refundAmount) => acc + refundAmount.refundableAmount, 0) ?? 0;

  return (
    <div className={`${s.container} ${modal ? '' : s.containerNoModal}`}>
      {returned ? <ConfirmModal/> : <>
        <Title order={3}>{t('return confirmation data.title')}</Title>
        <Table>
          <thead>
          <tr>
            <th></th>
            <th>{t('return confirmation data.total')}</th>
            <th>{t('return confirmation data.to return')}</th>
            <th>{t('return confirmation data.rest')}</th>
          </tr>
          </thead>
          <tbody>
          {refundAmounts?.map((refundAmount, index) => (
            <tr key={index}>
              <td>{refundAmount.name || t(`return confirmation data.types.${refundAmount.type}`)}</td>
              <td><CurrencyNumber>{refundAmount.totalAmount}</CurrencyNumber></td>
              <td><CurrencyNumber>{refundAmount.refundableAmount}</CurrencyNumber></td>
              <td><CurrencyNumber>{refundAmount.totalAmount - refundAmount.refundableAmount}</CurrencyNumber></td>
            </tr>
          ))}
          <tr>
            <td className="font-bold">{t('return confirmation data.total')}</td>
            <td className="font-bold"><CurrencyNumber>{total}</CurrencyNumber></td>
            <td className="font-bold">
              <CurrencyNumber>{totalRefund}</CurrencyNumber>
            </td>
            <td className="font-bold">
              <CurrencyNumber>{total - totalRefund}</CurrencyNumber>
            </td>
          </tr>
          </tbody>
        </Table>

        <Button className="mt-10" loading={refundLoading} onClick={confirmReturnClicked}>
          {t('return confirmation data.confirm')}
        </Button>
        <p className="mt-10 text-xl">
          {t('return confirmation data.refund time')}
        </p>
      </>}
    </div>
  );
};


export default ReturnConfirmation;