import s from './ListFacilities.module.css';
import React from "react";
import {ReactComponent as BlackTick} from "../../../../../resources/svg/black_tick.svg";
import {FacilityGroup} from "../../../facilities/util";


interface ListFacilitiesProps {
  groups: FacilityGroup[]
}

export function ListFacilities({groups}: ListFacilitiesProps) {
  return <div className="flex flex-wrap justify-between">
    {groups.map(({name, facilities}, i) => (
      <div key={i} className="mb-10" style={{flexBasis: '50%'}}>
        {name && <h4 className={s.groupTitle}>{name}</h4>}
        <div className="flex flex-wrap flex-col mt-6">
          {facilities.map((facility, index) => (
            <div key={index} className={s.item}>
              {facility.icon ? <div className="mr-5">{facility.icon}</div> : <BlackTick className="mr-5"/>}

              {facility.description + ' '}
              {facility.value}
              {/*{!!facility.number && facility.number}
              {!!facility.distance && facility.distance}

              {!!facility.amount && facility.amount + ' '}
              {!!facility.currency && facility.currency}*/}
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>

}

export default ListFacilities;