import React, {useState} from 'react';
import s from './AuthPages.module.css';
import {Outlet, useNavigate} from "react-router-dom";
import bases from "../../routes/bases";

const AuthPages = props => {
  return (
    <div className={s.container}>
      <Outlet/>
    </div>
  );
};


export default AuthPages;
