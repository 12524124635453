// el conjunto de code y group puede emparejar con varios servicios
// por ejemplo, {code: 11, group: 80, description: 'Desayuno y cena'}

import {ServiceCodes} from "../../../types/hotels/serviceCodes";

export type ServiceCodesList = {
  facilities: { code: number, group: number }[];
  code: ServiceCodes;
}[]

export const serviceCodes: ServiceCodesList = [
  {
    facilities: [
      {code: 261, group: 60},
      {code: 100, group: 60}
    ],
    code: ServiceCodes.wifiOrInternet
  },
  {
    facilities: [
      {code: 261, group: 80},
    ],
    code: ServiceCodes.fullBoard
  },
  {
    facilities: [
      {code: 10, group: 70},
      {code: 170, group: 60},
      {code: 180, group: 60},
      {code: 575, group: 71},
    ],
    code: ServiceCodes.airConditioning
  },
  {
    facilities: [
      {code: 180, group: 73}
    ],
    code: ServiceCodes.casino
  },
  {
    facilities: [
      {code: 170, group: 72},
      {code: 575, group: 72}
    ],
    code: ServiceCodes.conferenceMeetingRoom
  },
  {
    facilities: [
      {code: 10, group: 70},
      {code: 10, group: 91},
      {code: 100, group: 91},
      {code: 105, group: 91},
    ],
    code: ServiceCodes.covidProtocol
  },
  {
    facilities: [{code: 170, group: 90}],
    code: ServiceCodes.waterSkiing
  },
  {
    facilities: [
      {code: 170, group: 80}, // desayuno tardio
      {code: 180, group: 80}, // desayuno caliente
      {code: 11, group: 80},
      {code: 12, group: 80},
      {code: 160, group: 80},
      {code: 200, group: 80}, // desayuno servido
    ],
    code: ServiceCodes.breakfast
  },
  {
    facilities: [{code: 12, group: 80}],
    code: ServiceCodes.lunch
  },
  {
    facilities: [{code: 11, group: 80}],
    code: ServiceCodes.dinner
  },
  {
    facilities: [{code: 180, group: 90}],
    code: ServiceCodes.jetSki
  },
  {
    facilities: [{code: 575, group: 70}],
    code: ServiceCodes.library
  },
  {
    facilities: [
      {code: 320, group: 70}
    ],
    code: ServiceCodes.parking
  },
  {
    facilities: [
      {code: 320, group: 90}
    ],
    code: ServiceCodes.mountainBiking
  },
  {
    facilities: [
      {code: 10, group: 60},
      {code: 11, group: 60}
    ],
    code: ServiceCodes.bathroom
  },
  {
    facilities: [{code: 1, group: 85}],
    code: ServiceCodes.lgbtiq
  },
  {
    facilities: [{code: 1, group: 91}],
    code: ServiceCodes.barceloClub
  },
  {
    facilities: [{code: 10, group: 40}],
    code: ServiceCodes.cityCenter
  },
  {
    facilities: [{code: 100, group: 10}],
    code: ServiceCodes.suites
  },
  {
    facilities: [{code: 100, group: 70}],
    code: ServiceCodes.supermarket
  },
  {
    facilities: [{code: 11, group: 70}],
    code: ServiceCodes.fireplace
  },
  {
    facilities: [{code: 110, group: 10}],
    code: ServiceCodes.apartment
  },
  {
    facilities: [
      {code: 110, group: 60},
      {code: 115, group: 60}
    ],
    code: ServiceCodes.kitchen
  },
  {
    facilities: [{code: 110, group: 70}],
    code: ServiceCodes.shop
  },
  {
    facilities: [{code: 12, group: 70}],
    code: ServiceCodes.smokeDetector
  },
  {
    facilities: [{code: 120, group: 10}],
    code: ServiceCodes.smokeDetector
  },
  {
    facilities: [
      {code: 120, group: 60}, // minibar
      {code: 130, group: 71}  // bar
    ],
    code: ServiceCodes.bar
  },
  {
    facilities: [{code: 120, group: 74}],
    code: ServiceCodes.hairdressingSalon
  },
  {
    facilities: [{code: 123, group: 10}],
    code: ServiceCodes.villas
  },
  {
    facilities: [
      {code: 124, group: 10} // Disability-friendly rooms
    ],
    code: ServiceCodes.disability
  },
  {
    facilities: [{code: 125, group: 70}],
    code: ServiceCodes.garden
  },
  {
    facilities: [{code: 132, group: 10}],
    code: ServiceCodes.twinRoom
  },
  {
    facilities: [{code: 126, group: 10}],
    code: ServiceCodes.tripleRoom
  },
  {
    facilities: [{code: 127, group: 10}],
    code: ServiceCodes.quadrupleRoom
  },
  {
    facilities: [{code: 128, group: 10}],
    code: ServiceCodes.executiveRooms
  },
  {
    facilities: [{code: 130, group: 40}],
    code: ServiceCodes.golf
  },
  {
    facilities: [{code: 130, group: 60}],
    code: ServiceCodes.fridge
  },
  {
    facilities: [
      {code: 135, group: 70},
      {code: 240, group: 60},
    ],
    code: ServiceCodes.terrace
  },
  {
    facilities: [{code: 230, group: 60}],
    code: ServiceCodes.balcony
  },
  {
    facilities: [{code: 145, group: 40}],
    code: ServiceCodes.trainStation
  },
  {
    facilities: [{code: 145, group: 60}],
    code: ServiceCodes.washingMachine
  },
  {
    facilities: [
      {code: 150, group: 61},
      {code: 150, group: 62},
    ],
    code: ServiceCodes.doubleBed
  },
  {
    facilities: [
      {code: 155, group: 61},
      {code: 155, group: 62},
    ],
    code: ServiceCodes.kingBed
  },
  {
    facilities: [{code: 150, group: 73}],
    code: ServiceCodes.nightClub
  },
  {
    facilities: [{code: 160, group: 20}],
    code: ServiceCodes.hostel
  },
  {
    facilities: [{code: 160, group: 40}],
    code: ServiceCodes.snowSki
  },
  {
    facilities: [{code: 160, group: 73}],
    code: ServiceCodes.theatre
  },
  {
    facilities: [{code: 160, group: 90}],
    code: ServiceCodes.bananaBoat
  },
  {
    facilities: [{code: 190, group: 73}],
    code: ServiceCodes.gameRoom
  },
  {
    facilities: [
      {code: 190, group: 60}, // calefaccion central
      {code: 195, group: 60}, // calefaccion individual
    ],
    code: ServiceCodes.heating
  },
  {
    facilities: [
      {code: 2, group: 85},
    ],
    code: ServiceCodes.nudist
  },
  {
    facilities: [{code: 20, group: 10}],
    code: ServiceCodes.yearOfConstruction
  },
  {
    facilities: [{code: 20, group: 40}],
    code: ServiceCodes.nearestBusStop
  },
  {
    facilities: [{code: 20, group: 60}],
    code: ServiceCodes.shower
  },
  {
    facilities: [{code: 200, group: 60}],
    code: ServiceCodes.safeBox
  },
  {
    facilities: [{code: 200, group: 71}],
    code: ServiceCodes.restaurant
  },
  {
    facilities: [{code: 200, group: 90}],
    code: ServiceCodes.diving
  },
  {
    facilities: [{code: 203, group: 85}],
    code: ServiceCodes.onlyAdults
  },
  {
    facilities: [{code: 210, group: 90}],
    code: ServiceCodes.windSurfing
  },
  {
    facilities: [{code: 220, group: 71}],
    code: ServiceCodes.nonSmokingArea
  },
  {
    facilities: [{code: 225, group: 71}],
    code: ServiceCodes.smokingArea
  },
  {
    facilities: [{code: 240, group: 80}],
    code: ServiceCodes.noAlcoholIsServed
  },
]