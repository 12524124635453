import React, {useEffect, useState} from 'react';
import s from './UniqueExperienceRequestBudged.module.css';
import {useTranslation} from "react-i18next";
import {FormProvider, useForm, useFormContext} from "react-hook-form";
import Button from "../../Inputs/Button/Button";
import HotelPassengerNumberStartYourTrip from "../../Hotels/HotelPassengerNumberStartYourTrip";
import {ReactComponent as DeleteSVG} from "../../../resources/svg/delete_small_circle.svg";
import {ReactComponent as RoundPlusSVG} from "../../../resources/svg/round_plus.svg";
import UEBOriginDate from "./UEBOriginDate";
import UEBDistribution from "./UEBDistribution";
import UEBInsurance from "./UEBInsurance";
import UEBContact from "./UEBContact";
import UEBPackage from "./UEBPackage";
import {useUniqueExperiences} from "../../../controllers/useUniqueExperiences";
import ShowErrors from "../../ErrorManager/ShowErrors";

const Distribution = () => {
  const {t} = useTranslation();

  const [roomsNumber, setRoomsNumber] = useState([0]);

  const form = useFormContext();

  const {setValue, watch, register, unregister} = form;
  const addRoom = e => {
    e.preventDefault();
    setRoomsNumber(prev => [...prev, (prev[prev.length - 1] + 1) || 0]);
  }

  const removeRoom = (e, id) => {
    e.preventDefault();
    setRoomsNumber(prev => prev.filter(i => i !== id));
    unregister(`room_distribution_${id}`);
    unregister(`hotel_passenger_number_${id}`);
  }


  return <div>
    <div className={s.title}>{t('experience_details.rbd.distribution')}</div>
    <table className={s.table}>
      <thead>
      <tr>
        <th>{t('start your trip.room')}</th>
        <th>{t('start your trip.distribution')}</th>
      </tr>
      </thead>
      <tbody>
      {roomsNumber.map((id, i) =>
        <tr>
          <td className={s.table_room_td}>
            <div>{i + 1}</div>
          </td>
          <td>
            <HotelPassengerNumberStartYourTrip style={{zIndex: 100 - i}} name={`hotel_passenger_number_${id}`}
                                               id="hotel_passenger_number"
                                               label={t('hotel.persons')}/>
          </td>
          {roomsNumber.length > 1 &&
            <td><DeleteSVG className={s.delete_svg} width={34} height={34} onClick={e => removeRoom(e, id)}/></td>}
        </tr>
      )}
      </tbody>
    </table>

    <div className="w-full flex cursor-pointer" onClick={e => addRoom(e)}>
      <RoundPlusSVG style={{fill: '#1C7263'}} className="mx-5"/>
      <div className="text-2xl" style={{color: '#1C7263'}}>{t('start your trip.add room')}</div>
    </div>
  </div>

}

const UniqueExperienceRequestBudged = ({experience, hotels, activities}) => {
  const {t} = useTranslation();


  const {requestBudget} = useUniqueExperiences();

  const [error, setError] = useState(null);

  const form = useForm({
    defaultValues: {}
  });
  const [pos, setPos] = useState(0);

  const steps = [
    UEBOriginDate, UEBDistribution, UEBPackage, UEBInsurance, UEBContact
  ];

  const {handleSubmit} = form;

  useEffect(() => {
    document.body.classList.add(s.body);
    return () => document.body.classList.remove(s.body);
  }, []);

  const onSubmit = async data => {

    let distribution = [];
    let i = 0;
    while (data[`passenger_number_${i}`]) {
      let adults = data[`passenger_number_${i}`].find(a => a.name === 'adults').value;
      let children = data[`passenger_number_${i}`].find(a => a.name === 'children').value;
      let r = {adults, children, child_age: []};
      if (data[`child_age_${i}`]) r.child_age.push(data[`child_age_${i}`]);
      distribution.push(r);
      i++;
    }


    let body = {
      experience_id: experience._id,
      date_from: data.date_from.toISOString(),
      describe_your_group: data.describe_your_group,
      others: data.others,
      email: data.email,
      whatsapp: data.whatsapp,
      insurance: data.insurance_type,
      origin: data.origin.raw,
      package_id: data.selected_package,
      distribution
    };

    const {error, success} = await requestBudget({body});
    if (error) return setError(error);
    if (success) return alert('gracias!');

  }

  useEffect(() => {
    if (Array.isArray(experience.packages)) form.setValue('selected_package', experience.packages[0]._id);
  }, [experience]);

  let Step = steps[pos];

  return (
    <>
      <div className={s.container}>
        <FormProvider {...form}>
          {steps.map((Step, i) => <div key={i} className={pos === i ? '' : s.el_hidden}>
            <Step key={i}
                  experience={experience}
                  hotels={hotels}
                  activities={activities}
            />
          </div>)}

          <ShowErrors errors={error}/>

          <div className="flex justify-center mt-20">
            {pos > 0 && <div className={`${s.button} mr-10`}>
              <Button onClick={() => setPos(prev => prev - 1)}>{t('prev')}</Button>
            </div>}

            {pos < steps.length - 1 && <div className={s.button}>
              <Button onClick={e => setPos(prev => prev + 1)}>{t('next')}</Button>
            </div>}

            {pos + 1 === steps.length && <div className={s.button}>
              <Button onClick={() => onSubmit(form.getValues())}>{t('finish')}</Button>
            </div>}
          </div>
        </FormProvider>
      </div>
      <div className={s.steps_container}>
        {Array.from(Array(steps.length).keys()).map(v => <div className={`${v === pos ? s.step_on : s.step_ball}`}/>)}
      </div>
    </>

  );
};


export default UniqueExperienceRequestBudged;