import {query} from "../../helpers/query";
import {PaymentData} from "../../types/payment";
import {CheckRatesResult} from "../../types/hotels/hotels";
import {ErrorType} from "../../components/ErrorManager/models";
import {
  ConfirmRefundResponse,
  CreatePaymentIntentProps,
  CreatePaymentIntentReturn,
  GetCancellationPoliciesResponse
} from "./usePurchase.types";


export const usePurchase = () => {
  // throws
  const createPaymentIntent = async ({
                                       products,
                                       destinationsISO2Codes
                                     }: CreatePaymentIntentProps): Promise<CreatePaymentIntentReturn> => {
    // products will contain hotels and activities objects
    // see createProductsForPaymentIntent function for more info
    const {error, data} = await query('/api/p/intent', {...products, destinationsISO2Codes}, 'POST');

    if (error) throw error;

    return {
      payment_id: data?.payment_id, // intent.id
      client_secret: data?.client_secret,
      order_id: data?.order_id,
      hotelUpdatedRates: data?.hotelUpdatedRates,
    };
  }
  // throws
  const updatePaymentIntent = async ({order, payment_id, order_id}: {
    order: PaymentData,
    payment_id: string,
    order_id: string
  }): Promise<{ success: boolean, hotelUpdatedRates: CheckRatesResult[] }> => {
    const {error, data} = await query('/api/p/update_intent', {
      order,
      payment_id,
      order_id
    }, 'POST');
    if (error) throw error;
    return {hotelUpdatedRates: data?.hotelUpdatedRates, success: data?.success};
  }

  const getCancellationPolicies = async ({ordered_id}: {
    ordered_id: string
  }): Promise<GetCancellationPoliciesResponse> => {
    const {error, data} = await query('/api/p/cancellation_policies', {ordered_id}, 'POST');
    if (error) {
      throw error
    }
    return data;
  }

  const confirmRefund = async (ordered_id: string): Promise<ConfirmRefundResponse> => {
    const {error, data} = await query(`/api/p/confirm_refund`, {ordered_id}, 'POST');
    if (error) {
      throw error
    }
    return data
  }

  const getPurchaseStatus = async ({order_id}: { order_id: string }): Promise<{
    loading: boolean,
    success: boolean,
    errors: ErrorType[],
    tracking_number?: string;
  }> => {
    const {error, data} = await query(`/api/p/status`, {order_id}, 'POST');
    if (error) throw error
    return data;
  }

  const confirmPaymentForDebug = async (order_id: string) => {
    const {error, data} = await query(`/api/p/test_confirm`, {order_id}, 'POST');
    return {success: data?.success};
  }

  return {
    createPaymentIntent,
    updatePaymentIntent,
    getCancellationPolicies,
    confirmRefund,
    getPurchaseStatus,
    confirmPaymentForDebug
  };
}

export default usePurchase;
