import {Hotel, HotelRoomWithAllServices} from "../../../../../types/hotels/hotels";
import s from "./HotelDetailsRoomsTable.module.css";
import React, {useEffect} from "react";
import HotelImage from "../../../HotelImage/HotelImage";
import {useTranslation} from "react-i18next";
import HotelDetailsRoomStays from "../HotelDetailsRoomStays";
import {firstCapitalize} from "../../../../../helpers/util";
import {useImages} from "../../../../../hooks/useImages";
import {doesRoomProvideMoreInfo} from "../../../util";

interface Props {
  hotel: Hotel,
  room: HotelRoomWithAllServices
  setSelectedRoom: (room: HotelRoomWithAllServices) => void;
}


export const RoomTypeWithImage = ({room, hotel, setSelectedRoom}: Props) => {
  const {t} = useTranslation();

  const filterImagesByRoomCode = (image: Hotel['images'][number]) => image.roomCode === room.code;
  const filterImagesByRoomType = (image: Hotel['images'][number]) => image.roomType === room.roomType;

  let filteredImages = hotel?.images?.filter(filterImagesByRoomCode);
  if (filteredImages?.length === 0) {
    filteredImages = hotel?.images?.filter(filterImagesByRoomType);
  }
  const {images, setImages, onFailed} = useImages({
    images: filteredImages
  })

  useEffect(() => {
    let images = hotel?.images?.filter(filterImagesByRoomCode)
    if (images?.length === 0) {
      images = hotel?.images?.filter(filterImagesByRoomType)
    }
    setImages(images)
  }, [setImages, hotel]);

  return <>

    <div className={s.roomImageContainer}>
      <div className={s.roomImage}>
        <HotelImage
          hotel={hotel}
          onFailed={() => onFailed(0)}
          imagePath={images[0]?.path}
          alt={room.name}
          style={{height: '150px'}}
        />
        <div className={s.roomName}>{firstCapitalize(room.name)}</div>
        <div className="pl-5"><HotelDetailsRoomStays room={room} hotel={hotel}/></div>

        {doesRoomProvideMoreInfo(hotel, room) && <div className={s.showServices}
                                                      onClick={() => setSelectedRoom(room)}>{t('hotel_rooms.more info')}</div>}
      </div>
    </div>
  </>
}