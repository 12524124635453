import img_acuaticas from "../../resources/images/activities/acuaticas.jpeg";
import img_pareja from "../../resources/images/activities/pareja.jpg";
import img_historia from "../../resources/images/activities/history.png";
import img_aventura_campo from "../../resources/images/activities/adventure.jpg";
import img_ocio from "../../resources/images/activities/leisure.jpg";
import img_voluntariado from "../../resources/images/activities/volunteering.png";
import img_ski from "../../resources/images/activities/skiing.png";
import img_fauna from "../../resources/images/activities/fauna.jpg";
import img_flora from "../../resources/images/activities/nature.png";
import {t} from "i18next";


export interface ActivityCategory {
  id: string;
  label: string;
  img: string;
  description?: string;
}

export const getActivityCategories = () => [
  {id: 'aquatic', label: t('syt_activities.acuaticas'), img: img_acuaticas, description: 'Emocionantes Aventuras Acuáticas: Descubre la Pasión del Mar con Nuestros Servicios Exclusivos'},
  {id: 'partner', label: t('syt_activities.pareja'), img: img_pareja, description: 'Descubre experiencias románticas diseñadas especialmente para parejas aventureras y enamoradas'},
  {id: 'history', label: t('syt_activities.historia'), img: img_historia, description: 'Sumérgete en la fascinante historia de nuestra agencia de viajes, donde cada destino cuenta su propia historia esperando ser explorada por ti.'},
  {id: 'adventure', label: t('syt_activities.aventura'), img: img_aventura_campo},
  {id: 'leisure', label: t('syt_activities.ocio'), img: img_ocio},
  {id: 'volunteering', label: t('syt_activities.voluntariado'), img: img_voluntariado},
  {id: 'ski', label: t('syt_activities.ski'), img: img_ski},
  {id: 'fauna', label: t('syt_activities.fauna'), img: img_fauna},
  {id: 'flora', label: t('syt_activities.flora'), img: img_flora},
]