import {query} from "../../helpers/query";
import {CheckRatesResult, HotelForPurchaseRoom} from "../../types/hotels/hotels";
import {useCache} from "../useCache";
import {UseHotelTypes} from "./models";
import {formatHotelFromServer} from "./util";
import {useEffect} from "react";

export const useHotel = () => {
  const [types, setTypes] = useCache({
    key: 'hotel_types', defaultValue: false
  }) //as [UseHotelTypes, (types: UseHotelTypes) => any];
  const [boards, setBoards] = useCache({
    key: 'hotel_boards',
    defaultValue: false
  });
  const [chains, setChains] = useCache({
    key: 'hotel_chains',
    defaultValue: false
  });


  const searchHotelsBar = async ({q, allowHotels = true, signal}: {
    q: string,
    allowHotels: boolean,
    signal: AbortSignal
  }) => {
    const {error, data} = await query('/api/sb/hotels', {q, allowHotels}, 'POST', {signal});
    return {error, results: data?.results};
  }

  const searchHotels = async ({body, offset = 0, limit = 12, filters, sort}: {
    body: any,
    offset: number,
    limit: number,
    filters: any,
    sort: string
  }) => {
    let request;

    if (filters) body.filters = filters;

    if (!filters) {
      request = await query(`/api/s/hotels?offset=${offset}&limit=${limit}&sort=${sort}`, body);
    } else {
      request = await query(`/api/s/hotels/filter?offset=${offset}&limit=${limit}&sort=${sort}`, body);
    }

    const {error, data} = request;
    let hotels = data?.hotels;
    const types = await getTypes();

    if (Array.isArray(hotels)) {

      hotels = hotels.map((hotel: any) => {
        if (types) {
          return formatHotelFromServer(hotel, types);
        } else {
          return hotel;
        }
      });
    }
    //hotels = hotels.filter((hotel: any) => hotel.rooms?.length > 0);

    return {error, hotels, filters: data?.filters, total: data?.total};
  }

  const getTypes = async ({renew = false} = {}): Promise<UseHotelTypes | undefined> => {
    if (!types || types?.commission === undefined) {
      const {error, data} = await query('/api/hotel/types', false, 'POST');
      if (error) return;
      const result = {...(data?.types ?? {}), commission: data?.commission} as UseHotelTypes;
      setTypes(result);
      return result
    }
    return types as UseHotelTypes;
  }

  const getBoards = async () => {
    if (!boards) {
      const {error, data} = await query('/api/hotel/types/boards', {});
      if (error) return {error};
      setBoards(data?.boards);
      return {error, boards: data?.boards};
    }
    return {error: false, boards};
  }
  const getChains = async () => {
    if (!chains) {
      const {error, data} = await query('/api/hotel/types/chains', {});
      if (error) return {error};
      setChains(data?.chains);
      return {error, chains: data?.chains};
    }
    return {error: false, chains};
  }
  const getHotel = async (_hotel: any, raw = false) => {
    let body = raw ? _hotel : [_hotel];
    const {error, hotels} = await getHotels(body, raw);
    if (error) return {error};
    let hotel = hotels[0];

    const types = await getTypes();

    const formattedHotel = formatHotelFromServer(hotel, types!);

    return {error, hotel: formattedHotel}
  }

  const getHotels = async (_hotels: any, raw = false) => {
    // temporal fix
    if (raw) _hotels.hotels = [{code: _hotels.code || _hotels.id, api: _hotels.api}];

    const {error, data} = await query(`/api/hotel`, raw ? _hotels : {hotels: _hotels}, 'POST');
    if (error) return {error};
    let hotels = data?.hotels;

    const types = await getTypes();

    if (Array.isArray(hotels)) hotels = hotels.map((hotel: any) => formatHotelFromServer(hotel, types!));
    return {hotels};
  }

  const getHotelsByCode = async (_hotels: any[]) => {
    let hotels = [];
    for (let i = 0; i < _hotels.length; i++) {
      const {error, data} = await query(`/api/hotel/code`, _hotels[i], 'POST');
      if (error) return {error};
      hotels.push(data?.hotel);
    }
    return {hotels};
  }

  const getRawHotelsByCode = async (codes: number[], api: string) => {
    const {error, data} = await query(`/api/raw_hotels`, {codes, api}, 'POST');
    if (error) return {error};
    return {hotels: data?.hotels};
  }

  const likeHotel = async (hotel: { code: number, api: string }) => {
    const {error, data} = await query(`/api/user/like`, {
      code: hotel.code,
      type: 'hotel',
      api: hotel.api
    });

    return {error, success: data?.success};
  }
  const dislikeHotel = async (hotel: { code: number, api: string }) => {
    const {error, data} = await query('/api/user/like', {
      code: hotel.code,
      type: 'hotel',
      api: hotel.api
    }, 'DELETE');
    return {error, success: data?.success};
  }


  const getImageTypeDescription = ({code, api = 'apitude'}: { code: string, api: string }) => {
    if (!types?.image) return '';
    let a = types?.imageTypes[api].find((type: any) => type.code === code)?.description || '';
    return a;
  }

  const checkRates = async ({rooms}: { rooms: HotelForPurchaseRoom[] }) => {
    let {error, data} = await query('/api/hotel/check_rates', {hotels: [{api: 'apitude', rooms}]});
    // data might be [{error: ...}]
    return {error, data: data as CheckRatesResult[]};
  }

  const myProfileFutureHotels = async () => {
    const {error, data} = await query('/api/user/hotel', {type: 'future'}, 'POST');
    return {error, hotels: data?.hotels};
  }

  const myProfilePastHotels = async () => {
    const {error, data} = await query('/api/user/hotel', {type: 'past'}, 'POST');
    return {error, hotels: data?.hotels};
  }

  const myProfileRefundedHotels = async () => {
    const {error, data} = await query('/api/user/hotel', {type: 'refunded'}, 'POST');
    return {error, hotels: data?.hotels};
  }

  const purchasedHotelDetails = async ({code, api = 'apitude', ordered_id}: {
    code: string,
    api: string,
    ordered_id: string
  }) => {
    const {error, data} = await query(`/api/user/hotel/${ordered_id}`, {code, api}, 'POST');

    const types = await getTypes();

    let hotel = data?.hotel;
    if (hotel) {
      hotel = formatHotelFromServer(hotel, types!, false);
    }

    return {error, hotel};
  }


  const downloadVoucher = async ({title, code, ordered_id, api}: {
    title: string,
    code: string,
    ordered_id: string,
    api: string
  }) => {
    let r = await fetch('/api/user/hotel_voucher', {
      method: 'POST',
      mode: 'cors',
      /*withCredentials: true,*/
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({code, ordered_id, api})
    });
    let blob = await r.blob();
    /*    downloadjs(blob, title);*/
    blob = blob.slice(0, blob.size, 'application/pdf');
    let fileURL = URL.createObjectURL(blob);
    window.open(fileURL);
  }

  useEffect(() => {
    getTypes({renew: true});
  }, []);


  return {
    searchHotelsBar,
    searchHotels,
    getBoards,
    getChains,
    getTypes,
    getHotels,
    getHotelsByCode,
    getHotel,
    likeHotel,
    dislikeHotel,
    getImageTypeDescription,
    checkRates,
    myProfileFutureHotels,
    myProfilePastHotels,
    purchasedHotelDetails,
    downloadVoucher,
    myProfileRefundedHotels,
    getRawHotelsByCode
  };
}

export default useHotel;
