import React, {useEffect, useState} from 'react';
import s from './CheckboxFilter.module.css';
import Checkbox from "../../../Inputs/Checkbox";
import {Controller} from "react-hook-form";
import Input from "../../../Inputs/Input";

const CheckboxFilter = ({
													onChange,
													filter = () => true,
													getData,
													defaultValue = {},
													name,
													canSearch = false,
													onError,
													key = 'code',
													label = 'description'
												}) => {
	const [data, setData] = useState(null);
	const [search, setSearch] = useState('');

	const download = async () => {
		const r = await getData();
		if (r.error) {
			onError && onError(r.error);
			return;
		}
		let data = r[name];
		setData(data.filter(filter));
	}
	const selectFilter = (obj, checked) => {
		onChange && onChange(obj, checked);
	}

	const formatLabel = label => {
		let a = label.toLowerCase();
		return a.charAt(0).toUpperCase() + a.slice(1);
	}

	useEffect(() => {
		if (!data) download();
	}, []);

	return (
		<div className="mt-7">
			{data &&
				<Controller
					name={name}
					defaultValue={defaultValue}
					render={({field: {onChange, value}}) => {
						return <div className={s.checksContainer}>
							{canSearch && <Input
								containerClassName={s.searchInput}
								placeholder="Buscar..."
								onChange={e => setSearch(e.target.value)}
							/>}
							{data.filter(obj => obj[label] ? obj[label].toLowerCase().includes(search.toLowerCase()) : false).map(obj => (
								<div key={obj[key]}>
									<Checkbox
										checked={value[obj[key]]}
										id={`checkbox_filter_${key}_${obj[key]}`}
										label={formatLabel(obj[label])}
										onChange={(e) => {
											onChange({
												...value,
												[obj[key]]: e.target.checked
											});
											selectFilter(obj, e.target.checked)
										}}
									/>
								</div>
							))}
						</div>
					}}
				/>
			}
		</div>
	);
};


export default CheckboxFilter;
