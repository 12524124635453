import React from 'react';
import sc from '../Checkout.module.css';
import {useTranslation} from "react-i18next";
import {parseError} from "../../ErrorManager/util";
import {ErrorStringType} from 'components/ErrorManager/models';


interface Props {
  errors: ErrorStringType[]
}

const ShowPurchaseErrors = ({errors}: Props) => {
  const {t} = useTranslation();

  if (!Array.isArray(errors) || errors.length === 0) return <></>;

  return (
    <div className={sc.box}>
      <h2>{t('checkout.fix errors')}</h2>
      <ul className="list-decimal ml-10">
        {errors.map((error, i) => <li key={i} className="text-3xl text-red-500">
          {parseError(error)}
        </li>)}
      </ul>
    </div>
  );
};


export default ShowPurchaseErrors;