import React, {useState} from 'react';
import s from './StartYourTripHotel.module.css';
import es from '../../StartYourTripRouter/StartYourTripRouter.module.css';
import {useTranslation} from "react-i18next";

import {useFormContext} from "react-hook-form";

import {ReactComponent as RoundPlusSVG} from "../../../../resources/svg/round_plus.svg";
import {ReactComponent as DeleteSVG} from "../../../../resources/svg/delete_small_circle.svg";
import HotelPassengerNumberStartYourTrip from "../../../Hotels/HotelPassengerNumberStartYourTrip";
import {Button} from "../../Inputs/Button/Button";
import {Rating} from "../../Inputs/Rating/Rating";
import CheckboxWithContainer from "../../Inputs/CheckboxWithContainer";
import cn from "classnames";

const StartYourTripHotel = ({onPrev, onNext}) => {
  const {t} = useTranslation();

  const [roomsNumber, setRoomsNumber] = useState([0]);

  const form = useFormContext();

  const {setValue, watch, register, unregister} = form;

  const need_home = watch('need_home');

  const addRoom = e => {
    e.preventDefault();
    setRoomsNumber(prev => [...prev, (prev[prev.length - 1] + 1) || 0]);
  }

  const removeRoom = (e, id) => {
    e.preventDefault();
    setRoomsNumber(prev => prev.filter(i => i !== id));
    unregister(`room_distribution_${id}`);
    unregister(`hotel_passenger_number_${id}`);
  }


  return (
    <div>
      <input type="checkbox" checked={need_home} className="invisible" {...register('need_home')} />
      <div className={es.title}>{t('start your trip.need hotel')}</div>
      <div className={es.subtitle}>{t('start your trip.need hotel subtitle')}</div>
      <div className="px-5 sm:px-0 my-20">
        <div className="flex flex-nowrap m-auto mt-20 mb-20" style={{maxWidth: 400}}>
          <Button className={`uppercase mr-10 ${need_home ? es.normal_button : ''}`}
                  onClick={e => {
                    e.preventDefault();
                    setValue('need_home', 0)
                  }}>{t('no')}</Button>

          <Button className={`uppercase ${need_home ? '' : es.normal_button}`}
                  onClick={e => {
                    e.preventDefault();
                    setValue('need_home', 1)
                  }}>{t('yes')}</Button>
        </div>
        {!!need_home &&
          <>

            <table className={s.table}>
              <thead>
              <tr>
                <th className={"hidden sm:table-cell"}>{t('start your trip.room')}</th>
                <th>{t('start your trip.distribution')}</th>
              </tr>
              </thead>
              <tbody>
              {roomsNumber.map((id, i) =>
                <tr key={i}>
                  <td className={s.table_room_td}>
                    <div>{i + 1}</div>
                  </td>
                  <td>
                    <HotelPassengerNumberStartYourTrip index={id} style={{zIndex: 100 - i}}
                                                       name={`hotel_passenger_number_${id}`}
                                                       id={`hotel_passenger_number_${id}`}
                                                       label={t('hotel.persons')}/>
                  </td>
                  {roomsNumber.length > 1 &&
                    <td><DeleteSVG className={s.delete_svg} width={34} height={34}
                                   onClick={e => removeRoom(e, id)}/>
                    </td>}
                </tr>
              )}
              <tr>
                <td colSpan={2}>
                  <div className="flex cursor-pointer" onClick={e => addRoom(e)}>
                    <RoundPlusSVG style={{fill: '#1C7263'}} className="mx-5"/>
                    <div className="text-2xl" style={{color: '#1C7263'}}>{t('start your trip.add room')}</div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>


            <div className="my-20">
              <div className={cn([es.label, 'pb-10'])}>{t('start your trip.choose your hotel type')}</div>
              <div className="flex flex-wrap md:flex-nowrap">
                <CheckboxWithContainer containerClassName="w-full p-20 md:mr-10 mb-10 md:mb-0"
                                       label={t('start your trip.hotel')}
                                       name="type.hotel"/>
                <CheckboxWithContainer containerClassName="w-full md:mr-10 mb-10 md:mb-0"
                                       label={t('start your trip.camping')}
                                       name="type.camping"/>
                <CheckboxWithContainer containerClassName="w-full md:mr-10 mb-10 md:mb-0"
                                       label={t('start your trip.apartment')}
                                       name="type.apartment"/>

                <CheckboxWithContainer containerClassName="w-full"
                                       label={t('start your trip.hostel')}
                                       name="type.hostel"/>

              </div>
            </div>

            <div className="my-20">
              <div className={cn([es.label, 'pb-10'])}>{t('start your trip.choose regime')}</div>
              <div className="flex flex-wrap md:flex-nowrap">
                <CheckboxWithContainer containerClassName="w-full md:mr-10 mb-10 md:mb-0"
                                       label={t('start your trip.all included')}
                                       name="regime.all_included"/>
                <CheckboxWithContainer containerClassName="w-full md:mr-10 mb-10 md:mb-0"
                                       label={t('start your trip.full board')}
                                       name="regime.full_board"/>
                <CheckboxWithContainer containerClassName="w-full md:mr-10 mb-10 md:mb-0"
                                       label={t('start your trip.half board')}
                                       name="regime.half_board"/>
                <CheckboxWithContainer containerClassName="w-full md:mr-10 mb-10 md:mb-0"
                                       label={t('start your trip.accommodation and breakfast')}
                                       name="regime.accommodation_and_breakfast"/>
                <CheckboxWithContainer containerClassName="w-full"
                                       label={t('start your trip.accommodation only')}
                                       name="regime.accommodation_only"/>
              </div>
            </div>
            <div className="mt-10">
              <div className={cn([es.label, 'pb-10'])}>{t('start your trip.minimum rating')}</div>
              <div className="w-full flex justify-center">
                <Rating name={"stars"}/>
              </div>
            </div>
          </>
        }</div>
      <div className="flex justify-center mt-20">
        <div className={`${es.button} mr-10`}>
          <Button className={es.back_button} onClick={e => onPrev(e)}>{t('prev')}</Button>
        </div>
        <div className={`${es.button}`}>
          <Button onClick={e => onNext(e)}>{t('next')}</Button>
        </div>
      </div>
    </div>
  );
};

export default StartYourTripHotel;
