import React from 'react';
import cs from '../CheckoutSummary.module.css';
import useCheckoutProvider from "../../../../providers/CheckoutProvider/useCheckoutProvider";
import {useTranslation} from "react-i18next";
import CurrencyNumber from "../../../CurrencyNumber";

const TotalSummary = props => {
  const {t} = useTranslation();
  const {hotels, activities, selectedInsurance} = useCheckoutProvider();


  let total = React.useMemo(() => {
    let total = 0;
    if (Array.isArray(activities)) {
      activities.forEach(activity => activity.selectedRates.forEach(rate => {
        total += rate.price * 1;
      }));
    }
    if (Array.isArray(hotels)) {
      hotels.forEach(hotel => hotel.rooms.forEach(room => total += room.rate.finalPrice + room.rate.taxes));
    }

    if (selectedInsurance?.price) total += selectedInsurance.price * 1;

    return total;
  }, [hotels, activities, selectedInsurance]);

  return (
    <div>
      <table>
        <tbody>
        <tr>
          <th className="uppercase">{t('checkout.total')}</th>
          <th className={cs.summary_table_price}><CurrencyNumber>{total}</CurrencyNumber>
          </th>
        </tr>
        </tbody>
      </table>
    </div>
  );
};


export default TotalSummary;
