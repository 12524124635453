import React, {useState} from 'react';
import s from './VerifyPasswordPage.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate, useSearchParams} from "react-router-dom";
import useAuth from "../../providers/AuthProvider/useAuth";
import {useForm} from "react-hook-form";
import Title from "../../components/TextComponents/Title";
import ShowErrors from "../../components/ErrorManager/ShowErrors";
import Button from "../../components/Inputs/Button/Button";
import bases from "../../routes/bases";
import MiddleText from "../../components/TextComponents/MiddleText";
import SmallText from "../../components/TextComponents/SmallText";
import PasswordInput from "../../components/Inputs/PasswordInput";
import Header from "../../components/Header/Header";
import logoSrc from "../../resources/images/logo/logo_text.png";
import {Footer} from "../../components/FooterV2/Footer";


const VerifyPasswordPage = props => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {verifyPasswordRecovery} = useAuth();
  const [params] = useSearchParams();

  const token = params.get('token');
  const id = params.get('id');

  const {register, handleSubmit, formState: {errors, isSubmitting}, setError, clearErrors} = useForm();

  const [success, setSuccess] = useState(false);


  const onSubmit = async data => {
    if (data.password !== data.password2) {
      return setError('server', {
        type: 'manual',
        message: 'account.passwordsDontMatch'
      });
    }

    const {error, success} = await verifyPasswordRecovery({
      token,
      user_id: id,
      password: data.password
    });
    if (error || !success) return setError('server', {
      type: 'manual',
      message: error?.message
    });
    setSuccess(true);
  }

  return (
    <>
      <Header/>
      <div style={{background: '#eff4f2'}}>
        <div className={s.bigContainer}>
          <div className="w-full mx-auto z-10 pt-5 px-10 sm:px-0" style={{maxWidth: 600}}>
            <div className={s.formContainer}>
              <img src={logoSrc} className="m-auto mb-20" alt="Logo" width={300}/>
              <Title className={s.titleLogin}>{t("resetPassword.title")}</Title>
              {!success ? (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <PasswordInput
                      placeholder={t("password")}
                      {...register('password', {
                        required: true
                      })}
                      containerClassName="mb-10"
                    />
                    <PasswordInput
                      placeholder={t("repeat password")}
                      {...register('password2', {
                        required: true
                      })}
                    />

                    <div className="mt-10">
                      <ShowErrors defaultStyle={true} onlyOne={true} errors={errors?.server?.message}/>
                    </div>
                    <div className="mt-10 mx-auto" style={{maxWidth: 300}}>
                      <Button onClick={() => clearErrors('server')}
                              loading={isSubmitting}>{t("resetPassword.button")}</Button>
                    </div>
                  </form>
                ) :
                <MiddleText>
                  {t("password change done")}
                  <br/><br/>
                  <span className="underline cursor-pointer"
                        onClick={() => navigate(bases.login)}>{t('initiate a session')}</span>
                </MiddleText>
              }
            </div>

            <div className="w-11/12 lg:w-10/12 flex flex-wrap justify-between m-auto">
              <SmallText className="underline">{t("terms and conditions")}</SmallText>
              <SmallText>{t("copyright trippy")}</SmallText>
            </div>
          </div>

          <div className={s.bannerContainer}>
            <div className={s.banner}>
              <div className={s.bannerText}>{t('login.bannerText')}</div>
              <div className={s.bannerQuote}>{t('login.bannerQuote')}</div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};


export default VerifyPasswordPage;
