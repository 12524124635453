const routes = {
  main: '/m',
  auth: '/a',
  login: `/a/login`,
  register: '/a/register',
  search: '/s',
  resetPassword: '/a/reset_password',
  verifyPassword: '/verify_password',
  searchHotels: '/s/hotels',
  profile_router: '/p',
  profile_edit: '/p/my_profile_edit',
  chat: '/p/chat',
  profile_likes: '/p/likes',
  profile_hotels: '/p/hotels',
  profile_activities: '/p/activities',
  profile_insurances: '/p/insurances',


  mainHotels: '/m/hotels',

  trippy_members: '/tpm',

  // build your trip
  btrip: '/m/btrip',
  btrip_step1: '/m/btrip',
  btrip_step2: '/m/btrip/2',
  btrip_step3: '/m/btrip/3',

  // start your trip
  startYourTripRouter: '/m/syt',
  startYourTrip_activity: '/syp/activity',
  startYourTrip_map: '/syp/map',
  startYourTrip_insurance: '/syp/insurance',
  startYourTrip_contact: '/syp/contact',

  // unique experience prepare to search
  uniqueExperienceSelectCategories: '/m/unique',
  uniqueExperienceSelectCountries: '/m/unique/countries',
  uniqueExperienceBeforeSearch: '/m/unique/s_data',

  // activities
  searchActivity: '/s/activities',
  searchUniqueExperience: '/s/unique',

  // tour operators
  tourOperator: '/to',

  searchFlight: '/s/flights',


  contact_us: '/contact_us',


  // details
  hotelDetails: '/i/hotel',
  activityDetails: '/i/activity',
  flightDetails: '/i/flight',
  uniqueExperienceDetails: '/i/unique_experience',


  // purchased items details
  purchasedHotel: '/pd/hotel',

  checkout: '/p/checkout',

  terms: '/terms'
};

export default routes;
