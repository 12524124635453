import React from 'react';
import s from './HeaderFooterRouter.module.css';
import Header from "../Header/Header";
import {Outlet} from "react-router-dom";
import Footer from "../Footer";

const HeaderFooterRouter = props => {
    return (
      <div>
          <Header/>
          <Outlet/>
          <Footer/>
      </div>
    );
};


export default HeaderFooterRouter;