import React, {useEffect, useState} from 'react';
import s from './ContactUs.module.css';
import Header from "../Header/Header";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import Button from "../Inputs/Button/Button";
import {query} from "../../helpers/query";
import {Footer} from "../FooterV2/Footer";

const ContactUs = () => {
  const {t} = useTranslation();

  const [done, setDone] = useState(false);

  const form = useForm();
  const {handleSubmit, register, formState} = form;
  const {isSubmitting} = formState;

  useEffect(() => {
    document.body.classList.add(s.body);
    return () => document.body.classList.remove(s.body);
  }, []);


  const onSubmit = async data => {
    let r = await query('/api/contact_us', data, 'POST');
    console.log(r);
    setDone(true);
  }

  return (
    <>
      <div className="min-h-screen">
        <Header/>
        <div className={s.container}>

          <h1>{t('contact us.title')}</h1>
          <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={s.form_container}>
                {!done && <>

                  <h2>{t('contact us.small title')}</h2>
                  <input {...register('name')} placeholder={t('name and surname')}/>
                  <input {...register('email')} placeholder={t('email')} type="email"/>
                  <input {...register('subject')} placeholder={t('contact us.subject')}/>
                  <textarea {...register('message')} rows={5} placeholder={t('contact us.message')}/>
                  <div className="w-full flex justify-end">
                    <div className="w-72">
                      <Button loading={isSubmitting}>{t('send')}</Button>
                    </div>
                  </div>
                </>}
                {done && <h2>{t('contact us.done')}</h2>}
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
      <Footer/>
    </>
  );
};


export default ContactUs;
