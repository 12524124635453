import React, {useEffect, useState} from 'react';
//import themes from '../../helpers/themes';
import {ChatContext} from './ChatContext';
import {io} from 'socket.io-client';
import useAuth from "../AuthProvider/useAuth";
import {query} from '../../helpers/query';

const URL = 'http://localhost';
let socket = false;

const ChatProvider = (props) => {
  const {getUser} = useAuth();

  const [chatList, setChatList] = useState([]);
  const [messages, setMessages] = useState([]);


  useEffect(() => {
    return;
    socket = io(URL, {
      autoConnect: false,
      withCredentials: true,
      path: '/api/socket.io',
      secure: true
    });
    socket.on('new_message', message => {
      newMessageReceived(message);
    });
  }, []);

  useEffect(() => {
    if (typeof socket !== 'object') return;
    socket.connect();

  }, [socket]);


  const newChat = (data, callback) => {
    socket.emit('new_chat', data, (chat) => {

    });
  }
  const newMessage = (data, callback = () => 0) => {
    data.type = data.type || 'text';
    data.chat_id = data.chat_id || findSelectedChat().chat_id;

    socket.emit('new_message', data, result => {
      if (!result.error && result.message) newMessageReceived(result.message);
      callback(result);
    });
  }

  const listChats = async () => {
    const {error, data} = await query('/api/chat', false, 'GET');
    return {error, chats: data?.chats};
  }
  const listMessages = async (chat_id) => {
    chat_id = chat_id || findSelectedChat().chat_id;
    if (!chat_id) return [];
    const {error, data} = await query(`/api/chat/${chat_id}/message`, false, 'GET');
    return {error, messages: data?.messages};
  }

  const findSelectedChat = () => chatList.find(chat => chat.selected);


  const isChatSelected = () => !!chatList.find(chat => chat.selected);


  const newMessageReceived = message => {
    setMessages(prev => [...prev, message]);
  }

  return (
    <ChatContext.Provider
      value={{
        socket,
        newChat, newMessage, listChats, listMessages, setMessages, messages,
        chatList, setChatList,
        isChatSelected, findSelectedChat
      }}>
      {props.children}
    </ChatContext.Provider>
  );

};

export default ChatProvider;
