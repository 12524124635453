import React from 'react';

const ArrowIcon = ({width,height}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.47141 0.52827C9.73176 0.788619 9.73176 1.21073 9.47141 1.47108L5.47141 5.47108C5.21106 5.73143 4.78895 5.73143 4.5286 5.47108L0.528598 1.47108C0.268248 1.21073 0.268248 0.788619 0.528598 0.52827C0.788947 0.26792 1.21106 0.26792 1.47141 0.52827L5 4.05687L8.5286 0.52827C8.78895 0.26792 9.21106 0.26792 9.47141 0.52827Z" fill="black"/>
    </svg>
  );
};


export default ArrowIcon;
