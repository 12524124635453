import React from 'react';
import s from './SelectInsurance.module.css';
import {useTranslation} from "react-i18next";
import useCheckoutProvider from "../../providers/CheckoutProvider/useCheckoutProvider";
import CurrencyNumber from "../CurrencyNumber";

import {ReactComponent as SelectedSVG} from "../../resources/svg/selected_small_circle.svg";
import {Police} from "../../types/insurance";
import ShowErrors from "../ErrorManager/ShowErrors";
import cn from "classnames";
import {Loader, Popover} from "@mantine/core";
import {CiCircleInfo} from "react-icons/ci";
import {useInsurance} from "../../controllers/useInsurance";
import {BsCloudDownload} from 'react-icons/bs';


interface SelectInsuranceProps {
  loading: boolean,
  error: string | undefined
  insurances: Police[],
}

const SelectInsurance = ({loading, error, insurances}: SelectInsuranceProps) => {
  const {t} = useTranslation();
  const [showDocuments, setShowDocuments] = React.useState<Record<string, boolean>>({});
  const [documentLoading, setDocumentLoading] = React.useState<Record<string, boolean>>({});

  const {
    selectedInsurance,
    setSelectedInsurance
  } = useCheckoutProvider();

  const {downloadDocument} = useInsurance()


  const onInsuranceSelect = (insurance: Police) => {
    setSelectedInsurance(prev => {
      if (prev && prev.id === insurance.id) return null;
      return insurance;
    });
  }

  const downloadDocumentClicked = async (insurance_id: number, document_id: number) => {
    setDocumentLoading(prev => ({...prev, [insurance_id]: true}))
    setShowDocuments({})
    try {
      await downloadDocument({document_id: String(document_id)})
    } catch (error) {
      alert(t('errors.unexpected error'))
    }
    setDocumentLoading(prev => ({...prev, [insurance_id]: false}))
  }

  if (error) return <ShowErrors errors={error} onlyOne={true}/>
  if (loading) return <>
    <h2>{t('insurance page.loading')}</h2>
    <Loader/>
  </>

  return (
    <>
      <h2>{t('insurance page.title')}</h2>
      <div className="flex items-center justify-center gap-20">
        {insurances.map(insurance => <div
          key={insurance.id}
          onMouseLeave={
            () => setShowDocuments(prev => ({...prev, [insurance.id]: false}))
          }
          className={cn(s.container, {[s.selectedContainer]: insurance.id === selectedInsurance?.id})}
          onClick={() => onInsuranceSelect(insurance)}>
          <div className={`${selectedInsurance?.id === insurance.id ? s.selected : s.not_selected}`}>
            <SelectedSVG/>
          </div>
          {insurance.documents && <div className={s.showDocumentsCircleContainer}>
            <Popover
              opened={showDocuments[insurance.id]}
              withinPortal={false}
              trapFocus={false}
              className={"cursor-default"}
              spacing={0}
              width={250}
              onClose={() => setShowDocuments(prev => ({...prev, [insurance.id]: false}))}
              target={
                documentLoading[insurance.id] ? <Loader color="teal" size="sm"/> : <div
                  onMouseEnter={(e) => {
                    setShowDocuments(prev => ({...prev, [insurance.id]: true}))
                  }}
                  className={s.showDocumentsCircle}><CiCircleInfo size={25}/></div>
              }>
              <div className={s.documentsContainer}>
                <div className={s.insuranceDocumentsTitle}>
                  <CiCircleInfo size={22} className="pr-2"/>{t('insurance page.documents')}
                </div>
                <div className="p-8">
                  {insurance.documents.map(document => <div key={document.id} className={s.document}
                                                            onClick={() => downloadDocumentClicked(insurance.id, document.id)}>
                    <BsCloudDownload size={18} className="pr-2"/>
                    {document.name}
                  </div>)}
                </div>
              </div>
            </Popover>
          </div>}

          <div className={s.background}>
            <img src={insurance.background} alt="insurance background"/>
          </div>
          {/*<div className={s.logo}>*/}
          {/*  <img src={insurance.logo} alt="Insurance logo"/>*/}
          {/*</div>*/}
          <div className={s.infoContainer}>
            <div className={s.name}>{insurance.name}</div>
            <div className={s.price}><CurrencyNumber>{insurance.price}</CurrencyNumber></div>
          </div>
        </div>)}
      </div>
    </>
  );
};


export default SelectInsurance;