import s from './HotelRoomDetails.module.css';
import {Hotel, HotelRoomWithAllServices, Image} from "../../../types/hotels/hotels";
import React, {useState} from "react";
import HotelImage from "../HotelImage/HotelImage";
import {buildImagePath, HotelImageSize} from "../util";
import {ShowRoomServices} from "../HotelDetails/components/HotelDetailsRoomsTable/ShowRoomServices";
import GalleryPopup from "../../GalleryPopup";
import {useHotel} from "../../../controllers/useHotel";
import {useImages} from "../../../hooks/useImages";

interface HotelRoomProps {
  hotel: Hotel;
  room: HotelRoomWithAllServices;
}

const HotelRoomDetails = ({hotel, room}: HotelRoomProps) => {
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const {getImageTypeDescription} = useHotel();

  const filterImages = (images?: Image[]) => {
    let result = images?.filter(image => image.roomCode === room.roomCode);
    if (result?.length === 0) {
      result = images?.filter(image => image.roomType === room.roomType);
    }
    return result ?? [];
  }
  
  const {images, onFailed} = useImages({images: filterImages(hotel?.images)})

  return (
    <>
      <GalleryPopup
        images={images.map(image => buildImagePath(hotel, image.path, HotelImageSize.PX1024))}
        titles={images.map(image => getImageTypeDescription({code: image.imageTypeCode, api: hotel.api}))}
        index={imageIndex}
        setIndex={setImageIndex}
        isOpen={isGalleryOpen}
        setIsOpen={setIsGalleryOpen}
      />
      <div className="text-3xl my-5 font-bold">{room.name}</div>
      <div className="flex flex-wrap lg:flex-nowrap gap-10">
        <div className={s.gallery}>
          <div className={s.chosen_image}>
            <HotelImage
              hotel={hotel}
              onFailed={() => {
                onFailed(imageIndex);
                setImageIndex(0);
              }}
              imagePath={images[imageIndex]?.path}
              className={s.mainImage}
              onClick={() => setIsGalleryOpen(true)}
              alt="hotel room chosen image"
            />
          </div>
          <div className={s.rest_images}>
            {images.map((image, i) =>
              <HotelImage
                key={i}
                hotel={hotel}
                onFailed={() => {
                  onFailed(i);
                  setImageIndex(0);
                }}
                imagePath={image.path}
                onClick={() => setImageIndex(i)}
                className={s.thumbnailImage}
                maxSize={HotelImageSize.PX800}
              />
            )}
          </div>
        </div>
        <div className="w-full lg:w-1/2">
          <ShowRoomServices room={room} hotel={hotel}/>
        </div>
      </div>
    </>
  )
}

export default HotelRoomDetails;