import React from 'react';
import {useTranslation} from "react-i18next";
import PartsOfTheWorldMap from "../../../Maps/PartsOfTheWorldMap";

const BuildYourTripStep3 = props => {
  const {t} = useTranslation();

  // HAY QUE ACTUALIZAR EL MAPA CON LA VERSION 2

  return (
    <div className="w-full flex items-center">
      <PartsOfTheWorldMap onChange={(data) => console.log(data)}/>
    </div>
  );
};


export default BuildYourTripStep3;
