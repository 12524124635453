import React, {useEffect} from 'react';
import s from './SearchActivitiesLocation.module.css';
import {useTranslation} from "react-i18next";
import InputWithLabel from "../Inputs/InputWithLabel";
import DatePickerWithLabel from "../Inputs/DatePickerWithLabel";
import {formatSearchBarActivities} from "../../helpers/search";
import useActivity from "../../controllers/useActivity/useActivity";
import {useFormContext} from "react-hook-form";
import InputWithOutsideLabel from "../Inputs/InputWithOutsideLabel";
import DatePickerWithOutsideLabel from "../Inputs/DatePickerWithOutsideLabel";
import ActivityPassengerNumber from "../Inputs/ActivityPassengerNumber";
import moment from "moment";
import LocationInput from "../Inputs/LocationInput";

const SearchActivitiesLocation = ({defaultValue, onSearch, outsideLabel = false} = {}) => {
  const {t} = useTranslation();
  const {searchActivityBar} = useActivity();

  const {setValue, getValues} = useFormContext();

  let wrap = outsideLabel;

  useEffect(() => {
    if (!defaultValue) return;

    document.getElementById('activity_search_page_destination').value = defaultValue.text;
    setValue('activity_destination', {
      name: defaultValue.text,
      lng: defaultValue.destination?.lng,
      lat: defaultValue.destination?.lat
    });
    setValue('arrive_date', defaultValue.from);
    setValue('departure_date', defaultValue.to);
  }, []);

  const onArriveDateChange = date => {
    let to = getValues('departure_date');
    if (to <= date || !to) setValue('departure_date', moment(date));
  }

  const isOutsideRange = day => {
    let from = getValues('arrive_date') || moment();
    return day < from;
  }

  let Input = outsideLabel ? InputWithOutsideLabel : InputWithLabel;
  let DatePicker = outsideLabel ? DatePickerWithOutsideLabel : DatePickerWithLabel;


  let RestInputs = props => <>
    <div className={`flex mt-10 w-full ${wrap ? '' : 'lg:w-2/3 lg:mr-5'} mr-0`}>
      <div className={`w-full ${wrap ? 'mb-5' : ''} mr-5`}>
        <DatePicker
          label={t("input.arrive_date")}
          placeholder={outsideLabel ? t('date') : ''}
          name="arrive_date"
          id="activity_page_arrive_date"
          onChange={onArriveDateChange}
        />
      </div>
      <div className="w-full">
        <DatePicker
          label={t("input.departure_date")}
          placeholder={outsideLabel ? t('date') : ''}
          name="departure_date"
          id="activity_page_departure_date"
          isOutsideRange={isOutsideRange}
        />
      </div>
    </div>
    <div className={`w-full ${wrap ? '' : 'lg:w-1/3 lg:mt-0'} mt-5 `}>
      {outsideLabel && <label className="label_outside"
                              htmlFor={"activity_passenger_number"}>{t('search_activity_persons')}</label>}
      <ActivityPassengerNumber containerClassName={`${!wrap ? 'mt-5 sm:mt-10' : ''}`} name="activity_passenger_number"
                               id="activity_passenger_number" defaultValue={defaultValue}/>
    </div>
  </>;


  return (
    <div className={s.container}>
      <LocationInput
        label={t("input.activity_destination")}
        id="activity_search_page_destination"
        name="activity_destination"
        Input={Input}
        onDestinationSelect={(data, input) => {
          if (input.current) input.current.value = data.text;
        }}
        searchFunction={searchActivityBar}
        formatFunction={formatSearchBarActivities}
        onSearch={onSearch}
      />

      {!wrap ?
        <div className={`w-full flex flex-wrap ${wrap ? '' : 'lg:flex-nowrap'}`}>
          <RestInputs/>
        </div>
        : <RestInputs/>
      }
    </div>
  );
};


export default SearchActivitiesLocation;
