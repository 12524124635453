import React from 'react';
import s from './RedirectTo.module.css';

const RedirectTo = ({url}) => {
	window.location.href = url;

	return (
		<div/>
	);
};


export default RedirectTo;