import React from 'react';
import Header from "../../Header/Header";
import {Outlet} from "react-router-dom";
import {Footer} from "../../FooterV2/Footer";

const HotelRouter = props => {
  return (
    <div>
      <Header sticky={true}/>
      <Outlet/>
      {/*<div className="my-40"/>*/}
      {/*<SmallFooter/>*/}
      <Footer/>
    </div>
  );
};


export default HotelRouter;
