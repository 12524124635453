import React from 'react';
import s from './UnderDevelopment.module.css';
import {useTranslation} from "react-i18next";

const UnderDevelopment = props => {
	const {t} = useTranslation();

	return (
		<div className="notDone">{t('under development')}</div>
	);
};


export default UnderDevelopment;