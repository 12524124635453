import React, {useState} from 'react';
import s from './PlaneDatePicker.module.css';
import {Controller} from "react-hook-form";
import {DayPickerRangeController} from "react-dates";

//import './override_dates.css';

const PlaneDatePicker = ({name, id = "planeDatePicker"}) => {
  const [focusedInput, setFocusedInput] = useState('startDate');


  return (<>
      <div id={id}>
        <Controller
          name={name}
          render={({field: {onChange, value}}) => (
            <DayPickerRangeController
              onFocusChange={f => setFocusedInput(f || 'startDate')}
              focusedInput={focusedInput}
              startDate={value?.startDate}
              endDate={value?.endDate}
              onDatesChange={dates => {
                onChange(dates)
              }}
              hideKeyboardShortcutsPanel={true}
            />
          )}
        />
      </div>
    </>
  );
};


export default PlaneDatePicker;
