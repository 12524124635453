import React from 'react';
import config from "../../../config";

const PoliticaPrivacidasEN = props => {
  return (
    <>
      <h1>PRIVACY POLICY AND DATA PROTECTION</h1>
      <h2>1. Collection and use of data. Main purposes of processing.</h2>
      <p><b></b></p>
      <p>In compliance with the provisions of Organic Law 3/2018 of December 5 on the Protection of Personal Data and
        guarantee of digital rights, Regulation (EU) 2016/679 of April 27, 2016, on data protection (GDPR) and its
        development regulations on data protection, TRIPPY TREKKERS, S.L, informs you that the personal data of the
        User, obtained or collected through the website www.trippytrekkers.com (hereinafter, the Portal), will be
        processed by TRIPPY TREKKERS, S.L for the fulfillment, among others, of the following purposes:</p>
      <p>1. Enable the provision of services offered on the Portal.</p>
      <p>2. Manage your registration as a user on our platform.</p>
      <p>3. Manage the contracting of products and/or services.</p>
      <p>4. Address requests for consultation, information, and/or complaints/claims.</p>
      <p>5. Inform Users about news, product offers, and other matters of interest.</p>
      <p>Whenever requested, and the User provides, an email address, mobile phone, or other electronic communication
        means, the User expressly accepts and authorizes that such means be preferably used as a communication channel,
        as a channel to fulfill all or some of the aforementioned purposes, as well as a channel through which to inform
        you of any other relevant changes that occur on the Portal.</p>
      <h2><b>2. Legitimization</b> for the processing of your personal data</h2>
      <ol>
        <li>Execution of the contract</li>
        <li>Consent.</li>
        <li>Legitimate interest.</li>
        <li>Fulfillment of legal obligations.</li>
      </ol>
      <p><b>3. User rights.</b></p>
      <p><b></b></p>
      <p>The User has recognized rights of access, rectification, cancellation, and opposition (ARCO). They also have
        the right to request the limitation of their treatment and the right to data portability. You may exercise your
        rights by sending a postal communication, and a photocopy of your ID, to the following address C/ Camino de
        Hoyarrasa 106, 28109 Alcobendas, Madrid, or by sending an email to <a
          href={`mailto:${config.trippy_trekkers.contact_email}`}>{config.trippy_trekkers.contact_email}</a></p>
      <p><b>4. Data processing controller.</b></p>
      <p><b></b></p>
      <p>The data processing controller for the different processes regarding the management of our users, customers, or
        suppliers is TRIPPY TREKKERS, S.L, with address at Calle Camino de Hoyarrasa 106, 28109 Alcobendas, Madrid, with
        CIF B -B88592381. Registered in the Madrid Mercantile Registry.
        Email <a
          href={`mailto:${config.trippy_trekkers.contact_email}`}>${config.trippy_trekkers.contact_email}</a></p>
      <p>The personal data that may be collected will be treated with absolute confidentiality, committing ourselves to
        keep them secret and guaranteeing the duty to keep them by adopting all necessary measures to prevent their
        alteration, loss, treatment or unauthorized access, in accordance with the provisions of applicable
        legislation.</p>
      <p>The TRIPPY TREKKERS, S.L website may contain links (links) to other third-party websites. Therefore, TRIPPY
        TREKKERS, S.L cannot assume any responsibility for the content that may appear on third-party pages. The texts,
        images, sounds, animations, software, and other content included on this website are the exclusive property of
        TRIPPY TREKKERS, S.L or its licensors. Any act of transmission, distribution, assignment, reproduction, storage,
        or total or partial public communication must have the express consent of TRIPPY TREKKERS, S.L.</p>
      <p><b>5. Collection and types of personal data.</b></p>
      <p><b></b></p>
      <p>The personal data handled by TRIPPY TREKKERS, S.L have been obtained through the following means freely
        informed by the interested party: (Add those that correspond according to the activity).</p>
      <ol>
        <li>Formulario de contacto</li>
        <li>Vía correo electrónico</li>
        <li>Vía telefónica</li>
        <li>Formulario de…</li>
      </ol>
      <p>
        Las categorías de datos personales que TRIPPY TREKKERS trata sobre sus clientes y proveedores son:
      </p>
      <ol>
        <li>Datos de identificación.</li>
        <li>Direcciones postales o electrónicas.</li>
        <li>Información comercial.</li>
      </ol>
      <p>
        No se tratan categorías de datos especialmente protegidos.
      </p>
      <p><b>6. Plazos de conservación.</b></p>
      <p><b></b></p>
      <p>
        Los datos personales relativos a personas físicas vinculadas a clientes potenciales, clientes y proveedores que
        TRIPPY TREKKERS, S.L recopile mediante los distintos formularios de contacto y/o recogida de información se
        conservarán mientras no se solicite su supresión por el interesado. Los datos proporcionados por nuestros
        clientes y proveedores se conservarán mientras se mantenga la relación mercantil entre las partes respetando en
        cualquier caso los plazos mínimos legales de conservación según la materia.
      </p>
      <p>
        Mantendremos sus datos de carácter personal mientras exista una relación o mientras no ejerza su derecho de
        supresión, cancelación y/o limitación del tratamiento de sus datos. En estos casos, bloquearemos sus datos, sin
        darle ningún uso, y se conservarán mientras pueda ser necesaria para el ejercicio o defensa de reclamaciones o
        pueda derivarse algún tipo de responsabilidad judicial, legal o contractual de su tratamiento, que deba ser
        atendida y para lo cual sea necesaria su recuperación.
      </p>
      <p><b>7. Destinatarios de la comunicación de datos de carácter personal.</b></p>
      <p><b></b></p>
      <p>
        Los datos de carácter personal que nos proporcione se comunicarán a terceros (operadores turísticos en destino,
        hoteles, cruceros, líneas aéreas).
      </p>
      <p>
        También se comunicarán a su solicitud y por obligación legal, cuando sea necesario, a la Administración Pública,
        a los Jueces y Tribunales, o a destinatarios en cumplimiento de una obligación legal. Fuera de estos supuestos,
        sus datos serán comunicados a terceras entidades solicitando de forma previa su consentimiento.
      </p>
      <p><b>8. Exactitud y veracidad de los datos.</b></p>
      <p>
        El usuario es el único responsable de garantizar la corrección y autenticidad de todos aquellos datos que
        comunique a través de la página web <a href="http://www.trippytrekkers.com/">www.trippytrekkers.com</a>, y
        mantendrá actualizada la información que facilite a TRIPPY TREKKERS, S.L, de forma que responda en todo momento
        a su situación real, siendo el único responsable de las manifestaciones falsas o inexactas que realice, así como
        de los perjuicios que cause por ello a TRIPPY TREKKERS, S.L. o a terceros.
      </p>
      <p>
        TRIPPY TREKKERS, S.L. no puede asumir ninguna responsabilidad derivada del uso incorrecto, inapropiado o ilícito
        de la información aparecida en la página web www.trippytrekkers.com.
      </p>
      <p><b>9. Aceptación y consentimiento.</b></p>
      <p>
        El usuario declara haber sido informado de las condiciones sobre protección de datos de carácter personal,
        aceptando y consintiendo el tratamiento de los mismos por parte del Propietario de la web en la forma y para las
        finalidades indicadas en esta política de privacidad.
      </p>
      <p><b>10. Revocabilidad.</b></p>
      <p>
        El consentimiento prestado, tanto para el tratamiento como para la cesión de los datos de los interesados, es
        revocable en cualquier momento comunicándolo al Propietario de la web en los términos establecidos en esta
        Política para el ejercicio de los derechos ARCO. Esta revocación en ningún caso tendrá carácter retroactivo.
      </p>
      <p><b>11. Cambios en la política de privacidad.</b></p>
      <p>
        El Propietario de la web se reserva el derecho a modificar la presente política para adaptarla a novedades
        legislativas o jurisprudenciales, así como a prácticas de la industria.
      </p>
      <p><b></b></p>
      <p><b>12. Ley aplicable y jurisdicción.</b></p>
      <p>
        Las presentes condiciones generales se rigen por la Legislación española y europea en materia de protección de
        datos de carácter personal y de privacidad.
      </p>
      <p>
        Cualquier controversia entre TRIPPY TREKKERS, S.L y el usuario acerca de la política de privacidad y protección
        de datos, con renuncia expresa a cualquier otro fuero que pudiera corresponderles, se someterá a los Juzgados y
        Tribunales de la ciudad de Alcobendas, Madrid, España.
      </p>
    </>
  );
};


export default PoliticaPrivacidasEN;