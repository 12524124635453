import s from "./Rating.module.css";
import React, {useState} from "react";
import {useFormContext} from "react-hook-form";
import {ReactComponent as StarSVG} from "../../../../resources/svg/star2.svg";
import cn from "classnames";

interface Props {
  name: string;
}

export function Rating({name}: Props) {
  const form = useFormContext();
  const [hovered, setHovered] = useState(-1);

  const {setValue, watch} = form;
  const stars = watch(name);


  return <div className={s.container}>
    {/*<label>{t('start your trip.minimum rating')}</label>*/}
    <div className={s.star_container} onMouseLeave={() => setHovered(-1)}>
      {[...Array(5)].map((i, v) =>
        <div
          key={v}
          className={cn([s.star, {[s.active_star]: v + 1 <= stars || v <= hovered}])}
          onClick={() => setValue(name, v + 1)}
          onMouseEnter={() => setHovered(v)}
        >
          <StarSVG/>
        </div>
      )}
    </div>
  </div>

}