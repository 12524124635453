import React, {useEffect} from 'react';
import s from './MyProfileMenu.module.css';
import ProfileImage from '../../../resources/my_profile_menu/profile_image.png';
import {useTranslation} from "react-i18next";
import useAuth from "../../../providers/AuthProvider/useAuth";
import MiddleText from "../../TextComponents/MiddleText";
import NormalText from "../../TextComponents/NormalText";
import {ReactComponent as MyProfileSVG} from "../../../resources/my_profile_menu/my_profile.svg";
import {ReactComponent as HotelsSVG} from "../../../resources/my_profile_menu/hotels.svg";
import {ReactComponent as LikesSVG} from "../../../resources/my_profile_menu/likes.svg";
import {ReactComponent as SignOffSVG} from "../../../resources/my_profile_menu/sign_off.svg";
import {ReactComponent as TravelSaveSVG} from "../../../resources/svg/travel_save_check.svg";


import bases from "../../../routes/bases";
import {NavLink, useLocation} from "react-router-dom";
import cn from "classnames";


const MenuOption = ({Image, text, mobile, small, src = bases.main, selected, ...props}) =>
  <NavLink
    className={cn(s.menu_option_nav_link, {'hidden md:block': !mobile}, {[s.menu_option_nav_link_selected]: selected})}
    to={src}>
    <div
      className={cn(selected ? s.menu_option_container_selected : s.menu_option_container, {[s.smallMenuOption]: small})} {...props}>
      <div className={selected ? s.menu_option_icon_selected : s.menu_option_icon}>
        <Image height={20} width={20}/>
      </div>
      {!small && <div className="pl-10 hidden md:block">
        <NormalText
          className={`${s.menu_option_text} ${selected ? s.menu_option_text_selected : ''}`}>{text}</NormalText>
      </div>}
    </div>
  </NavLink>


const smallMenuRegex = [
  new RegExp('\\/p\\/pd\\/hotel\\/\\w+\\/\\w+\\/\\w+', 'gi'),
]

const MyProfileMenu = props => {
  const {t} = useTranslation();
  const {getUserFullName} = useAuth();
  const [smallMenu, setSmallMenu] = React.useState(false);

  const location = useLocation();

  useEffect(() => {
    const pathname = window.location.pathname;
    //if (location.pathname === '/p') return;
    setSmallMenu(smallMenuRegex.some(regex => regex.test(pathname)))
  }, [location.pathname]);


  const options = [
    {text: t('profile options.my profile'), Image: MyProfileSVG, src: bases.profile_router, mobile: true},
    {text: t('profile options.my likes'), Image: LikesSVG, src: bases.profile_likes, mobile: true},
    //	{text: t('profile options.my experiences'), Image: ExperiencesSVG, mobile: true},
    /*
        {text: t('profile options.chat'), Image: ChatSVG, mobile: true},
    */
    /*		{text: t('profile options.my packages'), Image: PacketsSVG, mobile: true},*/
    /*		{text: t('profile options.my flights'), Image: PlaneSVG},*/
    {text: t('profile options.my accommodations'), src: bases.profile_hotels, Image: HotelsSVG, mobile: true},
    //{text: t('profile options.my activities'), src: bases.profile_activities, Image: ActivitiesSVG, mobile: true},
    {text: t('profile options.my insurances'), src: bases.profile_insurances, Image: TravelSaveSVG, mobile: true},
  ];


  return (
    <div className={smallMenu ? s.smallContainer : s.container}>
      <div className="md:w-full hidden md:block text-center">
        <div className="w-full flex justify-center px-4 py-5">
          <img src={ProfileImage} alt={t('alt.profile_image')}
               className="shadow-lg rounded-full max-w-full h-auto align-middle border-none"/>
        </div>
        {!smallMenu && <div>
          <MiddleText className={s.full_person_name}>{getUserFullName()}</MiddleText>
        </div>}
      </div>
      <div className={`${s.separator}`}/>
      <div className="w-full flex flex-nowrap md:flex-wrap justify-center items-center">
        {options.map(option => <MenuOption small={smallMenu} {...option} selected={location.pathname === option.src}/>)}
        <div className={s.separator}/>
        <MenuOption text={t('profile options.sign off')} small={smallMenu} Image={SignOffSVG}/>
      </div>
    </div>
  );
};


export default MyProfileMenu;
