import {query} from "../../helpers/query";

export const useCurrency = () => {
  const requestExchanges = async () => {
    const {error, data} = await query('/api/currency/exchanges', false, 'GET');
    return {error, exchanges: data?.exchanges};
  }

  return {
    requestExchanges
  }
}
