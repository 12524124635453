import {UseFormReturn} from "react-hook-form";
import {SearchHotelURLParams} from "../../../../types/hotels/hotels";
import {useCallback, useState} from "react";
import moment from "moment";
import {SearchHotelsFormValues, SearchHotelsRoomsFormValues} from "../../../Main/Hotels/models";
import {formDataToSearchHotelURLParams} from "../../../Hotels/HotelDetails/util";
import {Moment} from "moment/moment";

interface LoadDefaultDataProps {
  form: UseFormReturn;
  searchParams: SearchHotelURLParams;
}

export function loadDefaultData({form, searchParams}: LoadDefaultDataProps) {
  const {setValue} = form;
  const hotel_destination_input = document.getElementById('hotel_search_page_destination');

  // @ts-ignore
  if (hotel_destination_input) hotel_destination_input.value = searchParams.t;
  setValue('hotel_destination', searchParams.t);
  setValue('arrive_date', searchParams.from);
  setValue('departure_date', searchParams.to);

  const rooms: SearchHotelsRoomsFormValues[] = searchParams.rooms.map(room => ({
    adults: room.adults,
    children: room.children,
    childrenAge: room.childrenAge ? room.childrenAge.map(age => ({age})) : []
  }))

  setValue('rooms', rooms);
}


interface UseUtilFunctionsProps {
  form: UseFormReturn;
  setFocusSecondDate: (value: boolean) => void;
  onSearch: (params: SearchHotelURLParams, rawData: SearchHotelsFormValues) => Promise<void>;
}

export function useUtilFunctions({form, setFocusSecondDate, onSearch}: UseUtilFunctionsProps) {
  const [isLoading, setIsLoading] = useState(false);
  const onArriveDateChange = useCallback((date: any) => {
    setFocusSecondDate(true)
    let to = form.getValues('departure_date');
    if (to <= date || Math.abs(date.diff(to, 'day')) > 30) form.setValue('departure_date', moment(date).add(1, 'day'));
  }, [form, setFocusSecondDate]);

  const isOutsideRange = useCallback((day: any) => {
    let from = form.getValues('arrive_date') || moment().add(3, 'day');
    return day <= from || Math.abs(day.diff(from, 'day')) > 30;
  }, [form]);

  const isOutsideRangeStart = (day: any) => {
    return day <= moment().add(3, 'day')
  }

  const isDayHighlighted = (day: Moment) => {
    let from = form.getValues('arrive_date');
    if (!from) return false;

    if (Math.abs(day.diff(from, 'day')) === 0) return true;

    let to = form.getValues('departure_date');
    if (!to) return false;
    return day.isBetween(from, to, 'day');
  }


  const isFormValid = useCallback((data: any) => {
    data = data || form.getValues();
    const required = ['departure_date', 'arrive_date', 'rooms'];
    return !required.find(key => !data[key]);
  }, [form]);

  const onSubmit = async (data: SearchHotelsFormValues) => {
    if (!isFormValid(data)) return;
    setIsLoading(true);
    await onSearch(formDataToSearchHotelURLParams(data), data);
    setIsLoading(false);
  }

  return {isOutsideRange, onArriveDateChange, onSubmit, isDayHighlighted, isLoading, isOutsideRangeStart};
}