import React, {useEffect, useRef, useState} from 'react';
import s from './Menu.module.css';
import {useTranslation} from "react-i18next";
import bases from "../../routes/bases";
import {NavLink} from "react-router-dom";

import {ReactComponent as FacebookSVG} from '../../resources/svg/social_media/facebook.svg';
import {ReactComponent as InstagramSVG} from '../../resources/svg/social_media/instagram.svg';
import config from "../../config";

const Menu = ({triggerRef}) => {
  const {t} = useTranslation();
  const [state, setState] = useState({
    visible: false
  });
  const node = useRef(null);

  const listenerHide = e => {
    if (!node.current) return;
    if (!node.current?.contains(e.target) && !triggerRef?.current?.contains(e.target)) {
      setState(prev => ({...prev, visible: false}));
    }
  }
  const listenerToggle = () => {
    setState(prev => ({...prev, visible: !prev.visible}));
  }

  useEffect(() => {
    const current = triggerRef.current;
    document.body.addEventListener('click', listenerHide);
    if (current) current.addEventListener('click', listenerToggle);
    return () => {
      document.body.removeEventListener('click', listenerHide);
      if (current) current.removeEventListener('click', listenerToggle);
    };
  }, []);

  return state.visible ? (
      <div ref={node} className={s.container}>
        <svg onClick={() => listenerToggle()} className={s.closeIcon} width="14" height="14" viewBox="0 0 14 14"
             fill="none">
          <path
            d="M13.3001 0.700106C12.9135 0.313506 12.2867 0.313506 11.9001 0.700106L7.00011 5.60011L2.10011 0.700106C1.71351 0.313506 1.08671 0.313506 0.700106 0.700106C0.313506 1.08671 0.313506 1.71351 0.700106 2.10011L5.60011 7.00011L0.700106 11.9001C0.313506 12.2867 0.313506 12.9135 0.700106 13.3001C1.08671 13.6867 1.71351 13.6867 2.10011 13.3001L7.00011 8.40011L11.9001 13.3001C12.2867 13.6867 12.9135 13.6867 13.3001 13.3001C13.6867 12.9135 13.6867 12.2867 13.3001 11.9001L8.40011 7.00011L13.3001 2.10011C13.6867 1.71351 13.6867 1.08671 13.3001 0.700106Z"
            fill="black"/>
        </svg>
        <div className={s.optionContainer}>
          <NavLink to={bases.mainHotels}>
            <div className={s.option}>{t('menu.hotels')}</div>
          </NavLink>
          <NavLink to={bases.startYourTripRouter}>
            <div className={s.option}>{t('menu.start your trip')}</div>
          </NavLink>
          {/*<div className={s.option}>{t('menu.unique experiences')}</div>*/}
          <div className={s.option}><a href={config.trippyDivers} target="_blank" rel="noreferrer">Trippy divers</a></div>
          {/*<div className={s.option}>{t('menu.who are we')}</div>*/}
          <NavLink to={bases.contact_us}>
            <div className={s.option}>
              {t('menu.contact us')}
            </div>
          </NavLink>
        </div>

        <div className={s.social}>
          <div className={s.follow_us}>{t("menu.follow us")}</div>
          <a href={config.facebook} target="_blank" rel="noreferrer"><FacebookSVG/></a>
          {/*<YoutubeSVG/>*/}
          <a href={config.instagram} target="_blank" rel="noreferrer"><InstagramSVG/></a>
        </div>

      </div>
    ) :
    <></>
    ;
};


export default Menu;
