import {Controller} from "react-hook-form";
import s from "./TextInput.module.css";
import React from "react";
import cn from "classnames";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string,
  name: string,
  label: string,
  containerClassName?: string;
  withAsterisk?: boolean;
}

export function TextInput({id, name, label, containerClassName, withAsterisk, ...props}: Props) {
  return (
    <Controller
      name={name}
      render={({field: {onChange: _onChange, value}}) => (
        <div className={cn(s.container, containerClassName)}>
          {label && <label htmlFor={id}>
            {withAsterisk && <b style={{color: '#ea4e6a'}}>* </b>}
            {label}
          </label>}
          <input type="text" name={name} id={id}
                 onChange={e => {
                   _onChange(e.target.value);
                 }}
                 value={typeof value === 'string' ? value : undefined}
                 {...props}
          />

        </div>
      )}
    />
  )
}