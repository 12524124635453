import React, {useEffect} from 'react';
import s from './ActivityPassengerNumber.module.css';
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";
import InputWithNumericOptions from "../InputWithNumericOptions/InputWithNumericOptions";
import {ReactComponent as PersonSVG} from "../../../resources/my_profile/person.svg";
import Select from "../Select";


export const AgeSelect = ({value, i, name}) => {
  const {t} = useTranslation();

  const options = React.useMemo(() => {
    let age_options = [];
    for (let i = 1; i <= 80; i++) {
      age_options.push({
        label: `${i} ${t('years')}`,
        value: i
      });
    }
    age_options.push({
      label: `80+ ${t('years')}`,
      value: 81
    });
    return age_options;
  }, []);

  return <div className="flex flex-nowrap w-full items-center pb-5">
    <div className={s.dataText}>{t('age')} {i}</div>
    <div className={s.childrenSelect}>
      <Select
        name={name}
        options={options}
        usePortal={true}
        defaultValue={value}
        styles={{
          menuPortal: (provided) => ({
            ...provided,
            zIndex: 5
          })
        }}
      />
    </div>
  </div>
}

const ActivityPassengerNumber = ({
                                   id = "activity_passenger_number",
                                   name = "activity_passenger_number",
                                   defaultValue,
                                   containerClassName,
                                   label,
                                   ...props
                                 }) => {
  const {t} = useTranslation();
  const {setValue} = useFormContext();


  const onChange = (data, newData, node) => {
    const {element, add} = data;

    if (element.name !== 'persons' || (element.value === 1 && !add)) return;
    if (element.value === 0 && !newData.find(d => d.separator)) newData.push({separator: true});

    if (add) {
      let person_id = element.value + 1;
      let name = `person_age_${person_id}`;
      newData.push({
        person_id,
        element: props => <AgeSelect {...props} name={name} i={element.value + 1}/>
      });
      setValue(name, 18);
      setValue(id, newData);
    } else {
      newData = newData.filter(d => (d.person_id && d.person_id < element.value) || !d.person_id);
      if (element.value === 1) newData = newData.filter(d => !d.separator);
      setValue(name, 18);
      setValue(id, newData);
    }
  }

  useEffect(() => {
    setValue('person_age_1', 18);
    if (!Array.isArray(defaultValue?.persons)) return;

    let passenger_number = [
      {name: 'persons', text: t('persons'), value: defaultValue.persons.length, min: 1},
      {separator: true},
      ...defaultValue.persons.map((age, i) => ({
        person_id: i + 1,
        element: props => <AgeSelect {...props} name={`person_age_${i + 1}`} i={i + 1}/>
      }))
    ];

    setValue('activity_passenger_number', passenger_number);
    defaultValue.persons.forEach((age, i) => setValue(`person_age_${i + 1}`, age));
  }, [defaultValue]);


  let defaultOptions = [
    {name: 'persons', text: t('persons'), value: 1, min: 1},
    {person_id: '1', element: props => <AgeSelect value={18} {...props} name={`person_age_1`} i={1}/>}
  ];

  return (
    <div className={containerClassName}>
      <InputWithNumericOptions
        label={label || t('input.passenger_number')}
        id={id}
        name={name}
        icon={<PersonSVG style={{fill: '#979797'}}/>}
        defaultOptions={defaultOptions}
        onChange={onChange}
        {...props}
      />
    </div>
  );
};


export default ActivityPassengerNumber;
