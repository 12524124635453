import React from 'react';
import ThemeProvider from "./ThemeProvider/ThemeProvider";
import AuthProvider from "./AuthProvider/AuthProvider";
import HotelsProvider from "./HotelsProvider/HotelsProvider";
import ChatProvider from "./ChatProvider/ChatProvider";
import ActivitiesProvider from "./ActivitiesProvider/ActivitiesProvider";
import CheckoutProvider from "./CheckoutProvider/CheckoutProvider";
import CurrencyProvider from "./CurrencyProvider/CurrencyProvider"
import UniqueExperienceProvider from "./UniqueExperienceProvider/UniqueExperienceProvider";

export const AllProviders = (props) => {
  return (
    <ThemeProvider>
      <CurrencyProvider>
        <AuthProvider>
          <CheckoutProvider>
            <HotelsProvider>
              <ActivitiesProvider>
                <UniqueExperienceProvider>
                  <ChatProvider>
                    {props.children}
                  </ChatProvider>
                </UniqueExperienceProvider>
              </ActivitiesProvider>
            </HotelsProvider>
          </CheckoutProvider>
        </AuthProvider>
      </CurrencyProvider>
    </ThemeProvider>
  );
}

export default AllProviders;
