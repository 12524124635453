import s from './TopHotel.module.css'
import {ApitudeHotelExclusiveDeal} from "../../../../types/hotels/hotels";
import React, {useState} from "react";
import {Popover} from "@mantine/core";
import {t} from "i18next";

interface Props {
  exclusiveDeal: ApitudeHotelExclusiveDeal
}

export function TopHotel({exclusiveDeal}: Props) {
  const [opened, setOpened] = useState(false);

  if (![ApitudeHotelExclusiveDeal.TopHotel, ApitudeHotelExclusiveDeal.ExclusiveOffer].includes(exclusiveDeal)) return null

  return (
    <Popover
      opened={opened}
      onClose={() => setOpened(false)}
      target={<div onMouseEnter={() => setOpened(true)} onMouseLeave={() => setOpened(false)} className={s.container}>
        Top Hotel
      </div>}
    >
      {t('top hotels info')}
    </Popover>
  )
}