import {t} from "i18next";
import Button from "../../Inputs/Button/Button";
import emptyCheckoutSrc from "../../../resources/images/checkout/empty_checkout.png";
import {useNavigate} from "react-router-dom";

export function EmptyCheckout() {
  const navigate = useNavigate();

  return <div className="w-full flex items-center justify-center">
    <div className="w-full flex flex-col items-center justify-center mb-20 px-5 py-20 bg-white" style={{width: 500}}>
      {/*<div className="mt-20 w-full flex items-center"><FiShoppingCart size={40}/></div>*/}
      <img src={emptyCheckoutSrc} alt="empty checkout"/>
      <div className="text-3xl my-20">{t('checkout.empty checkout')}</div>
      <div style={{width: 400}}><Button
        onClick={() => navigate('/')}>{t('checkout.back to main page')}</Button></div>
    </div>
  </div>
}