import s from './TickFilter.module.css'
import {Controller} from "react-hook-form";
import Checkbox from "../../../../../Inputs/Checkbox";

interface Props {
  name: string
  id: string
  title?: string
  label: string | JSX.Element
  defaultValue: boolean
  onChange?: (value: boolean) => void
}

export function TickFilter({name, id, label, defaultValue, title, onChange: externalOnChange}: Props) {
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      render={({field: {onChange, value}}) => {
        return <div className={s.container}>
          <Checkbox
            // @ts-ignore
            id={id}
            // @ts-ignore
            title={title}
            // @ts-ignore
            checked={value}
            onChange={(e: any) => {
              onChange(e.target.checked)
              externalOnChange && externalOnChange(e.target.checked)
            }}
            label={label}
          />
        </div>
      }}
    />
  )
}