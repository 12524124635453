import React from 'react';
import sp from './PurchaseForm.module.css';
import s from '../Checkout.module.css';
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {useTranslation} from "react-i18next";
import Button from "../../Inputs/Button/Button";


const PurchaseForm = ({onPurchase, onReady, loading}) => {
  const {t} = useTranslation();
  const elements = useElements();
  const stripe = useStripe();

  return (
    <div className={s.box}>
      <h2>{t('checkout.how do you want to pay')}</h2>
      <PaymentElement onReady={elements => onReady(elements)} id="stripe_payment_element" options={{layout: 'tabs'}}/>
      {/*			<div className={s.smallLabel}>{t('checkout.way of paying')}</div>
			<div className="flex flex-nowrap mt-5 w-[200px]">
				<RadioWithContainer
					containerClassName="w-full mr-10"
					id={`checkout_how_to_pay`}
					name={`checkout.how_to_pay`}
					defaultValue={"card"}
					options={methodsOfPurchases}
				/>
			</div>
			<div className="mt-10">
				<div className={s.smallLabel}>{t('checkout.write card data')}</div>
				<div className="flex flex-nowrap">
					<div className={sp.stripe_box}>
						<div id="checkout-card-number-element"/>
					</div>
					<div className={sp.stripe_box}>
						<div id="checkout-card-expiry-element"/>
					</div>
					<div className={sp.stripe_box}>
						<div id="checkout-card-cvc-element"/>
					</div>
				</div>
				<div className="flex flex-nowrap mb-10">
					<InputWithLabel
						label={t('checkout.card owner')}
						name={`checkout.card_owner`}
						id={`checkout_card_owner`}
						second_style={true}
						containerClassName="mr-5"
					/>
					<InputWithLabel
						label={t('checkout.identity document')}
						name={`checkout.identity_document`}
						id={`checkout_identity_document`}
						second_style={true}
					/>
				</div>
			</div>*/}
      <Button loading={loading} form="checkout_form" className={sp.button} loadButton={true} onClick={async () => {
        console.log('onclick executed')
        await onPurchase({stripe, elements});
      }}>{t('checkout.pay now')}</Button>
    </div>
  );
};


export default PurchaseForm;
