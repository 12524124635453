import React, {useState} from 'react';
import {HotelsContext} from './HotelsContext';
import {useHotel} from "../../controllers/useHotel/useHotel";
import {useForm} from "react-hook-form";
import {useCache} from "../../controllers/useCache";
import {objIsEqual} from "../../helpers/util";
import {createSearchData} from "./util";

const HotelsProvider = (props) => {
  const {searchHotels, likeHotel, dislikeHotel, getTypes} = useHotel();

  const filterForm = useForm();
  const [selectedRateKeys, setSelectedRateKeys] = useState([]);

  // loading values
  /// false === nothing loading
  // 1 === only filters
  // 2 === only hotels
  // 3 === all
  const [loading, setLoading] = useState(false);

  const [sort, setSort] = useCache({
    key: 'hotelsSort',
    defaultValue: 'recommended',
    expire: 99999999,
    expireCheck: 99999
  });

  const [hotelsCache, setHotelsCache] = useCache({
    key: 'hotelsProvider',
    defaultValue: [],
    //expire: 720000 // 2 hours
    expire: 99999999,
    expireCheck: 99999
  });

  const [lastSearchCoords, setLastSearchCoords] = useState({
    lat: 0,
    lng: 0
  });

  // used for "we found X hotels for you"
  // it is controllerd by this proveder
  // and useHotelsForMap
  const [count, setCount] = useState(0);

  const [allowedFilters, setAllowedFilters] = useState();
  const [pagination, setPagination] = useState({
    limit: 200,
    total: 0
  });


  const isCached = ({searchData, offset = 0, limit = 0}) => {
    return false;
    let r = (hotelsCache && objIsEqual(hotelsCache?.searchData, searchData));
    return r && (hotelsCache?.hotels?.length >= limit);
  }

  const updateHotels = async ({
                                loading = true,
                                sort: localSort,
                                onlyFilters = false,
                                loadMore = false,
                                lng,
                                lat
                              } = {}) => {
    if (loading) setLoading(onlyFilters ? 2 : 3);

    localSort = localSort || sort;

    const types = await getTypes()


    let searchData = createSearchData({
      add_filters: onlyFilters,
      filterForm,
      allowedFilters,
      lastSearchCoords,
      types,
      lat,
      lng
    });
    let limit = pagination.limit;
    if (loadMore) limit += 12;


    setLastSearchCoords({
      lat: searchData.body.destination.lat,
      lng: searchData.body.destination.lng
    });

    if (isCached({searchData, limit})) {
      setPagination({total: hotelsCache.total, limit});
      setAllowedFilters(hotelsCache.allowedFilters);
      setLoading(false);
      return;
    }


    let {error, hotels: newHotels, filters, total} = await searchHotels({
      body: searchData.body,
      filters: searchData.filters,
      sort: localSort,
      limit
    });

    if (error) {
      setLoading(false);
      return {error};
    }


    setHotelsCache({
      searchData,
      allowedFilters: filters,
      total,
      hotels: newHotels
    });

    if (!onlyFilters && !loadMore) setAllowedFilters(filters);

    if (!loadMore) {
      setPagination({limit: 200, total});
    } else {
      setPagination({limit, total});
    }

    setCount(total);

    if (loading) setLoading(false);
  }


  let returnedHotels = hotelsCache.hotels;
  if (pagination.limit < hotelsCache.total) {
    returnedHotels = returnedHotels.slice(0, pagination.limit);
  }

  const clearCache = () => {
    setHotelsCache([]);
  }


  const likeTheHotel = async (hotel) => {
    let f = hotel.is_liked ? dislikeHotel : likeHotel;
    const {error, success} = await f(hotel);

    if (error) return {error};
    if (success) {
      if (Array.isArray(hotelsCache?.hotels)) {
        setHotelsCache(prev => ({
          ...prev,
          hotels: prev.hotels.map(h => ({
            ...h,
            is_liked: h.code === hotel.code ? !hotel.is_liked : h.is_liked
          }))
        }));
      }
    }

    return {success};
  }

  return (
    <HotelsContext.Provider
      value={{
        clearCache,
        hotels: returnedHotels,
        /*
        // is better to not export setHotels and use updateHotels instead
          setHotels: hotels => setHotelsCache(prev => ({hotels})),*/
        /*        filterData,
                setFilterData,*/
        allowedFilters,
        setAllowedFilters,
        loading, setLoading,
        filterForm,
        updateHotels,
        likeTheHotel,
        pagination, setPagination,
        count, setCount,
        setSelectedRateKeys,
        selectedRateKeys,
        sort, setSort
      }}>
      {props.children}
    </HotelsContext.Provider>
  );

};

export default HotelsProvider;
