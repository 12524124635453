import React from 'react';
import PoliticaPrivacidadES from "./PoliticaPrivacidadES";
import PoliticaPrivacidadEN from "./PoliticaPrivacidadEN";

const PoliticaPrivacidad = ({lang}) => {
  if (lang === 'es-ES') {
    return <PoliticaPrivacidadES/>
  } else {
    return <PoliticaPrivacidadEN/>
  }
};


export default PoliticaPrivacidad;