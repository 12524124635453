import React, {useMemo, useState} from "react";
import {UniqueExperienceContext} from "./UniqueExperienceContext";
import {useForm} from "react-hook-form";
import {useUtil} from "../../hooks/useUtil";
import {useUniqueExperiences} from "../../controllers/useUniqueExperiences";


const UniqueExperienceProvider = props => {
  const {searchExperiences} = useUniqueExperiences();
  const {experience_paramsToObj} = useUtil();

  const [loading, setLoading] = useState(false);
  const [allowedFilters, setAllowedFilters] = useState();
  const [pagination, setPagination] = useState({
    page: 1,
    itemsPerPage: 10,
    total: 0
  });

  const [experiences, setExperiences] = useState([]);
  const filterForm = useForm();

  const updateExperiences = async ({loading = true, onlyFilters = true, loadMore = false} = {}) => {
    if (loading) setLoading(onlyFilters ? 2 : 3);
    let body = experience_paramsToObj();

    let searchFilters = false;
    const selectedFilters = filterForm.getValues();


    if (Object.keys(selectedFilters).length > 0 && allowedFilters) {
      searchFilters = {
        minRate: selectedFilters.experience_range_price[0],
        maxRate: selectedFilters.experience_range_price[1],
        minStars: selectedFilters.min_stars,
        maxStars: selectedFilters.max_stars,
        categories: Object.keys(selectedFilters.categories).filter(code => selectedFilters.categories[code]),
        countries: Object.keys(selectedFilters.countries).filter(code => selectedFilters.countries[code]),
      };
    }

    let page = pagination.page;
    if (loadMore) page++;

    const {error, experiences, filters, itemsPerPage, total} = await searchExperiences({
      body,
      page,
      filters: searchFilters
    });

    if (error) return {error};

    //if(!onlyFilters && !loadMore)setAllowedFilters(filters)

    setAllowedFilters(filters);

    if (loadMore) {
      setExperiences([...experiences, ...experiences]);
      setPagination({page, total, itemsPerPage});
    } else {
      setPagination({page: 1, total, itemsPerPage});
      setExperiences(experiences);
    }


    if (loading) setLoading(false);
  }

  const canLoadMore = useMemo(() => {
    if (!Array.isArray(experiences) || experiences.length === 0) return false;
    return experiences.length < pagination.total;
  }, [experiences, pagination]);

  return <UniqueExperienceContext.Provider value={{
    loading, setLoading,
    allowedFilters, setAllowedFilters,
    pagination, setPagination,
    filterForm,
    experiences, setExperiences,
    updateExperiences,
    canLoadMore
  }}>
    {props.children}
  </UniqueExperienceContext.Provider>
}

export default UniqueExperienceProvider;