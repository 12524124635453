import React, {useEffect} from 'react';
import s from './Chat.module.css';
import ChatList from "./ChatList";
import {useTranslation} from "react-i18next";
import useChatProvider from "../../../providers/ChatProvider/useChatProvider";
import useAuth from "../../../providers/AuthProvider/useAuth";
import {ReactComponent as SmileSVG} from "../../../resources/chat/smile.svg";
import {ReactComponent as SendSVG} from "../../../resources/chat/send.svg";
import {useForm} from "react-hook-form";
import ProfileImage from "../../../resources/my_profile_menu/profile_image.png";


const Message = ({message, self, next}) => {
  let show_icon = !self && (next ? next.user_id !== message.user_id : true);

 return <div className={`${s.messageContainer} ${self ? s.messageContainerSelf : ''}`}>
    {show_icon && <div className="mx-10" style={{width: '50px'}}>
      <img src={ProfileImage}
           className="shadow-lg p-2 bg-white rounded-full max-w-full w-full h-auto align-middle border-none"/>
    </div>
    }
    <div className={`${s.message} ${!show_icon && !self ? s.message_no_icon : ''}`}>
      {message.data}
    </div>
  </div>
}
const Chat = props => {
  const {t} = useTranslation();
  const {messages, chatList, setMessages, newMessage, listMessages, isChatSelected} = useChatProvider();
  const {getUser} = useAuth();
  const {register, setValue, handleSubmit} = useForm();

  const chatContainer = React.useRef();

  const loadMessages = async () => {
    const {error, messages} = await listMessages();
    if (Array.isArray(messages)) setMessages(messages);
  }

  useEffect(() => {
    let selected = chatList.find(chat => chat.selected);
    if (!selected) return setMessages([]);
    loadMessages(selected.chat_id);
  }, [chatList]);

  const sendMessage = data => {
    let msg = data.chat_input;
    newMessage({msg}, () => {
      setValue('chat_input', '');
    });
  }

  useEffect(() => {
    if(chatContainer.current)chatContainer.current.scrollTo(0, chatContainer.current.scrollHeight);
  }, [messages]);


  return (
    <div className="flex w-full">
      <ChatList/>
      <div className={s.chat} ref={chatContainer}>
        {isChatSelected() ?
          <div className="relative">

            <div style={{height: '740px'}}>
              {messages.map((message, i) => <Message next={messages[i+1]} message={message} self={message.user_id === getUser().user_id}/> )}
            </div>
            <div className={s.search_bar}>
              <div className="cursor-pointer">
                <SmileSVG width={40} height={40}/>
              </div>

              <input
                id="chat_input"
                {...register('chat_input', {required: true})}
                placeholder={t('chat.input placeholder')}
              />
              <div className="cursor-pointer mt-3" onClick={() => handleSubmit(sendMessage)()}>
                <SendSVG width={40} height={30}/>
              </div>
            </div>
          </div> :
          <div className={s.select_chat}>{t('chat.select chat')}</div>}
      </div>
    </div>
  );
};


export default Chat;
