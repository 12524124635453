import React, {useMemo} from 'react';
import FilterContainer from "../FilterContainer";
import CheckboxFilter from "../FilterContainer/CheckboxFilter";
import {useTranslation} from "react-i18next";
import {getActivityCategories} from "../../ChooseActivityCategories/categories";

const FilterUECategories = ({onChange}) => {
  const {t} = useTranslation();

  const activityCategories = useMemo(() => getActivityCategories(), [t]);


  return (
    <FilterContainer title={t('experience_details.filters.categories')}>
      <CheckboxFilter
        onChange={() => onChange()}
        getData={async () => {
          return {categories: activityCategories.map(c => ({code: c.id, description: c.label}))};
        }}
        name="categories"
      />

    </FilterContainer>
  );
};


export default FilterUECategories;