import React, {useState} from 'react';
import s from './Button.module.css';
import {useTranslation} from "react-i18next";


export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  loadButton?: boolean;
  loading?: boolean;
  type?: 'button' | 'submit' | 'reset';
  onClick?: ((e: React.MouseEvent<HTMLButtonElement>) => void) | ((e: React.MouseEvent<HTMLButtonElement>) => Promise<void>);
  className?: string;
  style?: React.CSSProperties;
}

const Button = ({
                  children,
                  loadButton = false,
                  loading = false,
                  type = 'submit',
                  onClick,
                  className = '',
                  style = {},
                  ...props
                }: ButtonProps) => {
  const {t} = useTranslation();
  const [_loading, _setLoading] = useState(false);

  const _onClick = async (e: any) => {
    if (typeof onClick !== 'function') return;
    if (loadButton) {
      _setLoading(true);
      await onClick(e);
      _setLoading(false);
    } else {
      onClick(e);
    }
  }

  const fLoading = loading || _loading;

  return (
    <button style={style} disabled={fLoading} type={type} className={`${s.button} ${className}`}
            onClick={_onClick} {...props}>
      {fLoading ? t('loading') : children}
    </button>
  );
};


export default Button;
