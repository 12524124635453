

const info = (msg) => {
  console.log(msg);
}
const error = msg => {
  console.error(msg);
}

const logger = {
  info,
  error
}

export default logger;
