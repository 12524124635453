import React from 'react';
import s from './Terms.module.css';
import AvisoLegal from "./AvisoLegal";
import CondicionesGenerales from "./CondicionesGenerales";
import Header from "../Header/Header";
import {Tab, Tabs} from "@mantine/core";
import PoliticasCookies from "./PoliticasCookies";
import PoliticaPrivacidad from "./PoliticaPrivacidad";
import {Footer} from "../FooterV2/Footer";
import {useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import i18n from "i18next";

const Terms = props => {
  const {t} = useTranslation()
  const [params, setParams] = useSearchParams({
    tab: 0
  });
  const lang = i18n.language;

  const tab = params.get('tab') * 1

  return (
    <>
      <Header/>
      <div className={s.content}>
        <Tabs active={tab} onTabChange={(n) => setParams({tab: n.toString()})}>
          <Tab className="w-full lg:w-auto" label={t('terms titles.aviso legal')}><AvisoLegal lang={lang}/></Tab>
          <Tab className="w-full lg:w-auto"
               label={t('terms titles.privacy')}><PoliticaPrivacidad lang={lang}/></Tab>
          <Tab className="w-full lg:w-auto"
               label={t('terms titles.general conditions')}><CondicionesGenerales lang={lang}/></Tab>
          <Tab className="w-full lg:w-auto" label={t('terms titles.cookies')}><PoliticasCookies lang={lang}/></Tab>
        </Tabs>
      </div>
      <Footer/>
    </>
  );
};


export default Terms;