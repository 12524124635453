import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import s from './Header.module.css';
import SelectLanguage from "../SelectLanguage";
import SelectCurrency from "../SelectCurrency";
import ProfileMenu from "../ProfileMenu";
import HamburgerIcon from "../ProfileMenu/HamburgerIcon";
import useAuth from "../../providers/AuthProvider/useAuth";
import {useNavigate} from "react-router-dom";
import Menu from "../Menu";

import logoWhite from "../../resources/images/logo/logo_text_white.png";
import logoDefault from "../../resources/images/logo/logo_text.png";
import {RegisterModal} from "../../pages/RegisterPage/RegisterModal";
import ProfileIcon from "../ProfileMenu/ProfileIcon";


export enum HeaderStyleType {
  white, default
}

interface HeaderProps {
  menuOpen?: boolean,
  styleType?: HeaderStyleType,
  sticky?: boolean
}

const Header = ({menuOpen = false, styleType = HeaderStyleType.default, sticky}: HeaderProps) => {
  const {isAuthenticated} = useAuth();
  const navigate = useNavigate();
  const hamburgerRef = useRef();
  const parentRef = useRef<HTMLDivElement>(null);
  const [registerModal, setRegisterModal] = React.useState(false);


  const style = useMemo(() => {
    switch (styleType) {
      case HeaderStyleType.white:
        return {logoURL: logoWhite, textColor: '#fff'};
      case HeaderStyleType.default:
        return {logoURL: logoDefault, textColor: '#000'};
    }
  }, [styleType]);

  const onScroll = useCallback(() => {
    if (!parentRef.current) return;

    if (window.scrollY > 0) {
      parentRef.current.classList.add(s.sticked);
    } else {
      parentRef.current.classList.remove(s.sticked);
    }
  }, [parentRef]);


  useEffect(() => {
    if (sticky) {
      window.addEventListener('scroll', onScroll);
    }
    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll]);


  const rightElementsStyle = {"--header-color": style.textColor} as React.CSSProperties;

  return (
    <>
      <RegisterModal visible={registerModal} setVisible={setRegisterModal}/>
      <div
        className={`${sticky ? 'sticky bg-white' : 'relative'} top-0 w-100 p-4 lg:p-7 flex justify-between z-20`}
        ref={parentRef}>
        <div className={`flex-none font-bold cursor-pointer ${sticky ? '' : 'sm:m-5'}`}>
          <div onClick={() => navigate('/')} className={s.logo} style={{backgroundImage: `url(${style.logoURL})`}}/>
        </div>
        <div className={s.rightElements} style={rightElementsStyle}>
          <SelectLanguage/>
          <SelectCurrency/>
          {isAuthenticated() ? <ProfileMenu/> :
            <div className={s.iconContainer}
                 onClick={() => setRegisterModal(true)}><ProfileIcon width={18} height={18}/></div>
          }
          {/* @ts-ignore*/}
          <HamburgerIcon ref={hamburgerRef} open={menuOpen}/>
        </div>
        <Menu triggerRef={hamburgerRef}/>
      </div>
    </>
  );
};


export default Header;
