import React, {useEffect} from 'react';
import s from './FilterButton.module.css';
import {useTranslation} from "react-i18next";
import {GrFilter} from "react-icons/gr";

const FilterButton = ({visible, changed, setVisible, onFilter}) => {
    const {t} = useTranslation();

    useEffect(() => {
        return () => {
            document.body.style.overflow = 'auto';
        }
    }, []);

    return (
        <div className={s.container} onClick={() => {
            setVisible(prevVisible => {
                if (prevVisible) {
                    document.body.style.overflow = 'auto';
                } else {
                    document.body.style.overflow = 'hidden';
                }
                if (prevVisible && changed) onFilter();
                return !prevVisible;
            });
        }}>
            {visible && <div className="mr-5">
                {t('filter.filter')}
            </div>}
            <GrFilter size={24}/>
        </div>
    );
};


export default FilterButton;