import React, {useCallback, useEffect, useMemo, useRef} from 'react';

import LightGallery from 'lightgallery/react';


const GalleryPopup = ({images, titles = {}, index, setIndex, isOpen, setIsOpen}) => {
  //if (!isOpen) return <></>;
  const lightGallery = useRef(null);

  let items = useMemo(() => {
    if (!Array.isArray(images)) return [];
    return images.map(src => ({src}));
  }, [images]);

  useEffect(() => {
    lightGallery.current.refresh(items);
  }, [items]);

  useEffect(() => {
    if (isOpen === true) lightGallery.current.openGallery(index);
  }, [isOpen]);

  const onInit = useCallback((detail) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  return (
    <LightGallery
      onInit={onInit}
      dynamic
      dynamicEl={items}
      //plugins={[lgZoom]}
      download={false}
      mobileSettings={{showCloseIcon: true, download: false}}
      onAfterClose={() => {
        setIsOpen(false)
      }}
    />
  );
};


export default GalleryPopup;
