import React, {useEffect, useState} from 'react';
import s from './UniqueExperienceActivities.module.css';
import {useTranslation} from "react-i18next";
import useActivity from "../../../controllers/useActivity/useActivity";
import Skeleton from "react-loading-skeleton";
import Slider from "../../Slider";
import SmallActivity from "../../SmallActivity";

const Loading = () => <div>
  <Skeleton count={1} height={200}/>
</div>
const UniqueExperienceActivities = ({activities: _activities, toLoad = false}) => {
  const {t} = useTranslation();

  const {getActivitiesByCode} = useActivity();
  const [activities, setActivities] = useState();
  const [error, setError] = useState(false);

  const loadActivities = async () => {
    const {error, activities} = await getActivitiesByCode(_activities.map(a => ({
      code: a.activity_id,
      api: a.api,
    })));
    if (error) return setError(error);


    setActivities(activities);
  }

  useEffect(() => {
    if (_activities && toLoad) {
      loadActivities();
    } else if (_activities) {
      setActivities(_activities);
    }
  }, [_activities]);

  if (!Array.isArray(activities) && !error) return <Loading/>;

  if (error) return <div className="text-2xl m-10">{t(`errors.${error}`)}</div>

  return (
    <div className={s.container}>
      <Slider elements={activities.map((activity, i) => <SmallActivity key={i} activity={activity} showMore={true}/>)}/>
    </div>
  );
};


export default UniqueExperienceActivities;