import {query} from "../../helpers/query";
import {Police} from "../../types/insurance";
import {Dayjs} from "dayjs";
import {ContractedInsurance} from "./types";


export const useInsurance = () => {
  // purchases : [boolean, boolean, boolean] | [hotel, activity, experience]
  // countryCodes = ISO2
  const getInsurances = async ({from, to, residenceCountries, destinationCountries, paxes, purchases}: {
    from: Dayjs,
    to: Dayjs,
    residenceCountries: string[],
    destinationCountries: string[],
    paxes: number,
    purchases: [boolean, boolean, boolean]
  }) => {
    const {error, data} = await query('/api/insurance/select', {
      from,
      to,
      residenceCountries,
      paxes,
      destinationCountries,
      purchases
    }, 'POST');
    return {error, insurances: data?.insurances as Police[]};
  }
  const downloadDocument = async ({purchase_id, insurance_id, document_id}: {
    purchase_id?: string,
    insurance_id?: string,
    document_id: string
  }) => {
    let r = await fetch(`/api/insurance/document/${document_id}`, {
      mode: 'cors',
    });
    let blob = await r.blob();
    blob = blob.slice(0, blob.size, 'application/pdf');
    let fileURL = URL.createObjectURL(blob);
    window.open(fileURL);
  }


  const getContractedInsurances = async (active?: boolean): Promise<{
    error: any,
    insurances: ContractedInsurance[]
  }> => {
    const {error, data} = await query(`/api/user/insurance/contracted`, {active: active ? 1 : 0}, 'GET');
    return {error, insurances: data?.insurances};
  }

  return {getInsurances, getContractedInsurances, downloadDocument}
}

export default useInsurance;