import React, {useMemo, useState} from 'react';
import s from './HotelServiceList.module.css';
import sDetails from '../HotelDetailsInfo/HotelDetailsInfo.module.css';
import {useTranslation} from "react-i18next";
import Button from "../../../../Inputs/Button/Button";
import {Modal} from "@mantine/core";
import HotelDetailsAllFacilities from "../HotelDetailsAllFacilities";
import {createOutstandingServices} from "../../../util";
import {useViewportSize} from "@mantine/hooks";

const HotelServiceList = ({hotel}) => {
  const {t} = useTranslation();

  const [allServiceModal, setAllServiceModal] = useState(false);

  const {width} = useViewportSize();

  const list = useMemo(() => createOutstandingServices(hotel.services), [hotel]);

  if (list.length === 0) return null;


  return <>
    <Modal size={width > 1000 ? '80%' : '98%'} centered={true} opened={allServiceModal}
           onClose={() => setAllServiceModal(false)}>
      <HotelDetailsAllFacilities hotel={hotel} services={hotel.services}/>
    </Modal>
    <div className={sDetails.boxContainer} id="hotel_my_services">
      <div className={sDetails.boxTitle}>{t('hotel.outstanding services')}</div>
      <div className="flex flex-nowrap justify-between items-end xl:items-center pl-4 pr-20 my-10">
        <div className={s.outstanding_services}>
          {list.slice(0, 6).map((service, i) => <div
            className={s.services_item} key={i}>
            <div>
              {service.icon}
            </div>
            <div className="ml-5 my-3 whitespace-normal">{t(service.label)}</div>
          </div>)}
        </div>
        <Button
          className={s.show_all_services_button}
          onClick={() => setAllServiceModal(true)}>
          {t('hotel.show all services')}
        </Button>
      </div>
    </div>
  </>

};


export default HotelServiceList;