import React from 'react';
import s from '../UniqueExperienceRequestBudged.module.css';
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";
import Input from "../../../Inputs/Input";
import {ReactComponent as EmailSVG} from "../../../../resources/svg/message_box.svg";
import {ReactComponent as WhatsappSVG} from "../../../../resources/svg/social_media/whatsapp.svg";

const UEBContact = props => {
	const {t} = useTranslation();
	const form = useFormContext();

	const {register} = form;

	return (
		<div>
			<div className={s.title}>{t('experience_details.rbd.contact')}</div>
			<div className={s.inputs_container}>
				<Input
					{...register("email")}
					containerClassName="sm:mr-10 sm:mb-0 mb-10"
					placeholder={t('experience_details.rbd.email')}
					icons={{
						left: {icon: <EmailSVG width={32} height={20} fill="#666"/>}
					}}
				/>
				<Input
					{...register("whatsapp")}
					placeholder={t('experience_details.rbd.whatsapp')}
					icons={{
						left: {icon: <WhatsappSVG width={40} height={40}/>}
					}}
				/>
			</div>
			<div className={s.info_box}>
				{t('experience_details.rbd.contact info')}
			</div>
		</div>
	);
};


export default UEBContact;