import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Title from "../../TextComponents/Title";
import DatePickerWithLabel from "../../Inputs/DatePickerWithLabel";
import Button from "../../Inputs/Button/Button";
import bases from "../../../routes/bases";
import {ReactComponent as ArrowButtonSVG} from "../../../resources/svg/arrow_button_right.svg";
import LocationInput from "../../Inputs/LocationInput";

const UniqueExperienceChooseData = props => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  useEffect(() => window.scrollTo(0, 0), []);


  const onSubmit = data => {

  }


  return (
    <div className="w-full">
      <Title className="w-full mb-10 block text-center">{t("search_experiences.your trip")}</Title>
      <div className="w-full flex flex-wrap sm:flex-nowrap">
        <div className="w-full sm:w-2/3">
          <LocationInput
            label={t("input.origin")}
            id="origin"
            name="origin"
            containerClassName="mr-10"
          />
        </div>

        <div className="w-full sm:w-1/3 sm:ml-10 mt-5 sm:mt-0">
          {/*<ActivityPassengerNumber name="activity_passenger_number" id="activity_passenger_number"/>*/}
          <DatePickerWithLabel
            label={t("input.departure_date")}
            name="departure"
            id="departure"
          />
        </div>
      </div>

      <div className="w-full flex justify-center mt-10">
        <div className="w-72 mr-10">
          <Button onClick={() => navigate(bases.uniqueExperienceSelectCountries)}>
            {t('prev')}
            <ArrowButtonSVG style={{fill: '#fff'}} className="inline-block ml-5 transform rotate-180"/>
          </Button>
        </div>
        <div className="w-72">
          <Button type="submit">
            {t('search')}
            <ArrowButtonSVG style={{fill: '#fff'}} className="inline-block ml-5"/>
          </Button>
        </div>
      </div>
    </div>
  );
};


export default UniqueExperienceChooseData;