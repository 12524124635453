import React from "react";
import {ReactComponent as WifiSVG} from "../../../../resources/svg/services/wifi.svg";
import {ReactComponent as CrippleSVG} from "../../../../resources/svg/services/cripple.svg";
import {ReactComponent as RoomServiceSVG} from "../../../../resources/svg/services/room_service.svg";
import {ReactComponent as FridgeSVG} from "../../../../resources/svg/services/fridge.svg";
import {ReactComponent as AirConditioningSVG} from "../../../../resources/svg/services/air_conditioning.svg";
import {ReactComponent as ParkingSVG} from "../../../../resources/svg/services/parking.svg";
import {ReactComponent as DryerSVG} from "../../../../resources/svg/services/dryer.svg";
import {FaSwimmingPool} from "react-icons/fa";
import {BiDrink, BiRestaurant} from "react-icons/bi";
import {CgGym, CgScreen} from "react-icons/cg";
import {BsWater} from "react-icons/bs";
import {RiTempHotLine} from "react-icons/ri";
import {MdEmojiTransportation, MdLocalDrink, MdLuggage, MdOutlinePets} from "react-icons/md";
import {GrCurrency, GrElevator} from "react-icons/gr";
import {GiCigarette} from "react-icons/gi";
import {ServiceCodes} from "../../../../types/hotels/serviceCodes";

export const serviceGroups = [
  {label: 'to.hotel.facilities.groups.building', value: 'building'},
  {label: 'to.hotel.facilities.groups.services', value: 'services'},
  {label: 'to.hotel.facilities.groups.hostelry', value: 'hostelry'},
  {label: 'to.hotel.facilities.groups.other', value: 'other'}
];


let iconServiceStyle1 = {size: 20};
let iconServiceStyle2 = {width: 20, height: 20};

export const localServiceList = [
  {label: 'to.hotel.facilities.pool', value: 'pool', group: 'building', icon: <FaSwimmingPool {...iconServiceStyle1}/>},
  {
    label: 'to.hotel.facilities.parking',
    value: 'parking',
    group: 'building',
    icon: <ParkingSVG {...iconServiceStyle2}/>
  },
  {label: 'to.hotel.facilities.wifi', value: 'wifi', group: 'services', icon: <WifiSVG {...iconServiceStyle2}/>},
  {
    label: 'to.hotel.facilities.restaurant',
    value: 'restaurant',
    group: 'hostelry',
    icon: <BiRestaurant {...iconServiceStyle1}/>
  },
  {label: 'to.hotel.facilities.bar', value: 'bar', group: 'hostelry', icon: <BiDrink {...iconServiceStyle1}/>},
  {label: 'to.hotel.facilities.gym', value: 'gym', group: 'building', icon: <CgGym {...iconServiceStyle1}/>},
  {label: 'to.hotel.facilities.spa', value: 'spa', group: 'building', icon: <BsWater {...iconServiceStyle1}/>},
  {
    label: 'to.hotel.facilities.sauna',
    value: 'sauna',
    group: 'building',
    icon: <RiTempHotLine {...iconServiceStyle1}/>
  },
  {
    label: 'to.hotel.facilities.airport shuttle',
    value: 'airport shuttle',
    group: 'other',
    icon: <MdEmojiTransportation {...iconServiceStyle1}/>
  },
  {
    label: 'to.hotel.facilities.24/7 hour reception',
    value: '24/7 hour reception',
    group: 'services',
    icon: <RoomServiceSVG {...iconServiceStyle2}/>
  },
  {
    label: 'to.hotel.facilities.disability access',
    value: 'disability',
    group: 'building',
    icon: <CrippleSVG {...iconServiceStyle2}/>
  },
  {
    label: 'to.hotel.facilities.elevator',
    value: 'elevator',
    group: 'building',
    icon: <GrElevator {...iconServiceStyle1}/>
  },
  {
    label: 'to.hotel.facilities.smoking area',
    value: 'smoking area',
    group: 'building',
    icon: <GiCigarette {...iconServiceStyle1}/>
  },
  {
    label: 'to.hotel.facilities.air conditioning',
    value: 'air conditioning',
    group: 'services',
    icon: <AirConditioningSVG {...iconServiceStyle2}/>
  },
  {label: 'to.hotel.facilities.pets', value: 'pets', group: 'other', icon: <MdOutlinePets {...iconServiceStyle1}/>},
  {label: 'to.hotel.facilities.tv', value: 'tv', group: 'building', icon: <CgScreen {...iconServiceStyle1}/>},
  {label: 'to.hotel.facilities.dryer', value: 'dryer', group: 'building', icon: <DryerSVG {...iconServiceStyle2}/>},
  {
    label: 'to.hotel.facilities.luggage storage',
    value: 'luggage storage',
    group: 'services',
    icon: <MdLuggage {...iconServiceStyle1}/>
  },
  {
    label: 'to.hotel.facilities.currency exchange',
    value: 'currency exchange',
    group: 'services',
    icon: <GrCurrency {...iconServiceStyle1}/>
  },
  {
    label: 'to.hotel.facilities.minibar',
    value: 'minibar',
    group: 'hostelry',
    icon: <MdLocalDrink {...iconServiceStyle1}/>
  },
  {label: 'to.hotel.facilities.fridge', value: 'fridge', group: 'building', icon: <FridgeSVG {...iconServiceStyle2}/>},
];

export interface HotelServicesList {
  icon: React.JSX.Element,
  label: string,
  key: string,
  codes: ServiceCodes[]
}

export const hotelServicesList: HotelServicesList[] = [
  {icon: <WifiSVG/>, label: 'service.free wifi', key: 'wifi', codes: [ServiceCodes.wifiOrInternet]},
  {icon: <CrippleSVG/>, label: 'service.creeple', key: 'disabilities', codes: [ServiceCodes.disability]},
  //{icon: <RoomServiceSVG/>, label: 'service.room service', key: 'room_service', codes: [ServiceCodes.room]},
  {icon: <FridgeSVG/>, label: 'service.fridge', key: 'fridge', codes: [ServiceCodes.fridge]},
  {
    icon: <AirConditioningSVG/>,
    label: 'service.air conditioning',
    key: 'air_conditioning',
    codes: [ServiceCodes.airConditioning]
  },
  {icon: <ParkingSVG/>, label: 'service.parking', key: 'parking', codes: [ServiceCodes.parking]}
];

