import React, {useEffect, useState} from 'react';
import s from './MyProfileData.module.css';
import ProfileImage from "../../../resources/my_profile_menu/profile_image.png";
import {useTranslation} from "react-i18next";
import {ReactComponent as PersonSVG} from "../../../resources/my_profile/person.svg";
import {ReactComponent as CakeSVG} from "../../../resources/my_profile/cake.svg";
import {ReactComponent as SexSVG} from "../../../resources/my_profile/sex.svg";
import {ReactComponent as LocationSVG} from "../../../resources/my_profile/location.svg";
import {ReactComponent as EmailSVG} from "../../../resources/my_profile/email.svg";
import {ReactComponent as PhoneSVG} from "../../../resources/my_profile/phone.svg";
import NormalText from "../../TextComponents/NormalText";
import bases from "../../../routes/bases";
import Button from "../../Inputs/Button/Button";
import {NavLink} from "react-router-dom";
import useUser from "../../../controllers/useUser/useUser";
import dayjs from "dayjs";


const PersonData = ({Image, title, content}) =>
  <div className="flex items-center flex-nowrap text-white my-4">
    <div className="mr-5 pt-2"><Image style={{fill: '#fff'}} width={24} height={24}/></div>
    <div>
      <NormalText className="block text-white sm:block hidden">{title}</NormalText>
      <div className={s.person_data_content}>{content}</div>
    </div>
  </div>

const MyProfileData = props => {
  const {t} = useTranslation();

  const {getUser} = useUser();
  const [user, setUser] = useState();

  const loadUser = async () => {
    const {error, user} = await getUser();
    if (error) return alert(error);
    setUser(user);
  }
  useEffect(() => {
    loadUser();
  }, []);

  const getYears = () => {
    let m = dayjs(user.birthday);
    if (!m.isValid()) return t('unknown');
    return Math.abs(m.diff(dayjs(), 'year'))
  }


  if (!user) return 'Cargando...';

  return (
    <div className="w-full h-full flex flex-wrap items-center p-4 px-8"
         style={{background: '#1C7263', borderRadius: '15px'}}>
      <div className={s.profileImage_container} style={{height: '160px'}}>
        <img src={ProfileImage} alt={t('alt.profile_image')}
             className="shadow-lg p-8 bg-white rounded-full max-w-full h-auto align-middle border-none"/>
      </div>
      <div className="w-full flex flex-wrap px-5">
        <div className="">
          {[
            {
              Image: PersonSVG,
              title: t('my profile show.name and surname'),
              content: `${user.name} ${user.surname || ''}`
            },
            {Image: CakeSVG, title: t('my profile show.years'), content: getYears()},
            {
              Image: SexSVG,
              title: t('my profile show.gender'),
              content: user.gender ? t(`gender.${user.gender}`) : t('unknown')
            },
            {
              Image: LocationSVG,
              title: t('my profile show.nationality'),
              content: user.nationality ? t(`countryList.${user.nationality}`) : t('unknown')
            },
            {Image: EmailSVG, title: t('my profile show.email'), content: user.email},
            {Image: PhoneSVG, title: t('my profile show.phone'), content: user.phone}
          ].map((data, i) => <PersonData key={i} {...data} content={data.content || t('unknown')}/>)}
          <div className="flex my-10">
            <NavLink style={{marginRight: '15px', display: 'block'}} to={bases.profile_edit}><Button
              className={s.edit_button}>{t('edit')}</Button></NavLink>
            {/*            <NavLink to={bases.profile_edit}><Button className={s.visited_countries}>
              {t('visited countries')}
            </Button></NavLink>*/}
          </div>
        </div>

      </div>

    </div>
  );
};


export default MyProfileData;
