import {useState} from 'react';

const getItem = (key) => {
	try {
		return JSON.parse(localStorage.getItem(key)) || null;
	} catch (error) {
		// QUOTA_EXCEEDED
		if (error.code === 22 || error.code === 1014 || [-2147024882, -2146828281, -21474675259].indexOf(error.number) > 0) {
			// think about what to do

			// LocalStorage is disabled
		} else if (error.code === 18 || error.code === 1000 || error.name === 'TypeError') {
			alert('Your browser need to be updated to use this website');
		}

		return ''
	}
}
const setItem = (key, value) => {
	return localStorage.setItem(key, JSON.stringify(value));
}
export const clearItem = (key) => {
	localStorage.removeItem(key);
}

export const useLocalStorage = (key, def) => {
	let initialState = getItem(key) || def;

	const [storedValue, setStoredValue] = useState(initialState);
	const setValue = value => {
		try {
			const valueToStore = value instanceof Function ? value(storedValue) : value;
			setItem(key, valueToStore);
			setStoredValue(valueToStore);
		} catch (err) {
		}
	};

	const clearData = () => {
		clearItem(key);
		setValue(def);
	};

	return [storedValue, setValue, clearData];
};


export default useLocalStorage;
