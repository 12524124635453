import React, {useEffect, useState} from 'react';
import s from './MyLikes.module.css';
import {useTranslation} from "react-i18next";
import useUser from "../../../controllers/useUser/useUser";


import {ReactComponent as HotelSVG} from "../../../resources/svg/bed.svg";
import {ReactComponent as HeartSVG} from "../../../resources/svg/likeBigHeart.svg";
import {ReactComponent as ExperienceSVG} from "../../../resources/my_profile_menu/experiences.svg";
import {ReactComponent as SearchSVG} from "../../../resources/svg/serach.svg";


import MiddleText from "../../TextComponents/MiddleText";
import moment from "moment";
import bases from "../../../routes/bases";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {buildImagePath, hotelDefaultImage} from "../../Hotels/util";
import {buildImages} from "../../../helpers/uniqueExperiences";
import {langRender} from "../../../helpers/i18n";
import Hotel from "../../MainSearch/SearchHotels/Hotel";
import dayjs from "dayjs";
import {hotelsObjToParams} from "../../Hotels/HotelDetails/util";

const Like = ({data, onClick}) => {
  const {t} = useTranslation();

  let Icon, image, text, destinationName, date;
  if (data.type === 'hotel') {
    Icon = <HotelSVG fill="#757575"/>;
    image = data.images && data.images[0].path ? buildImagePath(data, data.images[0].path) : hotelDefaultImage;
    text = `Hotel ${data.name} ${t('in')}`;
    destinationName = data.destinationName;
    date = moment(data.created_at).format('D MMM YYYY')
  } else if (data.type === 'experience') {
    Icon = <ExperienceSVG fill="#757575"/>;
    image = buildImages(data)[0];
    text = `Experience ${langRender(data.title)} ${t('in')}`;
  }


  return (
    <div onClick={onClick} className={s.likeContainer} style={{backgroundImage: `url("${image}")`}}>
      <div className={s.likeTypeIcon}>
        {Icon}
      </div>
      <div className={s.likeInfo}>
        <HeartSVG/>
        {text}
        {destinationName && <b>{destinationName}</b>}
        {date && <p>{date}</p>}
      </div>
    </div>
  );
}


const MyLikes = props => {
  const {t} = useTranslation();
  const {getLikes} = useUser();
  const navigate = useNavigate();

  const form = useForm();

  const {register, handleSubmit, watch} = form;
  const searchText = watch('search');

  const [likes, setLikes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(false);

  const [filters, setFilters] = useState([]);

  const loadLikes = async (_filters) => {
    setLoading(true);
    _filters = _filters || filters;
    const {error, likes} = await getLikes({type, q: searchText, filters: _filters});
    if (error) return alert(t(`error.${error}`));
    setLikes(likes);
    setLoading(false);
  }

  const onLikeClick = like => {
    if (like.type === 'hotel') {
      setLikes(likes.filter(l => l._id !== like._id));
    } else if (like.type === 'experience') {
      navigate(bases.uniqueExperienceDetails + '/' + like._id);
    }
  }

  const toggleFilter = filter => {
    let f = [...filters];
    let i = f.indexOf(filter);
    if (i === -1) {
      f.push(filter);
    } else {
      f.splice(i, 1);
    }
    setFilters(f);
    loadLikes(f);
  }


  useEffect(() => {
    loadLikes();
  }, []);


  const onSearch = data => {
    loadLikes();
  }

  const onMoreInfo = (hotel) => {
    let params = {
      from: moment().toISOString(),
      to: moment().add(5, 'days').toISOString(),
      rooms: [{adults: 1, children: 0, childrenAge: []}],
      code: hotel.code,
      api: hotel.api
    };

    navigate(bases.hotelDetails + '?' + hotelsObjToParams(params));
  }


  return (
    <div className="w-full">
      <form onSubmit={handleSubmit(onSearch)}>
        <MiddleText className="my-6 font-bold">{t('my likes')}</MiddleText>
        <div className={s.filterBar}>
          {loading && <div className={s.loadingBar}/>}
          {/*<div className={`${s.svgContainer} ${filters.includes('plane') ? s.svgChecked : ''}`}*/}
          {/*     onClick={() => toggleFilter('plane')}><PlaneSVG/></div>*/}
          {/*<div className={`${s.svgContainer} ${filters.includes('hotel') ? s.svgChecked : ''}`}*/}
          {/*     onClick={() => toggleFilter('hotel')}><HotelSVG/></div>*/}
          {/*<div className={`${s.svgContainer} ${filters.includes('activity') ? s.svgChecked : ''}`}*/}
          {/*     onClick={() => toggleFilter('activity')}><ActivitySVG/></div>*/}
          {/*<div className={`${s.svgContainer} ${filters.includes('packet') ? s.svgChecked : ''}`}*/}
          {/*     onClick={() => toggleFilter('packet')}><PacketSVG/></div>*/}
          {/*<div className={`${s.svgContainer} ${filters.includes('experience') ? s.svgChecked : ''}`}*/}
          {/*     onClick={() => toggleFilter('experience')}><ExperienceSVG/>*/}
          {/*</div>*/}
          {/*<div className={`${s.svgContainer} ${filters.includes('profile') ? s.svgChecked : ''}`}*/}
          {/*     onClick={() => toggleFilter('profile')}><ProfileSVG/></div>*/}
          <div className={s.searchBarContainer}>
            <input
              {...register('search')}
              placeholder={t('search') + '...'}
              spellCheck="false"
            />
            <button>
              <SearchSVG/>
            </button>
          </div>
        </div>
      </form>
      <div className={s.likesContainer}>
        {/*{likes.map((like, i) => <Like onClick={() => onLikeClick(like)} data={like} key={i}/>)}*/}
        {likes.map((like, i) => <Hotel onLike={onLikeClick} onMoreInfo={() => onMoreInfo(like)} checkIn={dayjs()}
                                       checkOut={dayjs().add(5, 'days')}
                                       hotel={like} key={i}/>)}
      </div>

    </div>
  );
};


export default MyLikes;
