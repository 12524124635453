import React from 'react';
import Header from "../Header/Header";
import {Outlet} from "react-router-dom";
import {Footer} from "../FooterV2/Footer";

const MainSearch = props => {
  return (
    <div>
      <Header sticky={true}/>
      <Outlet/>
      <Footer/>
    </div>
  );
};


export default MainSearch;
