import React from 'react';
import s from './MiddleText.module.css';

const MiddleText = ({children, className = '', ...props}) => {
  const _className = `${s.middle} ${className}`;
  return (
    <div className={_className} {...props}>{children}</div>
  );
};


export default MiddleText;
