import {useTranslation} from 'react-i18next';
import s from './HomePage.module.css';
import React from "react";
import {CgFacebook, CgInstagram} from "react-icons/cg";
import {useNavigate} from "react-router-dom";
import config from "../../config";

export function SmallFooter() {
  const {t} = useTranslation();
  const navigate = useNavigate();

  return <div className={s.footerContainer}>
    <div className={s.footerLinksContainer}>
      <div onClick={() => navigate('/terms?tab=3')}>{t('home small footer.cookies')}</div>
      <div onClick={() => navigate('/terms?tab=1')}>{t('home small footer.privacy')}</div>
      <div onClick={() => navigate('/terms?tab=0')}>{t('home small footer.legal')}</div>
    </div>
    <div className={s.socialsContainer}>
      <CgInstagram onClick={() => window.open(config.instagram, '_blank')} stroke="#fff"
                   size={30}/>
      <CgFacebook onClick={() => window.open(config.facebook, '_blank')}
                  style={{fill: '#fff'}} size={30}/>
    </div>
  </div>
}