import React from 'react';
import s from './InsuranceSummary.module.css';
import {useTranslation} from "react-i18next";
import CurrencyNumber from "../../../CurrencyNumber";
import cs from '../CheckoutSummary.module.css';

const InsuranceSummary = ({insurance}) => {
	const {t} = useTranslation();

	if(!insurance)return <></>;

	return (
		<div>
			<table>
				<tbody>
				<tr>
					<th colSpan={2}>{t('checkout.insurance')}</th>
					<td className={cs.summary_table_price}><CurrencyNumber>{insurance.price}</CurrencyNumber></td>
				</tr>
				</tbody>
			</table>
		</div>
	);
};


export default InsuranceSummary;