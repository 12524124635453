import React from 'react';
import s from './UEBPackageElements.module.css';
import {useTranslation} from "react-i18next";
import {buildImagePath, createOutstandingServices, generateStars} from "../../../../Hotels/util";
import Image from "../../../../Image/Image";
import config from "../../../../../config";
import {icons} from "../../../../../helpers/transportIcons";
import {MdHotel, MdLocalActivity} from "react-icons/md";
import {langRender} from "../../../../../helpers/i18n";

export const HotelElement = ({hotel, onClick}) => {
  const {t} = useTranslation();
  let image = Array.isArray(hotel.images) ? buildImagePath(hotel, hotel.images[0]?.path) : '';
  let nights = Math.abs(hotel.to_day - hotel.from_day);

  return <div className={s.elContainer} onClick={onClick}>
    <div className={s.elementIcon}><MdHotel size={22}/></div>
    <div className={s.elImageContainer}>
      <Image src={image} alt={`${langRender(hotel.name)} main`}/>
    </div>
    <div>
      <div className={s.elTitle}>{langRender(hotel.name)}</div>
      <div className="w-full flex flex-nowrap relative items-center">
        {generateStars(hotel)}
        {nights && <div className={s.nights}>{nights} {t('hotel.nights')}</div>}
      </div>
      <div className="w-full flex items-center">
        {createOutstandingServices(hotel).slice(0, 6).map((service, i) => <div className="mr-5 my-3" key={i}>
          {service.icon}
        </div>)}
      </div>
      {/*
			<div className={s.elShow_more} onClick={() => onShowMore && onShowMore(hotel)}>{t('hotel.show more')}</div>
*/}

    </div>
  </div>
}


export const ActivityElement = ({activity, onClick}) => {
  const image = config.imagesURL + '/' + activity.images[0];
  return <div className={s.elContainer} onClick={onClick}>
    <div className={s.elementIcon}><MdLocalActivity size={22}/></div>

    <div className={s.elImageContainer}>
      <Image src={image} alt={`${langRender(activity.name)} main`}/>
    </div>
    <div>
      <div className={s.elTitle}>{langRender(activity.name)}</div>
      {/*            <div>{cutText(langRender(activity.description), 100)}</div>*/}
    </div>
  </div>
}

export const TransportElement = ({transport}) => <div className={s.transportContainer}>
  {icons[transport.type] && <div className={s.icon}>{icons[transport.type]}</div>}
</div>
