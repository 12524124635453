import React, {useState} from 'react';
import s from './PurchasedHotelDetailsRooms.module.css';
import {useTranslation} from "react-i18next";
import {buildImagePath} from "../../../../Hotels/util";
import {useViewportSize} from "@mantine/hooks";
import {RoomTypeWithImage} from "../../../../Hotels/HotelDetails/components/HotelDetailsRoomsTable/RoomTypeWithImage";
import HotelRoomDetails from "../../../../Hotels/HotelRoomDetails/HotelRoomDetails";
import {Modal} from "@mantine/core";

const ImageRoom = ({room, hotel}) => {
  const {images} = hotel;
  let image = images.find(image => image.roomCode === room.code);
  if (!image) return <></>
  return <div className={s.roomImage}>
    <img src={buildImagePath(hotel, image.path)} alt={room.name}/>
  </div>
}

const PurchasedHotelDetailsRooms = ({hotel}) => {
  const {t} = useTranslation();
  const [selectedRoom, setSelectedRoom] = useState();
  const {width} = useViewportSize();


  return <>
    <Modal size={width > 1000 ? "80%" : '98%'} opened={!!selectedRoom} onClose={() => setSelectedRoom(undefined)}>
      {/*<HotelDetailsShowRoomFacilities room={room} hotel={hotel}/>*/}
      {selectedRoom && <HotelRoomDetails hotel={hotel} room={selectedRoom}/>}
    </Modal>
    <div id="hotel_my_rooms">

      <div className="font-bold text-3xl my-5">{t('purchased details data.my rooms')}</div>

      <div className="w-full flex">
        {hotel.rooms.map((room, i) => <RoomTypeWithImage key={i} className={s.room} hotel={hotel} room={room}
                                                         setSelectedRoom={(a) => {
                                                           setSelectedRoom(a)
                                                         }}/>)}
      </div>
    </div>
  </>
};


export default PurchasedHotelDetailsRooms;