import React from 'react';
import AvisoLegalES from "./AvisoLegalES";
import AvisoLegalEN from "./AvisoLegalEN";

const AvisoLegal = ({lang}) => {
  if (lang === 'es-ES') {
    return <AvisoLegalES/>
  } else {
    return <AvisoLegalEN/>
  }
};


export default AvisoLegal;