import React, {useState} from 'react';
import Skeleton from "react-loading-skeleton";
import {useTranslation} from "react-i18next";
import useActivitiesProvider from "../../../../providers/ActivitiesProvider/useActivitiesProvider";
import {FormProvider} from "react-hook-form";
import FilterContainer from "../../../Filters/FilterContainer";
import RangeSlider from "../../../Inputs/RangeSlider";
import CheckboxFilter from "../../../Filters/FilterContainer/CheckboxFilter";
import useActivity from "../../../../controllers/useActivity/useActivity";
import Button from "../../../Inputs/Button/Button";
import {Affix} from "@mantine/core";
import FilterButton from "../../../Filters/FilterButton";


const Loading = () => <div className="mt-10">
  <Skeleton count={4} height={200}/>
</div>

const FilterActivities = ({desktop, fVisible, setFVisible}) => {
  const {t} = useTranslation();
  const {getCategories} = useActivity();

  const {allowedFilters, filterForm, updateActivities, loading} = useActivitiesProvider();

  const [changed, setChanged] = useState(false);


  const onFilterChange = () => {
    setChanged(true);
    //updateActivities({onlyFilters: true});
  }

  const onFilter = () => {
    setChanged(false);
    updateActivities({onlyFilters: true});
  }


  if (!allowedFilters || (loading === 1 || loading === 3)) return <Loading/>

  return (
    <div>
      <Affix position={{right: 40, bottom: 80}}>
        <FilterButton changed={changed} onFilter={() => onFilter()} visible={fVisible} setVisible={setFVisible}/>
      </Affix>
      <FormProvider {...filterForm}>
        <FilterContainer initialVisible={true} title={t('filter.price')}>
          <RangeSlider
            name="activity_range_price"
            min={allowedFilters.minRate}
            max={allowedFilters.maxRate}
            defaultValue={[allowedFilters.minRate, allowedFilters.maxRate]}
            onMouseUp={onFilterChange}
          />
        </FilterContainer>
        <FilterContainer title={t("filter.categories")}>
          <CheckboxFilter
            filter={category => Array.isArray(allowedFilters.groupCodes) ? allowedFilters.groupCodes.includes(category.code) : true}
            onChange={() => onFilterChange()} getData={getCategories} name="categories" label="name"/>
        </FilterContainer>
      </FormProvider>
      {changed && <div className="w-1/2 m-auto mt-10">
        <Button type="button" onClick={() => {
          setChanged(false);
          updateActivities({updateFilters: true});
        }}>{t('filter.refresh activities')}</Button>
      </div>}
    </div>
  );
};


export default FilterActivities;
