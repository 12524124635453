import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";
import es from "../../StartYourTripRouter/StartYourTripRouter.module.css";
import {Button} from "../../Inputs/Button/Button";
import Input from "../../Inputs/Input/Input";

import {ReactComponent as EmailSVG} from "../../../../resources/svg/message_box.svg";
import useUser from "../../../../controllers/useUser/useUser";
import {validate} from "../../../../helpers/validate";
import {BsFillTelephoneFill} from "react-icons/bs";


const StartYourTripContact = ({onPrev, loading}) => {
  const {t} = useTranslation();
  const form = useFormContext();

  const {register} = form;
  const {getUser} = useUser();

  const canContinue = () => {
    const email = form.watch('contact.email');
    const phone = form.watch('contact.phone');
    const name = form.watch('person.name');
    const surname = form.watch('person.surname');
    return !(!email || !name || !surname || !phone) && validate.email(email) && validate.phone(phone);
  }

  const loadUser = async () => {
    const {error, user} = await getUser();
    if (error || !user) return;

    form.setValue('person.name', user.name);
    form.setValue('person.surname', user.surname);
    form.setValue('contact.email', user.email);
    form.setValue('contact.phone', user.phone);
  }

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className={es.title}>{t('start your trip.client data')}</div>
      <div className={es.subtitle}>{t('start your trip.client data subtitle')}</div>

      <div className="px-5 sm:px-0">
        <div className="m-auto flex flex-wrap sm:flex-nowrap mt-20 mb-5">
          <Input
            {...register("person.name")}
            containerClassName="sm:mr-10 sm:mb-0 mb-10"
            placeholder={t('start your trip.name')}
          />
          <Input
            {...register("person.surname")}
            placeholder={t('start your trip.surname')}
          />
        </div>

        <div className="w-full flex flex-wrap sm:flex-nowrap">
          <Input
            {...register("contact.email")}
            type="email"
            containerClassName="sm:mr-10 sm:mb-0 mb-5"
            placeholder={t('start your trip.email')}
            icon={<EmailSVG width={32} height={20} fill="#666"/>}
          />

          <Input
            {...register("contact.phone")}
            placeholder={t('start your trip.phone')}
            icon={<BsFillTelephoneFill fill="#666" size={20}/>}
          />
        </div>
      </div>


      <div className="flex justify-center mt-20">
        <div className={`${es.button} mr-10`}>
          <Button className={es.back_button} onClick={e => onPrev(e)}>{t('prev')}</Button>
        </div>
        <div className={`${es.button}`}>
          <Button disabled={!canContinue() || loading}>{loading ? t('loading') : t('finish')}</Button>
        </div>
      </div>

    </div>
  );
};


export default StartYourTripContact;
