import React, {useState} from 'react';
import s from './Hotels.module.css';
import {useTranslation} from "react-i18next";
import {FieldValues, FormProvider, useForm} from "react-hook-form";
import Button from "../../Inputs/Button/Button";
import {useNavigate} from "react-router-dom";
import bases from "../../../routes/bases";

import HotelPassengerNumberInput from "../../Inputs/HotelPassengerNumber/HotelPassengerNumberV2";
import {ReactComponent as ArrowButtonSVG} from "../../../resources/svg/arrow_button_right.svg";
import moment, {Moment} from "moment";
import LocationInput from "../../Inputs/LocationInput";
import {SearchHotelsFormValues} from "./models";
import {
  formDataToHotelDetailURLParams,
  formDataToSearchHotelURLParams,
  hotelsObjToParams
} from "../../Hotels/HotelDetails/util";
import DatePickerWithOutsideLabel from "../../Inputs/DatePickerWithOutsideLabel";

const Hotels = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const form = useForm();
  const {handleSubmit, clearErrors, getValues, setValue} = form;
  const [focusSecondDate, setFocusSecondDate] = useState(false);


  const isFormValid = (data?: any) => {
    data = data || form.getValues();
    let required = ['departure_date', 'arrive_date', 'hotel_destination', 'rooms'];
    return !required.find(key => !data[key]);
  }

  const onSubmit = (data: FieldValues) => {
    if (!isFormValid(data)) return;
    const raw = data?.hotel_destination?.raw;
    if (raw?.type === 'hotel') {
      const params = {
        ...data,
        code: raw.code,
        api: raw.api
      };

      navigate(bases.hotelDetails + '?' + hotelsObjToParams(formDataToHotelDetailURLParams(params as unknown as SearchHotelsFormValues)));
    } else {
      const hotelUrlParams = formDataToSearchHotelURLParams(data as unknown as SearchHotelsFormValues);
      navigate(bases.searchHotels + '?' + hotelsObjToParams(hotelUrlParams));
    }
  }

  const onArriveDateChange = (date: Moment) => {
    setFocusSecondDate(true);
    let to = getValues('departure_date');
    if (to <= date || Math.abs(date.diff(to, 'day')) > 30) setValue('departure_date', moment(date).add(1, 'day'));
  }

  const isOutsideRange = (day: Moment) => {
    let from = getValues('arrive_date') ?? moment().add(3, 'day');
    return day <= from || Math.abs(day.diff(from, 'day')) > 30;
  }

  const isOutsideRangeStart = (day: any) => {
    return day <= moment().add(3, 'day')
  }

  const isDayHighlightedFrom = (day: Moment) => {
    let from = getValues('arrive_date');
    if (!from) return false;

    if (Math.abs(day.diff(from, 'day')) === 0) return true;

    let to = getValues('departure_date');
    if (!to) return false;
    return day.isBetween(from, moment(to).add(1, 'day'), 'day');
  }

  const isDayHighlightedTo = (day: Moment) => {
    let from = getValues('arrive_date');
    if (!from) return false;

    if (Math.abs(day.diff(from, 'day')) === 0) return true;

    let to = getValues('departure_date');
    if (!to) return false;
    return day.isBetween(moment(from).subtract(10, 'day'), to, 'day');
  }


  return (
    <div className={s.bigContainer}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div
            className="w-full text-center text-3xl md:text-4xl lg:text-5xl font-bold pb-12">{t("select your accommodation")}</div>
          <div className="w-full text-center text-2xl">{t("find the best offers")}</div>
          <div className="px-5 sm:px-0 mt-20">
            <LocationInput
              label={t("input.hotel_destination")}
              id="hotel_page_destination"
              name="hotel_destination"
              labelClassName={s.date_label}
              containerClassName={s.locationInput}
            />
          </div>
          <div className="px-5 sm:px-0 mt-10 flex flex-wrap lg:flex-nowrap">
            <div className="flex flex-wrap md:flex-nowrap w-full lg:w-2/3 lg:mr-10 mr-0">
              <div className="w-full mb-10 md:mb-0 md:mr-10">
                {/* @ts-ignore */}
                <DatePickerWithOutsideLabel
                  placeholder={t("input.arrive_date")}
                  name="arrive_date"
                  id="hotel_page_arrive_date"
                  isOutsideRange={isOutsideRangeStart}
                  onChange={onArriveDateChange}
                  isDayHighlighted={isDayHighlightedFrom}
                  calendarInfo={t("input.arrive_date")}
                />
              </div>
              <div className="w-full">
                {/* @ts-ignore */}
                <DatePickerWithOutsideLabel
                  placeholder={t("input.departure_date")}
                  name="departure_date"
                  id="hotel_page_departure_date"
                  isOutsideRange={isOutsideRange}
                  onChange={() => setFocusSecondDate(false)}
                  externalFocused={focusSecondDate}
                  isDayHighlighted={isDayHighlightedTo}
                  calendarInfo={t("input.departure_date")}
                  initialVisibleMonth={() => {
                    return moment(form.getValues('arrive_date')).isValid() ? moment(form.getValues('arrive_date')) : undefined
                  }}
                />
              </div>
            </div>
            <div className="w-full lg:w-1/3  mt-10 lg:mt-0">
              {/*<HotelPassengerNumber label={t('input.passenger_number')} id="hotel_passenger_number"/>*/}
              <HotelPassengerNumberInput
                name="rooms"
              />
            </div>
          </div>
          <div className="w-full flex justify-center">
            <div className="mt-28 w-72">
              <Button disabled={!isFormValid()} onClick={() => clearErrors('other')}>
                {t('next')}
                <ArrowButtonSVG className="inline-block mt-2 ml-5"/>
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};


export default Hotels;
