import {CheckoutError} from "../../types/payment";
import {HotelForPurchase} from "../../types/hotels/hotels";
import {t} from "i18next";
import {CheckoutPerson} from "./CheckoutProvider";

export interface GeneralUtilProps {
  setHotels: (val: (HotelForPurchase[] | ((prevState: HotelForPurchase[]) => HotelForPurchase[]))) => void;
  setPersons: (val: (CheckoutPerson[] | ((prevState: CheckoutPerson[]) => CheckoutPerson[]))) => void;
}


export function manageHotelErrorInsufficientAllotment(error: CheckoutError, props: GeneralUtilProps) {
  removeHotelFromCodeAndOrRate(error, props)

  alert(t('errors.hotels.insufficientAllotment client message'))
}

export function manageCheckoutUnknownError(error: CheckoutError, props: GeneralUtilProps) {
  removeHotelFromCodeAndOrRate(error, props)

  alert(t('errors.checkout.unknown error'))
}


function removeHotelFromCodeAndOrRate(error: CheckoutError, props: GeneralUtilProps) {
  if (!error.hotelInfo) {
    props.setHotels([]);
    props.setPersons([]);
    return
  }
  props.setHotels((prev) => prev.map(hotel => {
    const info = error.hotelInfo!.filter(info => info.code === hotel.code)!
    if (info.length !== 0) {
      hotel.rooms = hotel.rooms.filter(room => !!info.find(a => a.rate !== room.rate.rateKey))
    }

    return hotel.rooms.length > 0 ? hotel : null;
  }).filter(a => a) as HotelForPurchase[])
}