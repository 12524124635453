import React from 'react';
import ErrorText from "../ErrorText/ErrorText";
import {parseError} from "../util";
import {ErrorStringType} from "../models";

interface Props {
  errors: ErrorStringType | ErrorStringType[],
  onlyOne?: boolean, // used from old versions
  maxVisibleErrors?: number,
  defaultStyle?: boolean;
  className?: string;
}

const ShowErrors = ({errors, onlyOne = false, maxVisibleErrors, defaultStyle, className}: Props) => {
  maxVisibleErrors = maxVisibleErrors || onlyOne ? 1 : 0;


  if (!Array.isArray(errors) && errors) {
    return <ErrorText defaultStyle={defaultStyle} className={className}>{parseError(errors)}</ErrorText>
  }

  if (Array.isArray(errors) && errors.length > 0) {
    let r = [];
    for (let i = 0; i < (maxVisibleErrors || errors.length); i++) {
      r.push(<ErrorText className={className} defaultStyle={defaultStyle} key={i}>{parseError(errors[i])}</ErrorText>);
    }
    return <>{r}</>;
  }


  return <></>;
};


export default ShowErrors;
