import React, {useMemo} from 'react';
import s from './ChooseActivityCategories.module.css';
import {useTranslation} from "react-i18next";
import {ReactComponent as SelectedMarkSVG} from "../../resources/svg/selected_small_circle.svg";
import {useFormContext} from "react-hook-form";
import Slider from "../Slider";
import {ActivityCategory, getActivityCategories} from "./categories";
import cn from "classnames";


interface ActivityProps {
  activity: ActivityCategory;
  selected: boolean;
  onClick: () => void;
}

const Activity = ({activity, selected, onClick}: ActivityProps) => {

  return <div onClick={onClick} className={cn([s.activity, {[s.selected]: selected}])}>
    <img draggable={false} src={activity.img} alt={activity.label}/>
    <div className={s.title}>{activity.label}</div>
    {/*{description && <div className={s.description}>{description}</div>}*/}
    <SelectedMarkSVG/>
  </div>
}

interface Props {
  onChange?: (categories: string[]) => void;
  name?: string;
}

export function ChooseActivityCategories({onChange, name = 'categories'}: Props) {
  const {t} = useTranslation();
  const form = useFormContext();

  const activityCategories = useMemo(() => getActivityCategories(), [t]);


  const selectedCategories = form.watch(name, []);

  //const [selectedCategories, setSelectedCategories] = React.useState([]);


  return (
    <div className={s.container}>
      <Slider
        navigation
        spaceBetween={20}
        centeredSlides={false}
        elements={activityCategories.map((activity) =>
          <Activity
            activity={activity}
            selected={selectedCategories.includes(activity.id)}
            onClick={() => form.setValue(name, selectedCategories.includes(activity.id) ? selectedCategories.filter((c: string) => c !== activity.id) : [...selectedCategories, activity.id])}
          />)}
        breakpoints={{
          1: {
            centeredSlides: true,
          },
          640: {
            centeredSlides: true,
          },
          768: {
            spaceBetween: 10
          },
          1025: {
            spaceBetween: 15
          },
          1280: {
            slidesPerView: "auto",
            spaceBetween: 20
          }
        }}
      />
    </div>
  );
}


export default ChooseActivityCategories;