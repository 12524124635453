import React from 'react';
import es from '../../StartYourTripRouter/StartYourTripRouter.module.css';
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";
import {Button} from "../../Inputs/Button/Button";
import ChooseActivityCategories from "../../../ChooseActivityCategories/ChooseActivityCategories";
import Textarea from "../../Inputs/Textarea/Textarea";

const StartYourTripActivities = ({onNext, onPrev}) => {
  const {t} = useTranslation();
  const form = useFormContext();

  const {setValue, watch, register} = form;

  const need_activities = watch('need_activities');
  const categories = watch('activity_categories');
  const other_activities = watch('other_activities');

  const canContinue = () => {
    if (!need_activities) return true;
    return !((!categories || !categories.length) && !other_activities);
  }

  return (
    <div>
      <input type="checkbox" checked={need_activities} className="invisible" {...register('need_activities')} />
      <div className={es.title}>{t('start your trip.need activities')}</div>
      <div className={es.subtitle}>{t('start your trip.activity subtitle')}</div>
      <div className="flex flex-nowrap m-auto my-20" style={{maxWidth: 400}}>
        <Button className={`uppercase mr-10 ${need_activities ? es.normal_button : ''}`}
                onClick={e => {
                  e.preventDefault();
                  setValue('need_activities', 0)
                }}>{t('no')}</Button>

        <Button className={`uppercase ${need_activities ? '' : es.normal_button}`}
                onClick={e => {
                  e.preventDefault();
                  setValue('need_activities', 1)
                }}>{t('yes')}</Button>
      </div>
      {!!need_activities &&
        <div className="w-full mt-20">
          <ChooseActivityCategories name="activity_categories"/>
          <div className="w-full">
            <Textarea
              name="other_activities"
              id="start_your_trip_other_activities"
              placeholder={t('start your trip.other activities')}
              rows={3}
            />
          </div>
        </div>
      }

      <div className="flex justify-center mt-20">
        <div className={`${es.button} mr-10`}>
          <Button className={es.back_button} onClick={e => onPrev(e)}>{t('prev')}</Button>
        </div>
        <div className={`${es.button}`}>
          <Button disabled={!canContinue()} onClick={e => onNext(e)}>{t('next')}</Button>
        </div>
      </div>

    </div>
  );
};

export default StartYourTripActivities;
