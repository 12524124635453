import React from 'react';
import s from './BuildYourTrip.module.css';
import {useForm, FormProvider} from "react-hook-form";
import {Outlet} from "react-router-dom";

const BuildYourTrip = props => {
  const form = useForm();

  const {handleSubmit} = form;


  const onSubmit = data => {
    console.log(data);
  }

  return (
    <div className={s.container}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Outlet/>
        </form>
      </FormProvider>
    </div>
  );
};


export default BuildYourTrip;
