import bases from "../routes/bases";
import {deleteCookie, getCookie, setCookie} from "./cookies";

export const debounce = (callback, delay, loaderCallback) => {
  let timeout = null;
  return async function (...args) {
    if (loaderCallback) loaderCallback();
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(async () => {
      await callback(...args);
      timeout = null;
    }, delay);
  }
}
export const cutText = (text, maxSize, add = "...") => {
  if (typeof text !== 'string') return text;
  if (text.length > maxSize) return text.substring(0, maxSize - add.length) + add;
  return text;
}
export const removeHTMLTags = text => text.replace(/(<([^>]+)>)/ig, '');


export const isObject = object => object !== null && typeof object === 'object';

export const objIsEqual = (obj1, obj2) => {
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') return obj1 === obj2;
  let props1 = Object.getOwnPropertyNames(obj1);
  let props2 = Object.getOwnPropertyNames(obj2);
  if (props1.length !== props2.length) return false;

  for (let i = 0; i < props1.length; i++) {
    let val1 = obj1[props1[i]];
    let val2 = obj2[props1[i]];
    let isObjects = isObject(val1) && isObject(val2);
    if ((isObjects && !objIsEqual(val1, val2)) || (!isObjects && val1 !== val2)) return false;
  }
  return true;
}

export const hashCode = string => {
  let hash = 0, i, chr;
  if (string.length === 0) return hash;
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export function firstCapitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getParams() {
  return window.location.search.substring(1);
}

export function paramsToObj(data) {
  let r = (data || getParams()).split('&').filter(([key, value]) => value).reduce((result, obj) => {
    let split = obj.split('=').map(a => decodeURIComponent(a).trim());

    if (result[split[0]]) {
      if (!Array.isArray(result[split[0]])) result[split[0]] = [result[split[0]]];
      result[split[0]].push(split[1]);
    } else {
      result[split[0]] = split[1];
    }
    return result;
  }, {});
  return r;
}

export function objToParams(obj) {
  let result = '';
  Object.entries(obj).filter(([key, value]) => String(value).length).forEach(([key, value]) => {
    if (Array.isArray(value)) value.forEach(a => result += `${encodeURIComponent(key)}=${encodeURIComponent(a)}&`);
    else if (value !== undefined) {
      const a = value?._isAMomentObject ? value?.toISOString() : value.toString();
      result += `${encodeURIComponent(key)}=${encodeURIComponent(a)}&`;
    }
  });
  return result.substring(0, result.length - 1);
}


export function getURLParams() {
  return paramsToObj();
  // let result = {};
  // try {
  //   let items = window.location.search.substring(1).split('&');
  //   for (let i = 0; i < items.length; i++) {
  //     let data = items[i].split('=');
  //     if (data[0].length) result[data[0]] = decodeURIComponent(data[1]);
  //   }
  // } catch (err) {
  // }
  // return result;
}

export function getGoToLoginPageURL() {
  if (window.location.pathname === bases.login) {
    return bases.login
  }
  storeLastURL(window.location.pathname + window.location.search)
  return bases.login;
}

export function storeLastURL(url) {
  if (!url && window.location.pathname === bases.login) return
  setCookie('lastURL', url ?? window.location.pathname + window.location.search, 360)
}

export function getLastURL() {
  return getCookie('lastURL')
}

export function goToLastURLIfExists() {
  const lastURL = getLastURL()
  if (lastURL) {
    console.log('going to last url:', lastURL)
    deleteCookie('lastURL')
    window.location.href = lastURL
  } else {
    console.log('no last url')
  }
}