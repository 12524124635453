import React, {useCallback, useState} from 'react';
import InputWithLabel from "../InputWithLabel";
import {useTranslation} from "react-i18next";
import {ReactComponent as LocationSVG} from "../../../resources/svg/location.svg";
import {debounce} from "../../../helpers/util";
import {formatSearchBarHotels} from "../../../helpers/search";
import {useHotel} from "../../../controllers/useHotel";
import {Loader} from "@mantine/core";

const LocationInput = ({
                         Input = InputWithLabel,
                         onDestinationSelect = undefined,
                         formatFunction = undefined,
                         searchFunction = undefined,
                         onSearch = undefined,
                         label,
                         id,
                         name,
                         ...props
                       }) => {
  const {t} = useTranslation();
  const {searchHotelsBar} = useHotel();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState()

  label = label || label === null ? label : t("input.hotel_destination");
  id = id || name;
  searchFunction = searchFunction || searchHotelsBar;
  formatFunction = formatFunction || formatSearchBarHotels;

  const onDestinationSelectCallback = useCallback((data, input) => {
    setSelected(data)
    if (onDestinationSelect) return onDestinationSelect(data, input)
    if (input.current) input.current.value = data.text + (data.smallText ? (', ' + data.smallText) : '');
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const findResults = useCallback(debounce(async str => {
    setLoading(true);
    const {results} = await searchFunction({q: str});
    setLoading(false);
    let data = formatFunction(results);
    setData(data);
    if (onSearch) {
      onSearch(data);
    }
  }, 500, () => setLoading(true)), []);

  const search = async e => {
    let str = e.target.value;
    if (str.length <= 1) return setData([]);
    findResults(str);
  }

  const icon = loading ? <Loader size="xs"/> : (selected?.icon ? selected.icon :
    <LocationSVG style={{fill: '#979797'}}/>);

  return (
    <Input
      label={label}
      id={id}
      name={name}
      icon={icon}
      onChange={search}
      onDataSelect={onDestinationSelectCallback}
      data={data}
      spellCheck={false}
      autoComplete="new-password"
      autoCorrect="off"
      aria-autocomplete="none"
      autoSelect={true}
      {...props}
    />
  );
};


export default LocationInput;