import React from 'react';
import s from './ErrorText.module.css';
import cn from "classnames";

interface Props {
  children: string | JSX.Element;
  className?: string;
  defaultStyle?: boolean;
}

const ErrorText = ({children, className = '', defaultStyle}: Props) => {
  return (
    <div className={cn([{[s.error]: defaultStyle}, className])}>{children}</div>
  );
};


export default ErrorText;
