import React from "react";
import {useViewportSize} from "@mantine/hooks";
import {useFormContext} from "react-hook-form";
import {SelectSubContinent} from "components/Maps/SelectSubContinent/SelectSubContinent";
import s from './StartYourTripMap.module.css'


export function ChooseDestination() {
  const form = useFormContext();
  const {setValue} = form;

  const {width} = useViewportSize();
  //const isDesktop = width >= 1280;

  //
  // return <div>{isDesktop ?
  //   <PartsOfTheWorldMap onChange={(data: any) => {
  //     setValue('country_codes', data?.codes ?? []);
  //   }}/>
  //   : <div className="w-full">
  //     <SelectContinent onChange={codes => setValue('country_codes', codes)}/>
  //   </div>
  // }
  // </div>

  return <div className={s.selectSubContinentContainer}>
    <SelectSubContinent onChange={(codes, selected) => {
      setValue('country_codes', codes)
      setValue('subcontinents', selected.map((s: any) => s.id));
    }
    }/>
  </div>
}