import s from './NoHotelsFound.module.css';
import {useTranslation} from "react-i18next";



const NoHotelsFound = () => {
  const {t} = useTranslation();
  return <div className={s.container}>
    {t('hotel.nothing found')}!
  </div>
}

export default NoHotelsFound;