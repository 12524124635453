import React from 'react';

import {ReactComponent as SingleBedSVG} from "../../../../../resources/svg/hotel/single_bed.svg";
import {ReactComponent as KingBedSVG} from "../../../../../resources/svg/hotel/king_bed.svg";

const bedSize = 26;

const DrawBeds = ({facility}) => {
  if ([1].includes(facility.facilityCode)) return <SingleBedSVG width={bedSize} height={bedSize}
                                                                title={facility?.description}/>
  if ([284, 150, 155].includes(facility.facilityCode)) return <KingBedSVG width={bedSize} height={bedSize}
                                                                          title={facility?.description}/>
  return <></>
}

const HotelDetailsRoomStays = ({room, hotel}) => {
    if (!room?.roomStays) return <></>;

    return (
      <div>
        {room?.roomStays.map((roomStay, i) => (
          <div key={i} className="">
            {Array.isArray(roomStay.roomStayFacilities) && roomStay.roomStayFacilities.map((facility, i) =>
              <div key={i} className="flex flex-nowrap items-center my-5">
                {roomStay.stayType === 'BED' && <DrawBeds facility={facility}/>}
                {facility.number > 1 && '(x' + facility.number + ') '}
                {facility?.description}

              </div>)}
          </div>
        ))}
      </div>
    );
  }
;


export default HotelDetailsRoomStays;