export enum ServiceCodes {
  wifiOrInternet,
  airConditioning,
  parking,
  disability,
  fridge,
  fullBoard,
  covidProtocol,
  conferenceMeetingRoom,
  waterSkiing,
  jetSki,
  snowSki,
  breakfast,
  lunch,
  dinner,
  casino,
  library,
  mountainBiking,
  bathroom, // no necesariamente privado
  barceloClub,
  lgbtiq,
  cityCenter,
  suites,
  apartment,
  supermarket,
  shop,
  restaurant,
  fireplace,
  kitchen,
  smokeDetector,
  bungalow,
  hostel,
  bar,
  hairdressingSalon,
  villas,
  garden,
  twinRoom,
  tripleRoom,
  quadrupleRoom,
  executiveRooms,
  golf,
  terrace,
  balcony,
  trainStation,
  washingMachine,
  doubleBed,
  kingBed,
  nightClub,
  theatre,
  bananaBoat,
  gameRoom,
  heating,
  nudist,
  yearOfConstruction,
  nearestBusStop,
  shower,
  safeBox,
  diving,
  onlyAdults,
  windSurfing,
  nonSmokingArea,
  smokingArea,
  noAlcoholIsServed,
}


// otros de interés:
// code: 575, group: 85 =  online check-in



