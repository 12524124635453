import React from 'react';
import config from "../../../config";

const CondicionesGeneralesES = props => {
  return (
    <>
      <h1>CONDICIONES GENERALES DE CONTRATACIÓN DE VIAJE COMBINADO</h1>
      <h2>
        1.
        Regulación jurídica aplicable

      </h2>
      <p>

      </p>
      <p>
        Las presentes Condiciones Generales de Contratación se rigen por lo
        dispuesto en el Real Decreto Legislativo 1/2007, de 16 de noviembre, de la
        Ley General para la Defensa de los Consumidores y Usuarios, modificado por
        el Real Decreto-ley 23/2018, de 21 de diciembre, de transposición de
        directivas en materia de marcas, transporte ferroviario y viajes combinados
        y servicios de viajes vinculados, y por la Ley 7/1998, de 13 de abril,
        sobre condiciones generales de contratación, así como otras leyes
        complementarias, además de por lo dispuesto a continuación.
      </p>
      <p>

      </p>
      <h2>
        2.
        Definiciones.
      </h2>
      <p>

      </p>
      <p>
        A los efectos de las presentes Condiciones Generales se entiende por:
      </p>
      <p>
        Programa:
        el documento informativo que recoge la descripción y condiciones del viaje
        combinado, que se facilita al viajero previa contratación del mismo.
      </p>
      <p>
        Contrato de Viaje Combinado
        : El acuerdo obligacional que vincula al organizador y al viajero.
      </p>
      <p>
        Viajero:
        toda persona que tiene intención de celebrar un contrato o tiene derecho a
        viajar en virtud de un contrato.
      </p>
      <p>
        Organizador:
        La mercantil TRIPPY TREKKERS, S.L., con domicilio en la calle Camino de
        Hoyarrasa 106, C.P. 28109 Alcobendas, Madrid, y C.I.C.M.A nº XXXX.
      </p>
      <p>
        Número de teléfono: <a
        href={`tel:${config.trippy_trekkers.contact_email.replaceAll(' ', '')}`}>{config.trippy_trekkers.phone_number}</a>
      </p>
      <p>
        Número de emergencia: <a
        href={`tel:${config.trippy_trekkers.emergency_number}`}>{config.trippy_trekkers.emergency_number}</a> (whats
        app)
      </p>
      <p>
        E-mail: <a href={`mailto:${config.trippy_trekkers.contact_email}`}>{config.trippy_trekkers.contact_email}</a>
      </p>
      <p>
        web: <a href="https://www.trippytrekkers.com/">www.trippytrekkers.com</a>
      </p>
      <p>
        <b>Minorista</b>:
        empresario distinto del organizador que vende u oferta viajes combinados
        por un organizador.
      </p>
      <p>
        <b>Información precontractual:</b>
        aquella que el organizador o, en su caso, el minorista está obligado a
        facilitar al viajero antes de que el viajero quede obligado por cualquier
        contrato u oferta.
      </p>
      <h2>
        3.
        Marco contractual aplicable.
      </h2>
      <p>
        Formarán parte integrante del contrato de viaje combinado, todas las
        condiciones que regulan el viaje combinado contratado, en concreto, las
        condiciones generales dispuestas en este texto, las condiciones
        precontractuales, y las condiciones particulares pactadas con el viajero.
      </p>
      <h2>
        4.
        Información Precontractual Adicional Preceptiva
      </h2>
      <p>
        El organizador ofrecerá al viajero interesado toda la información
        precontractual relativa al viaje combinado, exigida en el RDL 1/2007.
      </p>
      <p>
        Antes de que el viajero quede obligado por cualquier contrato de viaje
        combinado u oferta correspondiente, la agencia organizadora o, en su caso,
        la agencia minorista, entregarán al viajero el formulario de información
        normalizada para los contratos de viaje combinado, así como el resto de
        características e información del viaje de acuerdo con lo establecido en la
        legislación vigente.
      </p>
      <p>
        Aparte del programa, el organizador proporcionará al viajero el formulario
        con la información normalizada para los contratos de viaje combinado, que
        figura en el siguiente link:
      </p>
      <p>
        Caso A:
        <i>
          Formulario de información normalizada para contratos de viaje combinado
          en los que sea posible utilizar hiperenlaces (
        </i>
        <a
          href="https://noticias.juridicas.com/base_datos/Admin/rdleg1-2007.anexo2.html#anexo1anexo2)"
        >
          <i>
            https://noticias.juridicas.com/base_datos/Admin/rdleg1-2007.anexo2.html#anexo1anexo2
          </i>
          <i>)</i>
        </a>
        <i> HACER PDF CON EL APARTADO A</i>
      </p>
      <p>
        Caso B:
        <i>
          Formulario de información normalizada para contratos de viaje combinado
          en supuestos distintos de los contemplados en la parte A (
        </i>
        <a
          href="https://noticias.juridicas.com/base_datos/Admin/rdleg1-2007.anexo2.html#anexo1anexo2)"
        >
          <i>
            https://noticias.juridicas.com/base_datos/Admin/rdleg1-2007.anexo2.html#anexo1anexo2
          </i>
          <i>)</i>
        </a>
        <i> HACER PDF CON EL APARTADO B</i>
      </p>
      <p>
        <i></i>
      </p>
      <p>

      </p>
      <h2>
        5.
        Contratación del viaje.
      </h2>
      <p>

      </p>
      <p>
        El organizador ofrecerá al viajero interesado toda la información
        precontractual relativa al viaje combinado, exigida en el RDL 1/2007.
      </p>
      <p>
        Una vez abonado el depósito o cantidad que se requiera, y suscrito el
        contrato, que está integrado por las condiciones generales dispuestas en
        este texto, las condiciones precontractuales, y las condiciones
        particulares pactadas con el viajero, el organizador realizará las
        gestiones oportunas para obtener de cada proveedor la confirmación de los
        servicios contratados.
      </p>
      <p>
        En el supuesto de que alguno de estos servicios no pueda ser confirmado por
        falta de disponibilidad, se ofrecerá otro de características similares, con
        expresión del nuevo precio, en su caso, que el viajero podrá aceptar o
        rechazar.
      </p>
      <p>
        En el caso de que el viajero rechace el cambio propuesto, Trippy Trekkers
        reembolsará los importes depositados hasta la fecha.
      </p>
      <h2>
        6.- Delimitación de los servicios del viaje combinado.
      </h2>
      <p>
        6.1.- Alcance general.
      </p>
      <p>
        Las prestaciones que integran el contrato de viaje combinado resultan de la
        información proporcionada al viajero, e incluida en él.
      </p>
      <p>
        6.2.- Transportes.
      </p>
      <p>
        El cliente debe presentarse en el lugar indicado para la salida con la
        antelación prevista en la documentación de viaje facilitada.
      </p>
      <p>
        En los viajes en avión, la presentación en el aeropuerto se efectuará con
        un mínimo de antelación de tres horas en viajes internacionales y dos horas
        en nacionales sobre el horario oficial de salida, y en todo caso se
        seguirán estrictamente las recomendaciones específicas que indique la
        documentación del viaje facilitada al suscribir el contrato. Si el viajero
        no pudiera realizar el viaje por no haberse presentado con la antelación
        requerida, se aplicará el régimen previsto al desistimiento del viajero una
        vez comenzado el viaje.
      </p>
      <p>
        Las reservas se realizarán con los datos indicados por el viajero. Las
        compañías aéreas se reservan el derecho de que un billete con un nombre que
        no coincida con el que aparece en el pasaporte pueda ser motivo para
        denegar el embarque a un viajero.
      </p>
      <p>
        Una vez realizada la reserva, un error en el nombre o un nombre incompleto,
        provoca hacer una nueva reserva con la posibilidad de no conseguir plazas
        en los mismos vuelos o que el precio inicial varíe.
      </p>
      <p>
        La pérdida o daño que se produzca en relación con el equipaje de mano u
        otros objetos que el viajero lleve consigo y conserve bajo su custodia, son
        de su exclusiva cuenta y riesgo.
      </p>
      <p>
        Se entenderá siempre como trayecto aéreo directo aquel cuyo soporte
        documental sea un solo cupón de vuelo, con independencia de que el vuelo
        realice alguna parada técnica.
      </p>
      <p>
        6.3.- Alojamiento.
      </p>
      <p>
        <br/>
        6.3.1.- General.
      </p>
      <p>
        La calidad y contenido de los servicios prestados por el hotel vendrá
        determinada por la categoría turística oficial, si la hubiere, asignada por
        el órgano competente de su país. Con aquellos países en los que existe
        clasificación oficial de establecimientos hoteleros o de cualquier otro
        tipo de alojamiento, el contrato (programa) recoge la clasificación
        turística que se otorga en el correspondiente país. En aquellos en los que
        no existe clasificación oficial, la categoría que se indica en el programa
        es simplemente la orientativa dada por Trippy Trekkers.
      </p>
      <p>
        Las habitaciones o camarotes triples o cuádruples son generalmente
        habitaciones dobles a las que se añade una o dos camas, que suelen ser un
        sofá-cama o un plegatín, excepto en ciertos establecimientos donde en lugar
        de camas adicionales se emplean dos camas más grandes.
      </p>
      <p>
        El horario habitual para la entrada y salida en los hoteles está en función
        del primer y último servicio que el usuario vaya a utilizar. El horario de
        ocupación de las habitaciones depende de las normas establecidas en cada
        país. Como norma general y salvo que expresamente se pacte otra cosa en el
        contrato, las habitaciones podrán ser utilizadas a partir de las 14 horas
        del día de llegada y deberán quedar libres antes de las 12 horas del día de
        salida, con independencia de la hora en que esté prevista la llegada al
        hotel o de la hora en que esté prevista la continuación del viaje.
      </p>
      <p>
        Cuando el servicio contratado no incluya el acompañamiento permanente de
        guía y en el supuesto de que el usuario prevea su llegada al hotel o
        apartamento reservado en fechas u horas distintas a las reseñadas, es
        conveniente, para evitar problemas y malas interpretaciones, comunicar con
        la mayor anticipación posible tal circunstancia al organizador, o al hotel
        o a los apartamentos directamente, según los casos. Igualmente, debe el
        usuario consultar al organizador, en el momento de hacer la reserva, la
        posibilidad de llevar animales, pues generalmente no son admitidos en los
        hoteles y apartamentos. En el supuesto de haber confirmado la admisión de
        animales y se pretenda viajar con ellos, tal circunstancia deberá hacerse
        constar en el contrato.
      </p>
      <p>
        El servicio de alojamiento implicará que la habitación esté disponible en
        la noche correspondiente, entendiéndose prestado con independencia de que,
        por circunstancias propias del viaje, el horario de entrada en el mismo se
        produzca más tarde de lo inicialmente previsto.
      </p>
      <p>
        6.3.2.- Servicios Suplementarios.
      </p>
      <p>
        Cuando los usuarios soliciten servicios suplementarios (por ejemplo,
        habitación vista al mar, etc.) que no les puedan ser confirmados
        definitivamente por el organizador, el usuario podrá optar por desistir
        definitivamente del servicio suplementario solicitado o mantener su
        solicitud a la espera de que tales servicios puedan finalmente serle
        prestados. En el supuesto de que las partes hubieran convenido el pago
        previo del precio de los servicios suplementarios que finalmente no se
        puedan ser prestados, el importe abonado será reembolsado por el
        organizador inmediatamente al desistimiento del servicio por parte del
        viajero o al regreso del viaje, según el usuario haya optado por el
        desistimiento en la prestación del servicio suplementario solicitado, o
        haya mantenido la solicitud.
      </p>
      <p>
        6.4.- Régimen alimenticio.
      </p>
      <p>
        El régimen de media pensión, salvo que se indique de otro modo, incluye
        desayuno continental y cena. Por regla general, y a menos que se indique
        expresamente lo contrario, dichas comidas no incluyen las bebidas. Las
        dietas especiales (vegetarianas o de regímenes especiales) sólo se
        garantizan si han sido pactadas por las partes en condiciones particulares.
        Si por causa de retraso, sea cual fuere, se perdiera algún servicio de
        restaurante correspondiente a la pensión alimenticia contratada, no
        existirá derecho a devolución alguna.
      </p>
      <p>
        6.5.- Condiciones económicas especiales para niños.
      </p>
      <p>
        Dada la diversidad del tratamiento aplicable a los niños, dependiendo de su
        edad, del proveedor de servicios y de la fecha del viaje, se recomienda
        consultar siempre el alcance de las condiciones especiales que existan y
        que en cada momento serán objeto de información concreta y detallada y se
        recogerá en el contrato o en la documentación del viaje que se entregue. En
        general, en cuanto al alojamiento, serán aplicables siempre que el niño
        comparta la habitación con dos adultos.
      </p>
      <p>
        En lo referente a estancias de menores en el extranjero se estará a la
        información facilitada puntualmente para cada caso y a lo que pueda constar
        en el contrato o en la documentación del viaje que se entregue al
        suscribirlo.
      </p>
      <p>
        En todo caso, los niños siempre deben llevar su D.N.I. o Pasaporte en
        vigor. Si el menor no viaja acompañado por sus tutores, deberá de llevar
        también la autorización legal de los mismos.
      </p>
      <p>
        La normativa de algunas compañías aéreas obliga a todos los menores
        (consultar edad mínima a cada compañía aérea) a viajar acompañados por un
        adulto y debidamente documentados con DNI y/o Pasaporte. En caso de
        incumplimiento de dicha normativa, a estos menores se les denegará el
        embarque con estas compañías.
      </p>
      <h2>
        7.- Condiciones económicas.
      </h2>
      <p>
        7.1.- El precio del viaje.
      </p>
      <p>
        <br/>
        El precio del viaje incluye:
      </p>
      <p>
        <br/>
        a) El transporte, cuando dicho servicio esté incluido en el programa
        contratado, con el tipo de transporte, características y categoría que
        conste en el mismo.
      </p>
      <p>
        <br/>
        b) El alojamiento, cuando dicho servicio esté incluido en el programa
        contratado, en el establecimiento y con el régimen alimenticio que figura
        en el mismo.
      </p>
      <p>
        <br/>
        c) Las tasas o impuestos indirectos – por ej. Impuesto sobre el Valor
        Añadido (I.V.A.) cuando sean aplicables y no sean de pago directo por el
        viajero.
      </p>
      <p>
        <br/>
        d) Los gastos de gestión.
      </p>
      <p>
        <br/>
        e) Todos los demás servicios y complementos que se especifiquen
        concretamente en el contrato de viaje combinado suscrito.
      </p>
      <p>
        7.2.- Exclusiones.
      </p>
      <p>
        El precio del viaje no incluye todo aquello que no esté expresamente
        recogido en el contrato de viaje.
      </p>
      <p>
        En el caso de excursiones o visitas facultativas no contratadas en origen,
        debe tenerse presente que no forman parte del contrato de viaje. Su
        publicación en el programa tiene mero carácter informativo y el precio está
        expresado con el indicativo de “estimado”. Por ello, en el momento de
        contratarse en el lugar de destino, pueden producirse variaciones sobre sus
        costes, que alteren el precio estimado. Dichas excursiones serán ofrecidas
        al viajero con sus condiciones específicas y precio definitivo de forma
        independiente, no garantizándose hasta el momento de su contratación su
        posible realización.
      </p>
      <p>
        7.3.- Revisión de precios.
      </p>
      <p>
        El precio del viaje ha sido calculado en base a los tipos de cambio,
        tarifas de transporte, coste del carburante, tasas e impuestos, etc.
        aplicables en la fecha de edición del programa.
      </p>
      <p>
        El precio ahora establecido podrá ser revisado, tanto al alza como a la
        baja, cuando se produzcan cambios en:
      </p>
      <p>
        <br/>
        a) el precio del transporte de pasajeros derivado del coste del combustible
        o de otras fuentes de energía.
      </p>
      <p>
        b) el nivel de los impuestos o tasas sobre los servicios de viaje incluidos
        en el contrato, exigidos por terceros que no están directamente
        involucrados en la ejecución del viaje combinado, incluidas las tasas,
        impuestos y recargos turísticos, de aterrizaje y de embarque o desembarque
        en puertos y aeropuertos, o
      </p>
      <p>
        c) los tipos de cambio de divisa aplicables al viaje combinado.
        <br/>
        Dicha revisión solo podrá efectuarse con una antelación mayor de 20 días
        naturales con respecto a la fecha de salida del viaje. No cabrá en ningún
        caso la revisión en el supuesto de que el viajero tuviera ya abonado el
        precio total del viaje. No obstante, lo anterior, y en lo que al tipo de
        cambio se refiere, Trippy Trekkers garantiza y fija dicho precio, una vez
        confirmado el viaje, por lo que, tras dicha confirmación, el precio no
        sufrirá modificaciones por este concepto.
      </p>
      <p>
        7.4.- Forma de Pago acordada.
      </p>
      <p>
        Las partes acordarán en el contrato la forma de pago establecida, sea al
        contado, o fraccionado, si bien el importe íntegro deberá estar
        desembolsado antes de la fecha de salida. En caso, de no haberse recibido
        el precio, se entenderá que el viajero desiste unilateralmente del contrato
        con los gastos y penalizaciones establecidas en el RDL 1/2007.
      </p>
      <h2>
        8.
        Derechos y deberes de las partes antes de iniciar el viaje.
      </h2>
      <p>
        8.1.- Modificación del contrato antes de la salida.
      </p>
      <p>
        <br/>
        8.1.1.- Por Trippy Trekkers.
      </p>
      <p>
        Trippy Trekkers se compromete a facilitar a sus viajeros la totalidad de
        los servicios contenidos en el contrato, con las condiciones y
        características estipuladas, si bien antes de la salida de viaje podrá
        realizar aquellos cambios que sean necesarios para el buen fin del viaje y
        que no sean sustanciales.
      </p>
      <p>
        No obstante, lo anterior, en el supuesto de que Trippy Trekkers se vea
        obligada a realizar cambios sustanciales en los servicios contratados, o
        proponga aumentar el precio más de un 8%, lo pondrá en conocimiento del
        viajero sin demora, incluyendo las modificaciones programas y su
        repercusión en el precio.
      </p>
      <p>
        El viajero deberá notificar a Trippy Trekkers en un plazo máximo de 24
        horas si acepta las modificaciones del programa y el nuevo precio. La falta
        de notificación de la decisión en el plazo señalado, implicará la
        resolución del contrato. – POLITICAS DE CANCELACION
      </p>
      <p>
        Si las modificaciones aceptadas por el viajero supusieran un viaje de
        calidad inferior, tendrá derecho a una reducción adecuada del precio. En
        caso de resolución por el viajero del contrato por las modificaciones
        comunicadas por Trippy Trekkers, el primero tendrá derecho a la íntegra
        devolución de las cantidades entregadas a la fecha sin ninguna
        penalización, en un plazo máximo de catorce días naturales a partir de la
        fecha en que se produzca la resolución.
      </p>
      <p>
        8.1.2.- Por el viajero.
      </p>
      <p>
        Si en cualquier momento anterior a la fecha de salida, el viajero desea
        solicitar cambios referidos a los destinos, a los medios de transporte, a
        la duración, al calendario, al itinerario del viaje contratado o a
        cualquier otro extremo referido a las prestaciones y el organizador puede
        efectuarlos, éste podrá exigirle el abono de los gastos adicionales
        justificados que hubiese causado dicha modificación.
      </p>
      <p>
        Igualmente, el viajero podrá ceder su reserva en el viaje combinado a una
        tercera persona, siempre y cuando éste último reúna todas las condiciones
        requeridas para el citado viaje, debiéndolo comunicar por escrito al
        organizador con una antelación mínima de 7 días naturales a la fecha del
        inicio del viaje.
      </p>
      <p>
        Tanto la persona que ceda su reserva en el viaje, como el cesionario,
        responderán solidariamente ante el organizador del pago del precio del
        viaje, así como de cualquier comisión, recargo u gastos adicionales
        justificados que pudiera haber causado dicha cesión.
      </p>
      <p>
        8.2.- Resolución del contrato antes de la salida.
      </p>
      <p>
        8.2.1.- Resolución del viaje por el organizador.
      </p>
      <p>
        Trippy Trekkers podrá cancelar el contrato y reembolsar al viajero la
        totalidad de los pagos que éste haya realizado en un plazo máximo de 14
        días naturales desde la notificación de la cancelación, pero no será
        responsable de compensación adicional alguna si:
      </p>
      <p>
        a). El número de personas inscritas para el viaje combinado es inferior al
        número mínimo especificado en el contrato y Trippy Trekkers notifica al
        viajero la cancelación dentro de los siguientes plazos:
      </p>
      <p>
        • 20 días naturales antes del inicio del viaje combinado (en el caso de los
        viajes de más de seis días de duración).
      </p>
      <p>
        • 7 días naturales antes del inicio del viaje combinado (en el caso de los
        viajes de entre dos y seis días de duración).
      </p>
      <p>
        • 48 horas antes del inicio del viaje combinado en el caso de viajes de
        menos de dos días de duración.
      </p>
      <p>
        b). Trippy Trekkers se ve imposibilitada de ejecutar el contrato por
        circunstancias inevitables y extraordinarias y le notifica la cancelación
        al viajero tan pronto como tenga conocimiento de ello.
      </p>
      <p>
        8.2.2.- Resolución del viaje por el viajero.
      </p>
      <p>
        El viajero podrá en cualquier momento antes del inicio del viaje combinado
        dejarlo sin efecto, viniendo obligado a abonar a Trippy Trekkers todos los
        costes sufridos por ésta con motivo de tal anulación, además de una
        penalización adecuada y justificable.
      </p>
      <p>
        <i></i>
      </p>
      <p>
        Trippy Trekkers no aplicará la anterior penalización si la resolución se
        produce por los siguientes motivos, en los que el viajero tendrá derecho al
        reembolso total del precio del viaje combinado, pero no a una compensación
        adicional:
      </p>
      <p>
        a) como consecuencia de circunstancias inevitables y extraordinarias en el
        lugar de destino o en las inmediaciones que afecten de forma significativa
        a la ejecución del viaje combinado, o al transporte de pasajeros al lugar
        de destino.
      </p>
      <p>
        b) si se modifica significativamente alguno de los elementos esenciales del
        viaje combinado que no sea el precio.
      </p>
      <h4>
        c) en caso de no ejecución de los servicios cuando ello afecte
        sustancialmente a la ejecución del viaje combinado y el organizador no
        consiga solucionar el problema.
      </h4>
      <p>
        Trippy Trekkers vendrá obligada a abonar al viajero la cantidad que
        corresponda según los apartados anteriores, dentro de un plazo máximo de 14
        días naturales desde la notificación de la cancelación.
      </p>
      <h2>

        9.- Derechos y obligaciones de las partes después de iniciar el viaje.

      </h2>
      <p>
        9.1.- Derechos del viajero.
      </p>
      <p>
        El viajero tendrá derecho al correcto cumplimiento de los servicios de
        viaje incluidos en el contrato. Si alguno de los servicios contratados no
        pudiera prestarse, o se prestasen con calidad inferior, tendrá derecho a
        una reducción adecuada del precio y al pago de la indemnización de los
        daños y perjuicios que pudiera haber sufrido por tal causa.
      </p>
      <p>
        El viajero tiene el derecho a desistir del contrato de viaje combinado una
        vez comenzado el viaje, pero no podrá reclamar la devolución de las
        cantidades entregadas y continuará obligado a abonar las que estuvieran
        pendientes de pago.
      </p>
      <p>
        El viajero tendrá derecho a recibir asistencia en el menor tiempo posible,
        en los supuestos de circunstancias inevitables y extraordinarias, mediante
        el suministro de información sobre los servicios sanitarios, las
        autoridades locales y la asistencia consular, y a recibir ayuda para
        establecer comunicaciones a distancia y para encontrar fórmulas de viaje
        alternativas.
      </p>
      <p>
        9.2.- Obligaciones del viajero.
      </p>
      <p>
        El viajero deberá atenerse a las indicaciones que le facilite Trippy
        Trekkers para la adecuada ejecución del viaje, así como a las
        reglamentaciones que son de general aplicación a los usuarios de los
        servicios comprendidos en el viaje combinado. En particular, observará una
        conducta que no perjudique su normal desarrollo, actuando conforme a las
        elementales reglas de prudencia y sentido común.
      </p>
      <p>
        El viajero vendrá obligado a comunicar a Trippy Trekkers cualquier falta de
        conformidad por su parte en la ejecución del contrato a la mayor brevedad
        posible, así como a aceptar las nuevas propuestas de ésta, en relación a
        dicha disconformidad, si éstas son de la misma calidad que las incluidas en
        el contrato, o si siendo de menor calidad, se le hubiera ofrecido una
        rebaja adecuada del precio.
      </p>
      <p>
        En todo caso, el viajero está obligado a tomar las medidas adecuadas y
        razonables para intentar reducir los daños que puedan derivarse de la no
        ejecución o ejecución deficiente del contrato o para evitar que se agraven.
        Los daños que deriven de no haber adoptado dichas medidas serán de cuenta
        del viajero.
      </p>
      <p>
        La infracción grave de estos deberes faculta al organizador para resolver
        el contrato de contrato de viaje combinado por causa imputable al viajero,
        respondiendo éste último de los daños y perjuicios que se puedan haber
        irrogado al organizador.
      </p>
      <p>
        9.3.- Derechos de Trippy Trekkers después de iniciado el viaje.
      </p>
      <p>
        Trippy Trekkers en cuanto responsable de la ejecución del viaje, tiene el
        derecho a tomar las decisiones que considere pertinentes ante las
        situaciones imprevistas que puedan surgir en su transcurso, tales como,
        alteraciones climáticas, circunstancias políticas o bélicas, problemas
        producidos por intermediarios aéreos, etc., estableciendo los cambios
        necesarios en el mismo para garantizar la seguridad de los viajeros y el
        buen desarrollo del viaje.
      </p>
      <p>
        Igualmente tiene el derecho a exigir de los viajeros un comportamiento
        adecuado y conforme a las distintas reglamentaciones o costumbres de los
        países incluidos en el viaje, que no perjudique su realización, pudiendo
        dar instrucciones en tal sentido.
      </p>
      <p>
        Trippy Trekkers tendrá derecho a dar por terminada, en cualquier momento,
        la participación de cualquier persona, cuyo comportamiento pueda entenderse
        como peligroso para la seguridad del grupo o bienestar de los
        participantes, lo cual no dará derecho a reembolso alguno. Si bien tomará
        las medidas previstas para asegurar su repatriación.
      </p>
      <p>
        9.4.- Obligaciones de Trippy Trekkers.
      </p>
      <p>
        Trippy Trekkers viene obligada a ejecutar correctamente los servicios
        incluidos en el viaje contratado, con independencia de que éstos los
        ejecute ella directamente u otros prestadores de servicios, respondiendo de
        los daños sufridos por el viajero como consecuencia de la no ejecución o
        ejecución deficiente del contrato, salvo:
      </p>
      <p>
        1). Que los defectos observados en la ejecución del contrato sean
        imputables al viajero.
      </p>
      <p>
        2). Que los defectos aludidos se deban a circunstancias imprevisibles o
        inevitables, entendiendo por tales, cualquier situación fuera del control
        de la parte que alega esta situación y cuyas consecuencias no habrían
        podido evitarse, incluso si se hubieran adoptado todas las medidas
        razonables.
      </p>
      <p>
        En los anteriores supuestos de exclusión de responsabilidad, Trippy
        Trekkers estará obligada a prestar la necesaria asistencia al viajero que
        se encuentre en dificultades, a no ser que los defectos producidos sean
        atribuibles de modo exclusivo a una conducta intencional o negligente del
        viajero.
      </p>
      <p>
        Trippy Trekkers no responderá de los servicios no incluidos en su programa
        y que el viajero pueda contratar con terceros en el transcurso del viaje y
        con ocasión de éste.
      </p>
      <p>
        Tampoco responderá si es el viajero el que contrata independientemente los
        transportes al margen de lo dispuesto en el programa, en relación con
        cualquier retraso, cancelación, accidente o incidencia que pueda producirse
        en relación con dichos transportes, independientemente de la causa de la
        misma sin que el viajero ni los beneficiarios tengan nada que pedir o
        reclamar por este concepto.
      </p>
      <p>
        Asimismo, Trippy Trekkers no tendrá ningún tipo de responsabilidad en
        relación con el hecho de que tales eventuales incidencias puedan significar
        la imposibilidad de realizar todas o parte de las actividades contratadas,
        o la necesidad de retrasarlas, cancelarlas y, en general, modificar de
        cualquier forma el plan del viaje contratado, sin que deba devolver o
        abonar cantidad alguna al viajero por este hecho. Por último, el viajero
        reconoce y acepta que las pólizas de seguro contratadas por el organizador
        no cubrirán cualquier eventualidad que, en su caso, pudiera tener lugar en
        relación con los transportes contratados directamente por él.
      </p>
      <p>
        Si cualquiera de los servicios incluidos en el viaje no se ejecuta de
        conformidad con el contrato, Trippy Trekkers vendrá obligada a subsanar la
        falta de conformidad, salvo que resulte imposible o ello entrañara un coste
        desproporcionado, teniendo en cuenta la gravedad de la falta de conformidad
        y el valor de los servicios de viaje afectados.
      </p>
      <p>
        Cuando Trippy Trekkers no pueda prestar una proporción significativa de los
        servicios de viaje contratados ofrecerá, sin coste adicional alguno para el
        viajero, fórmulas alternativas adecuadas, de ser posible de calidad
        equivalente o superior a las especificadas en el contrato, para la
        continuación del viaje combinado. Si Trippy Trekkers no ofreciera tales
        fórmulas alternativas y el viaje incluyera el transporte, repatriará al
        viajero en un transporte equivalente sin dilaciones indebidas y sin coste
        adicional.
      </p>
      <h2>
        10.
        Límites de la responsabilidad de Trippy Trekkers.
      </h2>
      <p>
        Trippy Trekkers aplicará las limitaciones que, en relación con el alcance o
        las condiciones del pago de indemnizaciones por parte de prestadores de
        servicios de viaje incluidos en un viaje combinado, establezcan los
        convenios internacionales que vinculan a la Unión Europea.
      </p>
      <p>
        En los demás casos, Trippy Trekkers podrá limitar en los contratos que
        suscriba la indemnización que debe pagar al viajero siempre que esa
        limitación no se aplique a los daños corporales o perjuicios causados de
        forma intencionada, o por negligencia y que su importe no sea inferior al
        triple del precio total del viaje.
      </p>
      <h2>
        11.- Pasaportes, visados y vacunas.
      </h2>
      <p>
        Trippy Trekkers se compromete a informar al viajero sobre las formalidades
        sanitarias necesarias para el viaje y la estancia, así como sobre las
        condiciones aplicables en materia de pasaportes y de visados, respondiendo
        de la corrección de la información que facilite.
      </p>
      <p>
        El viajero deberá obtener la documentación necesaria para realizar el
        viaje, incluido pasaporte, visados y la referida a las formalidades
        sanitarias. El viajero debe asegurarse antes de iniciar el viaje de tener
        cumplidas todas las normas y requisitos aplicables en materia de visados a
        fin de poder entrar sin problemas en todos los países que vayan a
        visitarse. Los menores de 18 años deben llevar un permiso escrito firmado
        por sus padres o tutores, en previsión de que el mismo pueda ser solicitado
        por cualquier autoridad. Todos los daños que puedan derivarse de la falta
        de esa documentación serán de su cuenta y en particular, los gastos
        producidos por la interrupción del viaje y su eventual repatriación.
      </p>
      <p>
        Si el organizador acepta el encargo del viajero de tramitar los visados
        necesarios para alguno de los destinos previstos en el itinerario, podrá
        exigir el cobro del coste del visado, así como de los gastos de gestión por
        los trámites que deba realizar ante la representación diplomática o
        consular correspondiente. En este caso, el organizador responderá de los
        daños que le sean imputables de acuerdo con la diligencia normalmente
        exigible por los retrasos en la obtención de la documentación necesaria o
        por falta o insuficiencia de ésta, que imposibiliten el viaje.
      </p>
      <h2>
        12. Riesgos.
      </h2>
      <p>
        En algunos casos, Trippy Trekkers ofrece viajes expedicionarios fuera de
        las rutas turísticas tradicionales, en estrecho contacto con la naturaleza.
        En consecuencia, el viaje que se contrata puede tener un mayor riesgo del
        habitual, riesgo del que el destinatario que lo contrata es plenamente
        consciente, asumiendo la responsabilidad que de ello se deriva y declarando
        expresamente que el riesgo es precisamente una de las causas que influyen
        decisivamente en su contratación.
      </p>
      <h2>
        13.- Seguros.
      </h2>
      <p>
        Trippy Trekkers tiene suscrito para todos sus viajes un Seguro Básico de
        Asistencia en Viaje que cubre al viajero, e informará al viajero de la
        posibilidad que tiene de contratar un seguro facultativo que cubra los
        gastos originados en caso de que decida poner fin al contrato, o los gastos
        de asistencia, incluidos los de repatriación, en caso de accidente,
        enfermedad o fallecimiento.
      </p>
      <p>
        Los seguros, una vez contratados, no son reembolsables.
      </p>
      <p>

      </p>
      <h2>
        14.- Reclamaciones, acciones judiciales y prescripción.
      </h2>
      <p>
        En caso de controversia en cuanto a la interpretación o aplicación de las
        presentes condiciones o de reclamación del viajero, Trippy Trekkers, S.L,
        no está ni queda adherido a ningún sistema de mediación ni arbitral (Junta
        arbitral de consumo, transporte, etc.), manifestándose la renuncia a tales
        sistemas de resolución de conflictos, salvo expreso acuerdo en contrario, y
        sometiéndose las partes, con renuncia expresa a cualquier otro fuero que
        pudiera corresponderles, a la jurisdicción de los Juzgados y Tribunales de
        la ciudad correspondiente al domicilio social de Trippy Trekkers, S.L,
        salvo en aquellos casos que por ley se establezca, imperativamente, otro
        fuero.
      </p>
      <p>
        Las acciones judiciales derivadas del contrato de viaje combinado
        prescriben por el transcurso del plazo de dos años, cómputo que se iniciará
        el día de celebración del contrato. En todo caso, y con pleno respeto a lo
        dispuesto en el apartado precedente, Trippy Trekkers pone a disposición de
        los viajeros la siguiente información para que puedan interponer sus quejas
        y reclamaciones o solicitar información sobre el viaje contratado: TRIPPY
        TREKKERS, S.L., con domicilio en la calle Camino de Hoyarrasa 106,
        Alcobendas, Madrid, (España) y C.I.C.M.A nº XXXX, teléfono: XXXXX y email: <a
        href="mailto:comercial@nuba.net">XXXXXX</a>
      </p>
      <h2>
        15.- Protección de Datos de carácter personal.
      </h2>
      <p>

      </p>
      <p>
        En cumplimiento de la Ley Orgánica 3/2018, de 5 de diciembre, de Protección
        de Datos Personales y garantía de derechos digitales y del Reglamento
        Europeo 2016/679, relativo a la protección de las personas físicas en lo
        que respecta al tratamiento de datos personales y a la libre circulación de
        estos datos, Trippy Trekkers y el viajero se comprometen a respetar y
        cumplir con la normativa vigente en dicha materia.
      </p>
      <p>

      </p>
      <p>
        Con la adhesión a las presentes Condiciones Generales y la aceptación de
        las Condiciones Particulares, se presta el consentimiento expreso a que los
        datos personales que se proporcionen pasen a formar parte de un fichero
        automatizado de datos de carácter personal, de cuyo tratamiento es
        responsable la entidad TRIPPY TREKKERS S.L., con domicilio social en calle
        Camino de Hoyarrasa 106, Alcobendas, Madrid.
      </p>
      <p>
        Las finalidades del citado fichero son gestionar adecuadamente la reserva y
        la adecuada prestación de los servicios contratados, así como la
        transferencia internacional de tales datos a compañías organizadoras y
        prestatarias de los citados servicios, así como poder hacer llegar
        información sobre productos, servicios, ofertas y promociones relativas
        tanto al programa como a acciones ligadas a la actividad de TRIPPY
        TREKKERS, S.L, en el ámbito del turismo, la hostelería, la restauración y
        los viajes.
      </p>
      <p>
        Los datos sólo serán cedidos, única y exclusivamente, a terceros que
        intervengan de forma necesaria en la prestación de servicios vinculados al
        objeto del viaje contratado y con idénticas finalidades a las anteriores,
        ellos con salvaguardia del deber de secreto y aplicación de las medidas de
        seguridad exigidas por la normativa en vigor.
      </p>
      <p>
        Se podrán ejercer los derechos de acceso, rectificación, cancelación u
        oposición a su tratamiento, por medio de escrito acompañado de copia del
        Documento Nacional de Identidad o pasaporte en vigor y dirigido por correo
        a la anterior dirección o por correo electrónico<i> </i> xxxxx o a la
        atención del responsable de Protección de Datos.
      </p>
      <h2>
        16. Vigencia.
      </h2>
      <p>
        Las presentes Condiciones y Disposiciones Generales tendrán vigencia hasta
        su sustitución por otras nuevas.
      </p>
    </>
  );
};


export default CondicionesGeneralesES;