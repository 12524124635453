import React, {useState} from 'react';
import s from './StartYourTripStart.module.css';
import es from "../../StartYourTripRouter/StartYourTripRouter.module.css";
import DatePickerWithOutsideLabel from "../../../Inputs/DatePickerWithOutsideLabel";
import {useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {Button} from "../../Inputs/Button/Button";
import LocationInput from "../../../Inputs/LocationInput";
import {useHotel} from "../../../../controllers/useHotel";

const StartYourTripStart = ({onNext}) => {
  const {t} = useTranslation();

  const form = useFormContext();
  const {searchHotelsBar} = useHotel();

  const {getValues, setValue, watch} = form;
  const [focusSecondDate, setFocusSecondDate] = useState(false);

  const origin = watch('origin');
  const date_from = watch('date_from');
  const date_to = watch('date_to');


  const canContinue = () => {
    if (typeof origin !== 'object') return false;
    return (date_from && date_to);
  }

  const onArriveDateChange = departure_name => date => {
    setFocusSecondDate(true);
    let to = getValues(departure_name);
    if (to <= date) setValue(departure_name, moment(date).add(1, 'day'));
  }

  const isOutsideRange = arrive_name => day => {
    let from = getValues(arrive_name) || moment().subtract(1, 'day');
    return day <= from;
  }

  const checkData = e => {
    e.preventDefault();
    let origin = getValues('origin');
    let date_from = getValues('date_from');
    let date_to = getValues('date_to');

    if (typeof origin !== 'object') return alert(t('errors.syt.origin missing'));
    if (!date_from) return alert(t('errors.syt.date from missing'));
    if (!date_to) return alert(t('errors.syt.date to missing'));

    onNext(e);
  }

  const onDestinationSelect = (data, input) => {
    if (input.current) input.current.value = data.text;
  }

  const isDayHighlightedFrom = (day) => {
    if (!date_from) return false;

    if (Math.abs(day.diff(date_from, 'day')) === 0) return true;

    if (!date_to) return false;
    return day.isBetween(date_from, moment(date_to).add(1, 'day'), 'day');
  }

  const isDayHighlightedTo = (day) => {
    if (!date_from) return false;

    if (Math.abs(day.diff(date_from, 'day')) === 0) return true;

    if (!date_to) return false;
    return day.isBetween(moment(date_from).subtract(1, 'day'), date_to, 'day');
  }


  return (
    <div>
      <div className={es.title}>{t('start your trip.let\'s begin')}</div>
      <div className={es.subtitle}>{t('start your trip.select the place you want to travel from')}</div>
      <div className="px-5 sm:px-0 mt-20">
        <LocationInput
          searchFunction={async ({q}) => await searchHotelsBar({q, allowHotels: false})}
          label={t("start your trip.origin")}
          id="start_your_trip_origin"
          name="origin"
          onDestinationSelect={onDestinationSelect}
          labelClassName={s.date_label}
          containerClassName={s.locationInput}
        />
        <div className="flex flex-wrap md:flex-nowrap mt-10">
          <div className="w-full mb-10 md:mb-0 mr-0 md:mr-10">
            <DatePickerWithOutsideLabel
              placeholder={t("start your trip.date from")}
              labelClassName={s.date_label}
              name="date_from"
              id="start_your_trip_date_from"
              onChange={onArriveDateChange('date_to')}
              isDayHighlighted={isDayHighlightedFrom}
              calendarInfo={t("input.arrive_date")}
            />
          </div>
          <div className="w-full">
            <DatePickerWithOutsideLabel
              labelClassName={s.date_label}
              placeholder={t("start your trip.date to")}
              id="start_your_trip_date_to"
              isOutsideRange={isOutsideRange('date_from')}
              name="date_to"
              externalFocused={focusSecondDate}
              onChange={() => setFocusSecondDate(false)}
              isDayHighlighted={isDayHighlightedTo}
              calendarInfo={t("input.departure_date")}
            />
          </div>
        </div>
      </div>

      <div className="flex justify-center mt-20">
        <div className={`${es.button}`}>
          <Button disabled={!canContinue()} onClick={e => checkData(e)}>{t('next')}</Button>
        </div>
      </div>
    </div>
  );
};


export default StartYourTripStart;
