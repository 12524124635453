import React, {useState} from 'react';
import s from '../UniqueExperienceRequestBudged.module.css';
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";
import {useHotel} from "../../../../controllers/useHotel";
import moment from "moment";
import {formatSearchBarHotels} from "../../../../helpers/search";
import InputWithOutsideLabel from "../../../Inputs/InputWithOutsideLabel";
import {ReactComponent as LocationSVG} from "../../../../resources/svg/location.svg";
import DatePickerWithOutsideLabel from "../../../Inputs/DatePickerWithOutsideLabel";

const UEBOriginDate = props => {
	const {t} = useTranslation();

	const form = useFormContext();
	const [searchResults, setSearchResults] = useState();
	const {searchHotelsBar} = useHotel();

	const {getValues, setValue, register} = form;

	const onArriveDateChange = departure_name => date => {
		let to = getValues(departure_name);
		if (to <= date) setValue(departure_name, moment(date).add(1, 'day'));
	}

	const search = async e => {
		let str = e.target.value;
		if (str.length <= 1) return setSearchResults([]);
		const {error, results} = await searchHotelsBar({q: str});
		setSearchResults(formatSearchBarHotels(results));
	}
	const onDestinationSelect = (data, input) => {
		if (input.current) input.current.value = data.text;
	}

	return (
		<div>
			<div className={s.title}>{t('experience_details.rbd.let\'s begin')}</div>
			<div className="flex flex-wrap xl:flex-nowrap mt-10">
				<InputWithOutsideLabel
					label={t("start your trip.origin")}
					id="start_your_trip_origin"
					name="origin"
					icon={<LocationSVG style={{fill: '#979797'}}/>}
					onChange={search}
					onDataSelect={onDestinationSelect}
					data={searchResults}
					spellCheck={false}
					/*initialValue={searchParams.t}*/
					autoComplete="new-password"
					labelClassName={s.date_label}
					containerClassName="w-full xl:mr-10 mb-5 xl:mb-0"
				/>
				<div className="w-full mr-0 xl:mr-10">
					<DatePickerWithOutsideLabel
						label={t("start your trip.date from")}
						labelClassName={s.date_label}
						name="date_from"
						id="start_your_trip_date_from"
						onChange={onArriveDateChange('date_to')}
					/>
				</div>
			</div>
		</div>
	)

};


export default UEBOriginDate;