import React, {useEffect, useState} from 'react';
import s from './UniqueExperienceDetails.module.css';
import Skeleton from "react-loading-skeleton";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useUniqueExperiences} from "../../../controllers/useUniqueExperiences";
import GalleryPopup from "../../GalleryPopup";
import {ReactComponent as LikeSVG} from "../../../resources/svg/like_circle.svg";
import {ReactComponent as ShareSVG} from "../../../resources/svg/share_circle.svg";
import CurrencyNumber from "../../CurrencyNumber";
import {Accordion, Modal, Title} from "@mantine/core";
import {ReactComponent as StarSVG} from "../../../resources/svg/star.svg";


import {ReactComponent as ClockSVG} from "../../../resources/svg/clock.svg";
import {ReactComponent as DownloadSVG} from "../../../resources/svg/download.svg";
import UniqueExperienceHotels from "../UniqueExperienceHotels";
import Button from "../../Inputs/Button/Button";
import UniqueExperienceActivities from "../UniqueExperienceActivities";
import UniqueExperienceFeatures from "../UniqueExperienceFeatures";
import UniqueExperienceRequestBudged from "../UniqueExperienceRequestBudged";
import {useHotel} from "../../../controllers/useHotel";
import useActivity from "../../../controllers/useActivity/useActivity";
import {buildHighlightData, buildImages} from "../../../helpers/uniqueExperiences";
import {langRender} from "../../../helpers/i18n";
import {useViewportSize} from "@mantine/hooks";
import UniqueExperienceReviewTabs from "../UniqueExperienceReviewTabs";
import MapContainer from "../../MapContainer/MapContainer";
import bases from "../../../routes/bases";


const Loading = () => <div>
  <Skeleton count={1} height={50}/>
  <Skeleton count={6} height={100}/>
</div>

const HighlightData = ({experience}) => {
  const {t} = useTranslation();

  let hightlights = buildHighlightData({experience, t});


  return <div className={s.right_box}>
    <h3 className="mb-5">{t('experience_details.highligths')}</h3>
    <div className="w-full flex flex-wrap whitespace-nowrap">
      {hightlights.map((h, i) => <div
        key={i}
        className="flex items-center pr-5 mb-5" style={{flexBasis: '50%'}}>
        <div className={s.highlight_icon}>{h.icon}</div>
        {h.n} {h.text}
      </div>)}
    </div>
  </div>
}

const sortPlaces = (places) => {
  return places.sort((a, b) => {
    if (a.start < b.start) return 1;
    if (a.start > b.start) return -1;
    return 0;
  });
}

const ImportantInfo = ({experience}) => {
  const {t} = useTranslation();
  return <div className={s.right_box}>
    <h3 className="mb-5">{t('experience_details.important')}</h3>
    <div className={s.important_item}>
      <ClockSVG width={24} height={24}/>
      <div className="ml-3">
        <b>{t('experience_details.adventure start')}: </b>
        {sortPlaces(experience.places)[0].address.label}
      </div>
    </div>
    <div className={s.important_item}>
      <DownloadSVG width={24} height={24}/>
      <div className="ml-3"><b>{t('experience_details.download itinerary')}</b></div>
    </div>
  </div>
}

const FrequentQuestions = ({experience}) => {
  const {t} = useTranslation();
  return <div>
    <Title order={4} className="my-5">{t('experience_details.frequent questions')}</Title>
    <Accordion>
      <Accordion.Item label={t('experience_details.included')}
                      className={s.description}>{langRender(experience.included)}</Accordion.Item>
      <Accordion.Item label={t('experience_details.excluded')}
                      className={s.description}>{langRender(experience.excluded)}</Accordion.Item>
      <Accordion.Item label={t('experience_details.observations')}
                      className={s.description}>{langRender(experience.observations)}</Accordion.Item>
    </Accordion>
  </div>
}

const UniqueExperienceDetails = ({experience: _experience}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();


  const {width} = useViewportSize();
  const desktop = width >= 1280;


  const [experience, setExperience] = useState(false);
  const [error, setError] = useState(false);

  const [imageIndex, setImageIndex] = useState(0);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [askBudgedModal, setAskBudgedModal] = useState(false);


  const [loadedData, setLoadedData] = useState({});

  const {id} = useParams();
  const {getExperience} = useUniqueExperiences();
  const {getHotelsByCode} = useHotel();
  const {getActivitiesByCode} = useActivity();

  const loadHotels = async _hotels => {
    const {error, hotels} = await getHotelsByCode(_hotels.map(h => ({
      code: h.hotel_code,
      id: h.hotel_id,
      api: h.api || h.type,
    })));
    if (error) return setError(error);


    return hotels;
  }
  const loadActivities = async _activities => {
    const {error, activities} = await getActivitiesByCode(_activities.map(a => ({
      code: a.activity_id,
      api: a.api,
    })));
    if (error) return setError(error);


    return activities;
  }
  const loadData = async () => {
    let hotels = await loadHotels(experience.hotels);
    let activities = await loadActivities(experience.activities);
    setLoadedData({hotels, activities});
  }

  const loadExperience = async id => {
    let {error, experience} = await getExperience({id});
    if (error) return setError(error);

    // build images


    experience.min_price = Number.MAX_VALUE;
    //Math.min(...experience.packages.filter(a => !isNaN(a.price)).map(p => p.price));


    experience.images = buildImages(experience);

    setExperience(experience);
  }

  useEffect(() => {
    if (_experience) return setExperience(_experience);
    if (id) loadExperience(id);
  }, [id]);

  useEffect(() => {
    if (!experience) return;
    loadData();
  }, [experience]);

  if (!experience && !error) return <Loading/>

  if (error) return <div className="text-2xl m-10">{t(`errors.${error}`)}</div>
  //if(askBudgedModal)return <UniqueExperienceRequestBudged hotels={loadedData.hotels} activities={loadedData.activities} experience={experience}/>


  let stars = [];
  for (let i = 0; i < 5; i++) {
    stars.push(<StarSVG key={i} width={15} height={15} style={{fill: '#FBBC04'}}/>);
  }

  return (
    <>
      <Modal
        title={""}
        opened={askBudgedModal}
        onClose={() => setAskBudgedModal(false)}
        size={desktop ? '70%' : '95%'}
        closeOnEscape={true}
        closeOnClickOutside={false}
        styles={{
          close: {
            'svg': {
              width: '30px',
              height: '30px',
            }
          },
          modal: {
            boxShadow: '2px 2px 15px rgb(0 0 0 / 25%)',
            borderRadius: '30px',
            background: 'rgba(255,255,255,.9)'
          }
        }}
      >
        <UniqueExperienceRequestBudged hotels={loadedData.hotels} activities={loadedData.activities}
                                       experience={experience}/>
      </Modal>
      <div className="w-full" style={{background: '#F6F6F6'}}>
        <GalleryPopup
          images={experience.images}
          index={imageIndex}
          setIndex={setImageIndex}
          isOpen={isGalleryOpen}
          setIsOpen={setIsGalleryOpen}
        />
        <div className={s.experience}>
          <div className="w-full flex flex-wrap lg:flex-nowrap justify-center">
            <div className={s.gallery}>
              <div className={s.chosen_image}>
                <img onClick={() => setIsGalleryOpen(true)} className={s.mainImage}
                     src={experience.images[imageIndex]} alt={"experience main image"}/>
              </div>
              <div className={s.rest_images}>
                {experience.images.map((image, i) =>
                  <img onClick={() => setImageIndex(i)} className={s.thumbnailImage}
                       key={i}
                       src={image} alt="experience image"/>
                )}
              </div>
            </div>

            <div className="xl:ml-10 w-full lg:w-4/12 flex flex-col mt-10 lg:mt-0 mx-10 xl:mx-0">
              <div className="flex flex-nowrap justify-between items-center">
                <div>
                  <div className={s.tourOperatorName}
                       onClick={() => navigate(bases.tourOperator + '/62cedfff0f2144cf440cdd5e')}>Agency name
                  </div>
                  <div className="flex mt-5">{stars}</div>
                </div>
                <div className="flex flex-nowrap">
                  <div className="mr-5 cursor-pointer">
                    <LikeSVG/>
                  </div>
                  <div className="cursor-pointer">
                    <ShareSVG/>
                  </div>
                </div>
              </div>


              <div className={s.title}>
                {langRender(experience.title)}
              </div>
              <div className="flex lg:flex-col flex-wrap sm:flex-nowrap h-full items-center">
                <div className={s.min_price_container}>
                  <div className="text-2xl">{t('experience_details.from')}</div>
                  <CurrencyNumber className={s.min_price}>{experience.min_price}</CurrencyNumber>
                  <h4>{t('experience_details.min price')}</h4>

                  <Button onClick={() => setAskBudgedModal(true)}
                          className="mt-5">{t('experience_details.ask for budget')}</Button>
                </div>

                <div className={s.mapContainer}>
                  <MapContainer
                    containerStyle={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '15px',
                      maxHeight: '300px'
                    }}
                    marker={{name: ""}}
                    center={{
                      lat: 7.6925911780321465,
                      lng: 80.76066472570494
                    }}
                    zoom={16}
                  />
                </div>

              </div>
            </div>


          </div>

          <div className="w-full flex mt-14 flex-wrap xl:flex-nowrap">
            <div className="w-full xl:w-8/12 xl:pr-20">
              {experience.description && <Title order={4} className="my-5">{t('description')}</Title>}
              <div className={s.description}>
                {langRender(experience.description)}
              </div>

              <FrequentQuestions experience={experience}/>
            </div>
            <div className="w-full xl:w-4/12">
              <HighlightData experience={experience}/>
              <ImportantInfo experience={experience}/>
            </div>
          </div>

          {experience.hotels.length > 0 && <div>
            <Title order={4} className="my-5">{t('experience_details.hotel', {count: 2})}</Title>
            <UniqueExperienceHotels hotels={loadedData.hotels} toLoad={false}/>
          </div>}

          {experience.activities.length > 0 && <div>
            <Title order={4} className="my-5">{t('experience_details.activity', {count: 2})}</Title>
            <UniqueExperienceActivities activities={loadedData.activities} toLoad={false}/>
          </div>}

          {experience.features.length > 0 && <div>
            <Title order={4} className="my-5">{t('experience_details.feature', {count: 2})}</Title>
            <UniqueExperienceFeatures features={experience.features} toLoad={true}/>
          </div>}

          <div className="mb-20">
            <UniqueExperienceReviewTabs/>
          </div>

        </div>

      </div>
    </>
  );
};


export default UniqueExperienceDetails;