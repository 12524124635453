import React, {useState, useMemo} from 'react';
import s from './UniqueExperienceReviewTabs.module.css';
import {Tabs} from "@mantine/core";
import {useTranslation} from "react-i18next";
import ReviewsList from "../../ReviewsList";
import BlogPosts from "../../BlogPosts";

const UniqueExperienceReviewTabs = props => {
	const {t} = useTranslation();
	const [tab, setTab] = useState(1);



	const posts = useMemo(() => [
		{
			title: '7 Puntos destacados de Camboya',
			tags: [{title: 'CAMBOYA', color: '#1abc9c'}],
			image: 'http://blog.trippytrekkers.com/wp-content/uploads/2022/10/Captura-de-pantalla-2022-10-26-a-las-11.19.07-1.png',
			text: '1. Angkor Wat Esta considerado como Patrimonio de la Humanidad de la UNESCO, una de las mayores atracciónes turísticas de Camboya… y...',
			link: 'http://blog.trippytrekkers.com/7-puntos-destacados-de-camboya'
		},
		{
			title: 'Curiosidades de Mexico',
			tags: [{title: 'America', color: '#1abc9c'}, {title: "Mexico", color: '#3498db'}],
			image: 'http://blog.trippytrekkers.com/wp-content/uploads/2022/10/Captura-de-pantalla-2022-10-11-a-las-15.22.30.png',
			text: '1) Cuando se van a hacer negocios en México, hay que dirigirse a las personas por su título profesional, como doctor, profesor o licenciado. Por ello, es...',
			link: 'http://blog.trippytrekkers.com/curiosidades-del-pais'
		},
		{
			title: 'Fauna & flora de Chile',
			tags: [{title: 'Chile', color: '#1abc9c'}],
			image: 'http://blog.trippytrekkers.com/wp-content/uploads/2022/10/Captura-de-pantalla-2022-10-19-a-las-18.30.11.png',
			text: 'Chile es país largo, lo que provoca que tenga grandes variaciones en su flora y fauna. 1) Variaciones de la Fauna: En...',
			link: 'http://blog.trippytrekkers.com/fauna-flora-de-chile'
		},
		{
			title: '7 Puntos destacados de Camboya',
			tags: [{title: 'CAMBOYA', color: '#1abc9c'}],
			image: 'http://blog.trippytrekkers.com/wp-content/uploads/2022/10/Captura-de-pantalla-2022-10-26-a-las-11.19.07-1.png',
			text: '1. Angkor Wat Esta considerado como Patrimonio de la Humanidad de la UNESCO, una de las mayores atracciónes turísticas de Camboya… y...',
			link: 'http://blog.trippytrekkers.com/7-puntos-destacados-de-camboya'
		},
		{
			title: 'Curiosidades de Mexico',
			tags: [{title: 'America', color: '#1abc9c'}, {title: "Mexico", color: '#3498db'}],
			image: 'http://blog.trippytrekkers.com/wp-content/uploads/2022/10/Captura-de-pantalla-2022-10-11-a-las-15.22.30.png',
			text: '1) Cuando se van a hacer negocios en México, hay que dirigirse a las personas por su título profesional, como doctor, profesor o licenciado. Por ello, es...',
			link: 'http://blog.trippytrekkers.com/curiosidades-del-pais'
		},
		{
			title: 'Fauna & flora de Chile',
			tags: [{title: 'Chile', color: '#1abc9c'}],
			image: 'http://blog.trippytrekkers.com/wp-content/uploads/2022/10/Captura-de-pantalla-2022-10-19-a-las-18.30.11.png',
			text: 'Chile es país largo, lo que provoca que tenga grandes variaciones en su flora y fauna. 1) Variaciones de la Fauna: En...',
			link: 'http://blog.trippytrekkers.com/fauna-flora-de-chile'
		}
	], []);

	return (
		<Tabs
			active={tab}
			onTabChange={setTab}
			variant="unstyled"
			styles={{
				root: {
					maxWidth: '1200px',
					background: '#fff',
					borderRadius: '10px',
					overflow: 'hidden',
					border: '1px solid #DADADA',
				},
				tabsList: {
					justifyContent: 'space-around',
					background: '#F6F6F6'
				},
				tabControl: {
					width: '50%',
					fontSize: '1.6rem',
					padding: '3rem',
					color: '#4F4F4F',
					background: '#F6F6F6',
				},
				tabActive: {
					borderRight: '1px solid #DADADA',
					borderBottom: '1px solid #DADADA',
					borderTopRightRadius: '10px',
					borderTopLeftRadius: '10px',
					background: '#fff',
					fontWeight: 'bold',
					color: '#1C7263',
					fontSize: '1.7rem'
				},
				body: {
					padding: '3rem'
				}
			}}
		>
			<Tabs.Tab label="Reviews"><ReviewsList /></Tabs.Tab>
			<Tabs.Tab label="Blog"><BlogPosts posts={posts}/></Tabs.Tab>
		</Tabs>
	);
};


export default UniqueExperienceReviewTabs;