import React, {useMemo} from 'react';
import s from './CheckoutSummary.module.css';
import sc from '../Checkout.module.css';
import {useTranslation} from "react-i18next";
import useCheckoutProvider from "../../../providers/CheckoutProvider/useCheckoutProvider";

import {ReactComponent as CalendarSVG} from "../../../resources/my_profile/calendar.svg";
import {ReactComponent as MoonSVG} from "../../../resources/svg/moon.svg";
import HotelsSummary from "./HotelsSummary";
import FlightsSummary from "./FlightsSummary";
import ActivitiesSummary from "./ActivitiesSummary";
import TotalSummary from "./TotalSummary";
import dayjs from "dayjs";
import InsuranceSummary from "./InsuranceSummary";


const CheckoutSummary = props => {
  const {t} = useTranslation();
  const {flights, hotels, activities, getFinalDates, selectedInsurance} = useCheckoutProvider();

  const formatDate = date => dayjs(date).format('D MMM YYYY');


  const startEndDate = useMemo(() => {
    const {from, to} = getFinalDates();

    return {start: from, end: to, nights: to.diff(from, 'day')};
    /*    let start = moment().subtract(1, 'year');
        let end = moment().add(20, 'years');
        console.log(flights);

        if (Array.isArray(flights)) {
          flights.forEach(flight => {
            const departure = moment(flight.itineraries[0].segments[0].departure.at);
            const arrive = moment(flight.itineraries[flight.itineraries.length - 1].segments[0].departure.at);
            if (departure.isAfter(start)) start = departure;
            if (arrive.isBefore(end)) end = arrive;
          });
          return {start, end, nights: end.diff(start, 'days')};
        }*/
  }, [flights, hotels, activities]);


  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex justify-between">
        <div className={s.title}>{t('checkout.summary')}</div>
        {/*				<div className="flex items-center">
					<LikeCircleSVG style={{marginRight: "20px"}}/>
					<ShareCircleSVG/>
				</div>*/}
      </div>
      <div className={`${sc.box} ${s.box} w-full`}>
        <div className={s.summary_table_container}>
          <HotelsSummary hotels={hotels}/>
          <FlightsSummary flights={flights}/>
          <ActivitiesSummary activities={activities}/>
          <InsuranceSummary insurance={selectedInsurance}/>
          <TotalSummary/>
        </div>
        <div className="w-full h-1 bg-gray-200"/>
        {startEndDate && <div className="flex text-2xl font-bold justify-around py-10">
          <CalendarSVG style={{fill: '#757575'}}/>
          <div>{formatDate(startEndDate.start)} {!startEndDate.start.isSame(startEndDate.end) ? ` - ${formatDate(startEndDate.end)}` : ''}</div>
          {startEndDate.nights > 0 && <div className="flex items-center"><MoonSVG
            style={{marginRight: '10px'}}/> {startEndDate.nights} {t('nights')}</div>}
        </div>}

      </div>
    </div>
  );
};


export default CheckoutSummary;
