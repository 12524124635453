import React from 'react';

const PoliticasCookiesES = props => {
  return (
    <>
      <h1>Política de cookies</h1>
      <p>Esta web utiliza cookies propias y de terceros para ofrecerle una mejor experiencia y servicio. Al navegar o
        utilizar nuestros servicios acepta el uso que hacemos de las cookies. Sin embargo, tiene la opción de impedir la
        generación de cookies y la eliminación de las mismas mediante la selección de la correspondiente opción en su
        Navegador . En caso de bloquear el uso de cookies en su navegador es posible que algunos servicios o
        funcionalidades de la página W eb no estén disponibles.</p>
      <h2>¿Qué es una cookie?</h2>
      <p>Las cookies constituyen una herramienta empleada por los servidores W eb para almacenar y recuperar información
        acerca de sus visitantes. Es un identificador único en forma de fichero de texto que se remite al dispositivo
        del usuario para registrar información, permitiendo así mejorar la calidad y seguridad de la página web. Poseen
        una fecha de caducidad a partir de la cual dejan de ser operativas.</p>
      <h2>Utilidad de las cookies en mi página web</h2>
      <p>Utilizamos cookies para facilitar la navegación por la web y para obtener una mayor eficacia y personalización
        de los servicios que le ofrecemos. Las cookies empleadas se asocian únicamente con un Usuario anónimo y su
        dispositivo, no proporcionan referencias que permitan obtener sus datos personales ni incluir virus en el mismo.
        T ampoco se pueden leer las cookies implantadas en su dispositivo desde otros servidores. La información que
        analizamos haciendo uso de las cookies son la dirección de protocolo de internet (IP) empleada para conectar tu
        ordenador a internet, tipo y versión de navegador , sistema operativo y plataforma utilizada para la conexión y
        la fecha y la hora de dicha actividad.</p>
      <h2>Cookies empleadas</h2>
      <p>Utilizamos cookies permanentes que se almacenan en el dispositivo del usuario y que permiten recuperar la
        información de la navegación en la siguiente visita. T ienen fecha de caducidad. Estas cookies nos permiten
        personalizar sus visitas, a través de sus preferencias (por ejemplo, país e idioma).</p>
      <p>También utilizamos cookies de terceros que permiten gestionar y mejorar los servicios ofrecidos. Como por
        ejemplo, los servicios de Stripe.</p>
      <p>
        <bold>Cookies</bold>
      </p>
      <ol>
        <li>Idioma</li>
        <li>Cookie de Sesión</li>
        <li>Relacionadas con redes sociales</li>
      </ol>
      <p>
        <bold>LocalStorage</bold>
      </p>
      <ol>
        <li>Tipos de hoteles para filtros</li>
        <li>Tasa de cambio de divisas</li>
        <li>Carrito de compra</li>
        <li>Datos sobre el usuario que ha iniciado sesión</li>
        <li>Idioma elegido</li>
        <li>Datos para mejorar la accesibilidad</li>
        <li>Otros datos para identificar errores en la página. Estos no identifican al usuario pero son necesarios para
          la mejorar del producto.
        </li>
      </ol>

      <h2>Cookies de terceros</h2>
      <ol>
        <li>Stripe <a href="https://stripe.com/es/legal/cookies-policy"
                      target="_blank" rel="noreferrer">https://stripe.com/es/legal/cookies-policy</a></li>
        <li>Facebook <a href="https://www.facebook.com/policies/cookies/"
                        target="_blank" rel="noreferrer">https://www.facebook.com/policies/cookies/</a></li>
        <li>Instagram <a href="https://help.instagram.com/1896641480634370?ref=ig"
                         target="_blank" rel="noreferrer">https://help.instagram.com/1896641480634370?ref=ig</a></li>
        <li>YouTube <a href="https://policies.google.com/privacy?hl=es-419&gl=mx"
                       target="_blank" rel="noreferrer">https://policies.google.com/privacy?hl=es-419&gl=mx</a></li>
      </ol>

      <h2>¿Qué ocurre si se deshabilitan las cookies?</h2>
      <p>En el caso de bloquear o no aceptar la instalación de cookies es posible que ciertos servicios ofrecidos por
        nuestro sitio web que necesitan su uso queden deshabilitados y , por lo tanto, no estén disponibles para usted
        por lo que no podrá aprovechar por completo todo lo que nuestras webs y aplicaciones le ofrecen. Es posible
        también que la calidad de funcionamiento de la página web pueda disminuir.</p>
      <p>Para permitir , conocer , bloquear o eliminar las cookies instaladas en tu equipo puedes hacerlo mediante la
        configuración de las opciones del navegador instalado en tu ordenador .</p>
      <p>Por ejemplo puedes ver cómo hacerlo en las ayudas facilitadas por cada navegador dependiendo de cual
        utilices: </p>
      <ol>
        <li><a target="_blank"
               href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we"
               rel="noreferrer">Firefox</a>
        </li>
        <li><a target="_blank" href="http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647"
               rel="noreferrer">Chrome</a></li>
        <li><a target="_blank"
               href="http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9"
               rel="noreferrer">Explorer</a>
        </li>
        <li><a target="_blank" href="http://support.apple.com/kb/ph5042" rel="noreferrer">Safari</a></li>
        <li><a target="_blank" href="http://help.opera.com/Windows/11.50/es-ES/cookies.html" rel="noreferrer">Opera</a>
        </li>
      </ol>
    </>
  );
};


export default PoliticasCookiesES;