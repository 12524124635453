import React from 'react';
import s from './HamburgerIcon.module.css';

const HamburgerIcon = React.forwardRef(({open, onClick, className}, ref) => {
  const classNameFinal = `${s.container} ${className || ''} ` + (open ? s.open : '');

  return (
    <div ref={ref} className={classNameFinal} onClick={onClick}>
      <span/><span/><span/><span/>
    </div>
  );
});


export default HamburgerIcon;
