import MapBox, {MapPoint} from "../../../MapBox/MapBox";
import React, {useEffect, useMemo, useState} from "react";
import {useElementSize} from "@mantine/hooks";
import MapHotel from "../../../Hotels/MapHotel/MapHotel";
import useHotelsProvider from "../../../../providers/HotelsProvider/useHotelsProvider";
import {Hotel, ProjectedHotelForList, SearchHotelURLParams} from "../../../../types/hotels/hotels";

interface MapHotelsProps {
  center: MapPoint;
  hotels: ProjectedHotelForList[];
  searchParams: SearchHotelURLParams;
  hideFullScreenControl?: boolean
  selectedHotel?: Hotel
  zoom?: number
}

const MapHotels = ({center, selectedHotel, hotels, searchParams, hideFullScreenControl, zoom}: MapHotelsProps) => {
  const {ref: containerRef, width} = useElementSize();
  const {setCount} = useHotelsProvider();
  const [viewState, setViewState] = useState({
    longitude: center[0],
    latitude: center[1],
    zoom: zoom ?? 13
  });
  useEffect(() => {
    if (hotels?.length >= 0) setCount(hotels.length);
  }, [hotels]);


  const markers = useMemo(() => {
    if (!hotels || !hotels?.length) return [];
    return hotels.map(hotel => ({
        key: hotel._id,
        longitude: hotel.longitude,
        latitude: hotel.latitude,
        render: <MapHotel selected={selectedHotel?.code === hotel.code} setViewState={setViewState}
                          searchParams={searchParams} hotel={hotel}/>
      })
    )
  }, [hotels, selectedHotel]);


  return <div className="w-full" ref={containerRef}>
    <MapBox markers={markers} viewState={viewState} setViewState={setViewState}
            height={"calc(100vh - 117.5px)"}
            width={width + 'px'} hideFullScreenControl={hideFullScreenControl}
    />
  </div>
}

export default MapHotels;