import React from 'react';
import s from './RegisterPage.module.css';
import {useTranslation} from "react-i18next";
import {RegisterForm} from "./RegisterForm";
import Header from "../../components/Header/Header";
import {Footer} from "../../components/FooterV2/Footer";

const RegisterPage = () => {
  const {t} = useTranslation();

  return (
    <>
      <Header/>
      <div style={{background: '#eff4f2'}}>
        <div className={s.bigContainer}>
          <div className="w-full mx-auto z-10 pt-5 px-10 sm:px-0" style={{maxWidth: 600}}>
            <RegisterForm/>
          </div>
          <div className={s.bannerContainer}>
            <div className={s.banner}>
              <div className={s.bannerText}>{t('login.bannerText')}</div>
              <div className={s.bannerQuote}>{t('login.bannerQuote')}</div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};


export default RegisterPage;
