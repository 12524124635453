import React, {useState} from 'react';
import s from './QuestionPopover.module.css';
import {Popover} from "@mantine/core";
import {AiOutlineQuestionCircle} from "react-icons/ai";

const QuestionPopover = ({Icon = AiOutlineQuestionCircle, children}) => {
	const [opened, setOpened] = useState(false);

	return (
		<Popover
			opened={opened}
			onClose={() => setOpened(false)}
			target={<Icon onMouseEnter={() => setOpened(true)} onMouseLeave={() => setOpened(false)}/>}
		>
			{children}
		</Popover>
	);
};


export default QuestionPopover;