import React, {useEffect, useMemo, useState} from 'react';
import s from './HotelDetailsAllFacilities.module.css';
import {useTranslation} from "react-i18next";
import {useHotel} from "../../../../../controllers/useHotel";
import ListFacilities from "../ListFacilities/ListFacilities";
import {createFacilityGroups, getAllFacilitiesFromHotel} from "../../../facilities/util";

const HotelDetailsAllFacilities = ({hotel, services}) => {
  const {t} = useTranslation();

  const {getTypes} = useHotel();

  const [hotelTypes, setHotelTypes] = useState();

  const loadHotelTypes = async () => {
    const allTypes = await getTypes();
    if (!allTypes.facilityGroups) return;
    setHotelTypes(allTypes);
  }

  useEffect(() => {
    loadHotelTypes();
  }, []);

  let groups = useMemo(() => hotelTypes ? createFacilityGroups({
    facilities: getAllFacilitiesFromHotel({hotel}),
    hotelTypes
  }) : [], [hotelTypes, hotel]);

  if (!Array.isArray(groups) || groups.length === 0) return <div>{t('hotel.no facilities found')}</div>;

  return (
    <div>
      <h3 className={s.title}>{t('hotel.all facilities')}</h3>
      <ListFacilities groups={groups}/>
    </div>
  );
};


export default HotelDetailsAllFacilities;