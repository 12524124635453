import React from "react";

import {ReactComponent as ActivitySVG} from "../resources/svg/searchBar/activity.svg";
import {firstCapitalize} from "./util";
import {SearchBarSeparator} from "../components/Hotels/components/SearchBarSeparator";
import {CiLocationOn} from "react-icons/ci";
import {LuHotel} from "react-icons/lu";


const defaultFormat = (results, replace = {}) => {
  if (!Array.isArray(results)) return [];
  return results.map(result => ({
    text: result.label,
    smallText: result.smallLabel,
    // icon: <svg width="16" height="20" viewBox="0 0 16 20" fill="none">
    //   <path
    //     d="M8 0C3.8 0 0 3.22 0 8.2C0 11.38 2.45 15.12 7.34 19.43C7.72 19.76 8.29 19.76 8.67 19.43C13.55 15.12 16 11.38 16 8.2C16 3.22 12.2 0 8 0ZM8 10C6.9 10 6 9.1 6 8C6 6.9 6.9 6 8 6C9.1 6 10 6.9 10 8C10 9.1 9.1 10 8 10Z"
    //     fill="#979797"/>
    // </svg>,
    icon: <CiLocationOn size={20}/>,
    raw: result,
    ...replace
  })).filter(result => result.text && result.text.length > 0)
}

const hotelFormat = (results) => {
  if (!Array.isArray(results)) return [];
  return results.map(result => ({
    text: result.name,
    smallText: (result.city ? `${firstCapitalize(result.city.toLowerCase())}` : '') + (result.countryName ? `, ${firstCapitalize(result.countryName.toLowerCase())}` : ''),
    //icon: <HotelSVG/>,
    icon: <LuHotel size={20}/>,
    raw: result
  })).filter(result => result.text && result.text.length > 0)
}


export const formatSearchBarHotels = results => {
  if (!Array.isArray(results)) return [];
  const hotelResults = results.filter(result => result.type === 'hotel');
  const separator = []
  if (hotelResults.length) {
    separator.push({
      dataRender: 'custom',
      render: SearchBarSeparator
    })
  }


  return [
    ...defaultFormat(results.filter(result => result.type === 'address')),
    ...separator,
    ...hotelFormat(hotelResults)
  ]
}


export const formatSearchBarActivities = results => {
  if (!Array.isArray(results)) return [];

  return defaultFormat(results, {
    icon: <ActivitySVG/>
  });
}