import React from 'react';

const ErrorCallback = ({error, componentStack, resetErrorBoundary}) => {
  return (
    <div>{error}</div>
  );
};


export default ErrorCallback;
