import s from './Footer.module.css';
import LogoFeatherSrc from '../../resources/images/logo/white_feather_2.png'
import LogoTextSrc from '../../resources/images/logo/logo_only_text_white.png'
import React from "react";
import {t} from "i18next";
import {CgFacebook, CgInstagram} from "react-icons/cg";
import cn from "classnames";
import {useNavigate} from "react-router-dom";
import config from "../../config";

export function Footer({className}: { className?: string }) {
  const navigate = useNavigate();

  return <div className={cn(s.container, className)}>
    <div className={s.logoContainer} style={{flex: '0 0 230px'}}>
      <img src={LogoFeatherSrc} width={35} alt="logo feather" className="mr-2"/>
      <img src={LogoTextSrc} width={185} alt="logo text"/>
    </div>
    <div className={s.links}>
      <div onClick={() => navigate('/terms?tab=3')}>{t('home small footer.cookies')}</div>
      <div onClick={() => navigate('/terms?tab=1')}>{t('home small footer.privacy')}</div>
      <div onClick={() => navigate('/terms?tab=0')}>{t('home small footer.legal')}</div>
    </div>
    <div className={s.socialsContainer}>
      <a href={config.instagram} target="_blank" rel="noreferrer"><CgInstagram stroke="#fff" size={30}/></a>
      <a href={config.facebook} target="_blank" rel="noreferrer"><CgFacebook style={{fill: '#fff'}} size={30}/></a>
    </div>
  </div>
}