import React from 'react';
import config from "../../../config";

const CondicionesGeneralesEN = props => {
  return (
    // traduceme el siguiente texto a ingles respetando las etiquetas html

    <>
      <h1>GENERAL TERMS AND CONDITIONS OF COMBINED TRAVEL CONTRACT</h1>
      <h2>
        1.
        Applicable legal regulation

      </h2>
      <p>

      </p>
      <p>
        These General Contracting Conditions are governed by the provisions of Royal Decree Law 1/2007, of November 16,
        of the General Law for the Defense of Consumers and Users, modified by Royal Decree-Law 23/2018, of December 21,
        transposition of directives on trademarks, rail transport and combined travel and linked travel services, and by
        Law 7/1998, of April 13, on general contracting conditions, as well as other complementary laws, in addition to
        the provisions below.
      </p>
      <p>

      </p>
      <h2>
        2.
        Definitions.
      </h2>
      <p>

      </p>
      <p>
        For the purposes of these General Conditions, the following is understood as:
      </p>
      <p>
        Program:
        the informative document that includes the description and conditions of the combined trip, which is provided to
        the traveler before contracting it.
      </p>
      <p>
        Combined Travel Contract
        : The obligatory agreement that binds the organizer and the traveler.
      </p>
      <p>
        Traveler:
        any person who intends to enter into a contract or has the right to travel under a contract.
      </p>
      <p>
        Organizer:
        The company TRIPPY TREKKERS, S.L., with registered office at Camino de Hoyarrasa 106, C.P. 28109 Alcobendas,
        Madrid, and C.I.C.M.A nº XXXX.
      </p>
      <p>
        Telephone number: <a
        href={`tel:${config.trippy_trekkers.contact_email.replaceAll(' ', '')}`}>{config.trippy_trekkers.phone_number}</a>
      </p>
      <p>
        Emergency number: <a
        href={`tel:${config.trippy_trekkers.emergency_number}`}>{config.trippy_trekkers.emergency_number}</a> (whats
        app)
      </p>
      <p>
        E-mail: <a href={`mailto:${config.trippy_trekkers.contact_email}`}>{config.trippy_trekkers.contact_email}</a>
      </p>
      <p>
        web: <a href="https://www.trippytrekkers.com/">www.trippytrekkers.com</a>
      </p>
      <p>
        <b>Retailer</b>:
        a businessman other than the organizer who sells or offers combined trips by an organizer.
      </p>
      <p>
        <b>Pre-contractual information:</b>
        that which the organizer or, where appropriate, the retailer is obliged to
        provide to the traveler before the traveler is bound by any
        contract or offer.
      </p>
      <h2>
        3.
        Applicable contractual framework.
      </h2>
      <p>
        All the conditions that regulate the contracted combined trip will form an integral part of the combined travel
        contract, specifically, the general conditions set out in this text, the pre-contractual conditions, and the
        particular conditions agreed with the traveler.
      </p>
      <h2>
        4.
        Additional Pre-contractual Information Required
      </h2>
      <p>
        The organizer will offer the interested traveler all the pre-contractual information related to the combined
        trip, required in the RDL 1/2007.
      </p>
      <p>
        Before the traveler is bound by any combined travel contract or corresponding offer, the organizing agency or,
        where appropriate, the retail agency, will deliver to the traveler the standardized information form for
        combined travel contracts, as well as the rest of the characteristics and information of the trip in accordance
        with current legislation.
      </p>
      <p>
        Apart from the program, the organizer will provide the traveler with the form with the standardized information
        for combined travel contracts, which appears in the following link:
      </p>
      <p>
        Case A:
        <i>
          Standardized information form for combined travel contracts
          in which it is possible to use hyperlinks (
        </i>
        <a
          href="https://noticias.juridicas.com/base_datos/Admin/rdleg1-2007.anexo2.html#anexo1anexo2)"
        >
          <i>
            https://noticias.juridicas.com/base_datos/Admin/rdleg1-2007.anexo2.html#anexo1anexo2
          </i>
          <i>)</i>
        </a>
        <i> MAKE PDF WITH SECTION A</i>
      </p>
      <p>
        Case B:
        <i>
          Standardized information form for combined travel contracts
          in cases other than those contemplated in part A (
        </i>
        <a
          href="https://noticias.juridicas.com/base_datos/Admin/rdleg1-2007.anexo2.html#anexo1anexo2)"
        >
          <i>
            https://noticias.juridicas.com/base_datos/Admin/rdleg1-2007.anexo2.html#anexo1anexo2
          </i>
          <i>)</i>
        </a>
        <i> MAKE PDF WITH SECTION B</i>
      </p>
      <p>
        <i></i>
      </p>
      <p>

      </p>
      <h2>
        5.
        Contracting the trip.
      </h2>
      <p>

      </p>
      <p>
        The organizer will offer the interested traveler all the pre-contractual information related to the combined
        trip, required in the RDL 1/2007.
      </p>
      <p>
        Once the deposit or amount required has been paid, and the contract has been signed, which is made up of the
        general conditions set out in this text, the pre-contractual conditions, and the particular conditions agreed
        with the traveler, the organizer will make the appropriate arrangements to obtain confirmation of the contracted
        services from each provider.
      </p>
      <p>
        In the event that any of these services cannot be confirmed due to lack of availability, another one with
        similar characteristics will be offered, with an indication of the new price, if applicable, which the traveler
        may accept or reject.
      </p>
      <p>
        In the event that the traveler rejects the proposed change, Trippy Trekkers
        will refund the amounts deposited to date.
      </p>
      <h2>
        6.- Delimitation of the services of the combined trip.
      </h2>
      <p>
        6.1.- General scope.
      </p>
      <p>
        The services that make up the combined travel contract result from the
        information provided to the traveler, and included in it.
      </p>
      <p>
        6.2.- Transports.
      </p>
      <p>
        The client must present himself at the indicated place for departure with the
        advance notice provided in the travel documentation provided.
      </p>
      <p>
        On airplane trips, presentation at the airport will be made with a minimum advance of three hours on
        international flights and two hours on national flights over the official departure time, and in any case, the
        specific recommendations indicated in the travel documentation provided when signing the contract will be
        strictly followed. If the traveler could not make the trip because he did not present himself with the required
        advance notice, the regime provided for the traveler's withdrawal once the trip has begun will apply.
      </p>
      <p>
        Reservations will be made with the data indicated by the traveler. Airlines reserve the right that a ticket with
        a name that does not match the one on the passport may be a reason to deny a traveler boarding.
      </p>
      <p>
        Once the reservation has been made, an error in the name or an incomplete name,
        causes to make a new reservation with the possibility of not getting seats
        on the same flights or that the initial price varies.
      </p>
      <p>
        The loss or damage that occurs in relation to hand luggage or other objects that the traveler carries with him
        and keeps under his custody, are at his exclusive account and risk.
      </p>
      <p>
        A direct air route will always be understood as one whose documentary support is a single flight coupon,
        regardless of whether the flight makes a technical stop.
      </p>
      <p>
        6.3.- Accommodation.
      </p>
      <p>
        <br/>
        6.3.1.- General.
      </p>
      <p>
        The quality and content of the services provided by the hotel will be
        determined by the official tourist category, if any, assigned by the
        competent body of its country. With those countries where there is an official classification of hotel
        establishments or any other type of accommodation, the contract (program) includes the tourist classification
        that is granted in the corresponding country. In those where there is no official classification, the category
        indicated in the program is simply the indicative one given by Trippy Trekkers.
      </p>
      <p>
        Triple or quadruple rooms or cabins are generally double rooms to which one or two beds are added, which are
        usually a sofa bed or a folding bed, except in certain establishments where instead of additional beds two
        larger beds are used.
      </p>
      <p>
        The usual schedule for entry and exit in hotels is based on the first and last service that the user is going to
        use. The room occupancy schedule depends on the rules established in each country. As a general rule and unless
        expressly agreed otherwise in the contract, the rooms can be used from 2 pm on the day of arrival and must be
        free before 12 noon on the day of departure, regardless of the time at which the arrival at the hotel is
        scheduled or the time at which the continuation of the trip is scheduled.
      </p>
      <p>
        When the contracted service does not include the permanent accompaniment of
        a guide and in the event that the user foresees his arrival at the reserved hotel or apartment on dates or hours
        different from those indicated, it is advisable, to avoid problems and misunderstandings, to communicate as far
        in advance as possible this circumstance to the organizer, or to the hotel or apartments directly, as the case
        may be. Likewise, the user must consult the organizer, at the time of making the reservation, the possibility of
        bringing animals, as they are generally not admitted in hotels and apartments. In the event that the admission
        of animals has been confirmed and it is intended to travel with them, this circumstance must be stated in the
        contract.
      </p>
      <p>
        The accommodation service will imply that the room is available on the corresponding night, it is understood to
        be provided regardless of whether, due to circumstances of the trip, the time of entry into it occurs later than
        initially planned.
      </p>
      <p>
        6.3.2.- Supplementary Services.
      </p>
      <p>
        When users request supplementary services (for example,
        room with sea view, etc.) that cannot be definitively confirmed
        by the organizer, the user may choose to definitively withdraw
        from the supplementary service requested or maintain his
        request in the hope that such services can finally be
        provided. In the event that the parties have agreed on the prior payment
        of the price of the supplementary services that cannot finally be
        provided, the amount paid will be refunded by the
        organizer immediately upon withdrawal of the service by the
        traveler or upon return from the trip, depending on whether the user has opted for the
        withdrawal in the provision of the supplementary service requested, or
        has maintained the request.
      </p>
      <p>
        6.4.- Dietary regime.
      </p>
      <p>
        The half-board regime, unless otherwise indicated, includes
        continental breakfast and dinner. As a general rule, and unless expressly indicated otherwise, these meals do
        not include drinks. Special diets (vegetarian or special regimes) are only
        guaranteed if they have been agreed upon by the parties in specific conditions.
        If due to delay, whatever the cause, any restaurant service corresponding to the contracted meal plan is lost,
        there will be no right to any refund.
      </p>
      <p>
        6.5.- Special economic conditions for children.
      </p>
      <p>
        Given the diversity of the treatment applicable to children, depending on their
        age, the service provider and the date of the trip, it is always recommended
        to consult the extent of the special conditions that exist and
        that at each moment will be subject to specific and detailed information and will
        be included in the contract or in the travel documentation provided. In
        general, as for accommodation, they will be applicable whenever the child
        shares the room with two adults.
      </p>
      <p>
        Regarding stays of minors abroad, the
        information provided punctually for each case and what may appear
        in the contract or in the travel documentation that is delivered when
        subscribing to it will be followed.
      </p>
      <p>
        In any case, children must always carry their ID or
        valid Passport. If the minor is not traveling accompanied by his guardians, he must
        also carry their legal authorization.
      </p>
      <p>
        The regulations of some airlines require all minors
        (consult minimum age to each airline) to travel accompanied by an
        adult and properly documented with ID and/or Passport. In case of
        non-compliance with this regulation, these minors will be denied
        boarding with these companies.
      </p>
      <h2>
        7.- Economic conditions.
      </h2>
      <p>
        7.1.- The price of the trip.
      </p>
      <p>
        <br/>
        The price of the trip includes:
      </p>
      <p>
        <br/>
        a) Transportation, when this service is included in the contracted
        program, with the type of transport, characteristics and category that appears in it.
      </p>
      <p>
        <br/>
        b) Accommodation, when this service is included in the contracted
        program, in the establishment and with the food regime that appears in it.
      </p>
      <p>
        <br/>
        c) Taxes or indirect taxes – e.g. Value Added Tax (V.A.T.) when they are applicable and are not directly paid by
        the
        traveler.
      </p>
      <p>
        <br/>
        d) Management expenses.
      </p>
      <p>
        <br/>
        e) All other services and supplements that are specifically included in the combined travel contract signed.
      </p>
      <p>
        7.2.- Exclusions.
      </p>
      <p>
        The price of the trip does not include everything that is not expressly
        included in the travel contract.
      </p>
      <p>
        In the case of optional excursions or visits not contracted at origin,
        it should be noted that they do not form part of the travel contract. Their
        publication in the program is merely informative and the price is
        expressed with the indication of "estimated". Therefore, at the time of
        contracting at the destination, variations may occur in their
        costs, which alter the estimated price. These excursions will be offered
        to the traveler with their specific conditions and definitive price in a
        independent way, not guaranteeing until the moment of their contracting their
        possible realization.
      </p>
      <p>
        7.3.- Price review.
      </p>
      <p>
        The price of the trip has been calculated based on the exchange rates,
        transport rates, fuel cost, taxes and fees, etc.
        applicable on the date of the program edition.
      </p>
      <p>
        The price now established may be reviewed, both upwards and downwards, when changes occur in:
      </p>
      <p>
        <br/>
        a) the price of passenger transport derived from the cost of fuel
        or other sources of energy.
      </p>
      <p>
        b) the level of taxes or fees on travel services included
        in the contract, required by third parties who are not directly
        involved in the execution of the combined trip, including taxes,
        tourist taxes and surcharges, landing and boarding or disembarking
        in ports and airports, or
      </p>
      <p>
        c) the exchange rates applicable to the combined trip.
        <br/>
        Such review may only be carried out with a notice of more than 20
        calendar days prior to the departure date of the trip. In no case will there be a review in the event that the
        traveler has already paid the total price of the trip. However, the above, and as far as the exchange rate is
        concerned, Trippy Trekkers guarantees and fixes this price, once the trip is confirmed, so that, after this
        confirmation, the price will not suffer modifications for this concept.
      </p>
      <p>
        7.4.- Agreed payment method.
      </p>
      <p>
        The parties will agree in the contract the payment method established, whether in cash, or in installments,
        although the full amount must be disbursed before the departure date. In case, of not having received the price,
        it will be understood that the traveler unilaterally withdraws from the contract with the expenses and penalties
        established in the RDL 1/2007.
      </p>
      <h2>
        8.
        Rights and duties of the parties before starting the trip.
      </h2>
      <p>
        8.1.- Modification of the contract before departure.
      </p>
      <p>
        <br/>
        8.1.1.- By Trippy Trekkers.
      </p>
      <p>
        Trippy Trekkers undertakes to provide its travelers with all the services contained in the contract, with the
        conditions and
        characteristics stipulated, although before the departure of the trip it may
        make those changes that are necessary for the good end of the trip and
        that are not substantial.
      </p>
      <p>
        However, in the event that Trippy Trekkers is
        forced to make substantial changes in the contracted services, or
        propose to increase the price by more than 8%, it will inform the
        traveler without delay, including the program modifications and their
        impact on the price.
      </p>
      <p>
        The traveler must notify Trippy Trekkers within a maximum period of 24
        hours if they accept the modifications to the program and the new price. The lack
        of notification of the decision within the indicated period, will imply the
        resolution of the contract. – CANCELLATION POLICIES
      </p>
      <p>
        If the modifications accepted by the traveler resulted in a trip of
        lower quality, they will have the right to an adequate reduction in price. In
        case of resolution by the traveler of the contract due to the modifications
        communicated by Trippy Trekkers, the former will have the right to the full
        refund of the amounts delivered to date without any
        penalty, within a maximum period of fourteen calendar days from the
        date on which the resolution occurs.
      </p>
      <p>
        8.1.2.- By the traveler.
      </p>
      <p>
        If at any time prior to the departure date, the traveler wishes
        to request changes related to the destinations, to the means of transport, to
        the duration, to the calendar, to the itinerary of the contracted trip or to
        any other aspect related to the services and the organizer can
        make them, they may require the traveler to pay the additional costs
        justified that such modification would have caused.
      </p>
      <p>
        Likewise, the traveler may transfer their reservation on the combined trip to a
        third person, as long as the latter meets all the conditions
        required for the said trip, having to communicate it in writing to the
        organizer with a minimum notice of 7 calendar days before the date of
        the start of the trip.
      </p>
      <p>
        Both the person who transfers their reservation on the trip, and the assignee,
        will be jointly liable to the organizer for the payment of the price of
        the trip, as well as any commission, surcharge or additional costs
        justified that such transfer could have caused.
      </p>
      <p>
        8.2.- Resolution of the contract before departure.
      </p>
      <p>
        8.2.1.- Resolution of the trip by the organizer.
      </p>
      <p>
        Trippy Trekkers may cancel the contract and refund the traveler the
        total amount of the payments that they have made within a maximum period of 14
        calendar days from the notification of the cancellation, but will not be
        responsible for any additional compensation if:
      </p>
      <p>
        a). The number of people registered for the combined trip is less than
        the minimum number specified in the contract and Trippy Trekkers notifies the
        traveler of the cancellation within the following periods:
      </p>
      <p>
        • 20 calendar days before the start of the combined trip (in the case of trips
        lasting more than six days).
      </p>
      <p>
        • 7 calendar days before the start of the combined trip (in the case of trips
        lasting between two and six days).
      </p>
      <p>
        • 48 hours before the start of the combined trip in the case of trips
        lasting less than two days.
      </p>
      <p>
        b). Trippy Trekkers is unable to execute the contract due to
        unavoidable and extraordinary circumstances and notifies the traveler of the cancellation
        as soon as they become aware of it.
      </p>
      <p>
        8.2.2.- Resolution of the trip by the traveler.
      </p>
      <p>
        The traveler may at any time before the start of the combined trip
        terminate it, but will not be able to claim the return of the
        amounts delivered and will continue to be obliged to pay those that were
        pending payment.
      </p>
      <p>
        The traveler will have the right to receive assistance as soon as possible,
        in cases of unavoidable and extraordinary circumstances, by providing
        information about health services, local authorities and consular assistance, and to receive help to
        establish long-distance communications and to find alternative travel
        formulas.
      </p>
      <p>
        9.2.- Obligations of the traveler.
      </p>
      <p>
        The traveler must adhere to the indications provided by Trippy
        Trekkers for the proper execution of the trip, as well as to the
        regulations that are generally applicable to users of the
        services included in the combined trip. In particular, they will observe a
        behavior that does not harm its normal development, acting according to the
        basic rules of prudence and common sense.
      </p>
      <p>
        The traveler will be obliged to communicate to Trippy Trekkers any lack of
        conformity on their part in the execution of the contract as soon as possible,
        as well as to accept the new proposals of the latter, in relation to
        such disagreement, if these are of the same quality as those included in
        the contract, or if being of lower quality, an adequate reduction in price has been offered.
      </p>
      <p>
        In any case, the traveler is obliged to take the appropriate and
        reasonable measures to try to reduce the damages that may result from the non
        execution or defective execution of the contract or to prevent them from worsening.
        The damages resulting from not having adopted such measures will be the responsibility of
        the traveler.
      </p>
      <p>
        The serious breach of these duties empowers the organizer to resolve
        the contract of the combined travel contract due to a cause attributable to the traveler,
        with the latter being responsible for the damages and losses that may have
        been caused to the organizer.
      </p>
      <p>
        9.3.- Rights of Trippy Trekkers after the trip has started.
      </p>
      <p>
        As the party responsible for the execution of the trip, Trippy Trekkers has the
        right to make the decisions it deems appropriate in the face of
        unforeseen situations that may arise during its course, such as,
        climatic changes, political or war circumstances, problems
        caused by air intermediaries, etc., establishing the necessary changes
        in it to ensure the safety of the travelers and the
        good progress of the trip.
      </p>
      <p>
        It also has the right to demand from the travelers a behavior
        appropriate and in accordance with the different regulations or customs of the
        countries included in the trip, which do not harm its realization, being able
        to give instructions in this regard.
      </p>
      <p>
        Trippy Trekkers will have the right to terminate, at any time,
        the participation of any person, whose behavior may be understood
        as dangerous for the safety of the group or well-being of the
        participants, which will not give the right to any refund. However, it will take
        the measures provided to ensure their repatriation.
      </p>
      <p>
        9.4.- Obligations of Trippy Trekkers.
      </p>
      <p>
        Trippy Trekkers is obliged to correctly execute the services
        included in the contracted trip, regardless of whether these are
        executed by it directly or other service providers, responding to
        the damages suffered by the traveler as a result of the non-execution or
        defective execution of the contract, except:
      </p>
      <p>
        1). That the defects observed in the execution of the contract are
        attributable to the traveler.
      </p>
      <p>
        2). That the aforementioned defects are due to unforeseeable or
        inevitable circumstances, understanding by these, any situation beyond the control
        of the party alleging this situation and whose consequences could not have
        been avoided, even if all reasonable measures
        had been taken.
      </p>
      <p>
        In the above cases of exclusion of liability, Trippy
        Trekkers will be obliged to provide the necessary assistance to the traveler who
        is in difficulties, unless the defects produced are
        attributable exclusively to an intentional or negligent conduct of the
        traveler.
      </p>
      <p>
        Trippy Trekkers will not be responsible for services not included in its program
        and that the traveler may contract with third parties during the course of the trip and
        on the occasion of it.
      </p>
      <p>
        Nor will it be responsible if it is the traveler who contracts independently the
        transports apart from what is provided in the program, in relation to
        any delay, cancellation, accident or incident that may occur
        in relation to such transports, regardless of the cause of the
        same without the traveler or the beneficiaries having anything to ask or
        claim for this concept.
      </p>
      <p>
        Likewise, Trippy Trekkers will not have any type of responsibility in
        relation to the fact that such eventual incidents may mean
        the impossibility of carrying out all or part of the contracted activities,
        or the need to delay them, cancel them and, in general, modify in
        any way the plan of the contracted trip, without having to return or
        pay any amount to the traveler for this fact. Finally, the traveler
        acknowledges and accepts that the insurance policies contracted by the organizer
        will not cover any eventuality that, if applicable, could take place in
        relation to the transports contracted directly by him.
      </p>
      <p>
        If any of the services included in the trip is not executed
        in accordance with the contract, Trippy Trekkers will be obliged to remedy the
        lack of conformity, unless it is impossible or it entails a disproportionate cost,
        taking into account the seriousness of the lack of conformity
        and the value of the affected travel services.
      </p>
      <p>
        When Trippy Trekkers cannot provide a significant proportion of the
        contracted travel services, it will offer, at no additional cost to the
        traveler, suitable alternative formulas, if possible of equivalent quality or higher than those specified in the
        contract, for the
        continuation of the combined trip. If Trippy Trekkers does not offer such
        alternative formulas and the trip includes transportation, it will repatriate the
        traveler in an equivalent transport without undue delays and at no additional
        cost.
      </p>
      <h2>
        10.
        Limits of Trippy Trekkers' liability.
      </h2>
      <p>
        Trippy Trekkers will apply the limitations that, in relation to the scope or
        the conditions of the payment of compensations by part of travel service providers included in a combined trip,
        establish the
        international conventions that bind the European Union.
      </p>
      <p>
        In other cases, Trippy Trekkers may limit in the contracts it
        signs the compensation it must pay to the traveler as long as that
        limitation does not apply to bodily injuries or damages caused intentionally, or by negligence and that its
        amount is not less than three times the total price of the trip.
      </p>
      <h2>
        11.- Passports, visas and vaccines.
      </h2>
      <p>
        Trippy Trekkers undertakes to inform the traveler about the necessary
        health formalities for the trip and stay, as well as about the
        conditions applicable in terms of passports and visas, responding to
        the correctness of the information it provides.
      </p>
      <p>
        The traveler must obtain the necessary documentation to carry out the
        trip, including passport, visas and that referred to health
        formalities. The traveler must ensure before starting the trip that they have
        complied with all the rules and requirements applicable in terms of visas in
        order to be able to enter without problems in all the countries that are going to
        be visited. Minors under 18 must carry a written permission signed
        by their parents or guardians, in anticipation that it may be requested
        by any authority. All damages that may result from the lack of this documentation will be their responsibility
        and in particular, the expenses
        produced by the interruption of the trip and its possible repatriation.
      </p>
      <p>
        If the organizer accepts the traveler's assignment to process the visas
        necessary for any of the destinations provided in the itinerary, it may
        require the payment of the visa cost, as well as the management expenses for
        the procedures it must carry out before the corresponding diplomatic or
        consular representation. In this case, the organizer will be responsible for the
        damages that are attributable to it according to the diligence normally
        required for the delays in obtaining the necessary documentation or for lack or insufficiency of it, that make
        the trip impossible.
      </p>
      <h2>
        12. Risks.
      </h2>
      <p>
        In some cases, Trippy Trekkers offers expeditionary trips outside of
        traditional tourist routes, in close contact with nature.
        Consequently, the trip that is contracted may have a higher risk than
        usual, a risk that the recipient who contracts it is fully
        aware of, assuming the responsibility that derives from it and declaring
        expressly that the risk is precisely one of the causes that influence
        decisively in its contracting.
      </p>
      <h2>
        13.- Insurance.
      </h2>
      <p>
        Trippy Trekkers has subscribed for all its trips a Basic Travel
        Assistance Insurance that covers the traveler, and will inform the traveler of the
        possibility of contracting an optional insurance that covers the
        costs incurred in case they decide to terminate the contract, or the costs
        of assistance, including repatriation, in case of accident,
        illness or death.
      </p>
      <p>
        The insurances, once contracted, are not refundable.
      </p>
      <p>

      </p>
      <h2>
        14.- Claims, legal actions and prescription.
      </h2>
      <p>
        In case of controversy regarding the interpretation or application of these
        conditions or of a traveler's claim, Trippy Trekkers, S.L,
        is not and does not adhere to any mediation or arbitration system (Consumer
        Arbitration Board, transport, etc.), expressing the waiver to such
        conflict resolution systems, except by express agreement to the contrary, and
        submitting the parties, with express waiver of any other jurisdiction that
        could correspond to them, to the jurisdiction of the Courts and Tribunals of
        the city corresponding to the registered office of Trippy Trekkers, S.L,
        except in those cases where another jurisdiction is imperatively established by law.
      </p>
      <p>
        Legal actions derived from the combined travel contract
        prescribe by the lapse of two years, a period that will begin
        the day of the contract celebration. In any case, and with full respect to what
        is provided in the preceding section, Trippy Trekkers makes available to
        travelers the following information so that they can file their complaints
        and claims or request information about the contracted trip: TRIPPY
        TREKKERS, S.L., with registered office at Camino de Hoyarrasa 106,
        Alcobendas, Madrid, (Spain) and C.I.C.M.A nº XXXX, phone: XXXXX and email: <a
        href="mailto:comercial@nuba.net">XXXXXX</a>
      </p>
      <h2>
        15.- Protection of Personal Data.
      </h2>
      <p>

      </p>
      <p>
        In compliance with Organic Law 3/2018, of December 5, on Protection
        of Personal Data and guarantee of digital rights and the
        European Regulation 2016/679, relating to the protection of individuals with regard to
        the processing of personal data and the free movement of
        these data, Trippy Trekkers and the traveler commit to respect and
        comply with the current regulations in this matter.
      </p>
      <p>

      </p>
      <p>
        With the adherence to these General Conditions and the acceptance of
        the Particular Conditions, express consent is given for the
        personal data provided to become part of an automated file
        of personal data, for which the entity TRIPPY TREKKERS S.L., with registered office at Camino de Hoyarrasa 106,
        Alcobendas, Madrid, is responsible.
      </p>
      <p>
        The purposes of the aforementioned file are to manage the reservation properly and
        the adequate provision of the contracted services, as well as the
        international transfer of such data to organizing companies and
        providers of the aforementioned services, as well as being able to send
        information about products, services, offers and promotions related
        both to the program and to actions linked to the activity of TRIPPY
        TREKKERS, S.L, in the field of tourism, hospitality, catering and
        travel.
      </p>
      <p>
        The data will only be transferred, solely and exclusively, to third parties who
        intervene in a necessary way in the provision of services linked to the
        object of the contracted trip and with identical purposes to the previous ones,
        with safeguard of the duty of secrecy and application of the measures of
        security required by current regulations.
      </p>
      <p>
        The rights of access, rectification, cancellation or
        opposition to its processing can be exercised, by means of a written document accompanied by a copy of the
        current National Identity Document or passport and sent by mail
        to the previous address or by email<i> </i> xxxxx or to the
        attention of the Data Protection Officer.
      </p>
      <h2>
        16. Validity.
      </h2>
      <p>
        These Conditions and General Provisions will remain in force until
        they are replaced by new ones.
      </p>
    </>
  );
};


export default CondicionesGeneralesEN;