import {SearchHotelsRoomsFormValues} from "../../Main/Hotels/models";
import {useFieldArray, useWatch} from "react-hook-form";
import {t} from "i18next";
import {HotelPassengerNumberCounter} from "./components/HotelPassengerNumberCounter";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {Popover} from "@mantine/core";
import {ReactComponent as PersonSVG} from "../../../resources/my_profile/person.svg";
import s from './HotelPassengerNumber.module.css'
import {HotelPassengerNumberChildrenAges} from "./components/HotelPassengerNumberChildrenAges";
import {MdOutlineDelete} from "react-icons/md";
import {ReactComponent as NPlusSVG} from "../../../resources/svg/n_plus.svg";
import cn from "classnames";

interface HotelPassengerNumberInputProps {
  defaultValue?: SearchHotelsRoomsFormValues[]
  name: string
  className?: string
  withBorder?: boolean
  popOverStyle?: React.CSSProperties
}

export function HotelPassengerNumberInput({
                                            name,
                                            withBorder,
                                            popOverStyle,
                                            defaultValue
                                          }: HotelPassengerNumberInputProps) {
  const [isActive, setActive] = useState(false);
  const node = useRef<HTMLDivElement>(null);
  const input = useRef<HTMLInputElement>(null);

  const {fields, append, remove} = useFieldArray({
    name
  })

  const value = useWatch({
    name
  })

  const inputValue = useMemo(() => {
    if (!value) return ''
    const data = value as SearchHotelsRoomsFormValues[]
    const adults = data.reduce((acc, item) => acc + item.adults, 0)
    const children = data.reduce((acc, item) => acc + item.children, 0)
    const rooms = data.length
    let text = `${adults} ${t('adults', {count: adults})},`;
    if (children) {
      text += ` ${children} ${t('children', {count: children})},`
    }
    text += ` ${rooms} ${t('rooms', {count: rooms})}`
    return text
  }, [value])

  const onContainerClick = (e: any) => {
    e.preventDefault();
    setActive(prev => !prev);
  }

  useEffect(() => {
    const current = node.current;
    //document.body.addEventListener('click', listenerHide);
    if (current) current.addEventListener('click', onContainerClick);
    return () => {
      //document.body.removeEventListener('click', listenerHide);
      if (current) current.removeEventListener('click', onContainerClick);
    }
  }, []);


  useEffect(() => {
    const valueToSet = defaultValue ?? [{adults: 1, children: 0, childrenAge: []}]
    valueToSet.forEach((value) => {
      append(value)
    })
  }, [defaultValue]);

  const onRemove = (index: number) => {
    if (fields.length > 1) {
      remove(index)
    }
  }

  return <Popover
    opened={isActive}
    onClose={() => setActive(false)}
    // @ts-ignore
    position="bottom-start"
    width={node?.current?.offsetWidth}
    className="w-full"
    // style={{minWidth: 300}}
    style={popOverStyle}
    target={<div ref={node} className={cn(s.inputContainer, {[s.inputContainerBorder]: withBorder})}>
      <div className={s.icon}>
        <PersonSVG style={{fill: '#979797'}}/>
      </div>
      <input ref={input} id={name} className={s.input} type="text"
             readOnly name={name} value={inputValue}/>
    </div>}
  >
    <div className={s.countersContainer}>
      {fields.map((item, index) => (
        <div key={item.id} className="mb-5">
          <div className={s.roomTitle}>
            <div>{t('room')} {index + 1}</div>
            <MdOutlineDelete className="cursor-pointer" onClick={() => onRemove(index)} size={20}/>
          </div>
          <HotelPassengerNumberCounter name={`${name}.${index}.adults`} text={t('adults')}/>
          <HotelPassengerNumberCounter name={`${name}.${index}.children`} text={t('children')}/>
          {/*@ts-ignore*/}
          {value?.[index]?.children > 0 &&
            <HotelPassengerNumberChildrenAges children={value?.[index]?.children}
                                              name={`${name}.${index}.childrenAge`}/>}
        </div>
      ))}
    </div>
    <div>
      <div className={s.moreRooms}
           onClick={() => append({adults: 1, children: 0, childrenAge: []})}>
        <NPlusSVG className="mr-5" style={{fill: '#ccc'}}/>
        {t('more rooms')}
      </div>
    </div>
  </Popover>
}

export default HotelPassengerNumberInput