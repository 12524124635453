import React from 'react';

const PoliticasCookiesEN = props => {
  return (
    <>
      <h1>Cookie Policy</h1>
      <p>This website uses its own and third-party cookies to offer you a better experience and service. By browsing or
        using our services, you accept the use we make of cookies. However, you have the option to prevent the
        generation of cookies and the deletion of them by selecting the corresponding option in your Browser. In case of
        blocking the use of cookies in your browser, it is possible that some services or functionalities of the web
        page are not available.</p>
      <h2>What is a cookie?</h2>
      <p>Cookies are a tool used by web servers to store and retrieve information about their visitors. It is a unique
        identifier in the form of a text file that is sent to the user's device to record information, thus improving
        the quality and security of the website. They have an expiration date from which they cease to be
        operational.</p>
      <h2>Utility of cookies on my website</h2>
      <p>We use cookies to facilitate navigation on the web and to obtain greater efficiency and personalization of the
        services we offer. The cookies used are associated only with an anonymous User and his device, they do not
        provide references that allow obtaining his personal data nor include viruses in it. Nor can the cookies
        installed on your device from other servers be read. The information that we analyze using cookies is the
        internet protocol address (IP) used to connect your computer to the internet, type and version of browser,
        operating system and platform used for the connection and the date and time of such activity.</p>
      <h2>Cookies used</h2>
      <p>We use permanent cookies that are stored on the user's device and that allow retrieving navigation information
        on the next visit. They have an expiration date. These cookies allow us to personalize your visits, through your
        preferences (for example, country and language).</p>
      <p>We also use third-party cookies that allow managing and improving the services offered. Such as, Stripe
        services.</p>
      <p>
        <bold>Cookies</bold>
      </p>
      <ol>
        <li>Language</li>
        <li>Session Cookie</li>
        <li>Related to social networks</li>
      </ol>
      <p>
        <bold>LocalStorage</bold>
      </p>
      <ol>
        <li>Types of hotels for filters</li>
        <li>Exchange rate</li>
        <li>Shopping cart</li>
        <li>Data about the user who has logged in</li>
        <li>Chosen language</li>
        <li>Data to improve accessibility</li>
        <li>Other data to identify errors on the page. These do not identify the user but are necessary for the product
          improvement.
        </li>
      </ol>

      <h2>Third-party cookies</h2>
      <ol>
        <li>Stripe <a href="https://stripe.com/es/legal/cookies-policy"
                      target="_blank" rel="noreferrer">https://stripe.com/es/legal/cookies-policy</a></li>
        <li>Facebook <a href="https://www.facebook.com/policies/cookies/"
                        target="_blank" rel="noreferrer">https://www.facebook.com/policies/cookies/</a></li>
        <li>Instagram <a href="https://help.instagram.com/1896641480634370?ref=ig"
                         target="_blank" rel="noreferrer">https://help.instagram.com/1896641480634370?ref=ig</a></li>
        <li>YouTube <a href="https://policies.google.com/privacy?hl=es-419&gl=mx"
                       target="_blank" rel="noreferrer">https://policies.google.com/privacy?hl=es-419&gl=mx</a></li>
      </ol>

      <h2>What happens if cookies are disabled?</h2>
      <p>In the case of blocking or not accepting the installation of cookies, it is possible that certain services
        offered by our website that need their use are disabled and, therefore, are not available to you so you will not
        be able to take full advantage of everything that our websites and applications offer you. It is also possible
        that the quality of operation of the website may decrease.</p>
      <p>To allow, know, block or delete the cookies installed on your computer you can do so by configuring the options
        of the browser installed on your computer.</p>
      <p>For example, you can see how to do it in the aids provided by each browser depending on which one you use: </p>
      <ol>
        <li><a target="_blank"
               href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we" rel="noreferrer">Firefox</a>
        </li>
        <li><a target="_blank" href="http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647" rel="noreferrer">Chrome</a></li>
        <li><a target="_blank"
               href="http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9" rel="noreferrer">Explorer</a>
        </li>
        <li><a target="_blank" href="http://support.apple.com/kb/ph5042" rel="noreferrer">Safari</a></li>
        <li><a target="_blank" href="http://help.opera.com/Windows/11.50/es-ES/cookies.html" rel="noreferrer">Opera</a></li>
      </ol>
    </>
  );
};


export default PoliticasCookiesEN;