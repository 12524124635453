import React, {useMemo, useState} from "react";
import {ActivitiesContext} from "./ActivitiesContext";
import {useForm} from 'react-hook-form'
import {useUtil} from "../../hooks/useUtil";
import useActivity from "../../controllers/useActivity/useActivity";


const ActivitiesProvider = props => {

  const {activities_paramsToObj} = useUtil();
  const {searchActivities} = useActivity();

  // loading values
  /// false === nothing loading
  // 1 === only filters
  // 2 === only hotels
  // 3 === all
  const [loading, setLoading] = useState(false);
  const [allowedFilters, setAllowedFilters] = useState();
  const [pagination, setPagination] = useState({
    page: 1,
    itemsPerPage: 10,
    total: 0
  });

  const [activities, setActivities] = useState([]);

  const filterForm = useForm();


  const updateActivities = async ({loading = true, updateFilters = true, loadMore = false} = {}) => {
    if (loading) setLoading(updateFilters ? 2 : 3);

    let body = activities_paramsToObj();
    /*    let body = {
          from: params.from,
          to: params.to,
          destination: {
            lat: params.lat,
            lng: params.lng,
          },
          passengers: {
            adults: params.adults,
            children: params.children
          }
        };*/

    let searchFilters = false;
    const selectedFilters = filterForm.getValues();

    if (Object.keys(selectedFilters).length > 0 && allowedFilters) {
      searchFilters = {
        minRate: selectedFilters.activity_range_price[0],
        maxRate: selectedFilters.activity_range_price[1],
        categories: Object.keys(selectedFilters.categories).filter(code => selectedFilters.categories[code])
      };

      // si estamos en maximo y minimo de los precios anteriores no filtrar por precio para conseguir mas resultados
      if (allowedFilters.minRate === searchFilters.minRate) delete searchFilters.minRate;
      if (allowedFilters.maxRate === searchFilters.maxRate) delete searchFilters.maxRate;
    }

    let page = pagination.page;
    if (loadMore) page++;


    let {error, activities, filters, total, itemsPerPage} = await searchActivities({
      body,
      filters: searchFilters,
      page
    });

    if (error) return {error};


    // for now update filters only first time
    if (!allowedFilters && updateFilters) {
      setAllowedFilters(filters);
    }

    if (loadMore) {
      setActivities(prev => [...prev, ...activities]);
      setPagination({page, total, itemsPerPage});
    } else {
      setPagination({page: 1, total, itemsPerPage});
      setActivities(activities);
    }

    if (loading) setLoading(false);
  }


  const canLoadMore = useMemo(() => {
    if (!Array.isArray(activities) || activities.length === 0) return false;
    return activities.length < pagination.total;
  }, [activities, pagination]);


  return <ActivitiesContext.Provider value={{
    loading, setLoading,
    allowedFilters, setAllowedFilters,
    pagination, setPagination,
    filterForm,
    activities, setActivities,
    updateActivities,
    canLoadMore
  }}>
    {props.children}
  </ActivitiesContext.Provider>
}

export default ActivitiesProvider;
