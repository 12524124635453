import {useEffect, useMemo} from "react";
import dayjs from "dayjs";
import {useLocalStorage} from "@mantine/hooks";
import superjson from 'superjson';


// default expire time is 30 minutes
// expire check is the time for the next expire check in seconds
export const useCache = ({key, expire = 1800, isExpired: customIsExpired = false, defaultValue = {}, expireCheck = 60}) => {
	const [cache, setCache] = useLocalStorage({
		key,
		defaultValue: {__t: new Date(), value: defaultValue},
		serialize: superjson.stringify,
		deserialize: (str) => (str === undefined ? {__t: new Date(), value: defaultValue} : superjson.parse(str))
	});

	const setValue = newValue => {
		if(typeof newValue === 'function'){
			setCache(prev => ({
				__t: new Date(),
				value: typeof newValue === 'function' ? newValue(prev?.value) : newValue
			}));
		}else {
			setCache({
				value: newValue,
				__t: new Date()
			});
		}
	}

	const isExpired = () => {
		if(customIsExpired) return customIsExpired(cache, setCache);
		if(!cache.__t) return true;
		return dayjs().diff(dayjs(cache.__t), 'second') > expire;
	}
	const clearCache = () => window.localStorage.removeItem(key);



	useEffect(() => {
		let interval = setInterval(() => {
			if(isExpired())clearCache();
		}, expireCheck * 1000);
		return () => clearInterval(interval);
	}, []);

	const value = useMemo(() => {
		if(isExpired())return defaultValue;
		return cache?.value === undefined ? defaultValue : cache?.value;
	}, [cache]);


	return [value, setValue, {isExpired, clearCache}];
}