import React from 'react';
import s from './Title.module.css';

const Title = ({children, className = ''}) => {
  const _className = `${s.title} ${className}`;
  return (
    <div className={_className}>{children}</div>
  );
};


export default Title;
