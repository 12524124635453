import s from './SearchHotelsLocation.module.css';
import {useTranslation} from "react-i18next";
import React, {useEffect, useRef, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import LocationInput from "../../../Inputs/LocationInput";
import DatePickerWithOutsideLabel from "../../../Inputs/DatePickerWithOutsideLabel";
import InputWithOutsideLabel from "../../../Inputs/InputWithOutsideLabel";
import {AiOutlineSearch} from 'react-icons/ai';
import {SearchHotelsFormValues} from "../../../Main/Hotels/models";
import {SearchHotelURLParams} from "../../../../types/hotels/hotels";
import {NumberOfNights} from "./NumberOfNights/NumberOfNights";
import {loadDefaultData, useUtilFunctions} from "./util";
import {Loader} from '@mantine/core';
import moment from "moment";
import HotelPassengerNumberInput from "../../../Inputs/HotelPassengerNumber/HotelPassengerNumberV2";

export enum SearchHotelsLocationRenderStyle {
  horizontal, vertical
}

interface SearchHotelsLocationProps {
  searchParams: SearchHotelURLParams;
  onSearch: (params: SearchHotelURLParams, rawData: SearchHotelsFormValues) => Promise<void>;
  renderStyle?: SearchHotelsLocationRenderStyle;
  hotelPassengerNumberContainerClassName?: string;
  withBorder?: boolean;
  smallComponents?: boolean;
  disableLocation?: boolean;
  showNumberOfNights?: boolean;
}

const SearchHotelsLocationForHotelDetails = ({
                                               searchParams,
                                               showNumberOfNights,
                                               onSearch,
                                               withBorder = true,
                                               disableLocation,
                                               smallComponents = false,
                                               renderStyle = SearchHotelsLocationRenderStyle.vertical,
                                               hotelPassengerNumberContainerClassName
                                             }: SearchHotelsLocationProps) => {
  const {t} = useTranslation();
  const [focusSecondDate, setFocusSecondDate] = useState(false);

  const form = useForm();
  const parentRef = useRef<HTMLDivElement>(null);

  const hotel_destination = form.watch('hotel_destination');

  const {
    isOutsideRange,
    onSubmit,
    onArriveDateChange,
    isDayHighlighted,
    isLoading,
    isOutsideRangeStart
  } = useUtilFunctions({
    form,
    setFocusSecondDate,
    onSearch
  });

  useEffect(() => {
    loadDefaultData({form, searchParams});
  }, []);

  const isLocationInputVisible = () => !!hotel_destination;


  const isVertical = renderStyle === SearchHotelsLocationRenderStyle.vertical;

  // @ts-ignore
  return <FormProvider {...form}>
    <div className={`${isVertical ? s.containerVertical : s.containerHorizontal}`} ref={parentRef}>
      {isLocationInputVisible() && <div className="w-full lg:w-auto">
        <LocationInput
          // @ts-ignore
          Input={InputWithOutsideLabel}
          withBorder={withBorder}
          label={null}
          id="hotel_search_page_destination"
          name="hotel_destination"
          small={smallComponents}
          disabled={disableLocation}
        />
      </div>}
      <div className="w-full lg:w-auto flex gap-x-5 flex-nowrap lg:flex-nowrap">
        <div className="w-full">
          <DatePickerWithOutsideLabel
            label={undefined}
            withBorder={withBorder}
            placeholder={t('date')}
            name="arrive_date"
            id="hotel_page_arrive_date"
            onChange={onArriveDateChange}
            small={smallComponents}
            isDayHighlighted={isDayHighlighted}
            calendarInfo={t("input.arrive_date")}
            isOutsideRange={isOutsideRangeStart}
          />
        </div>
        <div className="w-full">
          <DatePickerWithOutsideLabel
            label={undefined}
            withBorder={withBorder}
            placeholder={t('date')}
            name="departure_date"
            id="hotel_page_departure_date"
            onChange={() => setFocusSecondDate(false)}
            isOutsideRange={isOutsideRange}
            externalFocused={focusSecondDate}
            small={smallComponents}
            isDayHighlighted={isDayHighlighted}
            calendarInfo={t("input.departure_date")}
            initialVisibleMonth={() => {
              return moment(form.getValues('arrive_date')).isValid() ? moment(form.getValues('arrive_date')) : undefined
            }}
          />
        </div>
      </div>
      <div className="w-full lg:w-auto flex gap-x-5">
        {showNumberOfNights && <NumberOfNights/>}
        <div className="w-full">
          <HotelPassengerNumberInput
            withBorder={withBorder}
            name="rooms"
            popOverStyle={{minWidth: 300}}
          />
        </div>
      </div>
      <div className={s.searchButton} onClick={() => onSubmit(form.getValues() as SearchHotelsFormValues)}>
        {!isLoading ? <>
          <AiOutlineSearch size={19} fill={'#1C7263'}/>
          <div className="ml-5">{t('search')}</div>
        </> : <Loader color="teal" size="xs"/>}
      </div>
    </div>
  </FormProvider>

}

export default SearchHotelsLocationForHotelDetails;