import React from 'react';
import s from './SelectWithLabel.module.css';
import ReactSelect, {components as componentsSelect} from 'react-select';
import {Controller} from "react-hook-form";
import {useTranslation} from "react-i18next";


const styles = {
  control: (provided, state) => ({
    ...provided,
    border: 'none !important',
    boxShadow: 'none !important',
    "&:hover": {
      borderColor: 'transparent !important',
      boxShadow: 'none !important'
    },
    "&:active": {
      borderColor: 'transparent',
      boxShadow: 'none'
    }
  }), indicatorSeparator: prev => ({
    ...prev,
    background: 'transparent'
  }),
  indicatorsContainer: prev => ({
    ...prev,
    fill: '#000 !important'
  }),
  menuPortal: base => ({...base, zIndex: 9999})
};

const SelectWithLabel = ({
                           name,
                           label,
                           id,
                           icon,
                           withAsterisk = false,
                           iconRightPadding = 29,
                           components,
                           options,
                           containerClassName,
                           usePortal = true,
                           placeholder,
                           onChange: _onChange,
                           defaultValue,
                           second_style = false,
                           ...props
                         }) => {
  const {t} = useTranslation();

  const NoOptionsMessage = props => {
    return <componentsSelect.NoOptionsMessage {...props}>No encontrado</componentsSelect.NoOptionsMessage>
  }

  let _props = {};

  if (usePortal) _props.menuPortalTarget = document.body;

  return (
    <Controller
      name={name}
      defaultValue={defaultValue || options[0]?.value}
      render={({field: {onChange, value, ref}}) =>
        <div className={`${s.container} ${second_style ? s.second_style : ''} ${containerClassName}`}>
          {icon && (<div  className={s.icon}>
            {icon}
          </div>)}
          <div style={{paddingLeft: icon ? (iconRightPadding + 'px') : 0}}>
            <ReactSelect
            portal={usePortal}
            inputRef={ref}
            inputProps={{id}}
            components={{...components, NoOptionsMessage}}
            value={options.find(c => c.value === value) || options[0]}
            onChange={val => {
              onChange(val.value);
              _onChange && _onChange(val.value);
            }}
            options={options}
            placeholder={placeholder || t('select')}
            styles={styles}
            {..._props}
            menuPortalTarget={document.body}
            {...props}
          />
          </div>
          {label && <label htmlFor={id}>{label}</label>}
        </div>
      }/>

  );
};


export default SelectWithLabel;
