import React from 'react';
import s from './Input.module.css';

const Input = React.forwardRef(
  ({
     containerClassName = '',
     type = 'text',
     icons,
     name,
     label,
     ...props
   }, ref) => {
    const iconLeftClass = `${s.icon} ${icons?.left?.onClick ? 'cursor-pointer' : ''}`;
    const iconRightClass = `${s.icon} ${icons?.right?.onClick ? 'cursor-pointer' : ''}`;

    const onIconClick = (e, icon) => {
      if (icons[icon]?.onClick) {
        icons[icon].onClick(e);
      }
    }


    return (
      <div className={`w-full ${containerClassName}`}>
        {label && <label className={s.label} htmlFor={name}>{label}</label>}
        <div className={s.container}>
          {icons?.left &&
            <div className={iconLeftClass} onClick={(e) => onIconClick(e, 'left')}>{icons?.left?.icon}</div>}
          <input ref={ref} type={type} name={name} {...props} />
          {icons?.right &&
            <div className={iconRightClass} onClick={(e) => onIconClick(e, 'right')}>{icons?.right?.icon}</div>}
        </div>
      </div>
    );
  });


export default Input;
