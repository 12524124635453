import React, {useEffect, useState} from 'react';
import s from './MyProfileActivities.module.css';
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import {useHotel} from "../../../controllers/useHotel/useHotel";
import sc from "../MyProfileCommon.module.css";
import {ReactComponent as SearchSVG} from "../../../resources/svg/serach.svg";
import Loader from "../../Loader";
import PurchasedActivity from "../MyProfileActivities/PurchasedActivity";
import NothingFoundContent from "../NothingFoundContent";
import {ReactComponent as BedSVG} from "../../../resources/svg/bed.svg";
import useActivity from "../../../controllers/useActivity/useActivity";

const MyProfileActivities = props => {
  const {t} = useTranslation();

  const [futureActivities, setFutureActivities] = useState();
  const [pastActivities, setPastActivities] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const form = useForm();
  const {handleSubmit, register} = form;

  const {myProfilePastActivities, myProfileFutureActivities} = useActivity();

  const stopWithError = error => {
    setLoading(false);
    setError(error);
  }

  const loadActivities = async () => {
    setLoading(true);
    const {error, activities: pastActivities} = await myProfilePastActivities();
    if (error) return stopWithError(error);
    if (Array.isArray(pastActivities)) setPastActivities(pastActivities);

    const {error: error2, activities: futureActivities} = await myProfileFutureActivities();
    if (error2) return stopWithError(error2);
    if (Array.isArray(futureActivities)) setFutureActivities(futureActivities);
    setLoading(false);
  }

  useEffect(() => {
    loadActivities();
  }, []);


  const onSearch = data => {
    console.log(data);
  }


  return (
    <div className="w-full">
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSearch)}>
          <div className={sc.headerContainer}>
            <h3 className={sc.headerTitle}>{t('my profile activities.future activities')}</h3>
            <div className={sc.searchBarContainer}>
              <input
                {...register('search')}
                placeholder={t('my profile activities.search bar text') + '...'}
                spellCheck="false"
              />
              <button>
                <SearchSVG/>
              </button>
            </div>
          </div>
        </form>
      </FormProvider>

      {loading ? <Loader/> : <div>
        {Array.isArray(futureActivities) && futureActivities.length > 0 ? futureActivities.map(activity => <div
            className="my-5">
            <PurchasedActivity activity={activity} key={activity.activityCode}/>
          </div>)
          : <NothingFoundContent
            icon={<BedSVG width={50} height={50} style={{fill: '#757575'}}/>}
            text={t('my profile activities.no future activities')}
            containerClassName="my-10"
          />
        }

        <h3 className={sc.headerTitle}>{t('my profile activities.past activities')}</h3>
        {Array.isArray(pastActivities) && pastActivities.length > 0 ? pastActivities.map(activity => <div
            className="my-5">
            <PurchasedActivity activity={activity} key={activity.activityCode}/>
          </div>)
          : <NothingFoundContent
            icon={<BedSVG width={50} height={50} style={{fill: '#757575'}}/>}
            text={t('my profile activities.no past activities')}
            containerClassName="my-10"
          />
        }
      </div>}

    </div>
  );
};


export default MyProfileActivities;
